import React, { useEffect } from "react";

export default function Test() {
  useEffect(() => {
    (async () => {
      let test1 = await fetch("C:/Users", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(test1);
    })();
  }, []);

  return <div>Test</div>;
}
