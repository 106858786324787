import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { checkPermission, getStampTime, getGroups } from "../js/main";
import Grid from "@mui/material/Grid";

import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function PP7E(props) {
  const { id } = useParams();
  const [student, setStudent] = useState();
  const [state, setState] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile && props.page && id) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setState({
            profile: props.profile,
            id: id,
            current_date: await getCurrentDate(),
          });
        }
      }
    })();
  }, [props, id]);
  useEffect(() => {
    (async () => {
      if (state) {
        let Student = await axios.get(
          Conf.endpoint.tonkla + "/reg/query/students/" + state.id,
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        if (Student.data && Student.data._id) {
          let Curators = await axios.post(
            Conf.endpoint.tonkla + "/reg/query",
            {
              collection: "curators",
              match: { student_id: Student.data._id },
            },
            {
              headers: {
                Authorization: cookie.access_token,
              },
            }
          );
          Student.data.father_name = await getCuratorName(
            Curators.data.result,
            "father"
          );
          Student.data.mother_name = await getCuratorName(
            Curators.data.result,
            "mother"
          );
          if (Student.data.current_group) {
            let group = await getGroups();
            let i = group.groups.findIndex(
              (j) => j._id === Student.data.current_group
            );
            if (i > -1) {
              let level = group.groups[i].level;
              let year = group.groups[i].level_year;
              if (year) level += "ปีที่ " + year;
              Student.data.level_text = level;
            }

            let dt = await getStampTime(Student.data.birth_date);
            Student.data.birth_date_text =
              dt.detail.day +
              " " +
              dt.detail.month_th +
              " " +
              (dt.detail.year + 543);
          }
          setStudent(Student.data);
        }
      }
    })();
  }, [state]);
  useEffect(() => {
    if (student) {
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }, [student]);

  function getCuratorName(curators, type) {
    return new Promise((resolve) => {
      let curator = curators.filter((j) => j.curator_type === type);
      if (curator[0]) {
        let name =
          curator[0].prefix +
          " " +
          curator[0].first_name +
          " " +
          (curator[0].middle_name || "") +
          " " +
          curator[0].last_name;
        resolve(name);
      } else resolve("");
    });
  }
  async function getCurrentDate() {
    return new Promise(async (resolve) => {
      let dt = await getStampTime();
      resolve(
        dt.detail.day + " " + dt.detail.month_th + " " + (dt.detail.year + 543)
      );
    });
  }
  const styles = {
    divValue: {
      float: "left",
      borderBottom: "1px dotted #000",
      margin: "0cm .1cm 0cm .1cm",
      height: ".8cm",
    },
  };
  return (
    <div
      style={{
        width: "21cm",
        height: "29.7cm",
        margin: "auto",
        padding: "2cm 2.5cm",
        fontSize: "16pt",
      }}
    >
      {student && (
        <>
          <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4} align="center">
              <img
                src={process.env.PUBLIC_URL + "/asset/img/printlogo.png"}
                style={{ width: "5cm" }}
                alt=""
              />
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: "1cm" }} align="right">
              ปพ.7
              <br />
              <br />
              <Grid container style={{ width: "5.5cm" }}>
                <Grid item style={{ paddingRight: ".2cm" }}>
                  เลขที่
                </Grid>
                <Grid
                  item
                  style={{ width: "4cm", borderBottom: "1px dotted #000" }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <div
                style={{ fontSize: "20pt", marginTop: ".5cm" }}
                className="bold"
              >
                ใบรับรองผลการศึกษา
              </div>
              <div
                style={{
                  fontSize: "12pt",
                  lineHeight: "20pt",
                  marginTop: ".5cm",
                  marginBottom: ".5cm",
                }}
              >
                โรงเรียนต้นกล้า 292 ม.6 ถ.เชียงใหม่–แม่โจ้ ต.หนองจ๊อม อ.สันทราย
                จ.เชียงใหม่ 50210
                <br />
                โทร 0-5334-5912 โทรสาร 0-5334-5913
              </div>
              <hr
                style={{
                  width: "10cm",
                  background: "#000",
                  border: "1px solid #000",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "2.5cm",
                paddingTop: "1cm",
                height: "2.2cm",
              }}
            >
              <div style={{ float: "left" }}>โรงเรียน</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "4cm",
                    textAlign: "center",
                  },
                }}
              >
                ต้นกล้า
              </div>
              <div style={{ float: "left" }}>อำเภอ</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "4.5cm",
                    textAlign: "center",
                  },
                }}
              >
                สันทราย
              </div>
              <div style={{ float: "left" }}>จังหวัด</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "4.5cm",
                    textAlign: "center",
                  },
                }}
              >
                เชียงใหม่
              </div>
            </Grid>
            <Grid item xs={12} style={{ height: "1.3cm" }}>
              <div style={{ float: "left" }}>ขอรับรองว่า</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "10cm",
                    textAlign: "center",
                  },
                }}
              >
                {student.prefix} {student.first_name} {student.middle_name}{" "}
                {student.last_name}
              </div>
              <div style={{ float: "left" }}>เลขประจำตัวนักเรียน</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "3cm",
                    textAlign: "center",
                  },
                }}
              >
                {student.student_code}
              </div>
            </Grid>
            <Grid item xs={12} style={{ height: "1.3cm" }}>
              <div style={{ float: "left" }}>เกิดวันที่</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "8cm",
                    textAlign: "center",
                  },
                }}
              >
                {student.birth_date_text}
              </div>
              <div style={{ float: "left" }}>เลขประจำตัวประชาชน</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "5.5cm",
                    textAlign: "center",
                  },
                }}
              >
                {student.idx}
              </div>
            </Grid>
            <Grid item xs={12} style={{ height: "1.3cm" }}>
              <div style={{ float: "left" }}>บิดาชื่อ</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "8.4cm",
                    textAlign: "center",
                  },
                }}
              >
                {student.father_name}
              </div>
              <div style={{ float: "left" }}>มารดาชื่อ</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "8.4cm",
                    textAlign: "center",
                  },
                }}
              >
                {student.mother_name}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "2.5cm",
                marginTop: ".3cm",
                height: "1.3cm",
              }}
            >
              <div style={{ float: "left" }}>มีสภาพทางการเรียน</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "13.7cm",
                    textAlign: "center",
                  },
                }}
              >
                ศึกษาอยู่ในระดับชั้น{student.level_text}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "2.5cm", height: "1.3cm" }}
            >
              <div style={{ float: "left" }}>ความประพฤติ</div>
              <div
                style={{
                  ...styles.divValue,
                  ...{
                    width: "15cm",
                    textAlign: "center",
                  },
                }}
              >
                เรียบร้อยดี
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "5cm", marginTop: ".5cm", height: "1.3cm" }}
            >
              ออกให้ ณ วันที่ {state.current_date}
            </Grid>

            <Grid item xs={2} style={{ paddingTop: "4cm" }}>
              <div
                style={{
                  width: "2.5cm",
                  height: "3.3cm",
                  border: "2px solid #000",
                  fontSize: "10pt",
                  textAlign: "center",
                }}
              >
                <br />
                <br />
                รูปถ่ายขนาด
                <br />
                <br />1 นิ้ว
              </div>
            </Grid>
            <Grid item xs={10} align="center" style={{ paddingTop: "3cm" }}>
              (นางชลิดา สถิตนิรามัย)
              <br />
              ผู้อำนวยการ
              <br />
              โรงเรียนต้นกล้า
              <br />
            </Grid>
            <Grid
              item
              xs={5}
              align="center"
              style={{ lingHeight: "50px", paddingTop: "3cm" }}
            >
              (นายชิษนุพงศ์ โกวิทพุฒินันท์)
              <br />
              นายทะเบียน
              <br />
              โรงเรียนต้นกล้า
            </Grid>
            <Grid item xs={7} />
            <Grid
              item
              xs={12}
              align="center"
              style={{ fontSize: "12pt", paddingTop: "1.4cm" }}
            >
              (ใบรับรองนี้ มีอายุ 120 วันนับจากวันออก)
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
