import React, { Fragment, useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  checkPermission,
  getGroups,
  getStampTime,
  getGroupsStudents,
} from "../js/main";
import { months } from "../const";
import { GridSelect } from "../components/InputComponents";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs /*, { Dayjs }*/ from "dayjs";

import axios from "axios";
import Conf from "../Conf";
import moment from "moment/moment";
import { Button } from "@mui/material";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const getCheckin = (group_id, check_date) => {
  return new Promise(async (resolve) => {
    let url =
      Conf.endpoint.tonkla +
      "/reg/checkpresent/group/" +
      group_id +
      "/" +
      check_date;
    if (Array.isArray(check_date) === true)
      url =
        Conf.endpoint.tonkla +
        "/reg/checkpresent/group/" +
        group_id +
        "/" +
        check_date[0] +
        "/" +
        check_date[1];
    let r = await axios.get(url, {
      headers: {
        Authorization: cookie.access_token,
      },
    });
    resolve(r.data);
  });
};

export default function CheckPresent(props) {
  const [profile, setProfile] = useState();
  const [state, setState] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) setProfile(props.profile);
      }
    })();
  }, [props]);
  useEffect(() => {
    (async () => {
      if (!state) {
        let groups = await getGroups();
        groups = groups.groups;
        groups = groups.filter((j) => j.student_id.length > 0);
        groups = await groups.map((j) => {
          j.label = j.name;
          j.value = j._id;
          return j;
        });
        setState({ groups, tabNo: 0 });
      }
    })();
  }, [state]);
  return (
    <>
      {state && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={state.tabNo}
              onChange={(e, val) => setState({ ...state, tabNo: val })}
            >
              <Tab label="การเช็คชื่อรายวัน" component="div" />
              <Tab label="การเช็คชื่อรายเดือน" component="div" />
              <Tab label="การเช็คชื่อตามช่วงเวลา" component="div" />
              <Tab label="ภาพรวมการมาเรียน" component="div" />
            </Tabs>
          </Box>
          <br />
          {
            {
              0: <RptDaily groups={state.groups} />,
              1: <RptMonth groups={state.groups} />,
              2: <RptMoment groups={state.groups} />,
              3: <RptOverall groups={state.groups} />,
            }[state.tabNo]
          }
          <iframe
            id="printFrame"
            name="printFrame"
            title="printFrame"
            style={{ border: "none", width: "0px", height: "0px" }}
          ></iframe>
        </div>
      )}
    </>
  );
}

function RptMonth({ groups }) {
  const [state, setState] = useState();
  const [days, setDays] = useState();
  const [students, setStudents] = useState();
  useEffect(() => {
    (async () => {
      let dt = await getStampTime();
      let years = [];
      for (
        let i = parseInt(dt.detail.year);
        i > parseInt(dt.detail.year) - 3;
        i--
      ) {
        years.push({ label: i });
      }
      setState({
        month: parseInt(dt.detail.month),
        year: parseInt(dt.detail.year),
        // month: 12,
        // year: 2021,
        group: groups[0].value,
        years,
      });
    })();
  }, [groups]);

  useEffect(() => {
    async function getStudents() {
      return new Promise(async (resolve) => {
        let Students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: {
              current_group: state.group,
            },
            select: "_id prefix first_name middle_name last_name student_code",
            sort: { student_code: 1 },
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(Students.data.result);
      });
    }
    async function getCheckin() {
      return new Promise(async (resolve) => {
        let keyword = state.year + "-" + String(state.month).padStart(2, "0");
        keyword = {
          value: keyword,
          fields: ["check_date"],
        };
        let match = {
          group_id: state.group,
        };
        let Statuses = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "checkins",
            match,
            keyword,
            sort: { check_date: 1 },
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(Statuses.data.result);
      });
    }
    async function renderReport(students, checkins) {
      return new Promise(async (resolve) => {
        let days = [];
        for (let student of students) {
          student.checkins = [];
          for (let chk of checkins.filter(
            (j) => j.student_id === student._id
          )) {
            let day = chk.check_date.split("-");
            day = parseInt(day[2]);
            student.checkins[day] = chk.status;
            day = String(day).padStart(2, "0");
            if (days.indexOf(day) < 0) days.push(day);
          }
        }
        days.sort();
        console.log(days);
        setDays(days);
        resolve(students);
      });
    }
    (async () => {
      if (state) {
        let Students = await getStudents();
        let Checkins = await getCheckin();
        Students = await renderReport(Students, Checkins);
        console.log(Students);
        setStudents(Students);
      }
    })();
  }, [state]);
  function cellStyle(status) {
    let r = { background: "#cfc" };
    if (status === "ส") r.background = "#f4ff81";
    else if (status === "ข") r.background = "#f66";
    else if (status === "ป") r.background = "#fcc";
    else if (status === "ล") r.background = "#ff9800";
    return r;
  }
  return (
    <div>
      {state && (
        <>
          <Grid container spacing={2}>
            <GridSelect
              grid={[12, 4, 4, 4]}
              disabled_empty={true}
              label="Group"
              options={groups}
              onChange={(val) => setState({ ...state, group: val })}
              value={state.group || "-"}
            />
            <GridSelect
              grid={[12, 4, 4, 4]}
              disabled_empty={true}
              label="Month"
              options={months}
              onChange={(val) => setState({ ...state, month: val })}
              value={state.month || "-"}
            />
            <GridSelect
              grid={[12, 4, 4, 4]}
              disabled_empty={true}
              label="Year"
              options={state.years}
              onChange={(val) => setState({ ...state, year: val })}
              value={state.year || "-"}
            />
          </Grid>
          <br />
          {students && (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ width: "80px" }}
                        className="th"
                      >
                        ID
                      </TableCell>
                      <TableCell
                        align="center"
                        className="th"
                        style={{ width: "300px" }}
                      >
                        Full name
                      </TableCell>
                      {days.map((day, key) => (
                        <TableCell
                          align="center"
                          style={{ padding: "0px 0px" }}
                          className="th"
                          key={key}
                        >
                          {day}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map((student, key) => (
                      <TableRow key={key}>
                        <TableCell align="center" className="td">
                          {student.student_code}
                        </TableCell>
                        <TableCell className="td">
                          {student.prefix} {student.first_name}{" "}
                          {student.middle_name} {student.last_name}
                        </TableCell>
                        {days.map((day, key) => (
                          <TableCell
                            align="center"
                            className="td"
                            key={key}
                            style={cellStyle(student.checkins[parseInt(day)])}
                          >
                            {student.checkins[parseInt(day)]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </div>
  );
}

function RptOverall({ groups }) {
  const [state, setState] = useState();
  const [levels, setLevels] = useState();
  // const [report, setReport] = useState();
  const [reports, setReports] = useState();
  const sumFields = [
    "student_count",
    "student_male_count",
    "student_female_count",
    "present_count",
    "present_male_count",
    "present_female_count",
    "absent_count",
    "absent_male_count",
    "absent_female_count",
  ];
  useEffect(() => {
    (async () => {
      let levels = [];
      for (let group of groups) {
        let i = await levels.findIndex((j) => j.value === group.level);
        if (i < 0) {
          levels.push({ value: group.level, label: "ระดับ" + group.level });
        }
      }
      setLevels(levels);
      let dt = await getStampTime();
      setState({
        level: levels[0].value,
        selectDate: dt.iso_date,
        fromDate: dt.iso_date,
        toDate: dt.iso_date,
      });
    })();
  }, [groups]);

  useEffect(() => {
    async function getTotal(groups) {
      return new Promise((resolve) => {
        let r = {
          student_count: 0,
          student_male_count: 0,
          student_female_count: 0,
          present_count: 0,
          present_male_count: 0,
          present_female_count: 0,
          absent_count: 0,
          absent_male_count: 0,
          absent_female_count: 0,
        };
        for (let group of groups) {
          r.student_count += group.summations.student_count;
          r.student_male_count += group.summations.student_male_count;
          r.student_female_count += group.summations.student_female_count;
          r.present_count += group.summations.present_count;
          r.present_male_count += group.summations.present_male_count;
          r.present_female_count += group.summations.present_female_count;
          r.absent_count += group.summations.absent_count;
          r.absent_male_count += group.summations.absent_male_count;
          r.absent_female_count += group.summations.absent_female_count;
        }
        resolve(r);
      });
    }

    async function getSummations(checkins, group_name) {
      return new Promise(async (resolve) => {
        let chk = checkins.filter((j) => j.group_name === group_name);
        let student_count = 0;
        let student_male_count = 0;
        let student_female_count = 0;
        let present_count = 0;
        let present_male_count = 0;
        let present_female_count = 0;
        let absent_count = 0;
        let absent_male_count = 0;
        let absent_female_count = 0;
        if (chk[0]) {
          student_count = chk[0].student_count;
          student_male_count = chk[0].student_male_count;
          student_female_count = chk[0].student_female_count;
          for (let sum of chk[0].summations) {
            if (
              sum.status === "ข" ||
              sum.status === "ล" ||
              sum.status === "ป"
            ) {
              absent_count += sum.count;
              absent_male_count += sum.male_count;
              absent_female_count += sum.female_count;
            } else {
              present_count += sum.count;
              present_male_count += sum.male_count;
              present_female_count += sum.female_count;
            }
          }
        }
        resolve({
          student_count,
          student_male_count,
          student_female_count,
          present_count,
          present_male_count,
          present_female_count,
          absent_count,
          absent_male_count,
          absent_female_count,
        });
      });
    }

    async function getAbsentPersons(groups, selectDate) {
      return new Promise(async (resolve) => {
        let ids = groups.map((j) => j.group_id);
        let checkins = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "checkins",
            match: {
              $and: [
                { check_date: selectDate },
                { group_id: { $in: ids } },
                { status: { $in: ["ข", "ล", "ป"] } },
              ],
            },
            populate: {
              path: "student_id",
              select: "prefix first_name middle_name last_name",
            },
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        checkins = checkins.data.result;
        for (let group of groups) {
          let persons = await checkins.filter(
            (j) => j.group_id === group.group_id
          );
          group.absent_persons = [];
          for (let person of persons) {
            group.absent_persons.push({
              status: person.status,
              fullname:
                person.student_id.prefix +
                " " +
                person.student_id.first_name +
                " " +
                (person.student_id.middle_name || "") +
                " " +
                person.student_id.last_name,
            });
          }
        }
        resolve(groups);
      });
    }
    (async () => {
      if (state) {
        let fromDate = new Date(state.fromDate);
        let selectDate = moment(fromDate).format("YYYY-MM-DD");
        let Reports = [];
        while (selectDate <= state.toDate) {
          let checkins = await axios.post(
            Conf.endpoint.tonkla + "/reg/query",
            {
              collection: "check_presents",
              match: { check_date: selectDate },
            },
            {
              headers: {
                Authorization: cookie.access_token,
              },
            }
          );
          checkins = checkins.data.result;
          let Report = {
            date_report: selectDate,
            groups: [],
          };
          for (let group of groups.filter((j) => j.level === state.level)) {
            let i = await Report.groups.findIndex(
              (j) => j.group_id === group._id
            );
            if (i < 0) {
              let summations = await getSummations(checkins, group.name);
              Report.groups.push({
                group_id: group._id,
                name: group.name,
                level: group.level + " " + group.level_year,
                summations,
              });
            }
          }
          Report.summations = await getTotal(Report.groups);
          Report.groups = await getAbsentPersons(Report.groups, selectDate);
          Reports.push(Report);
          // setReport(Report);
          fromDate.setDate(fromDate.getDate() + 1);
          selectDate = moment(fromDate).format("YYYY-MM-DD");
        }
        setReports(Reports);
      }
    })();
  }, [state, groups]);

  function print() {
    if (reports) {
      /*
      let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
      cookie.export = { reports, sumFields };
      localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
      */
      let url =
        process.env.PUBLIC_URL +
        "/print/checkpresent/overall/" +
        state.fromDate +
        "/" +
        state.toDate;
      document.getElementById("printFrame").src = url;
    }
  }
  return (
    <div>
      {state && (
        <Grid container spacing={1}>
          <GridSelect
            grid={[12, 6, 5, 4]}
            disabled_empty={true}
            label="ระดับชั้น"
            options={levels}
            onChange={(val) => setState({ ...state, level: val })}
            value={state.level || "-"}
          />
          <Grid
            item
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            md={3}
            lg={4}
          ></Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To Date"
                inputFormat="dd/MM/yyyy"
                defaultValue={dayjs(state.fromDate) || new Date()}
                onChange={async (newValue) => {
                  let dt = await getStampTime(newValue);
                  setState({ ...state, fromDate: dt.iso_date });
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    InputLabelProps: { shrink: true },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                label="To Date"
                defaultValue={dayjs(state.toDate) || new Date()}
                onChange={async (newValue) => {
                  let dt = await getStampTime(newValue);
                  setState({ ...state, toDate: dt.iso_date });
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    InputLabelProps: { shrink: true },
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} align="right">
            <Button variant="contained" color="primary" onClick={print}>
              Print
            </Button>
          </Grid>
          {reports &&
            reports.map((report, key) => (
              <Fragment key={key}>
                <RptOverallDaily report={report} sumFields={sumFields} />
              </Fragment>
            ))}
        </Grid>
      )}
    </div>
  );
}

function RptOverallDaily({ report, sumFields }) {
  return (
    <>
      <Grid item xs={12} style={{ paddingTop: "20px" }}>
        <h3 className="bold" style={{ margin: "0px auto 0px 15px" }}>
          {moment(report.date_report).format("dddd, DD MMMM YYYY")}
        </h3>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "20px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="td"
                  align="center"
                  style={{ width: "80px" }}
                  rowSpan={2}
                >
                  ลำดับ
                </TableCell>
                <TableCell className="td" align="center" rowSpan={2}>
                  กลุ่มเรียน
                </TableCell>
                <TableCell
                  className="td"
                  align="center"
                  colSpan={3}
                  style={{ borderLeft: "1px solid #ccc" }}
                >
                  จำนวนเต็ม
                </TableCell>
                <TableCell
                  className="td"
                  align="center"
                  colSpan={3}
                  style={{ borderLeft: "1px solid #ccc" }}
                >
                  จำนวนมาเรียน
                </TableCell>
                <TableCell
                  className="td"
                  align="center"
                  colSpan={3}
                  style={{ borderLeft: "1px solid #ccc" }}
                >
                  จำนวนขาดเรียน
                </TableCell>
                <TableCell
                  className="td"
                  align="center"
                  style={{
                    width: "100px",
                    borderLeft: "1px solid #ccc",
                  }}
                  rowSpan={2}
                >
                  หมายเหตุ
                </TableCell>
              </TableRow>
              <TableRow>
                {[0, 1, 2].map((status, key) => (
                  <Fragment key={key}>
                    <TableCell
                      className="td"
                      align="center"
                      style={{
                        width: "80px",
                        borderLeft: "1px solid #ccc",
                      }}
                    >
                      รวม
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{
                        width: "80px",
                      }}
                    >
                      ชาย
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      หญิง
                    </TableCell>
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {report.groups.map((group, key) => (
                <TableRow key={key} hover>
                  <TableCell className="td" align="center">
                    {key + 1}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {group.name}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    style={{ borderLeft: "1px solid #ccc" }}
                  >
                    {group.summations.student_count}
                  </TableCell>
                  <TableCell className="controlTd" align="center">
                    {group.summations.student_male_count}
                  </TableCell>
                  <TableCell className="controlTd" align="center">
                    {group.summations.student_female_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    style={{ borderLeft: "1px solid #ccc" }}
                  >
                    {group.summations.present_count}
                  </TableCell>
                  <TableCell className="controlTd" align="center">
                    {group.summations.present_male_count}
                  </TableCell>
                  <TableCell className="controlTd" align="center">
                    {group.summations.present_female_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    style={{ borderLeft: "1px solid #ccc" }}
                  >
                    {group.summations.absent_count}
                  </TableCell>
                  <TableCell className="controlTd" align="center">
                    {group.summations.absent_male_count}
                  </TableCell>
                  <TableCell className="controlTd" align="center">
                    {group.summations.absent_female_count}
                  </TableCell>
                  <TableCell
                    className="td"
                    style={{ borderLeft: "1px solid #ccc" }}
                  />
                </TableRow>
              ))}
              <TableRow>
                <TableCell className="th" align="center" colSpan={2}>
                  รวม
                </TableCell>
                {sumFields.map((field, key) => (
                  <TableCell
                    className="td"
                    align="center"
                    key={key}
                    style={
                      key % 3 === 0 ? { borderLeft: "1px solid #ccc" } : {}
                    }
                  >
                    <div className="bold">{report.summations[field]}</div>
                  </TableCell>
                ))}
                <TableCell
                  className="td"
                  style={{ borderLeft: "1px solid #ccc" }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} style={{ padding: "20px 20px" }}>
        จำนวนนักเรียนทั้งหมด{" "}
        <span className="bold">{report.summations.student_count}</span> คน
      </Grid>
      {["มาเรียน", "ขาดเรียน"].map((status, key) => (
        <Grid item xs={12} key={key}>
          <Grid container>
            <Grid item style={{ width: "200px", textAlign: "right" }}>
              {status}
            </Grid>
            <Grid item style={{ width: "120px", textAlign: "right" }}>
              <span className="bold" style={{ marginRight: "20px" }}>
                {status === "มาเรียน"
                  ? report.summations.present_count
                  : report.summations.absent_count}
              </span>
              คน
            </Grid>
            <Grid item style={{ width: "150px", textAlign: "right" }}>
              คิดเป็นร้อยละ
            </Grid>
            <Grid item style={{ width: "80px", textAlign: "right" }}>
              <span className="bold">
                {status === "มาเรียน"
                  ? (
                      (report.summations.present_count /
                        report.summations.student_count) *
                      100
                    ).toFixed(2)
                  : (
                      (report.summations.absent_count /
                        report.summations.student_count) *
                      100
                    ).toFixed(2)}
              </span>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} className="bold">
        รายชื่อผู้ขาดเรียน
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableBody>
            {report.groups.map((group, key) => (
              <TableRow key={key} hover>
                <TableCell
                  className="td"
                  align="center"
                  style={{ width: "120px", border: "none" }}
                >
                  ห้อง {group.name}
                </TableCell>
                <TableCell
                  className="td"
                  style={{ width: "60px", border: "none" }}
                >
                  ได้แก่
                </TableCell>
                <TableCell className="td">
                  {group.absent_persons.map((person, key) => (
                    <span
                      style={{
                        marginRight: "5px",
                        fontSize: ".8rem",
                      }}
                      key={key}
                    >
                      {person.fullname} ({person.status}),
                    </span>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}

function RptDaily({ groups }) {
  const [state, setState] = useState();
  const [students, setStudents] = useState([]);
  const [summations, setSummations] = useState({});
  useEffect(() => {
    (async () => {
      let dt = await getStampTime();
      let group_id = groups[0]._id;
      setState({ selectGroup: group_id || "-", selectDate: dt.iso_date });
    })();
  }, [groups]);
  useEffect(() => {
    (async () => {
      if (state) {
        let { selectGroup, selectDate } = state;
        let Students = await getGroupsStudents(selectGroup);
        let checkins = await getCheckin(selectGroup, selectDate);
        Students.result = await Students.result.map((j) => {
          let i = checkins.result.findIndex((k) => k.student_id === j._id);
          if (i < 0) j.check_status = null;
          else {
            let status = checkins.result[i].status;
            j.check_status = status;
          }
          return j;
        });
        let sum = {};
        for (let student of Students.result) {
          let status = student.check_status;
          if (status) {
            if (!sum[status]) sum[status] = 1;
            else sum[status]++;
          }
        }
        setSummations(sum);
        setStudents(Students.result);
      }
    })();
  }, [state]);
  return (
    <div>
      {state && (
        <Grid container spacing={1}>
          <GridSelect
            grid={[12, 6, 5, 4]}
            label="กลุ่มเรียน"
            options={groups}
            onChange={(val) => setState({ ...state, selectGroup: val })}
            value={state.selectGroup || "-"}
          />
          <Grid
            item
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            md={2}
            lg={4}
          ></Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <BasicDatePicker
              value={state.selectDate}
              changeValue={(val) => setState({ ...state, selectDate: val })}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: "20px" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "100px" }}
                    >
                      รหัสนักเรียน
                    </TableCell>
                    <TableCell className="td" align="center">
                      ชื่อ-นามสกุล
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "70px" }}
                    >
                      มาเรียน
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "70px" }}
                    >
                      ขาดเรียน
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "70px" }}
                    >
                      ป่วย
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "70px" }}
                    >
                      ลา
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "70px" }}
                    >
                      มาสาย
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student, key) => (
                    <TableRow key={key} hover>
                      <TableCell className="td" align="center">
                        {key + 1}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {student.student_code}
                      </TableCell>
                      <TableCell className="td">
                        {student.prefix} {student.first_name}{" "}
                        {student.middle_name} {student.last_name}
                      </TableCell>
                      {["ม", "ข", "ป", "ล", "ส"].map((choice, key) => (
                        <TableCell
                          className="controlTd"
                          align="center"
                          key={key}
                          style={{ borderLeft: "1px solid #e2e2e2" }}
                        >
                          {student.check_status === choice && <Icon>done</Icon>}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell className="th" align="center" colSpan={3}>
                      รวม
                    </TableCell>
                    {["ม", "ข", "ป", "ล", "ส"].map((choice, key) => (
                      <TableCell
                        className="td"
                        align="center"
                        key={key}
                        style={{ borderLeft: "1px solid #e2e2e2" }}
                      >
                        <div className="bold">{summations[choice] || 0}</div>
                        <div style={{ fontSize: ".7rem", color: "#777" }}>
                          (
                          {(
                            (summations[choice] / students.length) * 100 || 0
                          ).toFixed(0)}
                          %)
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function RptMoment({ groups }) {
  const [state, setState] = useState();
  const [students, setStudents] = useState([]);
  useEffect(() => {
    (async () => {
      if (groups && groups.length > 0) {
        let dt = await getStampTime();
        setState({
          group: groups[0]._id,
          formDate: dt.iso_date,
          toDate: dt.iso_date,
        });
      }
    })();
  }, [groups]);
  useEffect(() => {
    (async () => {
      if (state) {
        let Students = await getGroupsStudents(state.group);
        Students = Students.result;
        let checkins = await getCheckin(state.group, [
          state.formDate,
          state.toDate,
        ]);
        checkins = checkins.result;
        for (let student of Students) {
          let details = checkins.filter((j) => j.student_id === student._id);
          student.check = {
            count: 0,
            ม: { count: 0 },
            ข: { count: 0 },
            ป: { count: 0 },
            ล: { count: 0 },
            ส: { count: 0 },
          };
          for (let detail of details) {
            student.check.count++;
            student.check[detail.status].count++;
          }
        }
        setStudents(Students);
      }
    })();
  }, [state]);
  return (
    <div>
      {state && (
        <Grid container spacing={1}>
          <GridSelect
            grid={[12, 6, 5, 4]}
            label="กลุ่มเรียน"
            options={groups}
            onChange={(val) => setState({ ...state, group: val })}
            value={state.group || "-"}
          />
          <Grid
            item
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            md={1}
            lg={4}
          ></Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <BasicDatePicker
              value={state.formDate}
              changeValue={(val) => setState({ ...state, formDate: val })}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <BasicDatePicker
              value={state.toDate}
              changeValue={(val) => setState({ ...state, toDate: val })}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: "20px" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell className="td" align="center">
                      ชื่อ-นามสกุล
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      จำนวนวัน
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      มาเรียน
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      ขาดเรียน
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      ป่วย
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      ลา
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "80px" }}
                    >
                      มาสาย
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student, key) => (
                    <TableRow key={key} hover>
                      <TableCell className="td" align="center">
                        {key + 1}
                      </TableCell>
                      <TableCell className="td">
                        {student.prefix} {student.first_name}{" "}
                        {student.middle_name} {student.last_name}
                      </TableCell>
                      <TableCell className="controlTd" align="center" key={key}>
                        {student.check.count}
                      </TableCell>
                      {["ม", "ข", "ป", "ล", "ส"].map((choice, key) => (
                        <TableCell
                          className="controlTd"
                          align="center"
                          key={key}
                        >
                          <div>{student.check[choice].count}</div>
                          <div style={{ fontSize: ".7rem", color: "#777" }}>
                            (
                            {(
                              (student.check[choice].count /
                                student.check.count) *
                              100
                            ).toFixed(0)}
                            %)
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function BasicDatePicker({ value, changeValue }) {
  // const [value, setValue] = React.useState("2021-12-18");

  return (
    <div style={{ textAlign: "right" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="วันที่"
          defaultValue={dayjs(value) || new Date()}
          onChange={async (newValue) => {
            let dt = await getStampTime(newValue);
            changeValue(dt.iso_date);
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
