import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { checkPermission, getGroups, getStampTime } from "../js/main";
import { GridSelect, GridDatePicker } from "../components/InputComponents";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import Conf from "../Conf";
import { student_normal_status } from "../const";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
const dayName = [
  "วันอาทิตย์",
  "วันจันทร์",
  "วันอังคาร",
  "วันพุธ",
  "วันพฤหัสบดี",
  "วันศุกร์",
  "วันเสาร์",
];

export default function CheckPresent(props) {
  const [profile, setProfile] = useState();
  const [groups, setGroups] = useState();
  const [students, setStudents] = useState([]);
  const [state, setState] = useState();
  const [loading, setLoading] = useState(true);
  const getDateRange = () => {
    return new Promise(async (resolve) => {
      const d = new Date();
      let day = d.getDay();
      let dateRange = [];
      let backDay = await getStampTime(d.setDate(d.getDate()));
      dateRange.push({
        value: backDay.iso_date,
        weekDay: day,
        label: backDay.iso_date + " (" + dayName[day] + ")",
      });
      for (let i = day; i > 1; i--) {
        let backDay = d.setDate(d.getDate() - 1);
        backDay = await getStampTime(backDay);
        let dt = new Date(backDay.iso_date);
        dt = dt.getDay();
        dateRange.push({
          value: backDay.iso_date,
          weekDay: dt,
          label: backDay.iso_date + " (" + dayName[dt] + ")",
        });
      }
      dateRange = dateRange.reverse();
      resolve(dateRange);
    });
  };

  const getCheckin = (group_id, check_date) => {
    return new Promise(async (resolve) => {
      let r = await axios.get(
        Conf.endpoint.tonkla +
          "/reg/checkpresent/group/" +
          group_id +
          "/" +
          check_date,
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      resolve(r.data);
    });
  };
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
          let groups = await getGroups();
          groups = groups.groups;
          groups = groups.filter(
            (j) => j.advisors.indexOf(props.profile.usn) > -1
          );
          groups = await groups.map((j) => {
            j.label = j.name;
            j.value = j._id;
            return j;
          });
          let dateRange = await getDateRange();
          setState({
            dateRange,
            group: groups[0],
            selectDate: dateRange[dateRange.length - 1].value,
          });
          setGroups(groups);
        }
      }
    })();
  }, [props]);
  useEffect(() => {
    (async () => {
      if (state && state.group) {
        setLoading(true);
        let Students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: {
              $and: [
                { current_group: state.group._id },
                { student_status: student_normal_status },
              ],
            },
            sort: { student_code: 1 },
            select:
              "student_code prefix first_name middle_name last_name nickname gender",
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        let checkins = await getCheckin(state.group._id, state.selectDate);
        Students = await Students.data.result.map((j) => {
          let i = checkins.result.findIndex((k) => k.student_id === j._id);
          if (i < 0) j.check_status = null;
          else {
            j.check_status = checkins.result[i].status;
          }
          return j;
        });
        setStudents(Students);
        setLoading(false);
      }
    })();
  }, [state]);
  function changeStatus(student_id, e) {
    let Students = JSON.parse(JSON.stringify(students));
    let status = e.target.value;
    let key = students.findIndex((j) => j._id === student_id);
    Students[key].check_status = status;
    setStudents(Students);
  }
  async function save() {
    let i = groups.findIndex((j) => j._id === state.group._id);
    let send = {
      check_date: state.selectDate,
      group_id: state.group._id,
      group_name: groups[i].name,
      check_status: await students
        .filter((j) => j.check_status)
        .map((st) => {
          return {
            _id: st._id,
            gender: st.gender,
            status: st.check_status,
          };
        }),
    };
    let check = await axios.put(
      Conf.endpoint.tonkla + "/reg/checkpresent",
      send,
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    if (check.data.error) alert(check.data.error.message);
    else alert("Save completed");
  }
  function presentAll(e) {
    let checked = e.target.checked;
    let Students = JSON.parse(JSON.stringify(students));
    for (let student of Students) {
      if (checked === true) student.check_status = "ม";
      else student.check_status = null;
    }
    setStudents(Students);
  }
  return (
    <>
      {profile && state && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Grid container>
            <GridSelect
              grid={[12, 6, 5, 4]}
              label="กลุ่มเรียน"
              disabled_empty={true}
              options={groups}
              onChange={(val) => setState({ ...state, group: { _id: val } })}
              value={state.group._id || "-"}
            />
            <Grid
              item
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              md={2}
              lg={4}
            ></Grid>

            {profile.reg_staff === true ? (
              <>
                <GridDatePicker
                  gridStyles={{ textAlign: "right" }}
                  grid={[12, 6, 5, 4]}
                  label="วันที่"
                  onChange={(val) => setState({ ...state, selectDate: val })}
                  value={state.selectDate || "-"}
                />
              </>
            ) : (
              <>
                {state.dateRange && (
                  <GridSelect
                    grid={[12, 6, 5, 4]}
                    label="วันที่"
                    disabled_empty={true}
                    options={state.dateRange}
                    onChange={(val) => setState({ ...state, selectDate: val })}
                    value={state.selectDate || "-"}
                  />
                )}
              </>
            )}
            <Grid item xs={12}>
              <div style={{ textAlign: "right", marginTop: "20px" }}>
                <FormControlLabel
                  onChange={presentAll}
                  control={<Checkbox size="small" />}
                  label={
                    <div style={{ fontSize: ".9rem" }}>มาเรียนทั้งหมด</div>
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              {loading === true && <LinearProgress />}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "80px" }}
                      >
                        ลำดับ
                      </TableCell>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "100px" }}
                      >
                        รหัสนักเรียน
                      </TableCell>
                      <TableCell className="td" align="center">
                        ชื่อ-นามสกุล
                      </TableCell>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "120px" }}
                      >
                        ชื่อเล่น
                      </TableCell>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "70px" }}
                      >
                        มาเรียน
                      </TableCell>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "70px" }}
                      >
                        ขาดเรียน
                      </TableCell>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "70px" }}
                      >
                        ป่วย
                      </TableCell>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "70px" }}
                      >
                        ลา
                      </TableCell>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ width: "70px" }}
                      >
                        มาสาย
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map((student, key) => (
                      <TableRow key={key} hover>
                        <TableCell className="td" align="center">
                          {key + 1}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {student.student_code}
                        </TableCell>
                        <TableCell className="td">
                          {student.prefix} {student.first_name}{" "}
                          {student.middle_name} {student.last_name}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {student.nickname}
                        </TableCell>
                        {["ม", "ข", "ป", "ล", "ส"].map((choice, key) => (
                          <TableCell
                            className="controlTd"
                            align="center"
                            key={key}
                          >
                            <Radio
                              size="small"
                              checked={student.check_status === choice}
                              onChange={(e) => changeStatus(student._id, e)}
                              name={"check_" + student._id}
                              value={choice}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ textAlign: "right", paddingTop: "20px" }}>
                <Button
                  variant="contained"
                  style={{ width: "150px" }}
                  startIcon={<Icon>save</Icon>}
                  onClick={save}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
