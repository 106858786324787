import React from "react";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

// import ReactDOM from "react-dom";

import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

let theme = createTheme({
  palette: {
    primary: {
      main: "#009688",
    },
    // secondary: {
    //   main: "#f00",
    // },
    success: {
      main: "#00c853",
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
