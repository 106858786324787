import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Button, Grid } from "@mui/material";
import { fetchData } from "../../js/main";
import { Fragment } from "react";
import moment from "moment";

export default function Summary({ onShowDetail }) {
  const [filter, setFilter] = useState();
  const [pickups, setPickups] = useState();
  const [facet, setFacet] = useState();
  useEffect(() => {
    let dt = new Date();
    let fromDate = new Date();
    fromDate.setDate(dt.getDate() - 7);
    setFilter({
      fromDate: fromDate,
      toDate: moment(dt).format("YYYY-MM-DD"),
    });
  }, []);
  useEffect(() => {
    (async () => {
      if (filter) {
        let pickups = await fetchData("post", "/pickup/history/summary", {
          formDate: filter.fromDate,
          toDate: filter.toDate,
        });
        setFacet(pickups.facet);
        setPickups(pickups.result);
      }
    })();
  }, [filter]);

  return (
    <div>
      {filter && (
        <FilterForm
          filter={filter}
          changeFilter={(field, val) => setFilter({ ...filter, [field]: val })}
        />
      )}
      {pickups && (
        <SummaryTable
          facet={facet}
          pickups={pickups}
          onShowDetail={onShowDetail}
        />
      )}
    </div>
  );
}

function FilterForm({ filter, changeFilter }) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <DatePicker
              format="DD MMM YYYY"
              label="From Date"
              value={dayjs(filter.fromDate)}
              onChange={(val) =>
                changeFilter(
                  "fromDate",
                  moment(new Date(val)).format("YYYY-MM-DD")
                )
              }
              slotProps={{
                textField: {
                  size: "small",
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DatePicker
              format="DD MMM YYYY"
              label="To Date"
              value={dayjs(filter.toDate)}
              onChange={(val) => {
                changeFilter(
                  "toDate",
                  moment(new Date(val)).format("YYYY-MM-DD")
                );
              }}
              slotProps={{
                textField: {
                  size: "small",
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                },
              }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
}

function SummaryTable({ facet, pickups, onShowDetail }) {
  function showValue(type, group_id, dt) {
    let r = pickups.findIndex(
      (j) => j.request_date === dt && j.group_id === group_id
    );
    if (r > -1) {
      r = pickups[r][type] || 0;
    } else r = 0;
    return r;
  }
  function showDetail(type, group_id, dt) {
    let match = null;
    if (type === "count") {
      match = { $and: [{ request_date: dt }, { group_id }] };
    } else if (type === "confirm") {
      match = {
        $and: [
          { request_date: dt },
          { group_id },
          { pickup_stamp_time: { $exists: true } },
        ],
      };
    } else if (type === "assign") {
      match = { $and: [{ pickup_date: dt }, { group_id }] };
    }
    if (match) onShowDetail(match, type);
  }
  return (
    <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ width: "60px" }} rowSpan={3}>
              No
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderLeft: "1px solid #ccc" }}
              rowSpan={3}
            >
              Group
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "80px", borderLeft: "1px solid #ccc" }}
              rowSpan={3}
            >
              Program
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "60px", borderLeft: "1px solid #ccc" }}
              rowSpan={3}
            >
              ALL
            </TableCell>
            {facet.pickup_date.map((dt, key) => (
              <TableCell
                align="center"
                key={key}
                colSpan={3}
                sx={{
                  borderLeft: "2px solid #333",
                  fontSize: ".8rem",
                }}
              >
                {dt.label}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {facet.pickup_date.map((dt, key) => (
              <Fragment key={key}>
                {["Confirm", "Request", "Assign"].map((label, key) => (
                  <TableCell
                    align="center"
                    sx={{
                      width: "30px",
                      padding: "5px 5px",
                      borderLeft:
                        key === 0 ? "2px solid #333" : "1px solid #ccc",
                      fontSize: ".7rem",
                    }}
                    key={key}
                  >
                    {label}
                  </TableCell>
                ))}
              </Fragment>
            ))}
          </TableRow>
          <TableRow>
            {facet.pickup_date.map((dt, key) => (
              <Fragment key={key}>
                {["confirm", "count", "assign"].map((label, key) => (
                  <TableCell
                    align="center"
                    sx={{
                      width: "30px",
                      padding: "5px 5px",
                      borderLeft:
                        key === 0 ? "2px solid #333" : "1px solid #ccc",
                      fontSize: ".8rem",
                    }}
                    key={key}
                  >
                    {dt[label]}
                  </TableCell>
                ))}
              </Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {facet.groups.map((group, key) => (
            <TableRow key={key} hover>
              <TableCell align="center" sx={{ fontSize: ".8rem" }}>
                {key + 1}
              </TableCell>
              <TableCell
                align="center"
                sx={{ borderLeft: "1px solid #ccc", fontSize: ".8rem" }}
              >
                {group.label}
              </TableCell>
              <TableCell
                align="center"
                sx={{ borderLeft: "1px solid #ccc", fontSize: ".8rem" }}
              >
                {group.program && group.program.split(" ")[0]}
              </TableCell>
              <TableCell
                align="center"
                sx={{ borderLeft: "1px solid #ccc", fontSize: ".8rem" }}
              >
                {group.count + group.assign}
              </TableCell>

              {facet.pickup_date.map((dt, key) => (
                <Fragment key={key}>
                  {["confirm", "count", "assign"].map((label, key) => (
                    <TableCell
                      align="center"
                      sx={{
                        width: "30px",
                        padding: "0px 0px",
                        borderLeft:
                          key === 0 ? "2px solid #333" : "1px solid #ccc",
                      }}
                      key={key}
                    >
                      <Button
                        sx={{
                          padding: "0px 0px",
                          fontSize: ".8rem",
                          fontWeiht: "normal",
                        }}
                        onClick={() => showDetail(label, group._id, dt.label)}
                      >
                        {showValue(label, group._id, dt.label)}
                      </Button>
                    </TableCell>
                  ))}
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
