import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

import axios from "axios";
import Conf from "../../Conf";
import { sortJSON } from "../../js/main";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Brethren() {
  const [state, setState] = useState();
  const [groupCount, setGroupCount] = useState();
  const [brethrens, setBrethrens] = useState();
  useEffect(() => {
    setState({});
  }, []);
  useEffect(() => {
    function renderTable(brethrens) {
      let table = [];
      let groups = [];
      let group = 1;
      return new Promise(async (resolve) => {
        for (let brethren of brethrens) {
          // check group
          let i = table.findIndex(
            (j) =>
              j._id === brethren.person1._id || j._id === brethren.person2._id
          );
          if (i < 0) {
            // not exists student
            brethren.person1.group = group;
            brethren.person2.group = group;
            table.push(brethren.person1);
            table.push(brethren.person2);
            groups.push(group);
            group++;
          } else {
            i = table.findIndex((j) => j._id === brethren.person1._id);
            if (i > -1) {
              let j = table.findIndex((j) => j._id === brethren.person2._id);
              if (j < 0) {
                brethren.person2.group = table[i].group;
                table.push(brethren.person2);
              }
            } else {
              i = table.findIndex((j) => j._id === brethren.person2._id);
              if (i > -1) {
                let j = table.findIndex((j) => j._id === brethren.person1._id);
                if (j < 0) {
                  brethren.person1.group = table[i].group;
                  table.push(brethren.person1);
                }
              }
            }
          }
        }
        setGroupCount(groups);
        table = await sortJSON(table, "birth_date");
        resolve(table);
      });
    }
    (async () => {
      if (state) {
        let brethrens = await axios.get(
          Conf.endpoint.tonkla + "/reg/brethren",
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        brethrens.data = await brethrens.data.filter(
          (j) => j.person1 && j.person2
        );
        let b = await renderTable(brethrens.data);
        setBrethrens(b);
      }
    })();
  }, [state]);

  return (
    <div>
      {brethrens && <BrethrenTable groups={groupCount} brethrens={brethrens} />}
    </div>
  );
}
function BrethrenTable({ groups, brethrens }) {
  function groupRow(group) {
    let r = brethrens.filter((j) => j.group === group);
    return r;
  }
  function download() {
    let sheet = [];
    for (let group of groups) {
      let rows = groupRow(group);
      let count = 1;
      for (let row of rows) {
        sheet.push({
          no: group,
          name: row.prefix + " " + row.first_name + " " + row.last_name,
          nickname: row.nickname || "",
          group: row.current_group.name,
          count: count++,
        });
      }
    }
    let exportState = {
      sheet_name: "Test",
      head: [
        { label: "ลำดับ", value: "no" },
        { label: "ชื่อ - นามสกุล", value: "name" },
        { label: "ชื่อเล่น", value: "nickname" },
        { label: "ห้อง", value: "group" },
        { label: "ลำดับคนที่", value: "count" },
      ],
      sheet,
    };
    cookie.export = exportState;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
    document.getElementById("downloadFrame").src =
      process.env.PUBLIC_URL + "/exportexcel";
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="th"
                align="center"
                style={{ width: "100px" }}
                rowSpan={2}
              >
                ลำดับ
              </TableCell>
              <TableCell className="th" align="center" rowSpan={2}>
                ชื่อ - นามสกุล
              </TableCell>
              <TableCell
                className="th"
                align="center"
                style={{ width: "150px" }}
                rowSpan={2}
              >
                ชื่อเล่น
              </TableCell>
              <TableCell
                className="th"
                align="center"
                style={{ width: "100px" }}
                rowSpan={2}
              >
                ห้อง
              </TableCell>
              <TableCell
                className="th"
                align="center"
                style={{ width: "100px" }}
                rowSpan={2}
              >
                ลำดับคนที่
              </TableCell>
            </TableRow>
          </TableHead>
          {groups.map((group, key) => (
            <TableBody key={key} style={{ borderBottom: "2px solid #111" }}>
              {groupRow(group)
                .filter((j) => j.current_group)
                .map((student, key) => (
                  <TableRow key={key}>
                    <TableCell className="td" align="center">
                      {key === 0 && group}
                    </TableCell>
                    <TableCell className="td">
                      {student.prefix} {student.first_name}{" "}
                      {student.middle_name} {student.last_name}
                    </TableCell>
                    <TableCell className="td" align="center">
                      {student.nickname}
                    </TableCell>
                    <TableCell className="td" align="center">
                      {student.current_group.name}
                    </TableCell>
                    <TableCell className="td" align="center">
                      {key + 1}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: "20px", width: "150px" }}
          startIcon={<Icon>download</Icon>}
          onClick={download}
        >
          Download
        </Button>
      </div>
      <iframe
        name="downloadFrame"
        id="downloadFrame"
        title="downloadFrame"
        style={{ width: "0px", height: "0px", border: "none" }}
      ></iframe>
    </div>
  );
}
