import React, { useEffect, useState } from "react";
import ProfileBox from "./components/ProfileBox";
import PageNameBox from "./components/PageNameBox";
// import { checkPermission } from "./js/main";

export default function Student(props) {
  const [profile /*, setProfile*/] = useState();
  useEffect(() => {
    if (props.profile) {
      // let test = checkPermission(props.profile);
      // console.log(test);
      // setProfile(props.profile);
    }
  }, [props.profile]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
        </div>
      )}
    </>
  );
}
