import React, { useEffect, useState } from "react";
import { checkPermission } from "../js/main";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Conf from "../Conf";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function GroupTable(props) {
  const [students, setStudents] = useState();
  const [group, setGroup] = useState();

  useEffect(() => {
    async function getStudents() {
      let Students = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "students",
          limit: 0,
          match: { current_group: props.match.params.id },
          select:
            "student_code prefix first_name middle_name last_name prefix_en first_name_en middle_name_en last_name_en current_group",
          sort: { student_code: 1 },
          populate: "current_group",
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      setStudents(Students.data.result);
      if (Students.data.result.length > 0) {
        setGroup(Students.data.result[0].current_group);
      }
    }
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          await getStudents();
          setTimeout(() => window.print(), 1000);
        }
      }
    })();
  }, [props]);
  return (
    <div style={{ width: "29cm", margin: "auto" }}>
      {students && students.length > 0 && group && (
        <div>
          {props.match.params.language === "TH" ? (
            <THHeading group={group} />
          ) : (
            <ENHeading group={group} />
          )}
          <StudentTable students={students} />
        </div>
      )}
    </div>
  );
}
function THHeading({ group }) {
  return <div></div>;
}
function ENHeading({ group }) {
  return (
    <div>
      <h2 style={{ textAlign: "center", fontSize: "16pt" }}>
        CLASS RECORD : ......../................. of {group.name}
      </h2>
      <Grid container style={{ fontSize: "10pt" }}>
        <Grid item xs={4}>
          <span style={{ fontWeight: 500 }}>Title :</span>
          <span>
            {" "}
            ......................................................................................................
          </span>
        </Grid>
        <Grid item xs={8}>
          <span style={{ fontWeight: 500 }}>Homeroom Teacher</span>
          <span>
            {" "}
            .............................................................................................................................................................................................
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

function StudentTable({ students }) {
  const cols = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  return (
    <div>
      <TableContainer style={{ border: "1px solid #111", marginTop: ".1cm" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="th"
                style={{
                  width: ".8cm",
                  fontSize: "9pt",
                  borderBottom: "1px solid #111",
                }}
                align="center"
              >
                No
              </TableCell>
              <TableCell
                className="th"
                style={{
                  width: "1.8cm",
                  fontSize: "9pt",
                  borderBottom: "1px solid #111",
                }}
                align="center"
              >
                Student ID
              </TableCell>
              <TableCell
                className="th"
                style={{
                  width: "3.2cm",
                  fontSize: "9pt",
                  borderBottom: "1px solid #111",
                }}
                align="center"
              >
                First Name
              </TableCell>
              <TableCell
                className="th"
                style={{
                  width: "2.8cm",
                  height: "2cm",
                  fontSize: "9pt",
                  borderBottom: "1px solid #111",
                }}
                align="center"
              >
                Last Name
              </TableCell>
              {cols.map((col) => (
                <TableCell
                  className="th"
                  key={col}
                  style={{
                    borderLeft: "1px solid #111",
                    borderBottom: "1px solid #111",
                  }}
                ></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, key) => (
              <TableRow key={key}>
                <TableCell
                  className="td"
                  align="center"
                  style={{ borderBottom: "1px dotted #666", fontSize: "9pt" }}
                >
                  {key + 1}
                </TableCell>
                <TableCell
                  className="td"
                  align="center"
                  style={{ borderBottom: "1px dotted #666", fontSize: "9pt" }}
                >
                  {student.student_code}
                </TableCell>
                <TableCell
                  className="td"
                  style={{ borderBottom: "1px dotted #666", fontSize: "9pt" }}
                >
                  {student.prefix} {student.first_name}
                </TableCell>
                <TableCell
                  className="td"
                  style={{ borderBottom: "1px dotted #666", fontSize: "9pt" }}
                >
                  {student.last_name}
                </TableCell>
                {cols.map((col) => (
                  <TableCell
                    className="th"
                    key={col}
                    style={{
                      borderLeft: "1px solid #111",
                      borderBottom: "1px dotted #666",
                    }}
                  ></TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
