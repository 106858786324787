import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import FormStudent from "../components/FormStudent";
import FormCurator from "../components/FormCurator";
import Pagebar from "../components/Pagebar";
import FormStudy from "../components/FormStudy";
import FormPickupPerson from "../components/FormPickupPerson";
import ListStudent from "../components/ListStudent";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";

import { checkPermission, getOptions, groupBy } from "../js/main";
import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Student(props) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();
  const [student, setStudent] = useState();
  const [state, setState] = useState({ tabNo: 0 });
  const [options, setOptions] = useState();
  const params = useParams();
  const student_id = params.id;
  async function setAllOptions() {
    let prefixs = await getOptions("prefix");

    let prefixs_en = await prefixs
      .filter((j) => j.label_alt !== "")
      .map((j) => {
        return { label: j.label_alt, is_default: j.is_default };
      });
    prefixs_en = await groupBy(prefixs_en, "label");

    let student_prefixs = await getOptions("student_prefix");
    let student_prefixs_en = await student_prefixs
      .filter((j) => j.label_alt !== "")
      .map((j) => {
        return { label: j.label_alt, is_default: j.is_default };
      });
    student_prefixs_en = await groupBy(student_prefixs_en, "label");

    let origins = await getOptions("origin");
    let nationalities = await getOptions("nationality");
    let religions = await getOptions("religion");
    let jobs = await getOptions("job");
    let person_status = await getOptions("person_status");
    let family_statuses = await getOptions("family_status");
    let register_documents = await getOptions("register_document");
    let register_house_type = await getOptions("register_house_type");
    setOptions({
      prefixs: prefixs,
      prefixs_en: prefixs_en,
      student_prefixs,
      student_prefixs_en,
      origins,
      nationalities,
      religions,
      jobs,
      person_status,
      family_statuses,
      register_documents,
      register_house_type,
    });
  }
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) setProfile(props.profile);
      }
    })();
  }, [props]);
  useEffect(() => {
    (async () => {
      if (student_id) {
        await setAllOptions();
        let Student = await axios.get(
          Conf.endpoint.tonkla + "/reg/query/students/" + student_id,
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        if (Student.data && Student.data._id) setStudent(Student.data);
      } else setStudent(null);
      setLoading(false);
    })();
  }, [student_id]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <>
            {loading === true ? (
              <div style={{ paddingTop: "40px" }}>
                <LinearProgress />
              </div>
            ) : (
              <>{!student && <ShowList />}</>
            )}
            {student && options && (
              <>
                <Tabs
                  value={state.tabNo}
                  onChange={(e, val) => setState({ ...state, tabNo: val })}
                  style={{ margin: "20px auto 20px" }}
                >
                  <Tab label="ข้อมูลนักเรียน" component="div" />
                  <Tab label="ข้อมูลผู้ปกครอง" component="div" />
                  <Tab label="ข้อมูลการศึกษา" component="div" />
                  <Tab label="ผู้ปกครองรับส่งนักเรียน" component="div" />
                </Tabs>
                {
                  {
                    0: <FormStudent student={student} options={options} />,
                    1: <FormCurator student={student} options={options} />,
                    2: (
                      <FormStudy
                        student={student}
                        documents={options.register_documents}
                      />
                    ),
                    3: (
                      <FormPickupPerson
                        student_id={student._id}
                        register_id={student.register}
                        options={options}
                      />
                    ),
                  }[state.tabNo]
                }
              </>
            )}
          </>
        </div>
      )}
    </>
  );
}

const limit = 30;

function Students(form) {
  return new Promise(async (resolve) => {
    let keyword = null;

    if (form.keyword.trim() !== "") {
      keyword = {
        value: form.keyword.trim(),
        fields: [
          "first_name",
          "middle_name",
          "last_name",
          "first_name_en",
          "middle_name_en",
          "last_name_en",
          "student_code",
          "nickname",
          "nickname_en",
          "student_code",
        ],
      };
    }
    let match = null;
    if (form.status && form.status === "*") {
      match = { student_status: { $exists: false } };
    } else if (form.status && form.status !== "-")
      match = { student_status: form.status };
    let result = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      {
        collection: "students",
        skip: form.skip || 0,
        limit,
        select:
          "prefix first_name middle_name last_name nickname current_group student_code photo_url",
        keyword: keyword,
        match,
        sort: { first_name: 1, last_name: 1 },
      },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    if (result.data.result) resolve(result.data);
    else resolve({ count: 0, result: [] });
  });
}

function ShowList() {
  const [students, setStudents] = useState();
  const [form, setForm] = useState({ keyword: "", status: "-", skip: 0 });
  const [statuses, setStatuses] = useState([{ label: "ทั้งหมด", value: "-" }]);

  useEffect(() => {
    (async () => {
      let result = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "student_statuses",
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      let s = result.data.result;
      s = await s.map((j) => {
        j.value = j._id;
        return j;
      });

      s.push({ label: "ไม่ระบุ", value: "*" });
      s.unshift({ label: "ทั้งหมด", value: "-" });
      setStatuses(s);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      console.log("Change Query");
      let students = await Students(form);
      setStudents(students);
    })();
  }, [form]);
  async function goSearch() {
    setForm({ ...form, keyword: document.getElementById("keyword").value });
  }
  return (
    <div>
      {students && (
        <>
          <br />
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={6}
              style={{
                fontWeight: 500,
                paddingLeft: "15px",
                paddingBottom: "15px",
              }}
            >
              Found :{" "}
              <span style={{ padding: "0px 10px" }}>{students.count}</span>{" "}
              record(s)
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} align="right">
              <Grid container spacing={1} style={{ maxWidth: "405px" }}>
                <Grid item style={{ width: "200px" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                      native
                      label="Status"
                      value={form.status || "-"}
                      onChange={(e) =>
                        setForm({ ...form, status: e.target.value, skip: 0 })
                      }
                    >
                      {statuses.map((status, key) => (
                        <option value={status.value} key={key}>
                          {status.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "200px" }}>
                  <TextField
                    fullWidth
                    label="Keyword"
                    InputLabelProps={{ shrink: true }}
                    placeholder="ค้นหา..."
                    id="keyword"
                    size="small"
                    InputProps={{
                      endAdornment: <Icon>search</Icon>,
                    }}
                    onKeyUp={(e) => e.key === "Enter" && goSearch()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <Pagebar
                count={Math.floor(students.count / limit) + 1}
                page={form.skip / limit + 1}
                changePage={(val) => {
                  setForm({ ...form, skip: (val - 1) * limit });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <ListStudent students={students.result} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
