import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import axios from "axios";
import { checkPermission } from "../js/main";

import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Food(props) {
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) setProfile(props.profile);
      }
    })();
  }, [props]);
  useEffect(() => {
    (async () => {
      if (profile) {
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: {
              $and: [
                { "food_allergy.0": { $exists: true } },
                { current_group: { $exists: true } },
              ],
            },
            limit: 10000,
            populate: "current_group",
            select:
              "food_allergy prefix first_name middle_name last_name nickname current_group",
          },
          { headers: { Authorization: cookie.access_token } }
        );
        if (students.data.error) alert(students.data.error.message);
        else {
          setStudents(students.data.result);
        }
      }
    })();
  }, [profile]);

  return (
    <>
      {students && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <AllergyTable students={students} />
        </div>
      )}
    </>
  );
}

function AllergyTable({ students }) {
  function download() {
    let sheet = [];
    let no = 1;
    for (let student of students) {
      let obj = {
        no: no++,
        group: student.current_group.name,
        prefix: student.prefix,
        name:
          student.first_name +
          " " +
          (student.middle_name || "") +
          " " +
          student.last_name,
        nickname: student.nickname || "",
        food: "",
      };
      for (let food of student.food_allergy) {
        obj.food += food.food_name;
        if (food.symptoms) obj.food += " (" + food.symptoms + ")";
        obj.food += ", ";
      }
      sheet.push(obj);
    }
    let exportState = {
      sheet_name: "Food",
      head: [
        { label: "ลำดับ", value: "no" },
        { label: "ห้องเรียน", value: "group" },
        { label: "คำนำหน้า", value: "prefix" },
        { label: "ชื่อ - นามสกุล", value: "name" },
        { label: "ชื่อเล่น", value: "nickname" },
        { label: "อาหารที่แพ้", value: "food" },
      ],
      sheet,
    };
    cookie.export = exportState;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
    document.getElementById("downloadFrame").src =
      process.env.PUBLIC_URL + "/exportexcel";
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="th" style={{ width: "80px" }}>
                ลำดับ
              </TableCell>
              <TableCell className="th" style={{ width: "120px" }}>
                ห้องเรียน
              </TableCell>
              <TableCell className="th" style={{ width: "80px" }}>
                คำนำหน้า
              </TableCell>
              <TableCell className="th" style={{ width: "250px" }}>
                ชื่อ -นามสุกล
              </TableCell>
              <TableCell className="th" style={{ width: "120px" }}>
                ชื่อเล่น
              </TableCell>
              <TableCell className="th">อาหารที่แพ้</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, key) => (
              <TableRow key={key}>
                <TableCell className="td" align="center">
                  {key + 1}
                </TableCell>
                <TableCell className="td" align="center">
                  {student.current_group && student.current_group.name}
                </TableCell>
                <TableCell className="td" align="center">
                  {student.prefix}
                </TableCell>
                <TableCell className="td">
                  {student.first_name} {student.middle_name} {student.last_name}
                </TableCell>
                <TableCell className="td" align="center">
                  {student.nickname}
                </TableCell>
                <TableCell>
                  {student.food_allergy.map((food, key) => (
                    <div key={key}>
                      {food.food_name} {food.symptoms && <>({food.symptoms})</>}
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Button
          variant="contained"
          style={{ marginTop: "20px", width: "150px" }}
          startIcon={<Icon>download</Icon>}
          onClick={download}
        >
          Download
        </Button>
      </div>
      <iframe
        name="downloadFrame"
        id="downloadFrame"
        title="downloadFrame"
        style={{ width: "0px", height: "0px", border: "none" }}
      ></iframe>
    </div>
  );
}
