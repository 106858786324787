import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";

export default function CustomSeparator({ breadcrumbs }) {
  /*
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      MUI
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/getting-started/installation/"
      onClick={handleClick}
    >
      Core
    </Link>,
    <Typography key="3" color="text.primary">
      Breadcrumb
    </Typography>,
  ];
  */
  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((item, key) => (
          <div key={key}>
            {item.to ? (
              <>
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to={item.to}
                >
                  <div>{item.label}</div>
                </Link>
              </>
            ) : (
              <Typography color="text.primary" component="div">
                {item.label}
              </Typography>
            )}
          </div>
        ))}
      </Breadcrumbs>
    </Stack>
  );
}
