import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { getFullName } from "../../js/main";
import Conf from "../../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Curators({ student }) {
  const [curators, setCurators] = useState();
  useEffect(() => {
    (async () => {
      let r = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "curators",
          limit: 0,
          match: { student_id: student._id },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      setCurators(r.data.result || []);
    })();
  }, [student]);

  return (
    <div>
      {curators && (
        <>
          <ReportTable
            title="บุคคลที่ติดต่อฉุกเฉิน"
            curators={curators.filter((j) => j.curator_type === "emergency")}
          />
          <ReportTable
            title="ข้อมูลผู้ปกครอง"
            curators={curators.filter((j) => j.curator_type === "curator")}
          />
          <ReportTable
            title="ข้อมูลบิดา"
            curators={curators.filter((j) => j.curator_type === "father")}
          />
          <ReportTable
            title="ข้อมูลมารดา"
            curators={curators.filter((j) => j.curator_type === "mothor")}
          />
        </>
      )}
    </div>
  );
}

function ReportTable({ title, curators }) {
  return (
    <div>
      <h3 style={{ fontWeight: "500" }}>{title}</h3>
      {curators.length === 0 ? (
        <Paper
          style={{ color: "#999", lineHeight: "60px", textAlign: "center" }}
        >
          ไม่พบข้อมูล
        </Paper>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">ชื่อ - นามสกุล</TableCell>
                  <TableCell align="center" style={{ width: "30%" }}>
                    ความสัมพันธ์
                  </TableCell>
                  <TableCell align="center" style={{ width: "30%" }}>
                    เบอร์โทร
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {curators.map((curator) => (
                  <TableRow key={curator._id}>
                    <TableCell>{getFullName(curator)}</TableCell>
                    <TableCell align="center">{curator.relation}</TableCell>
                    <TableCell align="center">{curator.phone_number}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
