import React, { useEffect, useState } from "react";
import { url2code } from "../js/main";
import axios from "axios";
import Conf from "../Conf";
// import useSound from "use-sound";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
export default function Checkin() {
  // const [welcome] = useSound("/asset/welcome.mp3", { volume: 1 });
  // const [exists] = useSound("/asset/exists.mp3", { volume: 0.25 });
  // const [not_found] = useSound("/asset/not_found.mp3", { volume: 0.25 });
  //https://play.ht/text-to-speech-voices/ibm-watson/
  const [student, setStudent] = useState();
  const [audio, setAudio] = useState({
    playing: false,
    // welcome: new Audio(process.env.PUBLIC_URL + "/asset/welcome.mp3"),
    // exists: new Audio(process.env.PUBLIC_URL + "/asset/exists.mp3"),
    // not_found: new Audio(process.env.PUBLIC_URL + "/asset/not_found.mp3"),
  });
  useEffect(() => {
    document.getElementById("barcode").select();
  }, []);
  useEffect(() => {
    if (audio.playing && audio.playing !== false) {
      /*
      if (audio.playing === "not_found") audio.not_found.play();
      else if (audio.playing === "exists") audio.exists.play();
      else audio.welcome.play();
      */

      if (audio.playing === "not_found") {
        let x = document.getElementById("not_found_audio");
        x.play();
        // not_found();
      } else if (audio.playing === "exists") {
        let x = document.getElementById("exists_audio");
        x.play();
      } else {
        let x = document.getElementById("welcome_audio");
        x.play();
      }
      setAudio({ ...audio, playing: false });
    }
  }, [audio]);
  async function save() {
    let barcode = document.getElementById("barcode").value;
    // barcode = "http://hcs.tonkla.ac.th/9e7lzi3whp";
    barcode = await url2code(barcode);
    document.getElementById("barcode").value = "";
    if (document.getElementById("non_save").checked === true) {
      let result = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "students",
          match: {
            $or: [{ student_qrcode: barcode }, { curator_qrcode: barcode }],
          },
          select: "_id current_group prefix first_name middle_name last_name",
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      if (result.data.count < 1) setAudio({ ...audio, playing: "not_found" });
      else {
        setStudent(result.data.result[0]);
        setAudio({ ...audio, playing: "welcome" });
      }
    } else {
      let result = await axios.put(
        Conf.endpoint.tonkla + "/reg/checkpresent/checkin",
        {
          qrcode: barcode,
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      if (result.data.error) {
        if (result.data.error.message === "Invalid qr code")
          setAudio({ ...audio, playing: "not_found" });
        else setAudio({ ...audio, playing: "exists" });
      } else {
        setStudent(result.data);
        setAudio({ ...audio, playing: "welcome" });
      }
    }
    document.getElementById("barcode").select();
  }
  return (
    <div
      style={{ height: "100vh", textAlign: "center" }}
      onClick={() => document.getElementById("barcode").select()}
    >
      <TextField
        label="Qr Code"
        variant="outlined"
        id="barcode"
        onKeyUp={(e) => e.key === "Enter" && save()}
        style={{ marginTop: "30vh", width: "400px" }}
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
      />
      <div>
        <FormControlLabel
          control={<Checkbox id="non_save" />}
          label={<div>ไม่บันทึกลงฐานข้อมูล</div>}
        />
      </div>
      <div>
        {student && (
          <div
            style={{ paddingTop: "20px", fontSize: "1.5rem" }}
            className="bold"
          >
            {student.prefix} {student.first_name} {student.middle_name}{" "}
            {student.last_name}
          </div>
        )}
      </div>
      <audio id="not_found_audio">
        <source
          src={process.env.PUBLIC_URL + "/asset/not_found.ogg"}
          type="audio/ogg"
        />
      </audio>
      <audio id="welcome_audio">
        <source
          src={process.env.PUBLIC_URL + "/asset/welcome.ogg"}
          type="audio/ogg"
        />
      </audio>
      <audio id="exists_audio">
        <source
          src={process.env.PUBLIC_URL + "/asset/exists.ogg"}
          type="audio/ogg"
        />
      </audio>
    </div>
  );
}
