import React, { useEffect, useState } from "react";
import { months } from "../../const";
import { getFullName, getStampTime } from "../../js/main";
import { GridSelect } from "../../components/InputComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Conf from "../../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function WeightAndTall({ students }) {
  const [state, setState] = useState();
  const [evo, setEvo] = useState();
  function getEvo(students, year) {
    return new Promise(async (resolve) => {
      let ids = await students.map((j) => j._id);
      let result = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "monthly_evolutions",
          match: { student: { $in: ids }, year: year },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      let evo = [];
      for (let student of students) {
        let arr = result.data.result.filter((j) => j.student === student._id);
        evo.push({
          _id: student._id,
          fullname: getFullName(student),
          nickname: student.nickname,
          evo: arr,
        });
      }
      resolve(evo);
    });
  }
  useEffect(() => {
    (async () => {
      if (students) {
        let dt = await getStampTime();
        let year = dt.detail.year;
        let evo = await getEvo(students, year);
        let years = [];
        for (let i = year - 5; i < year + 2; i++) {
          years.push({
            label: i + 543,
            value: i,
          });
        }
        setState({ month: null, year, years });
        setEvo(evo);
      }
    })();
  }, [students]);
  function changeEvo(key, field, value, monthNo) {
    let newEvo = JSON.parse(JSON.stringify(evo));
    let i = newEvo[key].evo.findIndex((j) => j.month === monthNo);
    if (i < 0) {
      newEvo[key].evo.push({
        month: monthNo,
        year: state.year,
        [field]: value,
      });
    } else {
      newEvo[key].evo[i][field] = value;
    }
    setEvo(newEvo);
  }
  async function save() {
    let send = [];
    for (let student of evo) {
      for (let ev of student.evo) {
        ev.student = student._id;
        send.push(ev);
      }
    }
    let result = await axios.put(
      Conf.endpoint.tonkla + "/reg/evolution/monthly",
      send,
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    if (result.data.error) alert(result.data.error.message);
    else {
      let newEvo = await getEvo(students, state.year);
      setEvo(newEvo);
      alert("Save complete");
    }
  }
  async function changeYear(val) {
    setState({ ...state, year: val });
    let newEvo = await getEvo(students, val);
    setEvo(newEvo);
  }
  return (
    <div>
      {evo && (
        <>
          <Grid container style={{ marginBottom: "20px" }}>
            <GridSelect
              grid={[12, 12, 6, 4]}
              label="สถิติปี"
              disabled_empty={true}
              onChange={changeYear}
              options={state.years}
              value={state.year}
            />
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1900 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="th"
                    style={{ width: "80px" }}
                    rowSpan={2}
                  >
                    No
                  </TableCell>
                  <TableCell
                    className="th"
                    rowSpan={2}
                    style={{ minWidth: "220px" }}
                  >
                    Full name
                  </TableCell>
                  <TableCell
                    className="th"
                    style={{ width: "120px" }}
                    rowSpan={2}
                  >
                    Nickname
                  </TableCell>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
                    <TableCell
                      key={i}
                      className="controlTd"
                      colSpan={2}
                      style={{ borderLeft: "1px solid #ccc" }}
                    >
                      <Button
                        onClick={() => {
                          if (state.selectMonth === i)
                            setState({ ...state, selectMonth: null });
                          else setState({ ...state, selectMonth: i });
                        }}
                      >
                        {months[i].label}
                      </Button>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
                    <React.Fragment key={i}>
                      <TableCell
                        className="th"
                        style={{ width: "70px", borderLeft: "1px solid #ccc" }}
                      >
                        นน
                      </TableCell>
                      <TableCell
                        className="th"
                        style={{ width: "70px", borderLeft: "1px solid #ccc" }}
                      >
                        สส
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {evo.map((student, key) => (
                  <TableRow key={key} hover>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ fontSize: ".8rem" }}
                    >
                      {key + 1}
                    </TableCell>
                    <TableCell className="td" style={{ fontSize: ".8rem" }}>
                      {student.fullname}
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ fontSize: ".8rem" }}
                    >
                      {student.nickname}
                    </TableCell>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
                      <InputRow
                        key={i}
                        monthNo={i}
                        selectMonth={state.selectMonth}
                        evo={student.evo}
                        onChange={(field, value) =>
                          changeEvo(key, field, value, i + 1)
                        }
                      />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button
            variant="contained"
            style={{ width: "150px" }}
            startIcon={<Icon>save</Icon>}
            onClick={save}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
}

function InputRow({ monthNo, selectMonth, evo, onChange }) {
  function showValue(field) {
    let i = evo.findIndex((j) => j.month === monthNo + 1);
    if (i > -1) return evo[i][field];
    else return "";
  }
  return (
    <>
      <TableCell
        className="inputTd"
        align="center"
        style={{
          borderLeft: "1px solid #ccc",
          fontSize: ".8rem",
        }}
      >
        {selectMonth === monthNo ? (
          <TextField
            size="small"
            variant="outlined"
            InputProps={{ style: { fontSize: ".8rem" } }}
            value={showValue("weight") || ""}
            onChange={(e) => onChange("weight", e.target.value)}
          />
        ) : (
          <>{showValue("weight")}</>
        )}
      </TableCell>
      <TableCell
        className="inputTd"
        align="center"
        style={{
          borderLeft: "1px solid #ccc",
          fontSize: ".8rem",
        }}
      >
        {selectMonth === monthNo ? (
          <TextField
            size="small"
            variant="outlined"
            InputProps={{ style: { fontSize: ".8rem" } }}
            value={showValue("tall") || ""}
            onChange={(e) => onChange("tall", e.target.value)}
          />
        ) : (
          <>{showValue("tall")}</>
        )}
      </TableCell>
    </>
  );
}
