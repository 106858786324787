import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";

import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { GridSelect /*, GridInput*/ } from "../components/InputComponents";
import { checkPermission } from "../js/main";

import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const getOptions = async (type) => {
  return new Promise(async (resolve) => {
    let result = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      {
        collection: "options",
        match: { type },
        sort: "priority label",
      },
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    resolve(result);
  });
};

export default function Blank(props) {
  const [profile, setProfile] = useState();
  const [options, setOptions] = useState([]);
  const [state, setState] = useState({ type: "-" });
  const [types] = useState([
    { label: "คำนำหน้าชื่อ", value: "prefix" },
    { label: "คำนำหน้าชื่อนักเรียน", value: "student_prefix" },
    // { label: "หลักฐานการสมัครเรียน", value: "register_document" },
    { label: "สัญชาติ", value: "nationality" },
    { label: "เชื้อชาติ", value: "origin" },
    { label: "ศาสนา", value: "religion" },
    { label: "อาชีพ", value: "job" },
    { label: "สถานภาพบุคคล", value: "person_status" },
    { label: "สถานภาพครอบครัว", value: "family_status" },
  ]);
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
        }
      }
    })();
  }, [props]);

  useEffect(() => {
    (async () => {
      let type = state.type;
      if (type && type !== "-") {
        let options = await getOptions(type);
        setOptions(options.data.result);
      }
    })();
  }, [state.type]);

  useEffect(() => {
    (async () => {
      if (types) {
        let type = types[0].value;
        setState({ type });
      }
    })();
  }, [types]);

  return (
    <>
      {profile && state && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Grid container>
            <GridSelect
              grid={[12, 6, 4, 3]}
              label="Option Type"
              options={types}
              onChange={(val) => setState({ ...state, type: val })}
              value={state.type || "-"}
            />
          </Grid>
          <br />
          {options && <TableOptions options={options} type={state.type} />}
        </div>
      )}
    </>
  );
}

function TableOptions({ options, type }) {
  const [optionList, setOptionList] = useState([]);
  const [state, setState] = useState({});
  useEffect(() => {
    if (options) {
      setState({});
      setOptionList(options);
    }
  }, [options]);
  async function save(form) {
    let Options = JSON.parse(JSON.stringify(optionList));
    if (form.is_default === true) {
      for (let i = 0; i < Options.length; i++) {
        Options[i].is_default = false;
      }
    }
    form.type = type;
    let result = await axios.put(Conf.endpoint.tonkla + "/reg/option", form, {
      headers: { Authorization: cookie.access_token },
    });
    if (form._id) {
      let i = Options.findIndex((j) => j._id === form._id);
      if (i > -1) {
        Options[i] = result.data;
      }
    } else {
      Options.push(result.data);
    }
    setOptionList(Options);
    setState({ ...state, select_id: null });
  }
  async function deleteOption(id) {
    if (window.confirm("Confirm to delete")) {
      await axios.delete(Conf.endpoint.tonkla + "/reg/option/" + id, {
        headers: { Authorization: cookie.access_token },
      });
      let Options = JSON.parse(JSON.stringify(optionList));
      Options = await Options.filter((j) => j._id !== id);
      setOptionList(Options);
      setState({ ...state, select_id: null });
    }
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className="td"
                align="center"
                style={{ width: "80px" }}
              >
                ลำดับ
              </TableCell>
              <TableCell className="td" align="center">
                รายการ
              </TableCell>
              <TableCell className="td" align="center">
                รายการ (ภาษาอังกฤษ)
              </TableCell>
              <TableCell
                className="td"
                align="center"
                style={{ width: "80px" }}
              >
                ค่าเริมต้น
              </TableCell>
              <TableCell
                className="td"
                align="center"
                style={{ width: "100px" }}
              >
                ดำเนินการ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <InputRow data={{}} onSave={save} />
            {optionList.map((option, key) => (
              <React.Fragment key={key}>
                {option._id === state.select_id ? (
                  <InputRow
                    data={option}
                    onSave={save}
                    onReset={() => setState({ ...state, select_id: null })}
                    onDelete={(id) => deleteOption(id)}
                  />
                ) : (
                  <DataRow
                    data={option}
                    onSelect={(id) => setState({ ...state, select_id: id })}
                    no={key + 1}
                  />
                )}
              </React.Fragment>
              //   <InputRow data={option} onSave={save} key={key} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
function DataRow({ data, onSelect, no }) {
  return (
    <TableRow>
      <TableCell className="controlTd" align="center">
        {no}
      </TableCell>
      <TableCell className="inputTd">{data.label || ""}</TableCell>
      <TableCell className="inputTd">{data.label_alt || ""}</TableCell>
      <TableCell className="inputTd" align="center">
        {data.is_default === true && <Icon>done</Icon>}
      </TableCell>
      <TableCell className="controlTd" align="center">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => onSelect(data._id)}
        >
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function InputRow({ data, onSave, onReset, onDelete }) {
  const [form, setForm] = useState(data || {});
  function save() {
    onSave(form);
    if (!data._id) setForm({});
  }
  return (
    <TableRow>
      <TableCell className="controlTd" align="center">
        {form._id ? (
          <IconButton
            color="error"
            aria-label="upload picture"
            component="span"
            disabled={!form.label || form.label === ""}
            onClick={() => onDelete(form._id)}
          >
            <Icon>clear</Icon>
          </IconButton>
        ) : (
          <Icon style={{ color: "#999" }}>add</Icon>
        )}
      </TableCell>
      <TableCell className="inputTd">
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          value={form.label || ""}
          onChange={(e) => setForm({ ...form, label: e.target.value })}
        />
      </TableCell>
      <TableCell className="inputTd">
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          value={form.label_alt || ""}
          onChange={(e) => setForm({ ...form, label_alt: e.target.value })}
        />
      </TableCell>
      <TableCell className="inputTd" align="center">
        <Checkbox
          checked={form.is_default || false}
          value={true}
          onChange={(e) => setForm({ ...form, is_default: e.target.checked })}
        />
      </TableCell>
      <TableCell className="controlTd" align="center">
        {form._id && (
          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
            disabled={!form.label || form.label === ""}
            onClick={onReset}
          >
            <Icon>undo</Icon>
          </IconButton>
        )}
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          disabled={!form.label || form.label === ""}
          onClick={save}
        >
          <Icon>save</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
