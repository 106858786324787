import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import MultiType from "../charts/MultiType";
// import GroupStudent from "./rptStudent/GroupStudent";
// import Brethren from "./rptStudent/Brethren";
// import Resign from "./rptStudent/Resign";
import { GridSelect } from "../components/InputComponents";
import {
  checkPermission,
  // getGroups,
  getGroupsStudents,
  getGroupAdvisor,
} from "../js/main";
// import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Healthy(props) {
  const [profile, setProfile] = useState();
  const [state, setState] = useState();

  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
          console.log(props.profile.reg_staff);
          // let groups = await getGroups();
          let groups = await getGroupAdvisor(
            props.profile.usn,
            props.profile.reg_staff
          );
          groups = { groups: groups.result };
          console.log(groups);
          setState({ groups: groups.groups });
        }
      }
    })();
  }, [props]);
  useEffect(() => {}, [state]);

  return (
    <>
      {state && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Allergy groups={state.groups} />
          <iframe
            name="downloadFrame"
            id="downloadFrame"
            title="downloadFrame"
            style={{ width: "0px", height: "0px", border: "none" }}
          ></iframe>
        </div>
      )}
    </>
  );
}
function setGroupSelectValues(groups) {
  return new Promise(async (resolve) => {
    let gr = await groups.map((j) => {
      return { label: j.name, value: j._id };
    });
    resolve(gr);
  });
}

function Allergy({ groups }) {
  const [state, setState] = useState();
  const [students, setStudents] = useState();
  useEffect(() => {
    (async () => {
      if (groups[0]) {
        let gr = await setGroupSelectValues(groups);
        setState({ groups: gr, group: gr[0].value });
      }
    })();
  }, [groups]);
  useEffect(() => {
    (async () => {
      if (state && state.group) {
        let students = await getGroupsStudents(state.group);
        setStudents(students.result);
      }
    })();
  }, [state]);
  async function download() {
    let sheet = [];
    let no = 1;
    for (let student of students) {
      let disease = "";
      for (let item of student.congenital_disease) {
        disease += item.disease_name;
        if (item.symptoms) disease += "(" + item.symptoms + ")";
        disease += ", ";
      }
      let drug = "";
      for (let item of student.drug_allergy) {
        drug += item.drug_name;
        if (item.symptoms) drug += "(" + item.symptoms + ")";
        drug += ", ";
      }
      let food = "";
      for (let item of student.food_allergy) {
        food += item.food_name;
        if (item.symptoms) food += "(" + item.symptoms + ")";
        food += ", ";
      }
      let obj = {
        no: no++,
        fullname:
          student.prefix +
          " " +
          student.first_name +
          " " +
          (student.middle_name || "") +
          " " +
          student.last_name,
        nickname: student.nickname || "",
        disease,
        drug,
        food,
      };
      for (let food of student.food_allergy) {
        obj.food += food.food_name;
        if (food.symptoms) obj.food += " (" + food.symptoms + ")";
        obj.food += ", ";
      }
      sheet.push(obj);
    }
    let exportState = {
      sheet_name: state.group,
      head: [
        { label: "ลำดับ", value: "no" },
        { label: "ชื่อสกุล", value: "fullname" },
        { label: "ชื่อเล่น", value: "nickname" },
        { label: "โรคประจำตัว", value: "disease" },
        { label: "การแพ้ยา", value: "drug" },
        { label: "การแพ้อาหาร", value: "food" },
      ],
      sheet,
    };
    cookie.export = exportState;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
    document.getElementById("downloadFrame").src =
      process.env.PUBLIC_URL + "/exportexcel";
  }
  return (
    <div>
      {students && (
        <>
          <Grid container style={{ marginBottom: "20px" }}>
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Group"
              options={state.groups || []}
              onChange={(val) => setState({ ...state, group: val })}
              value={state.group}
              disabled_empty={true}
            />
            <Grid item xs={12} sm={6} md={9} lg={9} align="right">
              <Button
                variant="contained"
                style={{ width: "150px" }}
                startIcon={<Icon>download</Icon>}
                onClick={download}
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="th" style={{ width: "80px" }}>
                    ลำดับ
                  </TableCell>
                  <TableCell className="th">ชื่อสกุล</TableCell>
                  <TableCell className="th" style={{ width: "100px" }}>
                    ชื่อเล่น
                  </TableCell>
                  <TableCell className="th" style={{ width: "200px" }}>
                    โรคประจำตัว
                  </TableCell>
                  <TableCell className="th" style={{ width: "200px" }}>
                    การแพ้ยา
                  </TableCell>
                  <TableCell className="th" style={{ width: "200px" }}>
                    การแพ้อาหาร
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, key) => (
                  <TableRow hover key={key}>
                    <TableCell align="center" className="td">
                      {key + 1}
                    </TableCell>
                    <TableCell className="td">
                      {student.prefix} {student.first_name}{" "}
                      {student.middle_name} {student.last_name}
                    </TableCell>
                    <TableCell className="td" align="center">
                      {student.nickname}
                    </TableCell>
                    <TableCell className="td">
                      {student.congenital_disease.map((item, key) => (
                        <div key={key}>
                          {item.disease_name}{" "}
                          {item.symptoms && <>({item.symptoms})</>}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell className="td">
                      {student.drug_allergy.map((item, key) => (
                        <div key={key}>
                          {item.drug_name}{" "}
                          {item.symptoms && <>({item.symptoms})</>}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell className="td">
                      {student.food_allergy.map((item, key) => (
                        <div key={key}>
                          {item.food_name}{" "}
                          {item.symptoms && <>({item.symptoms})</>}
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
                {students.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      ไม่พบข้อมูล
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
