import React from "react";
import ReactExport from "react-export-excel";
import Conf from "../Conf";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
/*
const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
];
*/
function Download({ datas }) {
  return (
    <ExcelFile hideElement={true} filename={datas.filename || "Download"}>
      {/* <ExcelSheet data={dataSet1} name="Employees">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Wallet Money" value="amount" />
        <ExcelColumn label="Gender" value="sex" />
        <ExcelColumn
          label="Marital Status"
          value={(col) => (col.is_married ? "Married" : "Single")}
        />
      </ExcelSheet> */}
      <ExcelSheet data={datas.sheet} name="Sheet1">
        {datas.head.map((data, key) => (
          <ExcelColumn key={key} label={data.label} value={data.value} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
}

export default function ExcelExport() {
  const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
  const datas = cookie && cookie.export ? cookie.export : null;
  return <>{datas && <Download datas={datas} />}</>;
}
