import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { checkPermission, getStampTime, getGroups } from "../js/main";
import Grid from "@mui/material/Grid";

import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function PP7E(props) {
  const { id } = useParams();
  const [student, setStudent] = useState();
  const [state, setState] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile && props.page && id) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setState({
            profile: props.profile,
            id: id,
            current_date: await getCurrentDate(),
          });
        }
      }
    })();
  }, [props, id]);
  useEffect(() => {
    (async () => {
      if (state) {
        let Student = await axios.get(
          Conf.endpoint.tonkla + "/reg/query/students/" + state.id,
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        if (Student.data && Student.data._id) {
          let Curators = await axios.post(
            Conf.endpoint.tonkla + "/reg/query",
            {
              collection: "curators",
              match: { student_id: Student.data._id },
            },
            {
              headers: {
                Authorization: cookie.access_token,
              },
            }
          );
          Student.data.father_name = await getCuratorName(
            Curators.data.result,
            "father"
          );
          Student.data.mother_name = await getCuratorName(
            Curators.data.result,
            "mother"
          );
          if (Student.data.current_group) {
            let group = await getGroups();
            let i = group.groups.findIndex(
              (j) => j._id === Student.data.current_group
            );
            if (i > -1) {
              let level = group.groups[i].level;
              let year = group.groups[i].level_year;
              if (level === "เตรียมอนุบาล") level = "Pre Kindergarten";
              else if (level === "อนุบาล") level = "Kindergarten";
              else if (level === "ประถมศึกษา" && year < 4)
                level = "Lower Primary";
              else if (level === "ประถมศึกษา" && year > 3)
                level = "Lower Upper";
              if (year) level += " " + year;
              Student.data.level_text = level;
            }

            let dt = await getStampTime(Student.data.birth_date);
            Student.data.birth_date_text =
              dt.detail.month_en + " " + dt.detail.day + ", " + dt.detail.year;
          }
          setStudent(Student.data);
        }
      }
    })();
  }, [state]);

  useEffect(() => {
    if (student) {
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }, [student]);

  function getCuratorName(curators, type) {
    return new Promise((resolve) => {
      let curator = curators.filter((j) => j.curator_type === type);
      if (curator[0]) {
        let name =
          curator[0].prefix_en +
          " " +
          curator[0].first_name_en +
          " " +
          (curator[0].middle_name_en || "") +
          " " +
          curator[0].last_name_en;
        resolve(name);
      } else resolve("");
    });
  }
  async function getCurrentDate() {
    return new Promise(async (resolve) => {
      let dt = await getStampTime();
      resolve(dt.detail.day + "/" + dt.detail.month + "/" + dt.detail.year);
    });
  }
  return (
    <div
      style={{
        width: "21cm",
        height: "29.7cm",
        margin: "auto",
        padding: "2cm 2.5cm",
        fontSize: "16pt",
      }}
    >
      {student && (
        <>
          <Grid container>
            <Grid item xs={4}>
              <br />
              <br />
              <Grid container>
                <Grid item style={{ paddingRight: ".2cm" }}>
                  No
                </Grid>
                <Grid
                  item
                  style={{ width: "4cm", borderBottom: "1px dotted #000" }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} align="center">
              <img
                src={process.env.PUBLIC_URL + "/asset/img/printlogo.png"}
                style={{ width: "5cm" }}
                alt=""
              />
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: "1cm" }}>
              <div style={{ textAlign: "right" }}>PP.7</div>
              <br />
              Tonkla school
              <br />
              292 Moo 6 Nong Chom,
              <br />
              Sansai, Chiang Mai 50210
            </Grid>
            <Grid item xs={6}>
              <br />
              <br />
              To Whom It May Concern,
            </Grid>
            <Grid item xs={6}>
              <br />
              {state.current_date}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  marginTop: "1cm",
                  textAlign: "justify",
                  textIndent: "2.5cm",
                  lineHeight: "24pt",
                }}
              >
                This letter is to certify that {student.prefix_en}{" "}
                {student.first_name_en} {student.middle_name_en}{" "}
                {student.last_name_en} is studying in {student.level_text} at
                Tonkla School. Her student ID number is {student.student_code}{" "}
                and ID card number is {student.idx}. She was born on{" "}
                {student.birth_date_text}. Her father is {student.father_name}{" "}
                and her mother is Miss Amporn Davies .<br />
                <div
                  style={{
                    marginTop: "1cm",
                    textAlign: "justify",
                    textIndent: "2.5cm",
                    lineHeight: "24pt",
                  }}
                >
                  Please feel free to contact our office if you have any further
                  inquiries.
                </div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ paddingTop: "5.5cm" }}>
              <div
                style={{
                  width: "2.5cm",
                  height: "3.3cm",
                  border: "2px solid #000",
                  fontSize: "10pt",
                  textAlign: "center",
                }}
              >
                <br />
                <br />
                รูปถ่ายขนาด
                <br />
                <br />1 นิ้ว
              </div>
            </Grid>
            <Grid item xs={10} align="center" style={{ paddingTop: "1cm" }}>
              Yours sincerely,
              <br />
              <br />
              <br />
              <br />
              (Mrs. Chalida Satitniramai)
              <br />
              Director
              <br />
              Tonkla School
              <br />
            </Grid>

            <Grid
              item
              xs={5}
              align="center"
              style={{ lingHeight: "50px", paddingTop: "3.5cm" }}
            >
              (Mr. Chitsanupong Kowitphutthinan)
              <br />
              Registrar
              <br />
              Tonkla school
            </Grid>
            <Grid item xs={7} />
            <Grid item xs={12}>
              <br />
              Registration Office
              <br />
              Tel. (+66) 5334-5912
              <br />
              Fax (+66) 5334-5913
            </Grid>
            <Grid
              item
              xs={12}
              align="center"
              style={{ fontSize: "12pt", paddingTop: "3cm" }}
            >
              (The certificate is valid for 120 days from the date of issue)
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
