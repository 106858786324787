import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
// import Conf from "../Conf";
// import axios from "axios";
import { showUploadImageSrc } from "../js/main";

export default function StudentList(props) {
  const { onSelect } = props;
  const [students, setStudents] = useState();
  useEffect(() => {
    (async () => {
      if (props.students) {
        console.log("Start Render");
        let students = JSON.parse(JSON.stringify(props.students));
        /*
        for (let i = 0; i < students.length; i++) {
          if (students[i].photo_url) {
            let src = await axios.get(
              Conf.endpoint.upload + "/" + students[i].photo_url
            );
            students[i].icon = (
              <div
                style={{
                  background: "url(" + src.data + ")",
                  backgroundSize: "cover",
                  borderRadius: "50%",
                  width: "70px",
                  height: "70px",
                  border: "1px solid #ccc",
                }}
              ></div>
            );
          } else {
            students[i].icon = <Icon style={{ fontSize: "4rem" }}>person</Icon>;
          }
        }
        */
        console.log("Set Student");
        setStudents(students);
      }
    })();
  }, [props]);

  return (
    <Box sx={{ width: "100%" }} component={Paper}>
      <nav>
        <List>
          {students && students.length === 0 && (
            <ListItem>
              <ListItemText>
                <div style={{ textAlign: "center", lineHeight: "60px" }}>
                  Not found result
                </div>
              </ListItemText>
            </ListItem>
          )}
          {students &&
            students.map((student, key) => (
              <Fragment key={key}>
                <ItemStudent student={student} onSelect={onSelect} />
                {/* <ListItem disablePadding>
                  <ListItemButton
                    component={onSelect ? "button" : Link}
                    to={process.env.PUBLIC_URL + "/student/" + student._id}
                    onClick={() => {
                      onSelect && onSelect(student);
                    }}
                  >
                    <ListItemIcon style={{ width: "90px" }}>
                      {student.icon}
                    </ListItemIcon>
                    <ListItemText>
                      <div>
                        {student.prefix} {student.first_name}{" "}
                        {student.middle_name} {student.last_name}
                      </div>
                      <div style={{ fontSize: ".8rem", color: "#090" }}>
                        Nickname : {student.nickname || "-"}
                      </div>
                      <div style={{ fontSize: ".8rem", color: "#999" }}>
                        ID : {student.student_code || "-"}
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem> */}
                {key < students.length - 1 && <Divider />}
              </Fragment>
            ))}
        </List>
      </nav>
    </Box>
  );
}

function ItemStudent({ student, onSelect }) {
  const [icon, setIcon] = useState();
  useEffect(() => {
    (async () => {
      if (student && student.photo_url) {
        let src = await showUploadImageSrc(student.photo_url);
        let icon = (
          <div
            style={{
              backgroundImage: "url(" + src + ")",
              backgroundSize: "cover",
              borderRadius: "50%",
              width: "70px",
              height: "70px",
              border: "1px solid #ccc",
            }}
          ></div>
        );
        setIcon(icon);
      } else setIcon(null);
    })();
  }, [student]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={onSelect ? "button" : Link}
        to={process.env.PUBLIC_URL + "/student/" + student._id}
        onClick={() => {
          onSelect && onSelect(student);
        }}
      >
        <ListItemIcon style={{ width: "90px" }}>
          {student.photo_url && icon ? (
            icon
          ) : (
            <Icon style={{ fontSize: "4rem" }}>person</Icon>
          )}
        </ListItemIcon>
        <ListItemText>
          <div>
            {student.prefix} {student.first_name} {student.middle_name}{" "}
            {student.last_name}
          </div>
          <div style={{ fontSize: ".8rem", color: "#999" }}>
            {student.prefix_en} {student.first_name_en} {student.middle_name_en}{" "}
            {student.last_name_en}
          </div>
          <div style={{ fontSize: ".7rem", color: "#090" }}>
            Nickname : {student.nickname || "-"}
          </div>
          <div style={{ fontSize: ".7rem", color: "#999" }}>
            ID : {student.student_code || "-"}
          </div>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
