import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import Icon from "@mui/material/Icon";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs /*, { Dayjs }*/ from "dayjs";
/*
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
*/
import { getStampTime } from "../js/main";

export function GridSelect(props) {
  const {
    grid,
    options,
    label,
    size,
    onChange,
    value,
    placeholder,
    disabled_empty,
    required,
    id,
    disabled,
  } = props;
  return (
    <Grid item xs={grid[0]} sm={grid[1]} md={grid[2]} lg={grid[3]}>
      <FormControl
        fullWidth
        size={size || "small"}
        required={required}
        disabled={disabled || false}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          native
          id={id}
          label={label}
          value={value || "-"}
          onChange={(e) => onChange(e.target.value)}
        >
          {disabled_empty !== true && <option value="-">{placeholder}</option>}
          {options &&
            options.map((option, key) => (
              <option key={key} value={option.value || option.label}>
                {option.label}
              </option>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export function GridInput(props) {
  const {
    grid,
    label,
    size,
    onChange,
    value,
    placeholder,
    rows,
    type,
    required,
    id,
    disabled,
  } = props;
  return (
    <Grid item xs={grid[0]} sm={grid[1]} md={grid[2]} lg={grid[3]}>
      <TextField
        id={id}
        value={value || ""}
        size={size || "small"}
        multiline={rows ? true : false}
        type={type || "text"}
        rows={rows || 1}
        label={label}
        placeholder={placeholder || ""}
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        fullWidth
        required={required}
        disabled={disabled || false}
      />
    </Grid>
  );
}

export function GridDatePicker(props) {
  const {
    grid,
    label,
    size,
    onChange,
    value,
    placeholder,
    required,
    id,
    gridStyles,
  } = props;
  async function changeDate(value) {
    let dt = await getStampTime(value);
    onChange(dt.iso_date);
  }
  return (
    <Grid
      item
      xs={grid[0]}
      sm={grid[1]}
      md={grid[2]}
      lg={grid[3]}
      style={gridStyles}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]}> */}
        <DatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={dayjs(value)}
          onChange={changeDate}
          placeholder={placeholder || ""}
          fullWidth
          // renderInput={(params) => (
          //   <TextField
          //     id={id}
          //     {...params}
          //     required={required}
          //     fullWidth
          //     size={size || "small"}
          //     style={{ background: "#fff" }}
          //   />
          // )}

          slotProps={{
            textField: {
              id,
              required,
              size: size || "small",
              InputLabelProps: { shrink: true },
              fullWidth: true,
              sx: { background: "#fff" },
            },
          }}
        />
        {/* </DemoContainer> */}
      </LocalizationProvider>
    </Grid>
  );
}

export function InputTable({ title, data, icon, cols, changeFields }) {
  const [table, setTable] = useState();
  const [form, setForm] = useState({});
  const [state, setState] = useState({});
  useEffect(() => {
    (async () => {
      if (data) setTable(data);
    })();
  }, [data]);
  function addRow() {
    if (form.value0 && form.value0 !== "") {
      let Table = JSON.parse(JSON.stringify(table));
      Table.push(form);
      changeFields(Table);
      setTable(Table);
      setForm({});
    }
  }
  function deleteRow(key) {
    let Table = JSON.parse(JSON.stringify(table));
    Table.splice(key, 1);
    changeFields(Table);
    setTable(Table);
    setState({ ...state, selectRow: -1 });
  }
  function saveRow(key, row) {
    let Table = JSON.parse(JSON.stringify(table));
    Table[key] = row;
    changeFields(Table);
    setTable(Table);
    setState({ ...state, selectRow: -1 });
  }
  return (
    <div>
      {title && (
        <Grid container style={{ margin: "20px auto 10px" }}>
          <Grid item style={{ marginRight: "10px" }}>
            <Icon>{icon}</Icon>
          </Grid>
          <Grid item style={{ fontSize: ".9rem" }}>
            {title}
          </Grid>
        </Grid>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="td"
                align="center"
                style={{ width: "80px" }}
              >
                ลำดับ
              </TableCell>
              {cols &&
                cols.map((col, key) => (
                  <TableCell
                    className="td"
                    align="center"
                    key={key}
                    style={col.style || {}}
                  >
                    {col.title}
                  </TableCell>
                ))}

              <TableCell className="td" style={{ width: "100px" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {table &&
              table.map((row, key) => (
                <React.Fragment key={key}>
                  {state.selectRow !== key ? (
                    <InputTableRow
                      no={key + 1}
                      row={row}
                      cols={cols}
                      onSelect={() => setState({ ...state, selectRow: key })}
                    />
                  ) : (
                    <EditTableRow
                      row={row}
                      cols={cols}
                      clearSelect={() => setState({ ...state, selectRow: -1 })}
                      deleteRow={() => deleteRow(key)}
                      saveRow={(form) => saveRow(key, form)}
                    />
                  )}
                </React.Fragment>
              ))}
            <TableRow>
              <TableCell />
              {cols &&
                cols.map((col, key) => (
                  <TableCell className="inputTd" key={key}>
                    {(() => {
                      switch (col.inputType) {
                        case "date":
                          return (
                            <Grid container spacing={0}>
                              <GridDatePicker
                                grid={[12, 12, 12, 12]}
                                value={form["value" + key] || ""}
                                onChange={(val) =>
                                  setForm({ ...form, ["value" + key]: val })
                                }
                              />
                            </Grid>
                          );
                        default:
                          return (
                            <TextField
                              variant="outlined"
                              fullWidth
                              value={form["value" + key] || ""}
                              size="small"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  ["value" + key]: e.target.value,
                                })
                              }
                            />
                          );
                      }
                    })()}
                  </TableCell>
                ))}
              <TableCell className="inputTd" align="center">
                <IconButton color="primary" component="span" onClick={addRow}>
                  <Icon>save</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function InputTableRow({ no, row, cols, onSelect }) {
  return (
    <TableRow>
      <TableCell className="td" align="center">
        {no}
      </TableCell>
      {cols.map((col, key) => (
        <TableCell className="td" key={key} style={col.valueStyle || {}}>
          {row["value" + key]}
        </TableCell>
      ))}
      <TableCell className="controlTd" align="center">
        <IconButton color="primary" component="span" onClick={onSelect}>
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function EditTableRow({ row, cols, clearSelect, deleteRow, saveRow }) {
  const [form, setForm] = useState({});
  useEffect(() => {
    (async () => {
      if (row) setForm(row);
    })();
  }, [row]);
  return (
    <TableRow style={{ background: "#efe" }}>
      <TableCell className="controlTd" align="center">
        <IconButton
          color="error"
          component="span"
          onClick={deleteRow}
          align="center"
        >
          <Icon>clear</Icon>
        </IconButton>
      </TableCell>
      {cols.map((col, key) => (
        <TableCell className="inputTd" key={key} style={col.valueStyle || {}}>
          {(() => {
            switch (col.inputType) {
              case "date":
                return (
                  <Grid container spacing={0}>
                    <GridDatePicker
                      grid={[12, 12, 12, 12]}
                      value={form["value" + key]}
                      onChange={(val) =>
                        setForm({ ...form, ["value" + key]: val })
                      }
                    />
                  </Grid>
                );
              default:
                return (
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={form["value" + key] || ""}
                    size="small"
                    style={{ background: "#fff" }}
                    onChange={(e) =>
                      setForm({ ...form, ["value" + key]: e.target.value })
                    }
                  />
                );
            }
          })()}
        </TableCell>
      ))}
      <TableCell className="controlTd" align="center">
        <IconButton color="inherit" component="span" onClick={clearSelect}>
          <Icon style={{ color: "#2196f3" }}>undo</Icon>
        </IconButton>

        <IconButton
          color="primary"
          component="span"
          onClick={() => saveRow(form)}
        >
          <Icon>save</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

/*
function EditTableRow({ no, values, saveRow, deleteRow, clearSelect }) {
  const [form, setForm] = useState([]);
  useEffect(() => {
    (async () => {
      if (values && values[0]) {
        setForm(values);
      }
    })();
  }, [values]);
  return (
    <TableRow style={{ background: "#efe" }}>
      <TableCell className="controlTd" align="center">
        <IconButton
          color="error"
          component="span"
          onClick={deleteRow}
          align="center"
        >
          <Icon>clear</Icon>
        </IconButton>
      </TableCell>
      <TableCell className="inputTd">
        <TextField
          variant="outlined"
          fullWidth
          value={form[0] || ""}
          size="small"
          style={{ background: "#fff" }}
          onChange={(e) => setForm({ ...form, 0: e.target.value })}
        />
      </TableCell>
      <TableCell className="controlTd" align="center">
        <IconButton
          color="inherit"
          component="span"
          onClick={clearSelect}
          align="center"
        >
          <Icon style={{ color: "#2196f3" }}>undo</Icon>
        </IconButton>
        <IconButton
          color="primary"
          component="span"
          onClick={() => saveRow({ key: no, values: form })}
          align="center"
        >
          <Icon>save</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
*/
