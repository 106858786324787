import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MultiType from "../../charts/MultiType";
import axios from "axios";
import Conf from "../../Conf";
import { sortJSON } from "../../js/main";
import { student_normal_status } from "../../const";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function LevelReport() {
  const [levels, setLevels] = useState();
  const [chartState, setChartState] = useState();
  const [totals, setTotals] = useState();
  useEffect(() => {
    function getLevels() {
      return new Promise(async (resolve) => {
        let groups = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          { collection: "groups", select: "-student_id -limit -advisors" },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(groups.data.result);
      });
    }

    function getStudents() {
      return new Promise(async (resolve) => {
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: { student_status: student_normal_status },
            select: "gender current_group",
            limit: 0,
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(students.data.result);
      });
    }
    function renderReport(levels, students) {
      return new Promise(async (resolve) => {
        let Totals = { male: 0, female: 0, sum: 0 };
        for (let level of levels) {
          let Students = students.filter((j) => j.current_group === level._id);
          level.total_student = Students.length;
          level.total_male_student = 0;
          level.total_female_student = 0;
          for (let student of Students) {
            if (student.gender === "ชาย") {
              level.total_male_student++;
              Totals.male++;
            } else if (student.gender === "หญิง") {
              level.total_female_student++;
              Totals.female++;
            }
            Totals.sum++;
          }
        }
        setTotals(Totals);
        levels = levels.filter((j) => j.total_student > 0);
        let Levels = [];
        levels = await sortJSON(levels, "name");
        for (let level of levels) {
          let level_name = level.name;
          /*
          if (level.level_year && level.level_year !== "-") {
            level_name += " " + level.level_year;
          }
          */
          let i = Levels.findIndex((j) => j.name === level_name);
          if (i < 0) {
            Levels.push({
              name: level_name,
              total: level.total_student,
              male: level.total_male_student,
              female: level.total_female_student,
            });
          } else {
            Levels[i].total += level.total_student;
            Levels[i].male += level.total_male_student;
            Levels[i].female += level.total_female_student;
          }
        }
        resolve(Levels);
      });
    }
    (async () => {
      let Levels = await getLevels();
      let Students = await getStudents();
      Levels = await renderReport(Levels, Students);
      setLevels(Levels);
      setChartState({
        title: "จำนวนนักเรียน",
        labels: await Levels.map((level) => level.name),
        datasets: [
          {
            type: "bar",
            label: "ชาย",
            backgroundColor: "rgb(75, 192, 192)",
            data: await Levels.map((level) => level.male),
            borderColor: "white",
          },
          {
            type: "bar",
            label: "หญิง",
            backgroundColor: "rgb(53, 162, 235)",
            data: await Levels.map((level) => level.female),
            borderColor: "white",
          },
          {
            type: "bar",
            label: "รวม",
            backgroundColor: "rgb(255, 99, 132)",
            data: await Levels.map((level) => level.total),
            borderColor: "white",
          },
        ],
      });
    })();
  }, []);
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ width: "60px" }}
                      rowSpan={2}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell className="th" align="center" rowSpan={2}>
                      ชั้นเรียน
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      colSpan={3}
                      style={{ borderLeft: "1px solid #ccc" }}
                    >
                      จำนวนนักเรียน
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px", borderLeft: "1px solid #ccc" }}
                    >
                      ชาย
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px" }}
                    >
                      หญิง
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px" }}
                    >
                      รวม
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {levels &&
                    levels.map((level, key) => (
                      <TableRow key={key}>
                        <TableCell className="td" align="center">
                          {key + 1}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {level.name}
                        </TableCell>
                        <TableCell
                          className="td"
                          align="center"
                          style={{ borderLeft: "1px solid #ccc" }}
                        >
                          {level.male}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {level.female}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {level.total}
                        </TableCell>
                      </TableRow>
                    ))}
                  {totals && (
                    <>
                      <TableRow>
                        <TableCell colSpan={2} className="bold" align="center">
                          รวมทั้งหมด
                        </TableCell>
                        <TableCell className="bold" align="center">
                          {totals.male}
                        </TableCell>
                        <TableCell className="bold" align="center">
                          {totals.female}
                        </TableCell>
                        <TableCell className="bold" align="center">
                          {totals.sum}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper style={{ padding: "10px 10px" }}>
            {chartState && <MultiType state={chartState} />}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
