export const registrar_name = "นาย​ชิ​ษนุ​พงศ์​ โกวิท​พุฒิ​นันท์";
export const director_name = "นาง​ชลิ​ดา​ สถิต​นิรา​มัย​";
export const student_prefixs = [
  { value: "เด็กหญิง", label: "เด็กหญิง (Girl)" },
  { value: "เด็กชาย", label: "เด็กชาย (Boy)" },
  { value: "นาย", label: "นาย (Mr.)" },
  { value: "นางสาว", label: "นางสาว (Miss)" },
];
export const student_normal_status = "5e098ba9d6cad40654a9fd3f";
export const genders = [
  { value: "หญิง", label: "หญิง" },
  { value: "ชาย", label: "ชาย" },
];
export const jobs = [
  { label: "ข้าราชการ" },
  { label: "เกษตรกร" },
  { label: "พนักงานราชการ" },
  { label: "พนักงานบริษัทฯ" },
  { label: "พนักงานรัฐวิสาหกิจ" },
  { label: "ลูกจ้าง" },
  { label: "ธุรกิจส่วนตัว" },
  { label: "ค้าขาย" },
  { label: "อื่น ๆ" },
];

export const registration_documents = [
  {
    label: "สูติบัตรนักเรียน",
    group_no: 0,
    value: 1,
  },
  {
    label: "บัตรประชาชนนักเรียน",
    group_no: 0,
    value: 2,
  },
  {
    label: "ทะเบียนบ้านนักเรียน",
    group_no: 0,
    value: 3,
  },
  {
    label: "รูปถ่ายนักเรียน",
    group_no: 0,
    value: 4,
  },
  {
    label: "ใบเปลี่ยนชื่อตัว",
    group_no: 0,
    value: 5,
  },
  {
    label: "ใบเปลี่ยนชื่อสกุล",
    group_no: 0,
    value: 6,
  },
  {
    label: "เอกสารผลการเรียน (ปพ.1)",
    group_no: 0,
    value: 7,
  },
  {
    label: "บัตรประชาชนบิดา",
    group_no: 1,
    value: 101,
  },
  {
    label: "ทะเบียนบ้านบิดา",
    group_no: 1,
    value: 102,
  },
  {
    label: "รูปถ่ายบิดา",
    group_no: 1,
    value: 103,
  },
  {
    label: "ใบเปลี่ยนชื่อตัวบิดา",
    group_no: 1,
    value: 104,
  },
  {
    label: "ใบเปลี่ยนชื่อสกุลบิดา",
    group_no: 1,
    value: 105,
  },
  {
    label: "บัตรประชาชนมารดา",
    group_no: 2,
    value: 201,
  },
  {
    label: "ทะเบียนบ้านมารดา",
    group_no: 2,
    value: 202,
  },
  {
    label: "รูปถ่ายมารดา",
    group_no: 2,
    value: 203,
  },
  {
    label: "ใบเปลี่ยนชื่อตัวมารดา",
    group_no: 2,
    value: 204,
  },
  {
    label: "ใบเปลี่ยนชื่อสกุลมารดา",
    group_no: 2,
    value: 205,
  },
];

export const programs = [
  { label: "MEP (Mini English Program)" },
  { label: "IEP (Integrated English Program)" },
  { label: "IP (Inter Program)" },
];

// export const prefixs = [
//   { value: "นาย", label: "นาย (Mr.)" },
//   { value: "นาง", label: "นาง (Mrs)" },
//   { value: "นางสาว", label: "นางสาว (Miss)" },
// ];
export const prefixs = [
  {
    code: 1,
    prefix_th: "เด็กชาย",
    prefix_abbrev: "ด.ช.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 2,
    prefix_th: "เด็กหญิง",
    prefix_abbrev: "ด.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "MISS",
  },
  {
    code: 3,
    prefix_th: "นาย",
    prefix_abbrev: "นาย",
    prefix_en: "MR.",
    prefix_abbrev_en: "MR.",
  },
  {
    code: 4,
    prefix_th: "นางสาว",
    prefix_abbrev: "น.ส.",
    prefix_en: "MISS",
    prefix_abbrev_en: "MISS",
  },
  {
    code: 5,
    prefix_th: "นาง",
    prefix_abbrev: "นาง",
    prefix_en: "MRS.",
    prefix_abbrev_en: "MRS.",
  },
  {
    code: 120,
    prefix_th: "หม่อมเจ้า",
    prefix_abbrev: "ม.จ.",
    prefix_en: "M.C",
    prefix_abbrev_en: "M.C",
  },
  {
    code: 121,
    prefix_th: "หม่อมราชวงศ์",
    prefix_abbrev: "ม.ร.ว.",
    prefix_en: "M.R",
    prefix_abbrev_en: "M.R",
  },
  {
    code: 122,
    prefix_th: "หม่อมหลวง",
    prefix_abbrev: "หม่อมหลวง",
    prefix_en: "M.L",
    prefix_abbrev_en: "M.L",
  },
  {
    code: 123,
    prefix_th: "พระยา",
    prefix_abbrev: "พระยา",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 124,
    prefix_th: "หลวง",
    prefix_abbrev: "หลวง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 125,
    prefix_th: "ขุน",
    prefix_abbrev: "ขุน",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 126,
    prefix_th: "หมื่น",
    prefix_abbrev: "หมื่น",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 127,
    prefix_th: "เจ้าคุณ",
    prefix_abbrev: "เจ้าคุณ",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 129,
    prefix_th: "คุณ",
    prefix_abbrev: "คุณ",
    prefix_en: "KHUN",
    prefix_abbrev_en: "KHUN",
  },
  {
    code: 130,
    prefix_th: "คุณหญิง",
    prefix_abbrev: "คุณหญิง",
    prefix_en: "KHUNYING",
    prefix_abbrev_en: "KHUNYING",
  },
  {
    code: 134,
    prefix_th: "นายแพทย์",
    prefix_abbrev: "น.พ.",
    prefix_en: "DR.",
    prefix_abbrev_en: "DR.",
  },
  {
    code: 135,
    prefix_th: "แพทย์หญิง",
    prefix_abbrev: "พ.ญ.",
    prefix_en: "DR.",
    prefix_abbrev_en: "DR.",
  },
  {
    code: 136,
    prefix_th: "ทันตแพทย์",
    prefix_abbrev: "ท.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 137,
    prefix_th: "ทัณตแพทย์หญิง",
    prefix_abbrev: "ท.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 138,
    prefix_th: "สัตวแพทย์",
    prefix_abbrev: "สพ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 139,
    prefix_th: "สัตวแพทย์หญิง",
    prefix_abbrev: "สญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 140,
    prefix_th: "ดอกเตอร์",
    prefix_abbrev: "ดร.",
    prefix_en: "DR.",
    prefix_abbrev_en: "DR.",
  },
  {
    code: 142,
    prefix_th: "รองศาสตราจารย์",
    prefix_abbrev: "รศ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 143,
    prefix_th: "ศาสตราจารย์",
    prefix_abbrev: "ศ.",
    prefix_en: "PROF.",
    prefix_abbrev_en: "PROF.",
  },
  {
    code: 144,
    prefix_th: "เภสัชกรชาย",
    prefix_abbrev: "ภก.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 145,
    prefix_th: "เภสัชกรหญิง",
    prefix_abbrev: "ภญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 146,
    prefix_th: "หม่อม",
    prefix_abbrev: "หม่อม",
    prefix_en: "MOM",
    prefix_abbrev_en: "MOM",
  },
  {
    code: 147,
    prefix_th: "รองอำมาตย์เอก",
    prefix_abbrev: "รองอำมาตย์เอก",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 148,
    prefix_th: "ท้าว",
    prefix_abbrev: "ท้าว",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 149,
    prefix_th: "เจ้า",
    prefix_abbrev: "เจ้า",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 150,
    prefix_th: "ท่านผู้หญิง",
    prefix_abbrev: "ท่านผู้หญิง",
    prefix_en: "THANPHUYING",
    prefix_abbrev_en: "THANPHUYING",
  },
  {
    code: 151,
    prefix_th: "คุณพระ",
    prefix_abbrev: "คุณพระ",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 153,
    prefix_th: "ซิสเตอร์",
    prefix_abbrev: "ซิสเตอร์",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 154,
    prefix_th: "เจ้าชาย",
    prefix_abbrev: "เจ้าชาย",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 155,
    prefix_th: "เจ้าหญิง",
    prefix_abbrev: "เจ้าหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 156,
    prefix_th: "รองเสวกตรี",
    prefix_abbrev: "รองเสวกตรี",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 157,
    prefix_th: "เสด็จเจ้า",
    prefix_abbrev: "เสด็จเจ้า",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 158,
    prefix_th: "เอกอัครราชฑูต",
    prefix_abbrev: "เอกอัครราชฑูต",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 159,
    prefix_th: "พลสารวัตร",
    prefix_abbrev: "พลสารวัตร",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 160,
    prefix_th: "สมเด็จเจ้า",
    prefix_abbrev: "สมเด็จเจ้า",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 161,
    prefix_th: "เจ้าฟ้า",
    prefix_abbrev: "เจ้าฟ้า",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 162,
    prefix_th: "รองอำมาตย์ตรี",
    prefix_abbrev: "รองอำมาตย์ตรี",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 163,
    prefix_th: "หม่อมเจ้าหญิง",
    prefix_abbrev: "ม.จ.หญิง",
    prefix_en: "M.C.",
    prefix_abbrev_en: "M.C.",
  },
  {
    code: 164,
    prefix_th: "ทูลกระหม่อม",
    prefix_abbrev: "ทูลกระหม่อม",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 166,
    prefix_th: "เจ้านาง",
    prefix_abbrev: "เจ้านาง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 167,
    prefix_th: "จ่าสำรอง",
    prefix_abbrev: "จ่าสำรอง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 170,
    prefix_th: "กรมพระยา",
    prefix_abbrev: "กรมพระยา",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 171,
    prefix_th: "เจ้าพระยา",
    prefix_abbrev: "เจ้าพระยา",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 200,
    prefix_th: "พลเอก",
    prefix_abbrev: "พล.อ.",
    prefix_en: "GEN.",
    prefix_abbrev_en: "GEN.",
  },
  {
    code: 201,
    prefix_th: "ว่าที่พลเอก",
    prefix_abbrev: "ว่าที่ พล.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 202,
    prefix_th: "พลโท",
    prefix_abbrev: "พล.ท.",
    prefix_en: "LT.GEN.",
    prefix_abbrev_en: "LT.GEN.",
  },
  {
    code: 204,
    prefix_th: "พลตรี",
    prefix_abbrev: "พล.ต.",
    prefix_en: "MAJ.GEN.",
    prefix_abbrev_en: "MAJ.GEN.",
  },
  {
    code: 205,
    prefix_th: "ว่าที่พลตรี",
    prefix_abbrev: "ว่าที่ พล.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 206,
    prefix_th: "พันเอกพิเศษ",
    prefix_abbrev: "พ.อ.(พิเศษ)",
    prefix_en: "COL.",
    prefix_abbrev_en: "COL.",
  },
  {
    code: 208,
    prefix_th: "พันเอก",
    prefix_abbrev: "พ.อ.",
    prefix_en: "COL.",
    prefix_abbrev_en: "COL.",
  },
  {
    code: 209,
    prefix_th: "ว่าที่พันเอก",
    prefix_abbrev: "ว่าที่ พ.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 210,
    prefix_th: "พันโท",
    prefix_abbrev: "พ.ท.",
    prefix_en: "LT.COL.",
    prefix_abbrev_en: "LT.COL.",
  },
  {
    code: 211,
    prefix_th: "ว่าที่พันโท",
    prefix_abbrev: "ว่าที่ พ.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 212,
    prefix_th: "พันตรี",
    prefix_abbrev: "พ.ต.",
    prefix_en: "MAJ.",
    prefix_abbrev_en: "MAJ.",
  },
  {
    code: 213,
    prefix_th: "ว่าที่พันตรี",
    prefix_abbrev: "ว่าที่ พ.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 214,
    prefix_th: "ร้อยเอก",
    prefix_abbrev: "ร.อ.",
    prefix_en: "CAPT.",
    prefix_abbrev_en: "CAPT.",
  },
  {
    code: 215,
    prefix_th: "ว่าที่ร้อยเอก",
    prefix_abbrev: "ว่าที่ ร.อ.",
    prefix_en: "ACTING CAPT.",
    prefix_abbrev_en: "ACTING CAPT.",
  },
  {
    code: 216,
    prefix_th: "ร้อยโท",
    prefix_abbrev: "ร.ท.",
    prefix_en: "LT.",
    prefix_abbrev_en: "LT.",
  },
  {
    code: 217,
    prefix_th: "ว่าที่ร้อยโท",
    prefix_abbrev: "ว่าที่ ร.ท.",
    prefix_en: "ACTING LT.",
    prefix_abbrev_en: "ACTING LT.",
  },
  {
    code: 218,
    prefix_th: "ร้อยตรี",
    prefix_abbrev: "ร.ต.",
    prefix_en: "SUB.LT.",
    prefix_abbrev_en: "SUB.LT.",
  },
  {
    code: 219,
    prefix_th: "ว่าที่ร้อยตรี",
    prefix_abbrev: "ว่าที่ ร.ต.",
    prefix_en: "ACTING SUB LT.",
    prefix_abbrev_en: "ACTING SUB LT.",
  },
  {
    code: 220,
    prefix_th: "จ่าสิบเอก",
    prefix_abbrev: "จ.ส.อ.",
    prefix_en: "SM 1",
    prefix_abbrev_en: "SM 1",
  },
  {
    code: 221,
    prefix_th: "จ่าสิบโท",
    prefix_abbrev: "จ.ส.ท.",
    prefix_en: "SM 2",
    prefix_abbrev_en: "SM 2",
  },
  {
    code: 222,
    prefix_th: "จ่าสิบตรี",
    prefix_abbrev: "จ.ส.ต.",
    prefix_en: "SM 3",
    prefix_abbrev_en: "SM 3",
  },
  {
    code: 223,
    prefix_th: "สิบเอก",
    prefix_abbrev: "ส.อ.",
    prefix_en: "SGT.",
    prefix_abbrev_en: "SGT.",
  },
  {
    code: 224,
    prefix_th: "สิบโท",
    prefix_abbrev: "ส.ท.",
    prefix_en: "CPL.",
    prefix_abbrev_en: "CPL.",
  },
  {
    code: 225,
    prefix_th: "สิบตรี",
    prefix_abbrev: "ส.ต.",
    prefix_en: "PFC.",
    prefix_abbrev_en: "PFC.",
  },
  {
    code: 226,
    prefix_th: "พลทหาร",
    prefix_abbrev: "พลฯ",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 227,
    prefix_th: "นักเรียนนายร้อย",
    prefix_abbrev: "นนร.",
    prefix_en: "ARMY CADET",
    prefix_abbrev_en: "ARMY CADET",
  },
  {
    code: 228,
    prefix_th: "นักเรียนนายสิบ",
    prefix_abbrev: "นนส.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 229,
    prefix_th: "พลจัตวา",
    prefix_abbrev: "พล.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 230,
    prefix_th: "พลฯ อาสาสมัคร",
    prefix_abbrev: "พลฯ อาสา",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 231,
    prefix_th: "ร้อยเอกหม่อมเจ้า",
    prefix_abbrev: "ร.อ.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 232,
    prefix_th: "พลโทหม่อมเจ้า",
    prefix_abbrev: "พล.ท.ม.จ.",
    prefix_en: "LT.GEN.M.C.",
    prefix_abbrev_en: "LT.GEN.M.C.",
  },
  {
    code: 233,
    prefix_th: "ร้อยตรีหม่อมเจ้า",
    prefix_abbrev: "ร.ต.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 234,
    prefix_th: "ร้อยโทหม่อมเจ้า",
    prefix_abbrev: "ร.ท.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 235,
    prefix_th: "พันโทหม่อมเจ้า",
    prefix_abbrev: "พ.ท.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 236,
    prefix_th: "พันเอกหม่อมเจ้า",
    prefix_abbrev: "พ.อ.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 247,
    prefix_th: "พลฯ หม่อมหลวง",
    prefix_abbrev: "พลฯม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 248,
    prefix_th: "ร้อยเอกหม่อมหลวง",
    prefix_abbrev: "ร.อ.ม.ล.",
    prefix_en: "CAPT.M.L.",
    prefix_abbrev_en: "CAPT.M.L.",
  },
  {
    code: 249,
    prefix_th: "สิบโทหม่อมหลวง",
    prefix_abbrev: "ส.ท.ม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 250,
    prefix_th: "พลโทหม่อมหลวง",
    prefix_abbrev: "พล.ท.ม.ล.",
    prefix_en: "LT.GEN.M.L.",
    prefix_abbrev_en: "LT.GEN.M.L.",
  },
  {
    code: 251,
    prefix_th: "ร้อยโทหม่อมหลวง",
    prefix_abbrev: "ร.ท.ม.ล.",
    prefix_en: "LT.M.L.",
    prefix_abbrev_en: "LT.M.L.",
  },
  {
    code: 252,
    prefix_th: "ร้อยตรีหม่อมหลวง",
    prefix_abbrev: "ร.ต.ม.ล.",
    prefix_en: "SUB LT.M.L.",
    prefix_abbrev_en: "SUB LT.M.L.",
  },
  {
    code: 253,
    prefix_th: "สิบเอกหม่อมหลวง",
    prefix_abbrev: "ส.อ.ม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 254,
    prefix_th: "พลตรีหม่อมหลวง",
    prefix_abbrev: "พล.ต.ม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 255,
    prefix_th: "พันตรีหม่อมหลวง",
    prefix_abbrev: "พ.ต.ม.ล.",
    prefix_en: "MAJ.M.L.",
    prefix_abbrev_en: "MAJ.M.L.",
  },
  {
    code: 256,
    prefix_th: "พันเอกหม่อมหลวง",
    prefix_abbrev: "พ.อ.ม.ล.",
    prefix_en: "COL.M.L.",
    prefix_abbrev_en: "COL.M.L.",
  },
  {
    code: 257,
    prefix_th: "พันโทหม่อมหลวง",
    prefix_abbrev: "พ.ท.ม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 262,
    prefix_th: "ร้อยเอกนายแพทย์",
    prefix_abbrev: "ร.อ.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 263,
    prefix_th: "ร้อยเอกแพทย์หญิง",
    prefix_abbrev: "ร.อ.พ.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 264,
    prefix_th: "ร้อยโทนายแพทย์",
    prefix_abbrev: "ร.ท.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 265,
    prefix_th: "พันตรีนายแพทย์",
    prefix_abbrev: "พ.ต.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 267,
    prefix_th: "พันเอกนายแพทย์",
    prefix_abbrev: "พ.อ.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 268,
    prefix_th: "ร้อยตรีนายแพทย์",
    prefix_abbrev: "ร.ต.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 269,
    prefix_th: "ร้อยโทแพทย์หญิง",
    prefix_abbrev: "ร.ท.พ.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 270,
    prefix_th: "พลตรีนายแพทย์",
    prefix_abbrev: "พล.ต.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 271,
    prefix_th: "พันโทนายแพทย์",
    prefix_abbrev: "พ.ท.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 272,
    prefix_th: "จอมพล",
    prefix_abbrev: "จอมพล",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 273,
    prefix_th: "พันโทหลวง",
    prefix_abbrev: "พ.ท.หลวง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 276,
    prefix_th: "พันตรีหลวง",
    prefix_abbrev: "พ.ต.หลวง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 277,
    prefix_th: "พลโทหลวง",
    prefix_abbrev: "พล.ท.หลวง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 278,
    prefix_th: "ร้อยโทดอกเตอร์",
    prefix_abbrev: "ร.ท.ดร.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 279,
    prefix_th: "ร้อยเอกดอกเตอร์",
    prefix_abbrev: "ร.อ.ดร.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 280,
    prefix_th: "สารวัตรทหาร",
    prefix_abbrev: "ส.ห.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 281,
    prefix_th: "ร้อยตรีดอกเตอร์",
    prefix_abbrev: "ร.ต.ดร.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 282,
    prefix_th: "พันตรีคุณหญิง",
    prefix_abbrev: "พ.ต.คุณหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 284,
    prefix_th: "พลจัตวาหลวง",
    prefix_abbrev: "พล.จ.หลวง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 289,
    prefix_th: "พันโทคุณหญิง",
    prefix_abbrev: "พ.ท.คุณหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 290,
    prefix_th: "ร้อยตรีแพทย์หญิง",
    prefix_abbrev: "ร.ต.พ.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 291,
    prefix_th: "พลเอกหม่อมหลวง",
    prefix_abbrev: "พล.อ.มล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 294,
    prefix_th: "จ่าสิบเอกพิเศษ",
    prefix_abbrev: "จ.ส.อ.พิเศษ",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 295,
    prefix_th: "พลตรีหญิงคุณหญิง",
    prefix_abbrev: "พล.ต.หญิง คุณหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 351,
    prefix_th: "พลเรือเอก",
    prefix_abbrev: "พล.ร.อ.",
    prefix_en: "ADM.",
    prefix_abbrev_en: "ADM.",
  },
  {
    code: 352,
    prefix_th: "ว่าที่พลเรือเอก",
    prefix_abbrev: "ว่าที่ พล.ร.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 353,
    prefix_th: "พลเรือโท",
    prefix_abbrev: "พล.ร.ท.",
    prefix_en: "VADM.",
    prefix_abbrev_en: "VADM.",
  },
  {
    code: 354,
    prefix_th: "ว่าที่พลเรือโท",
    prefix_abbrev: "ว่าที่ พล.ร.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 355,
    prefix_th: "พลเรือตรี",
    prefix_abbrev: "พล.ร.ต.",
    prefix_en: "RADM.",
    prefix_abbrev_en: "RADM.",
  },
  {
    code: 356,
    prefix_th: "ว่าที่พลเรือตรี",
    prefix_abbrev: "ว่าที่ พล.ร.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 357,
    prefix_th: "นาวาเอกพิเศษ",
    prefix_abbrev: "น.อ.พิเศษ",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 359,
    prefix_th: "นาวาเอก",
    prefix_abbrev: "น.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 360,
    prefix_th: "ว่าที่นาวาเอก",
    prefix_abbrev: "ว่าที่ น.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 361,
    prefix_th: "นาวาโท",
    prefix_abbrev: "น.ท.",
    prefix_en: "CDR.",
    prefix_abbrev_en: "CDR.",
  },
  {
    code: 362,
    prefix_th: "ว่าที่นาวาโท",
    prefix_abbrev: "ว่าที่ น.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 363,
    prefix_th: "นาวาตรี",
    prefix_abbrev: "น.ต.",
    prefix_en: "LCDR.",
    prefix_abbrev_en: "LCDR.",
  },
  {
    code: 364,
    prefix_th: "ว่าที่นาวาตรี",
    prefix_abbrev: "ว่าที่ น.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 365,
    prefix_th: "เรือเอก",
    prefix_abbrev: "ร.อ.",
    prefix_en: "LT.",
    prefix_abbrev_en: "LT.",
  },
  {
    code: 366,
    prefix_th: "ว่าที่เรือเอก",
    prefix_abbrev: "ว่าที่ ร.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 367,
    prefix_th: "เรือโท",
    prefix_abbrev: "ร.ท.",
    prefix_en: "LT.JG.",
    prefix_abbrev_en: "LT.JG.",
  },
  {
    code: 368,
    prefix_th: "ว่าที่เรือโท",
    prefix_abbrev: "ว่าที่ ร.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 369,
    prefix_th: "เรือตรี",
    prefix_abbrev: "ร.ต.",
    prefix_en: "SUB LT.",
    prefix_abbrev_en: "SUB LT.",
  },
  {
    code: 370,
    prefix_th: "ว่าที่เรือตรี",
    prefix_abbrev: "ว่าที่ ร.ต.",
    prefix_en: "ACTING POL.SUB.LT.",
    prefix_abbrev_en: "ACTING POL.SUB.LT.",
  },
  {
    code: 371,
    prefix_th: "พันจ่าเอก",
    prefix_abbrev: "พ.จ.อ.",
    prefix_en: "CPO.1",
    prefix_abbrev_en: "CPO.1",
  },
  {
    code: 372,
    prefix_th: "พันจ่าโท",
    prefix_abbrev: "พ.จ.ท.",
    prefix_en: "CPO.2",
    prefix_abbrev_en: "CPO.2",
  },
  {
    code: 373,
    prefix_th: "พันจ่าตรี",
    prefix_abbrev: "พ.จ.ต.",
    prefix_en: "CPO.3",
    prefix_abbrev_en: "CPO.3",
  },
  {
    code: 374,
    prefix_th: "จ่าเอก",
    prefix_abbrev: "จ.อ.",
    prefix_en: "PO 1",
    prefix_abbrev_en: "PO 1",
  },
  {
    code: 375,
    prefix_th: "จ่าโท",
    prefix_abbrev: "จ.ท.",
    prefix_en: "PO 2",
    prefix_abbrev_en: "PO 2",
  },
  {
    code: 376,
    prefix_th: "จ่าตรี",
    prefix_abbrev: "จ.ต.",
    prefix_en: "PO 3",
    prefix_abbrev_en: "PO 3",
  },
  {
    code: 377,
    prefix_th: "พลฯทหารเรือ",
    prefix_abbrev: "พลฯ",
    prefix_en: "SEAMAN",
    prefix_abbrev_en: "SEAMAN",
  },
  {
    code: 378,
    prefix_th: "นักเรียนนายเรือ",
    prefix_abbrev: "นนร.",
    prefix_en: "NAVAL CADET",
    prefix_abbrev_en: "NAVAL CADET",
  },
  {
    code: 380,
    prefix_th: "พลเรือจัตวา",
    prefix_abbrev: "พล.ร.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 381,
    prefix_th: "นาวาโทหม่อมเจ้า",
    prefix_abbrev: "น.ท.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 382,
    prefix_th: "นาวาเอกหม่อมเจ้า",
    prefix_abbrev: "น.อ.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 383,
    prefix_th: "นาวาตรีหม่อมเจ้า",
    prefix_abbrev: "น.ต.ม.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 388,
    prefix_th: "นาวาโทหม่อมหลวง",
    prefix_abbrev: "น.ท.ม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 389,
    prefix_th: "นาวาตรีหม่อมหลวง",
    prefix_abbrev: "น.ต.ม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 391,
    prefix_th: "นาวาตรีแพทย์หญิง",
    prefix_abbrev: "น.ต.พ.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 392,
    prefix_th: "นาวาอากาศเอกหลวง",
    prefix_abbrev: "น.อ.หลวง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 394,
    prefix_th: "นาวาเอกหม่อมหลวง",
    prefix_abbrev: "น.อ.ม.ล.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 395,
    prefix_th: "นาวาตรีนายแพทย์",
    prefix_abbrev: "น.ต.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 397,
    prefix_th: "เรือเอกนายแพทย์",
    prefix_abbrev: "ร.อ.น.พ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 500,
    prefix_th: "พลอากาศเอก",
    prefix_abbrev: "พล.อ.อ.",
    prefix_en: "ACM",
    prefix_abbrev_en: "ACM",
  },
  {
    code: 501,
    prefix_th: "ว่าที่พลอากาศเอก",
    prefix_abbrev: "ว่าที่ พล.อ.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 502,
    prefix_th: "พลอากาศโท",
    prefix_abbrev: "พล.อ.ท.",
    prefix_en: "AM.",
    prefix_abbrev_en: "AM.",
  },
  {
    code: 503,
    prefix_th: "ว่าที่พลอากาศโท",
    prefix_abbrev: "ว่าที่ พล.อ.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 504,
    prefix_th: "พลอากาศตรี",
    prefix_abbrev: "พล.อ.ต.",
    prefix_en: "AVM",
    prefix_abbrev_en: "AVM",
  },
  {
    code: 505,
    prefix_th: "ว่าที่พลอากาศตรี",
    prefix_abbrev: "ว่าที่ พล.อ.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 508,
    prefix_th: "นาวาอากาศเอก",
    prefix_abbrev: "น.อ.",
    prefix_en: "GP.CAPT.",
    prefix_abbrev_en: "GP.CAPT.",
  },
  {
    code: 510,
    prefix_th: "นาวาอากาศโท",
    prefix_abbrev: "น.ท.",
    prefix_en: "WG.CDR.",
    prefix_abbrev_en: "WG.CDR.",
  },
  {
    code: 512,
    prefix_th: "นาวาอากาศตรี",
    prefix_abbrev: "น.ต.",
    prefix_en: "SQN.LDR.",
    prefix_abbrev_en: "SQN.LDR.",
  },
  {
    code: 514,
    prefix_th: "เรืออากาศเอก",
    prefix_abbrev: "ร.อ.",
    prefix_en: "FLT.LT.",
    prefix_abbrev_en: "FLT.LT.",
  },
  {
    code: 516,
    prefix_th: "เรืออากาศโท",
    prefix_abbrev: "ร.ท.",
    prefix_en: "FLG.OFF",
    prefix_abbrev_en: "FLG.OFF",
  },
  {
    code: 518,
    prefix_th: "เรืออากาศตรี",
    prefix_abbrev: "ร.ต.",
    prefix_en: "PLT.OFF.",
    prefix_abbrev_en: "PLT.OFF.",
  },
  {
    code: 520,
    prefix_th: "พันจ่าอากาศเอก",
    prefix_abbrev: "พ.อ.อ.",
    prefix_en: "FS.1",
    prefix_abbrev_en: "FS.1",
  },
  {
    code: 521,
    prefix_th: "พันจ่าอากาศโท",
    prefix_abbrev: "พ.อ.ท.",
    prefix_en: "FS.2",
    prefix_abbrev_en: "FS.2",
  },
  {
    code: 522,
    prefix_th: "พันจ่าอากาศตรี",
    prefix_abbrev: "พ.อ.ต.",
    prefix_en: "",
    prefix_abbrev_en: "FS. 3",
  },
  {
    code: 523,
    prefix_th: "จ่าอากาศเอก",
    prefix_abbrev: "จ.อ.",
    prefix_en: "SGT.",
    prefix_abbrev_en: "SGT.",
  },
  {
    code: 524,
    prefix_th: "จ่าอากาศโท",
    prefix_abbrev: "จ.ท.",
    prefix_en: "CPL.",
    prefix_abbrev_en: "CPL.",
  },
  {
    code: 525,
    prefix_th: "จ่าอากาศตรี",
    prefix_abbrev: "จ.ต.",
    prefix_en: "LAC",
    prefix_abbrev_en: "LAC",
  },
  {
    code: 526,
    prefix_th: "พลฯทหารอากาศ",
    prefix_abbrev: "พลฯ",
    prefix_en: "AMN.",
    prefix_abbrev_en: "AMN.",
  },
  {
    code: 528,
    prefix_th: "นักเรียนจ่าอากาศ",
    prefix_abbrev: "นจอ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 533,
    prefix_th: "เรืออากาศโทขุน",
    prefix_abbrev: "ร.ท.ขุน",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 538,
    prefix_th: "พลอากาศจัตวา",
    prefix_abbrev: "พล.อ.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 651,
    prefix_th: "พลตำรวจเอก",
    prefix_abbrev: "พล.ต.อ.",
    prefix_en: "POL.GEN.",
    prefix_abbrev_en: "POL.GEN.",
  },
  {
    code: 652,
    prefix_th: "ว่าที่พลตำรวจเอก",
    prefix_abbrev: "ว่าที่ พล.ต.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 653,
    prefix_th: "พลตำรวจโท",
    prefix_abbrev: "พล.ต.ท.",
    prefix_en: "POL.LT.GEN.",
    prefix_abbrev_en: "POL.LT.GEN.",
  },
  {
    code: 654,
    prefix_th: "ว่าที่พลตำรวจโท",
    prefix_abbrev: "ว่าที่ พล.ต.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 655,
    prefix_th: "พลตำรวจตรี",
    prefix_abbrev: "พล.ต.ต.",
    prefix_en: "POL.MAJ.GEN.",
    prefix_abbrev_en: "POL.MAJ.GEN.",
  },
  {
    code: 656,
    prefix_th: "ว่าที่พลตำรวจตรี",
    prefix_abbrev: "ว่าที่ พล.ต.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 657,
    prefix_th: "พลตำรวจจัตวา",
    prefix_abbrev: "พล.ต.จ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 661,
    prefix_th: "พันตำรวจเอก",
    prefix_abbrev: "พ.ต.อ.",
    prefix_en: "POL.COL.",
    prefix_abbrev_en: "POL.COL.",
  },
  {
    code: 663,
    prefix_th: "พันตำรวจโท",
    prefix_abbrev: "พ.ต.ท.",
    prefix_en: "POL.LT.COL.",
    prefix_abbrev_en: "POL.LT.COL.",
  },
  {
    code: 664,
    prefix_th: "ว่าที่พันตำรวจโท",
    prefix_abbrev: "ว่าที่ พ.ต.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 665,
    prefix_th: "พันตำรวจตรี",
    prefix_abbrev: "พ.ต.ต.",
    prefix_en: "POL.MAJ.",
    prefix_abbrev_en: "POL.MAJ.",
  },
  {
    code: 667,
    prefix_th: "ร้อยตำรวจเอก",
    prefix_abbrev: "ร.ต.อ.",
    prefix_en: "POL.CAPT.",
    prefix_abbrev_en: "POL.CAPT.",
  },
  {
    code: 669,
    prefix_th: "ร้อยตำรวจโท",
    prefix_abbrev: "ร.ต.ท.",
    prefix_en: "POL.LT.",
    prefix_abbrev_en: "POL.LT.",
  },
  {
    code: 671,
    prefix_th: "ร้อยตำรวจตรี",
    prefix_abbrev: "ร.ต.ต.",
    prefix_en: "POL.SUB LT.",
    prefix_abbrev_en: "POL.SUB LT.",
  },
  {
    code: 673,
    prefix_th: "นายดาบตำรวจ",
    prefix_abbrev: "ด.ต.",
    prefix_en: "POL.SEN.SGT.MAJ.",
    prefix_abbrev_en: "POL.SEN.SGT.MAJ.",
  },
  {
    code: 674,
    prefix_th: "จ่าสิบตำรวจ",
    prefix_abbrev: "จ.ส.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 675,
    prefix_th: "สิบตำรวจเอก",
    prefix_abbrev: "ส.ต.อ.",
    prefix_en: "POL.SGT.",
    prefix_abbrev_en: "POL.SGT.",
  },
  {
    code: 676,
    prefix_th: "สิบตำรวจโท",
    prefix_abbrev: "ส.ต.ท.",
    prefix_en: "POL.CPR.",
    prefix_abbrev_en: "POL.CPR.",
  },
  {
    code: 677,
    prefix_th: "สิบตำรวจตรี",
    prefix_abbrev: "ส.ต.ต.",
    prefix_en: "POL.L/C",
    prefix_abbrev_en: "POL.L/C",
  },
  {
    code: 680,
    prefix_th: "นักเรียนพลตำรวจ",
    prefix_abbrev: "นพต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 681,
    prefix_th: "พลตำรวจ",
    prefix_abbrev: "พลฯ",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 682,
    prefix_th: "พลตำรวจพิเศษ",
    prefix_abbrev: "พลฯพิเศษ",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 683,
    prefix_th: "พลตำรวจอาสาสมัคร",
    prefix_abbrev: "พลฯอาสา",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 684,
    prefix_th: "พลตำรวจสำรอง",
    prefix_abbrev: "พลฯสำรอง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 686,
    prefix_th: "พลตำรวจสมัคร",
    prefix_abbrev: "พลฯสมัคร",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 688,
    prefix_th: "นายกองใหญ่",
    prefix_abbrev: "ก.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 689,
    prefix_th: "นายกองเอก",
    prefix_abbrev: "ก.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 690,
    prefix_th: "นายกองโท",
    prefix_abbrev: "ก.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 691,
    prefix_th: "นายกองตรี",
    prefix_abbrev: "ก.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 692,
    prefix_th: "นายหมวดเอก",
    prefix_abbrev: "มว.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 693,
    prefix_th: "นายหมวดโท",
    prefix_abbrev: "มว.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 694,
    prefix_th: "นายหมวดตรี",
    prefix_abbrev: "มว.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 695,
    prefix_th: "นายหมู่ใหญ่",
    prefix_abbrev: "ม.ญ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 696,
    prefix_th: "นายหมู่เอก",
    prefix_abbrev: "ม.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 697,
    prefix_th: "นายหมู่โท",
    prefix_abbrev: "ม.ท.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 698,
    prefix_th: "นายหมู่ตรี",
    prefix_abbrev: "ม.ต.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 699,
    prefix_th: "สมาชิกเอก",
    prefix_abbrev: "สมาชิกเอก",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 700,
    prefix_th: "สมาชิกโท",
    prefix_abbrev: "สมาชิกโท",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 701,
    prefix_th: "สมาชิกตรี",
    prefix_abbrev: "สมาชิกตรี",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 724,
    prefix_th: "พันตำรวจตรีหลวง",
    prefix_abbrev: "พ.ต.ต.หลวง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 732,
    prefix_th: "ว่าที่สิบเอก",
    prefix_abbrev: "ว่าที่ ส.อ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 801,
    prefix_th: "สมเด็จพระสังฆราช*",
    prefix_abbrev: "สมเด็จพระสังฆราช*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 802,
    prefix_th: "สมเด็จพระราชาคณะ*",
    prefix_abbrev: "สมเด็จพระราชาคณะ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 804,
    prefix_th: "พระราชาคณะ*",
    prefix_abbrev: "พระราชาคณะ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 805,
    prefix_th: "พระเปรียญธรรม*",
    prefix_abbrev: "พระเปรียญธรรม*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 806,
    prefix_th: "พระหิรัญยบัฏ*",
    prefix_abbrev: "พระหิรัญยบัฏ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 807,
    prefix_th: "พระสัญญาบัตร*",
    prefix_abbrev: "พระสัญญาบัตร*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 808,
    prefix_th: "พระราช*",
    prefix_abbrev: "พระราช*",
    prefix_en: "PHRARAT",
    prefix_abbrev_en: "PHRARAT",
  },
  {
    code: 809,
    prefix_th: "พระเทพ*",
    prefix_abbrev: "พระเทพ*",
    prefix_en: "PHRATHEP",
    prefix_abbrev_en: "PHRATHEP",
  },
  {
    code: 810,
    prefix_th: "พระปลัดขวา*",
    prefix_abbrev: "พระปลัดขวา*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 811,
    prefix_th: "พระปลัดซ้าย*",
    prefix_abbrev: "พระปลัดซ้าย*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 812,
    prefix_th: "พระครูปลัด",
    prefix_abbrev: "พระครูปลัด",
    prefix_en: "RHRAKHRUPALAD",
    prefix_abbrev_en: "RHRAKHRUPALAD",
  },
  {
    code: 816,
    prefix_th: "พระสมุห์*",
    prefix_abbrev: "พระสมุห์",
    prefix_en: "PHRASAMU",
    prefix_abbrev_en: "PHRASAMU",
  },
  {
    code: 817,
    prefix_th: "พระครูสมุห์",
    prefix_abbrev: "พระครูสมุห์",
    prefix_en: "PHRAKHRUSAMU",
    prefix_abbrev_en: "PHRAKHRUSAMU",
  },
  {
    code: 818,
    prefix_th: "พระครู",
    prefix_abbrev: "พระครู",
    prefix_en: "PHRAKHRU",
    prefix_abbrev_en: "PHRAKHRU",
  },
  {
    code: 819,
    prefix_th: "พระครูใบฎีกา*",
    prefix_abbrev: "พระครูใบฎีกา*",
    prefix_en: "PHRAKHRUBAIDIKA",
    prefix_abbrev_en: "PHRAKHRUBAIDIKA",
  },
  {
    code: 820,
    prefix_th: "พระครูธรรมธร*",
    prefix_abbrev: "พระครูธรรมธร*",
    prefix_en: "PHRAKHRUTHAMMATHON",
    prefix_abbrev_en: "PHRAKHRUTHAMMATHON",
  },
  {
    code: 821,
    prefix_th: "พระครูวิมลภาณ*",
    prefix_abbrev: "พระครูวิมลภาณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 822,
    prefix_th: "พระครูศัพทมงคล*",
    prefix_abbrev: "พระครูศัพทมงคล*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 824,
    prefix_th: "พระครูสังฆรักษ์*",
    prefix_abbrev: "พระครูสังฆรักษ์*",
    prefix_en: "PHRAKHRUSANGKHARAK",
    prefix_abbrev_en: "PHRAKHRUSANGKHARAK",
  },
  {
    code: 826,
    prefix_th: "พระครูสังฆวิชิต*",
    prefix_abbrev: "พระครูสังฆวิชิต*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 827,
    prefix_th: "พระปิฎก*",
    prefix_abbrev: "พระปิฎก*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 828,
    prefix_th: "พระปริยัติ*",
    prefix_abbrev: "พระปริยัติ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 829,
    prefix_th: "เจ้าอธิการ",
    prefix_abbrev: "เจ้าอธิการ",
    prefix_en: "CHAOATHIKAN",
    prefix_abbrev_en: "CHAOATHIKAN",
  },
  {
    code: 830,
    prefix_th: "พระอธิการ",
    prefix_abbrev: "พระอธิการ",
    prefix_en: "PHRAATHIKAN",
    prefix_abbrev_en: "PHRAATHIKAN",
  },
  {
    code: 831,
    prefix_th: "พระ",
    prefix_abbrev: "พระ",
    prefix_en: "PHRA",
    prefix_abbrev_en: "PHRA",
  },
  {
    code: 832,
    prefix_th: "สามเณร",
    prefix_abbrev: "ส.ณ.",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 833,
    prefix_th: "พระปลัด",
    prefix_abbrev: "พระปลัด",
    prefix_en: "PHRAPARAD",
    prefix_abbrev_en: "PHRAPARAD",
  },
  {
    code: 835,
    prefix_th: "พระคาร์ดินัล",
    prefix_abbrev: "พระคาร์ดินัล",
    prefix_en: "H.E.CARDINAL",
    prefix_abbrev_en: "H.E.CARDINAL",
  },
  {
    code: 836,
    prefix_th: "พระสังฆราช*",
    prefix_abbrev: "พระสังฆราช*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 837,
    prefix_th: "พระคุณเจ้า*",
    prefix_abbrev: "พระคุณเจ้า*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 838,
    prefix_th: "บาทหลวง",
    prefix_abbrev: "บาทหลวง",
    prefix_en: "REV.",
    prefix_abbrev_en: "REV.",
  },
  {
    code: 839,
    prefix_th: "พระมหา",
    prefix_abbrev: "พระมหา",
    prefix_en: "PHRAMAHA",
    prefix_abbrev_en: "PHRAMAHA",
  },
  {
    code: 840,
    prefix_th: "พระราชปัญญา*",
    prefix_abbrev: "พระราชปัญญา*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 841,
    prefix_th: "ภาราดา",
    prefix_abbrev: "ภาราดา",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 843,
    prefix_th: "พระญาณโศภณ*",
    prefix_abbrev: "พระญาณโศภณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 845,
    prefix_th: "พระโสภณธรรมาภรณ์*",
    prefix_abbrev: "พระโสภณธรรมาภรณ์*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 847,
    prefix_th: "พระญาณ*",
    prefix_abbrev: "พระญาณ*",
    prefix_en: "PHRAYANNA",
    prefix_abbrev_en: "PHRAYANNA",
  },
  {
    code: 848,
    prefix_th: "พระอัครสังฆราช*",
    prefix_abbrev: "พระอัครสังฆราช*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 849,
    prefix_th: "พระธรรม*",
    prefix_abbrev: "พระธรรม*",
    prefix_en: "PHRATHAM",
    prefix_abbrev_en: "PHRATHAM",
  },
  {
    code: 850,
    prefix_th: "พระสาสนโสภณ*",
    prefix_abbrev: "พระสาสนโสภณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 851,
    prefix_th: "พระธรรมโสภณ*",
    prefix_abbrev: "พระธรรมโสภณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 852,
    prefix_th: "พระอุดมสารโสภณ*",
    prefix_abbrev: "พระอุดมสารโสภณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 856,
    prefix_th: "พระอธิธรรม*",
    prefix_abbrev: "พระอธิธรรม*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 857,
    prefix_th: "พระราชญาณ*",
    prefix_abbrev: "พระราชญาณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 858,
    prefix_th: "พระสุธีวัชโรดม*",
    prefix_abbrev: "พระสุธีวัชโรดม*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 859,
    prefix_th: "รองเจ้าอธิการ*",
    prefix_abbrev: "รองเจ้าอธิการ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 860,
    prefix_th: "พระครูวินัยธร*",
    prefix_abbrev: "พระครูวินัยธร*",
    prefix_en: "PHRAKHRUWINAITHORN",
    prefix_abbrev_en: "PHRAKHRUWINAITHORN",
  },
  {
    code: 861,
    prefix_th: "พระศรีวชิราภรณ์*",
    prefix_abbrev: "พระศรีวชิราภรณ์*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 862,
    prefix_th: "พระราชบัณฑิต*",
    prefix_abbrev: "พระราชบัณฑิต*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 863,
    prefix_th: "แม่ชี",
    prefix_abbrev: "แม่ชี",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 864,
    prefix_th: "นักบวช",
    prefix_abbrev: "นักบวช",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 865,
    prefix_th: "พระรัตน*",
    prefix_abbrev: "พระรัตน*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 872,
    prefix_th: "พระครูพัฒนสารคุณ*",
    prefix_abbrev: "พระครูพัฒนสารคุณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 880,
    prefix_th: "พระครูพิพิธวรคุณ*",
    prefix_abbrev: "พระครูพิพิธวรคุณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 881,
    prefix_th: "พระครูสุนทรปภากร*",
    prefix_abbrev: "พระครูสุนทรปภากร*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 887,
    prefix_th: "พระครูวรสังฆกิจ*",
    prefix_abbrev: "พระครูวรสังฆกิจ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 893,
    prefix_th: "พระสรภาณโกศล*",
    prefix_abbrev: "พระสรภาณโกศล*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 899,
    prefix_th: "พระธรรมเมธาจารย์*",
    prefix_abbrev: "พระธรรมเมธาจารย์*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 908,
    prefix_th: "พระพรหมมุนี*",
    prefix_abbrev: "พระพรหมมุนี*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 912,
    prefix_th: "พระพิศาลสารคุณ*",
    prefix_abbrev: "พระพิศาลสารคุณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 916,
    prefix_th: "พระครูวชิรวุฒิกร*",
    prefix_abbrev: "พระครูวชิรวุฒิกร*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 919,
    prefix_th: "พระราชพัชราภรณ์*",
    prefix_abbrev: "พระราชพัชราภรณ์*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 921,
    prefix_th: "องสุตบทบวร*",
    prefix_abbrev: "องสุตบทบวร*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 922,
    prefix_th: "พระครูจันทเขมคุณ*",
    prefix_abbrev: "พระครูจันทเขมคุณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 926,
    prefix_th: "พระครูบรรณวัตร*",
    prefix_abbrev: "พระครูบรรณวัตร*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 927,
    prefix_th: "พระครูวิสุทธาจาร*",
    prefix_abbrev: "พระครูวิสุทธาจาร*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 931,
    prefix_th: "พระราชเมธาภรณ์*",
    prefix_abbrev: "พระราชเมธาภรณ์*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 934,
    prefix_th: "พระมหานายก*",
    prefix_abbrev: "พระมหานายก*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 938,
    prefix_th: "พระราชวชิราภรณ์*",
    prefix_abbrev: "พระราชวชิราภรณ์*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 939,
    prefix_th: "พระครูสุนทรวรธัช*",
    prefix_abbrev: "พระครูสุนทรวรธัช*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 942,
    prefix_th: "พระพรหมวชิรญาณ*",
    prefix_abbrev: "พระพรหมวชิรญาณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 944,
    prefix_th: "พระราชาวิมลโมลี*",
    prefix_abbrev: "พระราชวิมลโมลี*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 948,
    prefix_th: "พระครูปิยสีลสาร*",
    prefix_abbrev: "พระครูปิยสีลสาร*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 954,
    prefix_th: "พระเทพญาณกวี*",
    prefix_abbrev: "พระเทพญาณกวี*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 957,
    prefix_th: "พระครูภาวนาวรกิจ*",
    prefix_abbrev: "พระครูภาวนาวรกิจ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 958,
    prefix_th: "พระครูศรีศาสนคุณ*",
    prefix_abbrev: "พระครูศรีศาสนคุณ*",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 962,
    prefix_th: "นาวาเอกหญิง",
    prefix_abbrev: "นาวาเอกหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 963,
    prefix_th: "นาวาโทหญิง",
    prefix_abbrev: "นาวาโทหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 964,
    prefix_th: "นาวาตรีหญิง",
    prefix_abbrev: "นาวาตรีหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 965,
    prefix_th: "เรือเอกหญิง",
    prefix_abbrev: "เรือเอกหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 966,
    prefix_th: "เรือโทหญิง",
    prefix_abbrev: "เรือโทหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 967,
    prefix_th: "เรือตรีหญิง",
    prefix_abbrev: "เรือตรีหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 968,
    prefix_th: "พันจ่าเอกหญิง",
    prefix_abbrev: "พันจ่าเอกหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 969,
    prefix_th: "พันจ่าโทหญิง",
    prefix_abbrev: "พันจ่าโทหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 970,
    prefix_th: "พันจ่าตรีหญิง",
    prefix_abbrev: "พันจ่าตรีหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 972,
    prefix_th: "จ่าเอกหญิง",
    prefix_abbrev: "จ่าเอกหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 974,
    prefix_th: "จ่าโทหญิง",
    prefix_abbrev: "จ่าโทหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 976,
    prefix_th: "จ่าตรีหญิง",
    prefix_abbrev: "จ่าตรีหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
  {
    code: 977,
    prefix_th: "นาวาอากาศหญิง",
    prefix_abbrev: "นาวาอากาศหญิง",
    prefix_en: "",
    prefix_abbrev_en: "",
  },
];

export const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const family_statuses = [
  { label: "อยู่" },
  { label: "ร่วมกัน" },
  { label: "หย่าร้าง" },
  { label: "แยกกันอยู่" },
  { label: "บิดาเสียชีวิต" },
  { label: "มารดาเสียชีวิต" },
  { label: "บิดาและมารดาเสียชีวิต" },
];
export const nationalities = [
  { code: "002", label: "โปรตุเกส" },
  { code: "003", label: "ดัตช์" },
  { code: "004", label: "เยอรมัน" },
  { code: "005", label: "ฝรั่งเศส" },
  { code: "006", label: "เดนมาร์ก" },
  { code: "007", label: "สวีเดน" },
  { code: "008", label: "สวิส" },
  { code: "009", label: "อิตาลี" },
  { code: "010", label: "นอร์เวย์" },
  { code: "011", label: "ออสเตรีย" },
  { code: "012", label: "ไอริช" },
  { code: "013", label: "ฟินแลนด์" },
  { code: "014", label: "เบลเยียม" },
  { code: "015", label: "สเปน" },
  { code: "016", label: "รัสเซีย" },
  { code: "017", label: "โปแลนด์" },
  { code: "018", label: "เช็ก" },
  { code: "019", label: "ฮังการี" },
  { code: "020", label: "กรีก" },
  { code: "021", label: "ยูโกสลาฟ" },
  { code: "022", label: "ลักเซมเบิร์ก" },
  { code: "023", label: "วาติกัน" },
  { code: "024", label: "มอลตา" },
  { code: "025", label: "ลีซู" },
  { code: "026", label: "บัลแกเรีย" },
  { code: "027", label: "โรมาเนีย" },
  { code: "028", label: "ไซปรัส" },
  { code: "029", label: "อเมริกัน" },
  { code: "030", label: "แคนาดา" },
  { code: "031", label: "เม็กซิโก" },
  { code: "032", label: "คิวบา" },
  { code: "033", label: "อาร์เจนตินา" },
  { code: "034", label: "บราซิล" },
  { code: "035", label: "ชิลี" },
  { code: "036", label: "อาข่า" },
  { code: "037", label: "โคลัมเบีย" },
  { code: "038", label: "ลั๊ว" },
  { code: "039", label: "เปรู" },
  { code: "040", label: "ปานามา" },
  { code: "041", label: "อุรุกวัย" },
  { code: "042", label: "เวเนซุเอลา" },
  { code: "043", label: "เปอร์โตริโก" },
  { code: "044", label: "จีน" },
  { code: "045", label: "อินเดีย" },
  { code: "046", label: "เวียดนาม" },
  { code: "047", label: "ญี่ปุ่น" },
  { code: "048", label: "พม่า" },
  { code: "049", label: "ฟิลิปปิน" },
  { code: "050", label: "มาเลเซีย" },
  { code: "051", label: "อินโดนีเซีย" },
  { code: "052", label: "ปากีสถาน" },
  { code: "053", label: "เกาหลีใต้" },
  { code: "054", label: "สิงคโปร์" },
  { code: "055", label: "เนปาล" },
  { code: "056", label: "ลาว" },
  { code: "057", label: "กัมพูชา" },
  { code: "058", label: "ศรีลังกา" },
  { code: "059", label: "ซาอุดีอาระเบีย" },
  { code: "060", label: "อิสราเอล" },
  { code: "061", label: "เลบานอน" },
  { code: "062", label: "อิหร่าน" },
  { code: "063", label: "ตุรกี" },
  { code: "064", label: "บังกลาเทศ" },
  { code: "065", label: "ถูกถอนสัญชาติ" },
  { code: "066", label: "ซีเรีย" },
  { code: "067", label: "อิรัก" },
  { code: "068", label: "คูเวต" },
  { code: "069", label: "บรูไน" },
  { code: "070", label: "แอฟริกาใต้" },
  { code: "071", label: "กะเหรี่ยง" },
  { code: "072", label: "ลาหู่" },
  { code: "073", label: "เคนยา" },
  { code: "074", label: "อียิปต์" },
  { code: "075", label: "เอธิโอเปีย" },
  { code: "076", label: "ไนจีเรีย" },
  { code: "077", label: "สหรัฐอาหรับเอมิเรตส์" },
  { code: "078", label: "กินี" },
  { code: "079", label: "ออสเตรเลีย" },
  { code: "080", label: "นิวซีแลนด์" },
  { code: "081", label: "ปาปัวนิวกินี" },
  { code: "082", label: "ม้ง" },
  { code: "083", label: "เมี่ยน" },
  { code: "084", label: "ชาวเขา" },
  { code: "086", label: "จีนฮ่อ" },
  { code: "087", label: "จีน (อดีต ทจช.)" },
  { code: "088", label: "ผู้พลัดถิ่นสัญชาติพม่า" },
  { code: "089", label: "ผู้อพยพเชื้อสายจากกัมพูชา" },
  { code: "090", label: "ลาว (ลาวอพยพ)" },
  { code: "091", label: "เขมรอพยพ" },
  { code: "092", label: "เวียตนามอพยพ" },
  { code: "093", label: "รอให้สัญชาติไทย" },
  { code: "094", label: "ไทย-อิสลาม" },
  { code: "095", label: "ไทย-จีน" },
  { code: "096", label: "ไร้สัญชาติ" },
  { code: "097", label: "อื่นๆ" },
  { code: "098", label: "ไม่ได้สัญชาติไทย" },
  { code: "099", label: "ไทย" },
  { code: "100", label: "อัฟกัน" },
  { code: "101", label: "บาห์เรน" },
  { code: "102", label: "ภูฏาน" },
  { code: "103", label: "จอร์แดน" },
  { code: "104", label: "เกาหลีเหนือ" },
  { code: "105", label: "มัลดีฟ" },
  { code: "106", label: "มองโกเลีย" },
  { code: "107", label: "โอมาน" },
  { code: "108", label: "กาตาร์" },
  { code: "109", label: "เยเมน" },
  { code: "110", label: "เยเมน(ใต้)" },
  { code: "111", label: "หมู่เกาะฟิจิ" },
  { code: "112", label: "คิริบาส" },
  { code: "113", label: "นาอูรู" },
  { code: "114", label: "หมู่เกาะโซโลมอน" },
  { code: "115", label: "ตองก้า" },
  { code: "116", label: "ตูวาลู" },
  { code: "117", label: "วานูอาตู" },
  { code: "118", label: "ซามัว" },
  { code: "119", label: "แอลเบเนีย" },
  { code: "120", label: "อันดอร์รา" },
  { code: "121", label: "เยอรมนีตะวันออก" },
  { code: "122", label: "ไอซ์แลนด์" },
  { code: "123", label: "ลิกเตนสไตน์" },
  { code: "124", label: "โมนาโก" },
  { code: "125", label: "ซานมารีโน" },
  { code: "126", label: "บริติช" },
  { code: "127", label: "แอลจีเรีย" },
  { code: "128", label: "แองโกลา" },
  { code: "129", label: "เบนิน" },
  { code: "130", label: "บอตสวานา" },
  { code: "131", label: "บูร์กินาฟาโซ" },
  { code: "132", label: "บุรุนดี" },
  { code: "133", label: "แคเมอรูน" },
  { code: "134", label: "เคปเวิร์ด" },
  { code: "135", label: "แอฟริกากลาง" },
  { code: "136", label: "ชาด" },
  { code: "137", label: "คอโมโรส" },
  { code: "138", label: "คองโก" },
  { code: "139", label: "ไอโวเรี่ยน" },
  { code: "140", label: "จิบูตี" },
  { code: "141", label: "อิเควทอเรียลกินี" },
  { code: "142", label: "กาบอง" },
  { code: "143", label: "แกมเบีย" },
  { code: "144", label: "กานา" },
  { code: "145", label: "กินีบีสเซา" },
  { code: "146", label: "เลโซโท" },
  { code: "147", label: "ไลบีเรีย" },
  { code: "148", label: "ลิเบีย" },
  { code: "149", label: "มาลากาซี" },
  { code: "150", label: "มาลาวี" },
  { code: "151", label: "มาลี" },
  { code: "152", label: "มอริเตเนีย" },
  { code: "153", label: "มอริเชียส" },
  { code: "154", label: "โมร็อกโก" },
  { code: "155", label: "โมซัมบิก" },
  { code: "156", label: "ไนเจอร์" },
  { code: "157", label: "รวันดา" },
  { code: "158", label: "เซาโตเมและปรินซิเป" },
  { code: "159", label: "เซเนกัล" },
  { code: "160", label: "เซเชลส์" },
  { code: "161", label: "เซียร์ราลีโอน" },
  { code: "162", label: "โซมาลี" },
  { code: "163", label: "ซูดาน" },
  { code: "164", label: "สวาซี" },
  { code: "165", label: "แทนซาเนีย" },
  { code: "166", label: "โตโก" },
  { code: "167", label: "ตูนิเซีย" },
  { code: "168", label: "ยูกันดา" },
  { code: "169", label: "ซาอีร์" },
  { code: "170", label: "แซมเบีย" },
  { code: "171", label: "ซิมบับเว" },
  { code: "172", label: "แอนติกาและบาร์บูดา" },
  { code: "173", label: "บาฮามาส" },
  { code: "174", label: "บาร์เบโดส" },
  { code: "175", label: "เบลิซ" },
  { code: "176", label: "คอสตาริกา" },
  { code: "177", label: "โดมินิกา" },
  { code: "178", label: "โดมินิกัน" },
  { code: "179", label: "เอลซัลวาดอร์" },
  { code: "180", label: "เกรเนดา" },
  { code: "181", label: "กัวเตมาลา" },
  { code: "182", label: "เฮติ" },
  { code: "183", label: "ฮอนดูรัส" },
  { code: "184", label: "จาเมกา" },
  { code: "185", label: "นิการากัว" },
  { code: "186", label: "เซนต์คิตส์และเนวิส" },
  { code: "187", label: "เซนต์ลูเซีย" },
  { code: "188", label: "เซนต์วินเซนต์และเกรนาดีนส์" },
  { code: "189", label: "ตรินิแดดและโตเบโก" },
  { code: "190", label: "โบลีเวีย" },
  { code: "191", label: "เอกวาดอร์" },
  { code: "192", label: "กายอานา" },
  { code: "193", label: "ปารากวัย" },
  { code: "194", label: "ซูรินาเม" },
  { code: "195", label: "อาหรับ" },
  { code: "196", label: "คะฉิ่น" },
  { code: "197", label: "ว้า" },
  { code: "198", label: "ไทยใหญ่" },
  { code: "199", label: "ไทยลื้อ" },
  { code: "200", label: "ขมุ" },
  { code: "201", label: "ตองสู" },
  { code: "202", label: "เงี้ยว" },
  { code: "203", label: "ละว้า" },
  { code: "204", label: "แม้ว" },
  { code: "205", label: "ปะหร่อง" },
  { code: "206", label: "ถิ่น" },
  { code: "207", label: "ปะโอ" },
  { code: "208", label: "มอญ" },
  { code: "209", label: "มลาบรี" },
  { code: "210", label: "เฮาะ" },
  { code: "211", label: "สก๊อตแลนด์" },
  { code: "212", label: "จีน (จีนฮ่ออิสระ)" },
  { code: "213", label: "จีนอพยพ" },
  { code: "214", label: "จีน (จีนฮ่ออพยพ)" },
  { code: "215", label: "ไต้หวัน" },
  { code: "216", label: "ยูเครน" },
  { code: "217", label: "อาณานิคมอังกฤษ" },
  { code: "218", label: "ดูไบ" },
  { code: "219", label: "จีน(ฮ่องกง)" },
  { code: "220", label: "จีน(ไต้หวัน)" },
  { code: "221", label: "โครเอเชีย" },
  { code: "222", label: "บริทิธ" },
  { code: "223", label: "คาซัค" },
  { code: "224", label: "อาร์เมเนีย" },
  { code: "225", label: "อาเซอร์ไบจาน" },
  { code: "226", label: "จอร์เจีย" },
  { code: "227", label: "คีร์กีซ" },
  { code: "228", label: "ทาจิก" },
  { code: "229", label: "อุซเบก" },
  { code: "230", label: "หมู่เกาะมาร์แชลล์" },
  { code: "231", label: "ไมโครนีเซีย" },
  { code: "232", label: "ปาเลา" },
  { code: "233", label: "เบลารุส" },
  { code: "234", label: "บอสเนียและเฮอร์เซโกวีนา" },
  { code: "235", label: "เติร์กเมน" },
  { code: "236", label: "เอสโตเนีย" },
  { code: "237", label: "ลัตเวีย" },
  { code: "238", label: "ลิทัวเนีย" },
  { code: "239", label: "มาซิโดเนีย" },
  { code: "240", label: "มอลโดวา" },
  { code: "241", label: "สโลวัก" },
  { code: "242", label: "สโลวีน" },
  { code: "243", label: "เอริเทรีย" },
  { code: "244", label: "นามิเบีย" },
  { code: "245", label: "โบลิเวีย" },
  { code: "246", label: "หมู่เกาะคุก" },
  { code: "247", label: "เนปาล (เนปาลอพยพ)" },
  { code: "248", label: "มอญ(ผู้พลัดถิ่นสัญชาติพม่า)" },
  { code: "249", label: "ไทยใหญ่(ผู้พลัดถิ่นสัญชาติพม่า" },
  { code: "250", label: "เวียดนาม(ญวนอพยพ)" },
  { code: "251", label: "มาเลเชีย(อดีต จคม.)" },
  { code: "252", label: "จีน(อดีต จคม.)" },
  { code: "253", label: "สิงคโปร์(อดีต จคม.)" },
  { code: "254", label: "กะเหรี่ยง(ผู้หลบหนีเข้าเมือง)" },
  { code: "255", label: "มอญ(ผู้หลบหนีเข้าเมือง)" },
  { code: "256", label: "ไทยใหญ่(ผู้หลบหนีเข้าเมือง)" },
  { code: "257", label: "กัมพูชา(ผู้หลบหนีเข้าเมือง)" },
  { code: "258", label: "มอญ(ชุมชนบนพื้นที่สูง)" },
  { code: "259", label: "กะเหรี่ยง(ชุมชนบนพื้นที่สูง)" },
  { code: "260", label: "ปาเลสไตน์" },
  { code: "261", label: "ติมอร์ตะวันออก" },
  { code: "262", label: "สละสัญชาติไทย" },
  { code: "263", label: "เซอร์เบีย แอนด์ มอนเตเนโกร" },
  { code: "264", label: "กัมพูชา(แรงงาน)" },
  { code: "265", label: "พม่า(แรงงาน)" },
  { code: "266", label: "ลาว(แรงงาน)" },
  { code: "267", label: "เซอร์เบียน" },
  { code: "268", label: "มอนเตเนกริน" },
  { code: "270", label: "กาบูเวร์ดี" },
  { code: "271", label: "คอซอวอ" },
  { code: "272", label: "เซาท์ซูดาน" },
  { code: "601", label: "เบลเยี่ยม" },
  { code: "989", label: "บุคคลที่ไม่มีสถานะทางทะเบียน" },
  { code: "999", label: "ไม่ระบุ" },
];
export const origins = [
  { code: "002", label: "โปรตุเกส" },
  { code: "003", label: "ดัตช์" },
  { code: "004", label: "เยอรมัน" },
  { code: "005", label: "ฝรั่งเศส" },
  { code: "006", label: "เดนมาร์ก" },
  { code: "007", label: "สวีเดน" },
  { code: "008", label: "สวิส" },
  { code: "009", label: "อิตาลี" },
  { code: "010", label: "นอร์เวย์" },
  { code: "011", label: "ออสเตรีย" },
  { code: "012", label: "ไอริช" },
  { code: "013", label: "ฟินแลนด์" },
  { code: "014", label: "เบลเยียม" },
  { code: "015", label: "สเปน" },
  { code: "016", label: "รัสเซีย" },
  { code: "017", label: "โปแลนด์" },
  { code: "018", label: "เช็ก" },
  { code: "019", label: "ฮังการี" },
  { code: "020", label: "กรีก" },
  { code: "021", label: "ยูโกสลาฟ" },
  { code: "022", label: "ลักเซมเบิร์ก" },
  { code: "023", label: "วาติกัน" },
  { code: "024", label: "มอลตา" },
  { code: "025", label: "ลีซู" },
  { code: "026", label: "บัลแกเรีย" },
  { code: "027", label: "โรมาเนีย" },
  { code: "028", label: "ไซปรัส" },
  { code: "029", label: "อเมริกัน" },
  { code: "030", label: "แคนาดา" },
  { code: "031", label: "เม็กซิโก" },
  { code: "032", label: "คิวบา" },
  { code: "033", label: "อาร์เจนตินา" },
  { code: "034", label: "บราซิล" },
  { code: "035", label: "ชิลี" },
  { code: "036", label: "อาข่า" },
  { code: "037", label: "โคลัมเบีย" },
  { code: "038", label: "ลั๊ว" },
  { code: "039", label: "เปรู" },
  { code: "040", label: "ปานามา" },
  { code: "041", label: "อุรุกวัย" },
  { code: "042", label: "เวเนซุเอลา" },
  { code: "043", label: "เปอร์โตริโก" },
  { code: "044", label: "จีน" },
  { code: "045", label: "อินเดีย" },
  { code: "046", label: "เวียดนาม" },
  { code: "047", label: "ญี่ปุ่น" },
  { code: "048", label: "พม่า" },
  { code: "049", label: "ฟิลิปปิน" },
  { code: "050", label: "มาเลเซีย" },
  { code: "051", label: "อินโดนีเซีย" },
  { code: "052", label: "ปากีสถาน" },
  { code: "053", label: "เกาหลีใต้" },
  { code: "054", label: "สิงคโปร์" },
  { code: "055", label: "เนปาล" },
  { code: "056", label: "ลาว" },
  { code: "057", label: "กัมพูชา" },
  { code: "058", label: "ศรีลังกา" },
  { code: "059", label: "ซาอุดีอาระเบีย" },
  { code: "060", label: "อิสราเอล" },
  { code: "061", label: "เลบานอน" },
  { code: "062", label: "อิหร่าน" },
  { code: "063", label: "ตุรกี" },
  { code: "064", label: "บังกลาเทศ" },
  { code: "065", label: "ถูกถอนสัญชาติ" },
  { code: "066", label: "ซีเรีย" },
  { code: "067", label: "อิรัก" },
  { code: "068", label: "คูเวต" },
  { code: "069", label: "บรูไน" },
  { code: "070", label: "แอฟริกาใต้" },
  { code: "071", label: "กะเหรี่ยง" },
  { code: "072", label: "ลาหู่" },
  { code: "073", label: "เคนยา" },
  { code: "074", label: "อียิปต์" },
  { code: "075", label: "เอธิโอเปีย" },
  { code: "076", label: "ไนจีเรีย" },
  { code: "077", label: "สหรัฐอาหรับเอมิเรตส์" },
  { code: "078", label: "กินี" },
  { code: "079", label: "ออสเตรเลีย" },
  { code: "080", label: "นิวซีแลนด์" },
  { code: "081", label: "ปาปัวนิวกินี" },
  { code: "082", label: "ม้ง" },
  { code: "083", label: "เมี่ยน" },
  { code: "084", label: "ชาวเขา" },
  { code: "086", label: "จีนฮ่อ" },
  { code: "087", label: "จีน (อดีต ทจช.)" },
  { code: "088", label: "ผู้พลัดถิ่นสัญชาติพม่า" },
  { code: "089", label: "ผู้อพยพเชื้อสายจากกัมพูชา" },
  { code: "090", label: "ลาว (ลาวอพยพ)" },
  { code: "091", label: "เขมรอพยพ" },
  { code: "092", label: "เวียตนามอพยพ" },
  { code: "093", label: "รอให้สัญชาติไทย" },
  { code: "094", label: "ไทย-อิสลาม" },
  { code: "095", label: "ไทย-จีน" },
  { code: "096", label: "ไร้สัญชาติ" },
  { code: "097", label: "อื่นๆ" },
  { code: "098", label: "ไม่ได้สัญชาติไทย" },
  { code: "099", label: "ไทย" },
  { code: "100", label: "อัฟกัน" },
  { code: "101", label: "บาห์เรน" },
  { code: "102", label: "ภูฏาน" },
  { code: "103", label: "จอร์แดน" },
  { code: "104", label: "เกาหลีเหนือ" },
  { code: "105", label: "มัลดีฟ" },
  { code: "106", label: "มองโกเลีย" },
  { code: "107", label: "โอมาน" },
  { code: "108", label: "กาตาร์" },
  { code: "109", label: "เยเมน" },
  { code: "110", label: "เยเมน(ใต้)" },
  { code: "111", label: "หมู่เกาะฟิจิ" },
  { code: "112", label: "คิริบาส" },
  { code: "113", label: "นาอูรู" },
  { code: "114", label: "หมู่เกาะโซโลมอน" },
  { code: "115", label: "ตองก้า" },
  { code: "116", label: "ตูวาลู" },
  { code: "117", label: "วานูอาตู" },
  { code: "118", label: "ซามัว" },
  { code: "119", label: "แอลเบเนีย" },
  { code: "120", label: "อันดอร์รา" },
  { code: "121", label: "เยอรมนีตะวันออก" },
  { code: "122", label: "ไอซ์แลนด์" },
  { code: "123", label: "ลิกเตนสไตน์" },
  { code: "124", label: "โมนาโก" },
  { code: "125", label: "ซานมารีโน" },
  { code: "126", label: "บริติช" },
  { code: "127", label: "แอลจีเรีย" },
  { code: "128", label: "แองโกลา" },
  { code: "129", label: "เบนิน" },
  { code: "130", label: "บอตสวานา" },
  { code: "131", label: "บูร์กินาฟาโซ" },
  { code: "132", label: "บุรุนดี" },
  { code: "133", label: "แคเมอรูน" },
  { code: "134", label: "เคปเวิร์ด" },
  { code: "135", label: "แอฟริกากลาง" },
  { code: "136", label: "ชาด" },
  { code: "137", label: "คอโมโรส" },
  { code: "138", label: "คองโก" },
  { code: "139", label: "ไอโวเรี่ยน" },
  { code: "140", label: "จิบูตี" },
  { code: "141", label: "อิเควทอเรียลกินี" },
  { code: "142", label: "กาบอง" },
  { code: "143", label: "แกมเบีย" },
  { code: "144", label: "กานา" },
  { code: "145", label: "กินีบีสเซา" },
  { code: "146", label: "เลโซโท" },
  { code: "147", label: "ไลบีเรีย" },
  { code: "148", label: "ลิเบีย" },
  { code: "149", label: "มาลากาซี" },
  { code: "150", label: "มาลาวี" },
  { code: "151", label: "มาลี" },
  { code: "152", label: "มอริเตเนีย" },
  { code: "153", label: "มอริเชียส" },
  { code: "154", label: "โมร็อกโก" },
  { code: "155", label: "โมซัมบิก" },
  { code: "156", label: "ไนเจอร์" },
  { code: "157", label: "รวันดา" },
  { code: "158", label: "เซาโตเมและปรินซิเป" },
  { code: "159", label: "เซเนกัล" },
  { code: "160", label: "เซเชลส์" },
  { code: "161", label: "เซียร์ราลีโอน" },
  { code: "162", label: "โซมาลี" },
  { code: "163", label: "ซูดาน" },
  { code: "164", label: "สวาซี" },
  { code: "165", label: "แทนซาเนีย" },
  { code: "166", label: "โตโก" },
  { code: "167", label: "ตูนิเซีย" },
  { code: "168", label: "ยูกันดา" },
  { code: "169", label: "ซาอีร์" },
  { code: "170", label: "แซมเบีย" },
  { code: "171", label: "ซิมบับเว" },
  { code: "172", label: "แอนติกาและบาร์บูดา" },
  { code: "173", label: "บาฮามาส" },
  { code: "174", label: "บาร์เบโดส" },
  { code: "175", label: "เบลิซ" },
  { code: "176", label: "คอสตาริกา" },
  { code: "177", label: "โดมินิกา" },
  { code: "178", label: "โดมินิกัน" },
  { code: "179", label: "เอลซัลวาดอร์" },
  { code: "180", label: "เกรเนดา" },
  { code: "181", label: "กัวเตมาลา" },
  { code: "182", label: "เฮติ" },
  { code: "183", label: "ฮอนดูรัส" },
  { code: "184", label: "จาเมกา" },
  { code: "185", label: "นิการากัว" },
  { code: "186", label: "เซนต์คิตส์และเนวิส" },
  { code: "187", label: "เซนต์ลูเซีย" },
  { code: "188", label: "เซนต์วินเซนต์และเกรนาดีนส์" },
  { code: "189", label: "ตรินิแดดและโตเบโก" },
  { code: "190", label: "โบลีเวีย" },
  { code: "191", label: "เอกวาดอร์" },
  { code: "192", label: "กายอานา" },
  { code: "193", label: "ปารากวัย" },
  { code: "194", label: "ซูรินาเม" },
  { code: "195", label: "อาหรับ" },
  { code: "196", label: "คะฉิ่น" },
  { code: "197", label: "ว้า" },
  { code: "198", label: "ไทยใหญ่" },
  { code: "199", label: "ไทยลื้อ" },
  { code: "200", label: "ขมุ" },
  { code: "201", label: "ตองสู" },
  { code: "202", label: "เงี้ยว" },
  { code: "203", label: "ละว้า" },
  { code: "204", label: "แม้ว" },
  { code: "205", label: "ปะหร่อง" },
  { code: "206", label: "ถิ่น" },
  { code: "207", label: "ปะโอ" },
  { code: "208", label: "มอญ" },
  { code: "209", label: "มลาบรี" },
  { code: "210", label: "เฮาะ" },
  { code: "211", label: "สก๊อตแลนด์" },
  { code: "212", label: "จีน (จีนฮ่ออิสระ)" },
  { code: "213", label: "จีนอพยพ" },
  { code: "214", label: "จีน (จีนฮ่ออพยพ)" },
  { code: "215", label: "ไต้หวัน" },
  { code: "216", label: "ยูเครน" },
  { code: "217", label: "อาณานิคมอังกฤษ" },
  { code: "218", label: "ดูไบ" },
  { code: "219", label: "จีน(ฮ่องกง)" },
  { code: "220", label: "จีน(ไต้หวัน)" },
  { code: "221", label: "โครเอเชีย" },
  { code: "222", label: "บริทิธ" },
  { code: "223", label: "คาซัค" },
  { code: "224", label: "อาร์เมเนีย" },
  { code: "225", label: "อาเซอร์ไบจาน" },
  { code: "226", label: "จอร์เจีย" },
  { code: "227", label: "คีร์กีซ" },
  { code: "228", label: "ทาจิก" },
  { code: "229", label: "อุซเบก" },
  { code: "230", label: "หมู่เกาะมาร์แชลล์" },
  { code: "231", label: "ไมโครนีเซีย" },
  { code: "232", label: "ปาเลา" },
  { code: "233", label: "เบลารุส" },
  { code: "234", label: "บอสเนียและเฮอร์เซโกวีนา" },
  { code: "235", label: "เติร์กเมน" },
  { code: "236", label: "เอสโตเนีย" },
  { code: "237", label: "ลัตเวีย" },
  { code: "238", label: "ลิทัวเนีย" },
  { code: "239", label: "มาซิโดเนีย" },
  { code: "240", label: "มอลโดวา" },
  { code: "241", label: "สโลวัก" },
  { code: "242", label: "สโลวีน" },
  { code: "243", label: "เอริเทรีย" },
  { code: "244", label: "นามิเบีย" },
  { code: "245", label: "โบลิเวีย" },
  { code: "246", label: "หมู่เกาะคุก" },
  { code: "247", label: "เนปาล (เนปาลอพยพ)" },
  { code: "248", label: "มอญ(ผู้พลัดถิ่นสัญชาติพม่า)" },
  { code: "249", label: "ไทยใหญ่(ผู้พลัดถิ่นสัญชาติพม่า" },
  { code: "250", label: "เวียดนาม(ญวนอพยพ)" },
  { code: "251", label: "มาเลเชีย(อดีต จคม.)" },
  { code: "252", label: "จีน(อดีต จคม.)" },
  { code: "253", label: "สิงคโปร์(อดีต จคม.)" },
  { code: "254", label: "กะเหรี่ยง(ผู้หลบหนีเข้าเมือง)" },
  { code: "255", label: "มอญ(ผู้หลบหนีเข้าเมือง)" },
  { code: "256", label: "ไทยใหญ่(ผู้หลบหนีเข้าเมือง)" },
  { code: "257", label: "กัมพูชา(ผู้หลบหนีเข้าเมือง)" },
  { code: "258", label: "มอญ(ชุมชนบนพื้นที่สูง)" },
  { code: "259", label: "กะเหรี่ยง(ชุมชนบนพื้นที่สูง)" },
  { code: "260", label: "ปาเลสไตน์" },
  { code: "261", label: "ติมอร์ตะวันออก" },
  { code: "262", label: "สละสัญชาติไทย" },
  { code: "263", label: "เซอร์เบีย แอนด์ มอนเตเนโกร" },
  { code: "264", label: "กัมพูชา(แรงงาน)" },
  { code: "265", label: "พม่า(แรงงาน)" },
  { code: "266", label: "ลาว(แรงงาน)" },
  { code: "267", label: "เซอร์เบียน" },
  { code: "268", label: "มอนเตเนกริน" },
  { code: "270", label: "กาบูเวร์ดี" },
  { code: "271", label: "คอซอวอ" },
  { code: "272", label: "เซาท์ซูดาน" },
  { code: "601", label: "เบลเยี่ยม" },
  { code: "989", label: "บุคคลที่ไม่มีสถานะทางทะเบียน" },
  { code: "999", label: "ไม่ระบุ" },
];
