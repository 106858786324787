import { useEffect, useState } from "react";
import { GridSelect, GridInput, GridDatePicker } from "./InputComponents";
import DialogZip from "../components/DialogZip";
import DialogStudent from "../components/DialogStudent";
import { genders, programs } from "../const";
import { getGroups, checkEmpty } from "../js/main";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";

import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const styles = {
  title: {
    fontWeight: 500,
    marginTop: "30px",
  },
};

const student_statuses = async () => {
  let statuses = await axios.post(
    Conf.endpoint.tonkla + "/reg/query",
    { collection: "student_statuses", limit: 0 },
    {
      headers: {
        Authorization: cookie.access_token,
      },
    }
  );
  statuses = await statuses.data.result.map((j) => {
    j.value = j._id;
    return j;
  });
  return statuses;
};

export default function FormStudent({ student, options }) {
  const [form, setForm] = useState({});
  const [groups, setGroups] = useState([]);
  // const [options, setOptions] = useState();
  useEffect(() => {
    (async () => {
      let groups = await getGroups();
      groups = groups.groups;
      groups = await groups.map((j) => {
        return { label: j.name, value: j._id };
      });
      setGroups(groups);
      setForm(student || {});
    })();
  }, [student]);
  function changeField(field, value, callback) {
    setForm({ ...form, [field]: value });
    if (callback) callback();
  }
  function changeFields(fields) {
    let Form = JSON.parse(JSON.stringify(form));
    for (let field of Object.keys(fields)) {
      Form[field] = fields[field];
    }
    setForm(Form);
  }

  function copyAddress() {
    let Form = JSON.parse(JSON.stringify(form));
    setForm({
      ...form,
      current_house_type: Form.house_type,
      current_house_no: Form.house_no,
      current_village: Form.village,
      current_moo: Form.moo,
      current_soi: Form.soi,
      current_street: Form.street,
      current_sub_district: Form.sub_district,
      current_district: Form.district,
      current_province: Form.province,
      current_zip_code: Form.zip_code,
    });
  }

  async function upload() {
    return new Promise(async (resolve) => {
      if (form.photo) {
        let path = await axios.put(Conf.endpoint.fileUpload, {
          file_stream: form.photo,
          file_type: "student",
        });
        resolve("file.php?id=" + path.data._id);
      } else resolve(false);
    });
  }
  async function save() {
    let photo_url = await upload();
    let chk = await checkEmpty(form);
    if (chk.empty === false) {
      if (form.idx.length !== 13) {
        document.getElementById("idx").focus();
        alert("เลขที่บัตรประจำตัวประชาชนต้องมี 13 หลัก");
      } else {
        let send = JSON.parse(JSON.stringify(form));
        delete send.photo;
        if (photo_url !== false) send.photo_url = photo_url;
        let result = await axios.put(
          Conf.endpoint.tonkla + "/reg/student",
          send,
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        if (result.data.error) alert(result.data.error.message);
        else alert("Save completed");
      }
    } else {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      document.getElementById(chk.emptyFields[0]).focus();
    }
  }
  return (
    <div>
      {form && options && (
        <>
          <PersonalInfo
            form={form}
            onChange={changeField}
            changeFields={changeFields}
            options={options}
          />
          <PhotoUpload form={form} onChange={changeField} />
          <StudentInfo form={form} onChange={changeField} groups={groups} />
          <AddressInfo
            form={form}
            onChange={changeField}
            onChangeFields={changeFields}
            onCopyAddress={copyAddress}
            options={options}
          />
          <BrotherhoodInfo student_id={form._id} groups={groups} />
          <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              style={{ marginTop: "20px", width: "150px" }}
              startIcon={<Icon>save</Icon>}
              onClick={save}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function PhotoUpload({ form, onChange }) {
  const [img, setImg] = useState();
  useEffect(() => {
    (async () => {
      if (form) {
        if (form.photo) setImg(form.photo);
        else if (form.photo_url) {
          let img = await axios.get(
            Conf.endpoint.upload + "/" + form.photo_url
          );
          if (img.data) setImg(img.data);
        }
      }
    })();
  }, [form]);

  function changeImage(e) {
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        let size = calc_image_size(reader.result);
        if (size < 500) {
          onChange("photo", reader.result);
          // setImg(reader.result);
        } else {
          alert("Image file must smaller than 500KB");
        }
      };
    }
  }
  function calc_image_size(image) {
    let y = 1;
    if (image.endsWith("==")) {
      y = 2;
    }
    const x_size = image.length * (3 / 4) - y;

    return Math.round(x_size / 1024);
  }
  return (
    <div>
      <h3 style={styles.title}>รูปถ่ายนักเรียน</h3>
      <div style={{ textAlign: "center" }}>
        {img ? (
          <>
            <img
              src={img}
              alt=""
              style={{ maxHeight: "200px", border: "2px solid #ddd" }}
            />
          </>
        ) : (
          <Icon style={{ fontSize: "10rem", color: "#999" }}>person</Icon>
        )}

        <div>
          <label htmlFor={"student-img"}>
            <input
              accept="image/png,image/jpeg,image/PNG,image/JPEG"
              id="student-img"
              type="file"
              onChange={changeImage}
              style={{ display: "none" }}
            />
            <Button
              aria-label="upload picture"
              component="span"
              startIcon={<Icon>photo_camera</Icon>}
            >
              Upload Photo
            </Button>

            {/* <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Icon>photo_camera</Icon>
            </IconButton> */}
          </label>
        </div>
      </div>
    </div>
  );
}

function BrotherhoodInfo({ student_id, groups }) {
  const [dialogState, setDialogState] = useState({ open: false });
  const [brethrens, setBrethrens] = useState();
  const [statuses, setStatuses] = useState();
  useEffect(() => {
    (async () => {
      let statuses = await student_statuses();
      setStatuses(statuses);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (student_id) {
        let brothers = await axios.get(
          Conf.endpoint.tonkla + "/reg/student/brethren/" + student_id,
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        let brethrens = [];
        for (let brother of brothers.data) {
          if (brother.person1._id === student_id) {
            brethrens.push(brother.person2);
          } else {
            brethrens.push(brother.person1);
          }
        }

        setBrethrens(brethrens);
      }
    })();
  }, [student_id]);
  async function save(student) {
    await axios.put(
      Conf.endpoint.tonkla + "/reg/student/brethren",
      {
        person1: student_id,
        person2: student._id,
      },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    let Brethrens = JSON.parse(JSON.stringify(brethrens));
    Brethrens.push(student);
    setBrethrens(Brethrens);
  }
  async function remove(id) {
    if (window.confirm("Confirm to remove")) {
      await axios.post(
        Conf.endpoint.tonkla + "/reg/student/brethren/delete",
        {
          id1: student_id,
          id2: id,
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      let Brethrens = JSON.parse(JSON.stringify(brethrens));
      Brethrens = Brethrens.filter((j) => j._id !== id);
      setBrethrens(Brethrens);
    }
  }
  function getStatus(id) {
    let i = statuses.findIndex((j) => j._id === id);
    if (i > -1) return statuses[i].label;
    else return "";
  }
  function getGroups(id) {
    let i = groups.findIndex((j) => j.value === id);
    if (i > -1) return groups[i].label;
    else return "";
  }
  return (
    <div>
      {brethrens && (
        <>
          <DialogStudent
            state={dialogState}
            onClose={() => setDialogState({ open: false })}
            onSelect={(val) => {
              save(val);
              setDialogState({ open: false });
            }}
          />
          <Grid container>
            <Grid item xs={6} sm={6} md={7} lg={8}>
              <h3 style={styles.title}>พี่-น้องภายในโรงเรียน</h3>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={5}
              lg={4}
              style={{ paddingTop: "15px" }}
              align="right"
            >
              <IconButton
                color="primary"
                onClick={() => setDialogState({ open: true })}
              >
                <Icon style={{ fontSize: "2rem" }}>add_circle</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th"
                        align="center"
                        style={{ width: "80px" }}
                      >
                        ลำดับ
                      </TableCell>
                      <TableCell className="th" align="center">
                        ชื่อ-นามสกุล
                      </TableCell>
                      <TableCell
                        className="th"
                        align="center"
                        style={{ width: "100px" }}
                      >
                        ชื่อเล่น
                      </TableCell>
                      <TableCell
                        className="th"
                        align="center"
                        style={{ width: "120px" }}
                      >
                        สถานะ
                      </TableCell>
                      <TableCell
                        className="th"
                        align="center"
                        style={{ width: "100px" }}
                      >
                        ห้องเรียน
                      </TableCell>
                      <TableCell
                        className="th"
                        align="center"
                        style={{ width: "80px" }}
                      >
                        ลบ
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {brethrens.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan="6"
                          align="center"
                          style={{ height: "60px", color: "#777" }}
                        >
                          ไม่พบข้อมูล
                        </TableCell>
                      </TableRow>
                    )}
                    {brethrens.map((person, key) => (
                      <TableRow key={key}>
                        <TableCell align="center" className="td">
                          {key + 1}
                        </TableCell>
                        <TableCell className="td">
                          {person.prefix} {person.first_name}{" "}
                          {person.middle_name} {person.last_name}
                        </TableCell>
                        <TableCell className="td">{person.nickname}</TableCell>
                        <TableCell className="td" align="center">
                          {getStatus(person.student_status)}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {getGroups(person.current_group)}
                        </TableCell>
                        <TableCell className="controlTd" align="center">
                          <IconButton
                            color="error"
                            onClick={() => remove(person._id)}
                          >
                            <Icon>clear</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

function PersonalInfo({ form, onChange, changeFields, options }) {
  return (
    <div>
      <h3 style={styles.title}>ข้อมูลส่วนตัวนักเรียน</h3>
      <Grid container spacing={2}>
        <GridSelect
          grid={[12, 6, 3, 3]}
          label="Prefix"
          id="prefix"
          options={options.student_prefixs || []}
          onChange={async (val) => {
            let prefix_en = "";
            let gender = "";
            if (val === "เด็กชาย") {
              prefix_en = "Master";
              gender = "ชาย";
            } else if (val === "เด็กหญิง") {
              prefix_en = "Miss";
              gender = "หญิง";
            }
            changeFields({ prefix: val, prefix_en, gender });
          }}
          value={form.prefix || "-"}
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="First name"
          placeholder="ชื่อ"
          id="first_name"
          onChange={(val) => onChange("first_name", val)}
          value={form.first_name}
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Middle name"
          placeholder="ชื่อกลาง"
          onChange={(val) => onChange("middle_name", val)}
          value={form.middle_name}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Last name"
          placeholder="นามสกุล"
          id="last_name"
          onChange={(val) => onChange("last_name", val)}
          value={form.last_name}
          required
        />

        <GridSelect
          grid={[12, 6, 3, 3]}
          label="Prefix (EN)"
          id="prefix_en"
          options={options.student_prefixs_en || []}
          // onChange={(val) => onChange("prefix_en", val)}

          onChange={async (val) => {
            let prefix = "";
            let gender = "";
            if (val === "Master") {
              prefix = "เด็กชาย";
              gender = "ชาย";
            } else if (val === "Miss") {
              prefix = "เด็กหญิง";
              gender = "หญิง";
            }
            changeFields({ prefix_en: val, prefix, gender });
          }}
          value={form.prefix_en || "-"}
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="First name (EN)"
          placeholder="ชื่อ (EN)"
          onChange={(val) => onChange("first_name_en", val)}
          value={form.first_name_en}
          id="first_name_en"
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Middle name (EN)"
          placeholder="ชื่อกลาง (EN)"
          onChange={(val) => onChange("middle_name_en", val)}
          value={form.middle_name_en}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Last name (EN)"
          placeholder="นามสกุล (EN)"
          onChange={(val) => onChange("last_name_en", val)}
          value={form.last_name_en}
          id="last_name_en"
          required
        />

        <GridInput
          grid={[12, 12, 4, 4]}
          label="Nickname"
          placeholder="ชื่อเล่น"
          onChange={(val) => onChange("nickname", val)}
          value={form.nickname}
          id="nickname"
          required
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="Nickname (EN)"
          placeholder="ชื่อเล่น (EN)"
          onChange={(val) => onChange("nickname_en", val)}
          value={form.nickname_en}
          id="nickname_en"
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Gender"
          options={genders}
          onChange={(val) => onChange("gender", val)}
          value={form.gender}
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="ID card number"
          placeholder="เลขบัตรประชาชน"
          onChange={(val) => onChange("idx", val)}
          value={form.idx}
          id="idx"
          required
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="Passport number"
          placeholder="เลขหนังสือเดินทาง"
          onChange={(val) => onChange("passport_no", val)}
          value={form.passport_no}
        />
        <GridDatePicker
          grid={[12, 12, 4, 4]}
          label="Birth date"
          placeholder="วันเกิด"
          onChange={(val) => onChange("birth_date", val)}
          value={form.birth_date}
          id="birth_date"
          // prefix,first_name,last_name,prefix_en,first_name_en,last_name_en,idx,birth_date
          required
        />
        {/* <GridInput
          grid={[12, 12, 3, 3]}
          label="Nationality"
          placeholder="เชื้อชาติ"
          onChange={(val) => onChange("nationality", val)}
          value={form.nationality}
        /> 
        <GridInput
          grid={[12, 12, 3, 3]}
          label="Origin"
          placeholder="สัญชาติ"
          onChange={(val) => onChange("origin", val)}
          value={form.origin}
        />*/}

        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Nationality"
          options={options.nationalities}
          onChange={(val) => onChange("nationality", val)}
          value={form.nationality}
          id="nationality"
          required
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Origin"
          options={options.origins || []}
          onChange={(val) => onChange("origin", val)}
          value={form.origin}
          id="origin"
          required
        />
        {/* <GridInput
          grid={[12, 12, 3, 3]}
          label="Religion"
          placeholder="ศาสนา"
          onChange={(val) => onChange("religion", val)}
          value={form.religion}
        /> */}
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Religion"
          options={options.religions || []}
          onChange={(val) => onChange("religion", val)}
          value={form.religion}
          required
          id="religion"
        />
      </Grid>
    </div>
  );
}

function StudentInfo({ form, groups, onChange }) {
  const [statuses, setStatuses] = useState([]);
  // const [groups, setGroups] = useState([]);
  useEffect(() => {
    (async () => {
      let statuses = await student_statuses();
      setStatuses(statuses);
    })();
  }, []);
  /*
  async function updateTable(field, values) {
    values = await values.map((j) => j.value0);
    onChange(field, values);
  }
  */
  return (
    <div>
      <h3 style={styles.title}>รายละเอียดนักเรียน</h3>
      <Grid container spacing={2}>
        <GridInput
          grid={[12, 12, 4, 4]}
          label="Student ID"
          placeholder="เลขประจำตัวนักเรียน"
          onChange={(val) => onChange("student_code", val)}
          value={form.student_code}
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Group"
          options={groups}
          onChange={(val) => onChange("current_group", val)}
          value={form.current_group}
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Status"
          options={statuses}
          onChange={(val) => onChange("student_status", val)}
          value={form.student_status}
        />

        <GridDatePicker
          grid={[12, 12, 4, 4]}
          label="Attended date"
          placeholder="วันที่เข้าเรียน"
          onChange={(val) => onChange("attended_date", val)}
          value={form.attended_date}
        />

        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Blood"
          options={[
            { label: "N/A" },
            { label: "A" },
            { label: "B" },
            { label: "AB" },
            { label: "O" },
          ]}
          onChange={(val) => onChange("blood_group", val)}
          value={form.blood_group}
          id="blood_group"
          required
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="e-mail"
          placeholder="อีเมล"
          onChange={(val) => onChange("account", val)}
          value={form.account}
        />

        <GridSelect
          grid={[12, 12, 4, 4]}
          label="e-mail status"
          options={[
            { label: "Active", value: 1 },
            { label: "Suspended", value: 0 },
          ]}
          onChange={(val) => onChange("account_status", val)}
          value={form.account_status}
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Subsidy"
          options={[
            { label: "เบิก" },
            { label: "ต่ำเกณฑ์" },
            { label: "ต่างชาติ" },
          ]}
          onChange={(val) => onChange("subsidy", val)}
          value={form.subsidy}
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Program"
          options={programs}
          onChange={(val) => onChange("program", val)}
          value={form.program}
        />

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <InputTable
            title="โรคประจำตัว"
            icon="coronavirus"
            data={form.congenital_disease || []}
            cols={[{ title: "ชื่อโรค" }, { title: "อาการ" }]}
            fields={["disease_name", "symptoms"]}
            updateTable={(values) => onChange("congenital_disease", values)}
            checkboxEl={{
              label: "ไม่มี",
              value: form.no_congenital_disease,
              onUpdate: (value) => {
                onChange("no_congenital_disease", value);
              },
            }}
          />
          <InputTable
            title="การแพ้ยา"
            icon="local_hospital"
            data={form.drug_allergy || []}
            cols={[{ title: "ชื่อยา" }, { title: "อาการ" }]}
            fields={["drug_name", "symptoms"]}
            updateTable={(values) => onChange("drug_allergy", values)}
            checkboxEl={{
              label: "ไม่แพ้",
              value: form.no_drug_allergy,
              onUpdate: (value) => {
                onChange("no_drug_allergy", value);
              },
            }}
          />
          <InputTable
            title="การแพ้อาหาร"
            icon="fastfood"
            data={form.food_allergy || []}
            fields={["food_name", "symptoms"]}
            cols={[{ title: "อาหารที่แพ้" }, { title: "อาการ" }]}
            updateTable={(values) => onChange("food_allergy", values)}
            checkboxEl={{
              label: "ไม่แพ้",
              value: form.no_food_allergy,
              onUpdate: (value) => {
                onChange("no_food_allergy", value);
              },
            }}
          />
        </Grid>
        <GridInput
          grid={[12, 12, 12, 12]}
          label="Note"
          rows={3}
          placeholder="บันทึกอื่น ๆ"
          onChange={(val) => onChange("student_note", val)}
          value={form.student_note}
        />
      </Grid>
    </div>
  );
}

function AddressInfo({
  form,
  onChange,
  onCopyAddress,
  onChangeFields,
  options,
}) {
  const [dialogState, setDialogState] = useState({ open: false });
  /*
  const house_type = [
    { label: "บ้านของตนเอง" },
    { label: "บ้านเช่า" },
    { label: "บ้านพัก" },
  ];
  */
  const house_type = options.register_house_type;
  function selectArea(area) {
    if (dialogState.type === "current") {
      onChangeFields({
        current_sub_district: area.area,
        current_district: area.district,
        current_province: area.province,
      });
    } else {
      onChangeFields({
        sub_district: area.area,
        district: area.district,
        province: area.province,
      });
    }
    setDialogState({ open: false });
  }
  return (
    <div>
      <DialogZip
        state={dialogState}
        onClose={() => setDialogState({ open: false })}
        onSelect={selectArea}
      />
      <h3 style={styles.title}>ข้อมูลที่อยู่ตามทะเบียนบ้าน</h3>
      <Grid container spacing={2}>
        <GridInput
          grid={[12, 6, 3, 3]}
          label="House ID"
          placeholder="รหัสบ้าน"
          onChange={(val) => onChange("house_id", val)}
          value={form.house_id}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="House No"
          placeholder="บ้านเลขที่"
          required
          onChange={(val) => onChange("house_no", val)}
          value={form.house_no}
          id="house_no"
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Village"
          placeholder="หมู่บ้าน"
          onChange={(val) => onChange("village", val)}
          value={form.village}
        />
        <GridInput
          grid={[12, 6, 2, 2]}
          label="Moo"
          placeholder="หมู่"
          onChange={(val) => onChange("moo", val)}
          value={form.moo}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="Soi"
          placeholder="ซอย"
          onChange={(val) => onChange("soi", val)}
          value={form.soi}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Street"
          placeholder="ถนน"
          onChange={(val) => onChange("street", val)}
          value={form.street}
        />

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            label="Zip"
            InputLabelProps={{ shrink: true }}
            placeholder="รหัสไปรษณีย์"
            required
            id="zip_code"
            fullWidth
            size="small"
            value={form.zip_code || ""}
            InputProps={{
              endAdornment: (
                <IconButton
                  edge="end"
                  onClick={() =>
                    setDialogState({
                      open: true,
                      type: "main",
                      code: form.zip_code,
                    })
                  }
                >
                  <Icon>search</Icon>
                </IconButton>
              ),
            }}
            onChange={(e) => onChange("zip_code", e.target.value)}
            onKeyUp={(e) =>
              e.key === "Enter" &&
              setDialogState({
                open: true,
                type: "main",
                code: form.zip_code,
              })
            }
          />
        </Grid>
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Sub-district"
          placeholder="ตำบล"
          required
          id="sub_district"
          onChange={(val) => onChange("sub_district", val)}
          value={form.sub_district}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="District"
          placeholder="อำเภอ"
          required
          id="district"
          onChange={(val) => onChange("district", val)}
          value={form.district}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Province"
          placeholder="จังหวัด"
          required
          id="province"
          onChange={(val) => onChange("province", val)}
          value={form.province}
        />
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <h3 style={styles.title}>ข้อมูลที่อยู่ปัจจุบัน</h3>
        </Grid>
        <Grid item xs={6} align="right" style={{ paddingTop: "30px" }}>
          <IconButton color="primary" component="span" onClick={onCopyAddress}>
            <Icon>file_copy</Icon>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <GridSelect
          grid={[12, 6, 3, 3]}
          label="House type"
          options={house_type}
          onChange={(val) => onChange("current_house_type", val)}
          value={form.current_house_type}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="House No"
          placeholder="บ้านเลขที่"
          required
          id="current_house_no"
          onChange={(val) => onChange("current_house_no", val)}
          value={form.current_house_no}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Village"
          placeholder="บ้านเลขที่"
          onChange={(val) => onChange("current_village", val)}
          value={form.current_village}
        />
        <GridInput
          grid={[12, 6, 2, 2]}
          label="Moo"
          placeholder="หมู่"
          onChange={(val) => onChange("current_moo", val)}
          value={form.current_moo}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="Soi"
          placeholder="ซอย"
          onChange={(val) => onChange("current_soi", val)}
          value={form.current_soi}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Street"
          placeholder="ถนน"
          onChange={(val) => onChange("current_street", val)}
          value={form.current_street}
        />

        <Grid item xs={12} sm={6} md={4} lg={4}>
          {/* <FormControl variant="outlined" fullWidth>
            <InputLabel shrink>Zip</InputLabel>
            <OutlinedInput
              value={form.current_zip_code || ""}
              onChange={(e) => onChange("current_zip_code", e.target.value)}
              onKeyUp={(e) =>
                e.key === "Enter" &&
                setDialogState({
                  open: true,
                  type: "current",
                  code: form.current_zip_code,
                })
              }
              size="small"
              placeholder="รหัสไปรษณีย์"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() =>
                      setDialogState({
                        open: true,
                        type: "current",
                        code: form.current_zip_code,
                      })
                    }
                  >
                    <Icon>search</Icon>
                  </IconButton>
                </InputAdornment>
              }
              label="Zip"
            /> 
          </FormControl>*/}
          <TextField
            label="Zip"
            InputLabelProps={{ shrink: true }}
            placeholder="รหัสไปรษณีย์"
            fullWidth
            id="current_zip_code"
            required
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton
                  edge="end"
                  onClick={() =>
                    setDialogState({
                      open: true,
                      type: "current",
                      code: form.current_zip_code,
                    })
                  }
                >
                  <Icon>search</Icon>
                </IconButton>
              ),
            }}
            value={form.current_zip_code || ""}
            onChange={(e) => onChange("current_zip_code", e.target.value)}
            onKeyUp={(e) =>
              e.key === "Enter" &&
              setDialogState({
                open: true,
                type: "main",
                code: form.current_zip_code,
              })
            }
          />
        </Grid>
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Sub-district"
          placeholder="ตำบล"
          required
          id="current_sub_district"
          onChange={(val) => onChange("current_sub_district", val)}
          value={form.current_sub_district}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="District"
          placeholder="อำเภอ"
          required
          id="current_district"
          onChange={(val) => onChange("current_district", val)}
          value={form.current_district}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Province"
          required
          id="current_province"
          placeholder="จังหวัด"
          onChange={(val) => onChange("current_province", val)}
          value={form.current_province}
        />
      </Grid>
    </div>
  );
}

function InputTable({
  title,
  data,
  icon,
  cols,
  fields,
  updateTable,
  checkboxEl,
}) {
  const [table, setTable] = useState();
  const [form, setForm] = useState({});
  const [state, setState] = useState({});
  useEffect(() => {
    (async () => {
      if (data && fields && !table) {
        let Data = await data.map((j, key) => {
          let i = 0;
          let r = { key };
          for (let field of fields) {
            r["value" + i] = j[field];
            i++;
          }
          return r;
        });
        setTable(Data);
      }
    })();
  }, [data, fields, table]);
  async function decodeTable(Table) {
    return new Promise((resolve) => {
      let decoded = [];
      for (let row of Table) {
        let send = {};
        let key = 0;
        for (let f of fields) {
          send[f] = row["value" + key];
          key++;
        }
        decoded.push(send);
      }
      resolve(decoded);
    });
  }
  async function addRow() {
    if (form.value0 && form.value0 !== "") {
      let Table = JSON.parse(JSON.stringify(table));
      form.key = Table.length;
      Table.push(form);
      setTable(Table);
      let decoded = await decodeTable(Table);
      updateTable(decoded);
      setForm({});
    }
  }
  async function deleteRow(key) {
    let Table = JSON.parse(JSON.stringify(table));
    Table = Table.filter((j) => j.key !== key);
    setTable(Table);
    let decoded = await decodeTable(Table);
    updateTable(decoded);
    setState({ ...state, selectRow: -1 });
  }
  async function saveRow(row) {
    let Table = JSON.parse(JSON.stringify(table));
    let i = Table.findIndex((j) => j.key === row.key);
    Table[i] = { key: row.key, value0: row.values[0], value1: row.values[1] };
    setTable(Table);
    let decoded = await decodeTable(Table);
    updateTable(decoded);
    setState({ ...state, selectRow: -1 });
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={7}>
          <Grid container style={{ margin: "20px auto 10px" }}>
            <Grid item style={{ marginRight: "10px" }}>
              <Icon>{icon}</Icon>
            </Grid>
            <Grid item style={{ fontSize: ".9rem" }}>
              {title}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} align="right" style={{ paddingTop: "15px" }}>
          <FormControlLabel
            control={
              <Checkbox checked={checkboxEl.value || false} size="small" />
            }
            label={<div style={{ fontSize: ".9rem" }}>{checkboxEl.label}</div>}
            onChange={(e) => checkboxEl.onUpdate(e.target.checked)}
          />
        </Grid>
      </Grid>
      <div styles={{ pointerEvents: "none" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="td"
                  align="center"
                  style={{ width: "80px" }}
                >
                  ลำดับ
                </TableCell>
                {cols &&
                  cols.map((col, key) => (
                    <TableCell className="td" align="center" key={key}>
                      {col.title}
                    </TableCell>
                  ))}

                <TableCell className="td" style={{ width: "100px" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {table &&
                table.map((row, key) =>
                  key === state.selectRow ? (
                    <EditTableRow
                      key={key}
                      no={row.key}
                      values={[row.value0, row.value1]}
                      deleteRow={() => deleteRow(row.key)}
                      clearSelect={() => setState({ ...state, selectRow: -1 })}
                      saveRow={(form) => saveRow(form)}
                      disabled={checkboxEl.value}
                    />
                  ) : (
                    <InputTableRow
                      key={key}
                      no={key + 1}
                      values={[row.value0, row.value1]}
                      selectRow={() => setState({ ...state, selectRow: key })}
                      disabled={checkboxEl.value}
                    />
                  )
                )}
              <TableRow>
                <TableCell />
                <TableCell className="inputTd">
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={form.value0 || ""}
                    size="small"
                    onChange={(e) =>
                      setForm({ ...form, value0: e.target.value })
                    }
                    disabled={checkboxEl.value}
                  />
                </TableCell>
                <TableCell className="inputTd">
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={form.value1 || ""}
                    size="small"
                    onChange={(e) =>
                      setForm({ ...form, value1: e.target.value })
                    }
                    disabled={checkboxEl.value}
                  />
                </TableCell>
                <TableCell className="inputTd" align="center">
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={addRow}
                    disabled={checkboxEl.value}
                  >
                    <Icon>save</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function InputTableRow({ no, values, selectRow, disabled }) {
  return (
    <TableRow>
      <TableCell className="td" align="center">
        {no}
      </TableCell>
      <TableCell className="td" style={{ width: "40%" }}>
        {values[0]}
      </TableCell>
      <TableCell className="td" style={{ width: "40%" }}>
        {values[1]}
      </TableCell>
      <TableCell className="controlTd" align="center" style={{ width: "40px" }}>
        <IconButton
          color="primary"
          component="span"
          onClick={selectRow}
          disabled={disabled}
        >
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
function EditTableRow({
  no,
  values,
  saveRow,
  deleteRow,
  clearSelect,
  disabled,
}) {
  const [form, setForm] = useState([]);
  useEffect(() => {
    (async () => {
      if (values && values[0]) {
        setForm(values);
      }
    })();
  }, [values]);
  return (
    <TableRow style={{ background: "#efe" }}>
      <TableCell className="controlTd" align="center">
        <IconButton
          color="error"
          component="span"
          onClick={deleteRow}
          disabled={disabled}
          align="center"
        >
          <Icon>clear</Icon>
        </IconButton>
      </TableCell>
      <TableCell className="inputTd">
        <TextField
          variant="outlined"
          fullWidth
          value={form[0] || ""}
          size="small"
          style={{ background: "#fff" }}
          onChange={(e) => setForm({ ...form, 0: e.target.value })}
          disabled={disabled}
        />
      </TableCell>
      <TableCell className="inputTd">
        <TextField
          variant="outlined"
          fullWidth
          value={form[1] || ""}
          size="small"
          style={{ background: "#fff" }}
          onChange={(e) => setForm({ ...form, 1: e.target.value })}
          disabled={disabled}
        />
      </TableCell>
      <TableCell className="controlTd" align="center">
        <IconButton
          color="inherit"
          component="span"
          onClick={clearSelect}
          align="center"
          disabled={disabled}
        >
          <Icon style={{ color: "#2196f3" }}>undo</Icon>
        </IconButton>
        <IconButton
          color="primary"
          component="span"
          onClick={() => saveRow({ key: no, values: form })}
          align="center"
          disabled={disabled}
        >
          <Icon>save</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
