import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

import {
  checkPermission,
  getGroups,
  getGroupsStudents,
  getFullName,
  fetchData,
} from "../js/main";
import { GridSelect } from "../components/InputComponents";

export default function GroupChange(props) {
  const [state, setState] = useState({});
  const [groups, setGroups] = useState();
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
          let groups = await getGroups();
          groups = await groups.groups.map((group) => {
            return { label: group.name, value: group._id };
          });
          setGroups(groups);
          setState({ fromGroup: groups[0].value, toGroup: groups[1].value });
        }
      }
    })();
  }, [props]);

  useEffect(() => {
    (async () => {
      if (state.fromGroup) {
        let group = JSON.parse(JSON.stringify(state.fromGroup));
        let students = await getGroupsStudents(group);
        setStudents(students.result);
      }
    })();
  }, [state.fromGroup]);

  function selectStudent(e, key) {
    let Students = JSON.parse(JSON.stringify(students));
    Students[key].chk = e.target.checked;
    setStudents(Students);
  }

  async function save() {
    let student_ids = await students
      .filter((j) => j.chk === true)
      .map((j) => j._id);
    let data = {
      fromGroup: state.fromGroup,
      toGroup: state.toGroup,
      student_ids,
    };
    if (student_ids[0] && state.fromGroup !== state.toGroup) {
      let result = await fetchData("put", "/group/change", data);
      if (result) {
        let students = await getGroupsStudents(state.fromGroup);
        setStudents(students.result);
        alert("Save Completed");
      }
    } else alert("Error");
  }
  function selectAll(e) {
    let value = e.target.checked;
    let Students = JSON.parse(JSON.stringify(students));
    for (let student of Students) {
      student.chk = value;
    }
    setStudents(Students);
  }
  return (
    <div className="content">
      <div style={{ textAlign: "right" }}>
        <ProfileBox profile={profile} />
      </div>
      <PageNameBox page={props.page} />
      {groups && (
        <>
          <br />
          <Grid container spacing={2}>
            <GridSelect
              grid={[12, 12, 6, 6]}
              label="ย้ายจากกลุ่ม"
              disabled_empty={true}
              onChange={(val) => {
                setState({ ...state, fromGroup: val });
              }}
              options={groups}
              value={state.fromGroup}
            />
            <GridSelect
              grid={[12, 12, 6, 6]}
              label="ย้ายไปกลุ่ม"
              disabled_empty={true}
              onChange={(val) => {
                setState({ ...state, toGroup: val });
              }}
              options={groups}
              value={state.toGroup}
            />
          </Grid>
          <br />
          {students && (
            <TableStudents
              students={students}
              onSelect={selectStudent}
              onSelectAll={selectAll}
            />
          )}
          <div style={{ textAlign: "right", marginTop: "15px" }}>
            <Button
              variant="contained"
              onClick={save}
              startIcon={<Icon>change_circle</Icon>}
            >
              Chenge Group
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function TableStudents({ students, onSelect, onSelectAll }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "80px" }}>No</TableCell>
              <TableCell sx={{ width: "150px" }}>Student ID</TableCell>
              <TableCell>Full name</TableCell>
              <TableCell sx={{ width: "200px" }}>Nickname</TableCell>
              <TableCell sx={{ width: "100px" }} className="controlTd">
                <Checkbox onChange={onSelectAll} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, key) => (
              <TableRow key={key}>
                <TableCell align="center">{key + 1}</TableCell>
                <TableCell align="center">{student.student_code}</TableCell>
                <TableCell>{getFullName(student)}</TableCell>
                <TableCell align="center">{student.nickname}</TableCell>
                <TableCell align="center" className="controlTd">
                  <Checkbox
                    checked={student.chk || false}
                    onChange={(e) => onSelect(e, key)}
                  />
                </TableCell>
              </TableRow>
            ))}
            {students && students.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#999",
                  }}
                >
                  ไม่พบข้อมูล
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
