import Conf from "../Conf";
import axios from "axios";
import { prefixs } from "../const";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export const fetchData = async (method, path, data) => {
  let result = await axios({
    method: method,
    url: Conf.endpoint.tonkla + "/reg" + path,
    data: data,
    headers: { Authorization: cookie.access_token },
  });
  if (result.data.error) {
    alert(result.data.error.message);
    return null;
  } else return result.data;
};

export const getPermission = (token) => {
  return new Promise(async (resolve) => {
    let currentPath = window.location.pathname;
    let isLoginPath =
      currentPath !== process.env.PUBLIC_URL + "/test" &&
      currentPath !== process.env.PUBLIC_URL + "/login" &&
      currentPath !== process.env.PUBLIC_URL + "/action/checkin"
        ? false
        : true;
    let cookie = null;
    if (token) {
      cookie = { access_token: token };
    } else {
      cookie = localStorage.getItem(Conf.cookie_name);
      cookie = JSON.parse(cookie);
    }
    if (cookie && cookie.access_token) {
      let permission = await axios.get(
        Conf.endpoint.tonkla + "/reg/admin/permission",
        { headers: { Authorization: cookie.access_token } }
      );
      if (permission.data.usn) {
        resolve(permission.data);
      } else if (!isLoginPath) {
        window.location = process.env.PUBLIC_URL + "/login";
      } else resolve(null);
    } else if (!isLoginPath) {
      window.location = process.env.PUBLIC_URL + "/login";
    } else {
      resolve(null);
    }
  });
};

export const checkRegStaff = (profile) => {
  return new Promise(async (resolve) => {
    let i = profile.permissions.paths.findIndex((j) => j === "reg");
    if (i < 0) resolve(false);
    else resolve(true);
  });
};

export const showUploadImageSrc = (path) => {
  return new Promise(async (resolve) => {
    let src = await axios.get(Conf.endpoint.upload + "/" + path);
    if (src && src.data) resolve(src.data);
    else resolve(null);
  });
};

export const getGroupAdvisor = (usn, reg_staff) => {
  return new Promise(async (resolve) => {
    let send = {
      collection: "groups",
    };
    if (!reg_staff || reg_staff !== true) send.match = { advisors: usn };
    let groups = await axios.post(Conf.endpoint.tonkla + "/reg/query", send, {
      headers: { Authorization: cookie.access_token },
    });
    resolve(groups.data || []);
  });
};

export const checkPermission = (profile, path) => {
  return new Promise(async (resolve) => {
    resolve(true);
  });
};

export const getLevels = (groups) => {
  return new Promise(async (resolve) => {
    let result = { levels: [], level_years: [] };
    for (let group of groups) {
      let i = result.levels.findIndex((j) => j.name === group.level);
      let obj = {
        level_name: group.level,
        level_year: group.level_year,
      };
      if (i < 0) {
        result.levels.push({ name: group.level });
        result.level_years.push(obj);
      } else {
        i = result.level_years.findIndex(
          (j) =>
            j.level_name === obj.level_name && j.level_year === obj.level_year
        );
        if (i < 0) result.level_years.push(obj);
      }
    }
    resolve(result);
  });
};

export const getStampTime = (dt) => {
  return new Promise((resolve) => {
    var arrMonthEN = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var arrMonthTH = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    var d = new Date();
    if (dt) d = new Date(dt);
    var dd = String(d.getDate()).padStart(2, "0");
    var month_en = arrMonthEN[d.getMonth()];
    var month_th = arrMonthTH[d.getMonth()];
    var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = d.getFullYear();
    var isoDate = yyyy + "-" + mm + "-" + dd;

    var h = String(d.getHours()).padStart(2, "0");
    var m = String(d.getMinutes()).padStart(2, "0");
    var s = String(d.getSeconds()).padStart(2, "0");
    resolve({
      iso_date: isoDate,
      time: h + ":" + m + ":" + s,
      detail: {
        day: dd,
        month: mm,
        year: yyyy,
        hour: h,
        minute: m,
        second: s,
        month_en,
        month_th,
      },
      stampTime: d.getTime(),
    });
  });
};

export const getGroups = () => {
  return new Promise(async (resolve) => {
    let groups = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      { collection: "groups", limit: 0, sort: "name" },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    groups = groups.data.result;
    resolve({ groups });
  });
};

export const getStudent = (student_id) => {
  return new Promise(async (resolve) => {
    let r = await axios.get(
      Conf.endpoint.tonkla + "/reg/query/students/" + student_id,
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    resolve(r.data);
  });
};

export const getStudentFromPickupId = (pickup_id) => {
  return new Promise(async (resolve) => {
    let r = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      {
        collection: "pickup_persons",
        match: { _id: pickup_id },
        populate: {
          path: "student_id",
          populate: "current_group",
          select:
            "prefix first_name middle_name last_name prefix_en first_name_en middle_name_en last_name_en nickname current_group program account photo_url",
        },
      },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    if (r.data.count > 0 && r.data.result[0].student_id) {
      resolve(r.data.result[0].student_id);
    } else resolve(null);
  });
};

export const getFullName = (student) => {
  let prefix = (student && student.prefix) || "";
  let first_name = (student && student.first_name) || "";
  let middle_name = (student && student.middle_name) || "";
  let last_name = (student && student.last_name) || "";
  return (
    prefix +
    " " +
    first_name +
    " " +
    (middle_name ? middle_name + " " : "") +
    last_name
  );
};

export const getFullNameFormFields = (p, f, m, l) => {
  return (p || "") + " " + (f || "") + " " + (m ? m + " " : "") + (l || "");
};

export const getGroupsStudents = (group_id) => {
  return new Promise(async (resolve) => {
    let r = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      {
        collection: "students",
        limit: 0,
        sort: "student_code",
        match: {
          $and: [
            { current_group: group_id },
            { student_status: "5e098ba9d6cad40654a9fd3f" },
          ],
        },
      },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    r = r.data;
    resolve(r);
  });
};

export const getOptions = async (type, language) => {
  return new Promise(async (resolve) => {
    let match = {};
    if (language && language === "en") {
      match = {
        $and: [
          { type },
          { label_alt: { $ne: "" } },
          { label_alt: { $exists: true } },
        ],
      };
    } else {
      match = {
        $and: [{ type }, { label: { $ne: "" } }, { label: { $exists: true } }],
      };
    }
    let send = {
      collection: "options",
      match: match,
      limit: 0,
      sort: { priority: 1, label: 1 },
    };
    let options = await axios.post(Conf.endpoint.tonkla + "/reg/query", send, {
      headers: {
        Authorization: cookie.access_token,
      },
    });
    resolve(options.data.result);
  });
};

export const getPrefixs = (type) =>
  prefixs
    .filter((j) => j[type] !== "")
    .map((p) => {
      return {
        label: p[type],
      };
    });

export const sortJSON = (arr, field) => {
  return new Promise(async (resolve) => {
    arr = arr.sort((a, b) =>
      a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
    );
    resolve(arr);
  });
};
export const sortJSONDESC = (arr, field) => {
  return new Promise(async (resolve) => {
    arr = arr.sort((a, b) =>
      a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0
    );
    resolve(arr);
  });
};

export const url2code = (url) => {
  return new Promise(async (resolve) => {
    //https://hca.tonkla.ac.th/a1de9fa5-00fa-43b8-8228-690caecc699e
    url = url.replace("https://hcs.tonkla.ac.th/", "");
    url = url.replace("http://hcs.tonkla.ac.th/", "");
    url = url.replace("https://hca.tonkla.ac.th/", "");
    url = url.replace("http://hca.tonkla.ac.th/", "");
    url = url.replace("http://www.tonkla.ac.th/profile.php?id=", "");
    resolve(url);
  });
};

export const groupBy = (array, key) => {
  return new Promise(async (resolve) => {
    let r = [];
    for (let arr of array) {
      let i = r.findIndex((j) => j[key] === arr[key]);
      if (i < 0) r.push(arr);
    }
    resolve(r);
  });
};
/*
export const diffYearMonthDay = (dt1, dt2) => {
  return new Promise(async (resolve) => {
    var time = (dt2.getTime() - dt1.getTime()) / 1000;
    var year = Math.abs(Math.round(time / (60 * 60 * 24) / 365.25));
    // var month = Math.abs(Math.round(time / (60 * 60 * 24 * 7 * 4)));
    var month = Math.abs(Math.round(time / (60 * 60 * 24 * 30)));
    var days = Math.abs(Math.round(time / (3600 * 24)));
    let r = {
      year,
      month,
      days,
    };
    resolve(r);
  });
};
*/
export const diffYearMonthDay = (dt1, dt2) => {
  return new Promise(async (resolve) => {
    var months;
    months = (dt2.getFullYear() - dt1.getFullYear()) * 12;
    months -= dt1.getMonth();
    months += dt2.getMonth();
    months = months <= 0 ? 0 : months;
    if (dt2.getDate() + 1 < dt1.getDate()) months--;
    let diffTime = Math.abs(dt2 - dt1);
    let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let years = Math.floor(months / 12);
    resolve({
      year: years,
      month: months,
      days,
    });
  });
};

export const getAge = (birth_date, current_date) => {
  return new Promise((resolve) => {
    var yearNow = current_date.getYear();
    var monthNow = current_date.getMonth();
    var dateNow = current_date.getDate();

    var yearDob = birth_date.getYear();
    var monthDob = birth_date.getMonth();
    var dateDob = birth_date.getDate();
    var age = {};
    var yearAge = yearNow - yearDob;

    var monthAge = 12 + monthNow - monthDob;

    if (monthNow >= monthDob) monthAge = monthNow - monthDob;
    else {
      yearAge--;
    }
    var dateAge = 31 + dateNow - dateDob;

    if (dateNow >= dateDob) dateAge = dateNow - dateDob;
    else {
      monthAge--;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };
    resolve(age);
  });
};

export const checkEmpty = (form) => {
  return new Promise((resolve) => {
    let inputs = document.getElementsByTagName("input");
    let r = false;
    let emptyFields = [];
    for (let i = 0; i < inputs.length; i++) {
      let id = inputs[i].getAttribute("id");
      let required = inputs[i].getAttribute("required");
      if (id && required !== null && id.indexOf("mui-") !== 0) {
        let Class = document.getElementById(id).getAttribute("class");
        if (form[id] && form[id] !== "") {
          Class = Class.replaceAll("error-text-field", "");
          document.getElementById(id).setAttribute("class", Class);
        } else {
          r = true;
          emptyFields.push(id);
          Class += " error-text-field";
          document.getElementById(id).setAttribute("class", Class);
        }
      }
    }

    let select = document.getElementsByTagName("select");
    for (let i = 0; i < select.length; i++) {
      let id = select[i].getAttribute("id");
      let required = select[i].getAttribute("required");
      if (id && required !== null && id.indexOf("mui-") !== 0) {
        let Class = document.getElementById(id).getAttribute("class");
        if (form[id] && form[id] !== "" && form[id] !== "-") {
          Class = Class.replaceAll("error-text-field", "");
          document.getElementById(id).setAttribute("class", Class);
        } else {
          r = true;
          emptyFields.push(id);
          Class += " error-text-field";
          document.getElementById(id).setAttribute("class", Class);
        }
      }
    }
    resolve({ empty: r, emptyFields });
  });
};

export const checkEmptyPageElement = () => {
  return new Promise((resolve) => {
    let inputs = document.getElementsByTagName("input");
    let r = false;
    let emptyFields = [];
    for (let i = 0; i < inputs.length; i++) {
      let id = inputs[i].getAttribute("id");
      let required = inputs[i].getAttribute("required");
      if (id && required !== null && id.indexOf("mui-") !== 0) {
        let Class = document.getElementById(id).getAttribute("class");
        let value = inputs[i].value;
        if (value && value !== "") {
          Class = Class.replaceAll("error-text-field", "");
          document.getElementById(id).setAttribute("class", Class);
        } else {
          r = true;
          emptyFields.push(id);
          Class += " error-text-field";
          document.getElementById(id).setAttribute("class", Class);
        }
      }
    }

    let select = document.getElementsByTagName("select");
    for (let i = 0; i < select.length; i++) {
      let id = select[i].getAttribute("id");
      let required = select[i].getAttribute("required");
      if (id && required !== null && id.indexOf("mui-") !== 0) {
        let Class = document.getElementById(id).getAttribute("class");
        let value = select[i].value;
        if (value && value !== "" && value !== "-") {
          Class = Class.replaceAll("error-text-field", "");
          document.getElementById(id).setAttribute("class", Class);
        } else {
          r = true;
          emptyFields.push(id);
          Class += " error-text-field";
          document.getElementById(id).setAttribute("class", Class);
        }
      }
    }
    resolve({ empty: r, emptyElements: emptyFields });
  });
};
