import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ListStudent from "../components/ListStudent";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function DialogStudent({ state, onClose, onSelect }) {
  const open = state.open;
  const [students, setStudents] = useState();

  const Students = async (keyword) => {
    return new Promise(async (resolve) => {
      if (keyword.trim() !== "") {
        keyword = {
          value: keyword.trim(),
          fields: [
            "first_name",
            "middle_name",
            "last_name",
            "student_code",
            "nickname",
          ],
        };
      }
      let match = null;
      let result = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "students",
          limit: 8,
          select:
            "prefix first_name middle_name last_name nickname current_group",
          keyword: keyword,
          match,
          sort: { first_name: 1, last_name: 1 },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      if (result.data.result) resolve(result.data);
      else resolve({ count: 0, result: [] });
    });
  };
  useEffect(() => {
    (async () => {
      setStudents([]);
    })();
  }, [state]);
  async function goSearch() {
    let keyword = document.getElementById("keyword").value;
    let students = await Students(keyword);
    setStudents(students.result || []);
  }
  return (
    <div>
      {students && (
        <Dialog open={open} maxWidth="lg">
          <DialogTitle>รายชื่อนักเรียน</DialogTitle>
          <DialogContent>
            <div style={{ paddingTop: "10px" }}>
              <TextField
                id="keyword"
                InputLabelProps={{ shrink: true }}
                style={{ minWidth: "400px" }}
                size="small"
                label="Search"
                placeholder="ค้นหา..."
                onKeyUp={(e) => e.key === "Enter" && goSearch()}
                autoComplete="current-password"
              />
            </div>
            <div style={{ paddingTop: "10px" }}>
              <ListStudent
                students={students}
                onSelect={(val) => {
                  onSelect(val);
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
