import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
// import FormStudent from "../components/FormStudent";
// import FormCurator from "../components/FormCurator";
import Pagebar from "../components/Pagebar";
import SyncStudent from "./forms/SyncStudent";
// import SyncCurator from "./forms/SyncCurator";
import ListStudent from "../components/ListStudent";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams, useNavigate } from "react-router-dom";

import { getStampTime, checkPermission, fetchData } from "../js/main";
// import axios from "axios";
// import Conf from "../Conf";

// const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Student(props) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();
  const [logs, setLogs] = useState();
  const [state, setState] = useState({ tabNo: 0 });
  const params = useParams();
  const student_id = params.id;
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
          if (!props.match.params.id) setLoading(false);
        }
      }
    })();
  }, [props]);
  useEffect(() => {
    (async () => {
      if (student_id) {
        let Student = await fetchData("post", "/query", {
          collection: "log_student_updates",
          match: { student_id: student_id },
          sort: { log_date: -1 },
        });
        for (let st of Student.result) {
          st.log_show_date = await getStampTime(st.log_date);
          st.log_show_date =
            st.log_show_date.iso_date + " " + st.log_show_date.time;
        }
        if (Student) {
          setLogs(Student.result);
        }
      }
      setLoading(false);
    })();
  }, [student_id]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <>
            {loading === true ? (
              <div style={{ paddingTop: "40px" }}>
                <LinearProgress />
              </div>
            ) : (
              <>
                {!logs ? (
                  <ShowList history={props.history} />
                ) : (
                  <>
                    <Tabs
                      value={state.tabNo}
                      onChange={(e, val) => setState({ ...state, tabNo: val })}
                      style={{ margin: "20px auto 20px" }}
                    >
                      <Tab label="ข้อมูลนักเรียน" component="div" />
                      <Tab label="ข้อมูลผู้ปกครอง" component="div" />
                    </Tabs>

                    {
                      {
                        0: <SyncStudent logs={logs} student_id={student_id} />,
                        1: (
                          <>
                            {/* <SyncCurator logs={logs} student_id={student_id} /> */}
                          </>
                        ),
                      }[state.tabNo]
                    }
                  </>
                )}
              </>
            )}
          </>
        </div>
      )}
    </>
  );
}

function ShowList({ history }) {
  const [students, setStudents] = useState();
  const [form, setForm] = useState({ keyword: "", status: "-", skip: 0 });
  const [statuses, setStatuses] = useState([{ label: "ทั้งหมด", value: "-" }]);
  const limit = 30;
  const navigate = useNavigate();
  const Students = async (form) => {
    return new Promise(async (resolve) => {
      let keyword = null;

      if (form.keyword.trim() !== "") {
        keyword = {
          value: form.keyword.trim(),
          fields: [
            "first_name",
            "middle_name",
            "last_name",
            "first_name_en",
            "middle_name_en",
            "last_name_en",
            "student_code",
            "nickname",
            "nickname_en",
            "student_code",
          ],
        };
      }
      let match = null;
      if (form.status && form.status === "*") {
        match = { student_status: { $exists: false } };
      } else if (form.status && form.status !== "-")
        match = { student_status: form.status };
      let result = await fetchData("post", "/query", {
        collection: "students",
        skip: form.skip || 0,
        limit,
        select:
          "prefix first_name middle_name last_name nickname current_group student_code",
        keyword: keyword,
        match,
        sort: { first_name: 1, last_name: 1 },
      });
      if (result.result) resolve(result);
      else resolve({ count: 0, result: [] });
    });
  };
  useEffect(() => {
    (async () => {
      let result = await fetchData("post", "/query", {
        collection: "student_statuses",
      });
      /*
      let result = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "student_statuses",
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      */
      let s = result.result;
      s = await s.map((j) => {
        j.value = j._id;
        return j;
      });

      s.push({ label: "ไม่ระบุ", value: "*" });
      s.unshift({ label: "ทั้งหมด", value: "-" });
      setStatuses(s);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      let students = await Students(form);
      setStudents(students);
    })();
  }, [form]);
  async function goSearch() {
    setForm({ ...form, keyword: document.getElementById("keyword").value });
  }
  return (
    <div>
      {students && (
        <>
          <br />
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={6}
              style={{
                fontWeight: 500,
                paddingLeft: "15px",
                paddingBottom: "15px",
              }}
            >
              Found :{" "}
              <span style={{ padding: "0px 10px" }}>{students.count}</span>{" "}
              record(s)
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} align="right">
              <Grid container spacing={1} style={{ maxWidth: "405px" }}>
                <Grid item style={{ width: "200px" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                      native
                      label="Status"
                      value={form.status || "-"}
                      onChange={(e) =>
                        setForm({ ...form, status: e.target.value, skip: 0 })
                      }
                    >
                      {statuses.map((status, key) => (
                        <option value={status.value} key={key}>
                          {status.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "200px" }}>
                  <TextField
                    fullWidth
                    label="Keyword"
                    InputLabelProps={{ shrink: true }}
                    placeholder="ค้นหา..."
                    id="keyword"
                    size="small"
                    InputProps={{
                      endAdornment: <Icon>search</Icon>,
                    }}
                    onKeyUp={(e) => e.key === "Enter" && goSearch()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <Pagebar
                count={Math.floor(students.count / limit) + 1}
                page={form.skip / limit + 1}
                changePage={(val) => {
                  setForm({ ...form, skip: (val - 1) * limit });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <ListStudent
                students={students.result}
                onSelect={(student) =>
                  navigate(process.env.PUBLIC_URL + "/sync/" + student._id)
                }
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
