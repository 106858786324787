import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fetchData } from "../js/main";
import { Button, Icon, TextField } from "@mui/material";
// import { checkPermission } from "./js/main";

export default function PickupTime(props) {
  const [profile, setProfile] = useState();
  const [groups, setGroups] = useState();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (props.profile) {
      // let test = checkPermission(props.profile);
      setProfile(props.profile);
    }
  }, [props.profile]);

  useEffect(() => {
    (async () => {
      if (profile) {
        let groups = await fetchData("post", "/query", {
          collection: "groups",
          sort: { name: 1 },
        });
        setGroups(groups);
      }
    })();
  }, [profile]);
  function changeValue(field, value, key) {
    let Groups = JSON.parse(JSON.stringify(groups));
    Groups.result[key][field] = value;
    setGroups(Groups);
  }
  async function save() {
    setDisabled(true);
    await fetchData("put", "/group", groups.result);
    setDisabled(false);
    alert("Save completed");
  }

  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          {groups && (
            <>
              <TableContainer component={Paper} style={{ marginTop: "15px" }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ width: "100px" }}>
                        No
                      </TableCell>
                      <TableCell align="center">Level</TableCell>
                      <TableCell align="center" sx={{ width: "150px" }}>
                        Level Year
                      </TableCell>
                      <TableCell align="center">Group Name</TableCell>
                      <TableCell align="center" sx={{ width: "150px" }}>
                        Pickup Gate
                      </TableCell>
                      <TableCell align="center" sx={{ width: "180px" }}>
                        Pickup Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groups.result.map((group, key) => (
                      <TableRow key={key} hover>
                        <TableCell align="center">{key + 1}</TableCell>
                        <TableCell align="center">{group.level}</TableCell>
                        <TableCell align="center">{group.level_year}</TableCell>
                        <TableCell align="center">{group.name}</TableCell>
                        <TableCell align="center" className="inputTd">
                          <TextField
                            size="small"
                            variant="outlined"
                            value={group.pickup_gate}
                            fullWidth
                            inputProps={{ style: { textAlign: "center" } }}
                            onChange={(e) =>
                              changeValue("pickup_gate", e.target.value, key)
                            }
                          />
                        </TableCell>
                        <TableCell align="center" className="inputTd">
                          <TextField
                            size="small"
                            variant="outlined"
                            value={group.start_pickup}
                            type="time"
                            fullWidth
                            inputProps={{ style: { textAlign: "center" } }}
                            onChange={(e) =>
                              changeValue("start_pickup", e.target.value, key)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<Icon>save</Icon>}
                  sx={{ width: "150px", marginTop: "20px" }}
                  onClick={save}
                  disabled={disabled}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
