import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {
  checkPermission,
  getLevels,
  getGroupsStudents,
  fetchData,
} from "../js/main";
import { Link } from "react-router-dom";

import axios from "axios";
import Conf from "../Conf";
import { getStampTime } from "../js/main";
import { FormControlLabel, FormGroup } from "@mui/material";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Group(props) {
  const [profile, setProfile] = useState();
  const [groups, setGroups] = useState([]);
  const [levels, setLevels] = useState([]);
  const fetchGroups = async () => {
    let groups = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      { collection: "groups", limit: 0, sort: "level name level_abbrev" },
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    return groups.data.result;
  };
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
        }
      }
    })();
  }, [props]);
  useEffect(() => {
    (async () => {
      if (profile) {
        let groups = await fetchGroups();
        let { levels } = await getLevels(groups);

        if (profile.reg_staff === false) {
          groups = groups.filter((j) => j.advisors.indexOf(profile.usn) > -1);
        }
        setGroups(groups);
        setLevels(levels);
      }
    })();
  }, [profile]);
  function filterGroup(level) {
    let r = groups.filter((j) => j.level === level);
    return r;
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          {levels.map((level, key) => (
            <Grid container key={key}>
              <Grid item style={{ width: "35px", paddingTop: "22px" }}>
                <Icon>account_balance</Icon>
              </Grid>
              <Grid item>
                <h3>{level.name}</h3>
              </Grid>
              <Grid item xs={12}>
                <GroupList
                  groups={filterGroup(level.name)}
                  reg_staff={profile.reg_staff}
                />
              </Grid>
            </Grid>
          ))}
          <iframe
            title="printFrame"
            id="printFrame"
            name="printFrame"
            style={{ width: "0px", height: "0px", border: "none" }}
          ></iframe>
        </div>
      )}
    </>
  );
}

function GroupList({ groups, reg_staff }) {
  const [dialogState, setDialogState] = useState({ open: false });
  const [dialogSelectFieldsState, setDialogSelectFieldsState] = useState({
    open: false,
  });
  const styles = {
    button: {
      padding: "5px 5px",
      fontSize: ".8rem",
    },
  };

  function openConfig(group) {
    setDialogState({
      open: true,
      title: "Group Setting " + group.name,
      dialog: "setting",
      group,
    });
  }

  async function exportExcel(group, fields) {
    let group_id = group._id;
    let students = await getGroupsStudents(group_id);
    let sheet = [];
    let head = [];
    let count = 0;
    let selectCurators = await fields.filter(
      (j) => j.collection === "curators"
    );
    let curators = [];
    if (selectCurators.length > 0) {
      let ids = students.result.map((student) => student._id);
      curators = await fetchData("post", "/query", {
        collection: "curators",
        match: { student_id: { $in: ids } },
      });
      curators = curators.result;
    }
    for (let student of students.result) {
      let obj = {};
      for (let field of fields) {
        let field_name = field.field;
        if (field.collection === "curators") {
          let arr = field_name.split("|");
          let i = curators.findIndex(
            (j) => j.curator_type === arr[1] && j.student_id === student._id
          );
          let value = "";
          if (i > -1) {
            value = curators[i][arr[0]];
          }
          obj[field_name] = value;
        } else {
          obj[field_name] = student[field_name] || "";
        }
        if (count < 1) {
          head.push({ label: field.label, value: field_name });
        }
      }
      count++;
      sheet.push(obj);
    }
    let exportState = {
      sheet_name: group.name,
      head,
      sheet,
    };
    cookie.export = exportState;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
    document.getElementById("printFrame").src =
      process.env.PUBLIC_URL + "/exportexcel";
    setDialogSelectFieldsState({ open: false });
  }
  return (
    <div>
      {groups && groups.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {groups.map((group, key) => (
                  <TableRow key={key}>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "100px" }}
                    >
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "25px",
                          background: "#ccc",
                          margin: "auto",
                          paddingTop: "7px",
                        }}
                      >
                        <Icon style={{ fontSize: "2rem", color: "#fff" }}>
                          groups
                        </Icon>
                      </div>
                    </TableCell>
                    <TableCell className="td">
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <div
                            style={{ fontSize: "1.1rem", color: "#333" }}
                            className="bold"
                          >
                            Group Name : {group.name}
                          </div>
                          <div style={{ fontSize: ".8rem", color: "#777" }}>
                            ระดับชั้น{group.level}{" "}
                            {group.level_year !== "-" && (
                              <>ปีที่ {group.level_year}</>
                            )}
                          </div>
                          <div style={{ fontSize: ".7rem", color: "#393" }}>
                            Student number : {group.student_id.length}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} align="right">
                          <IconButton
                            color="primary"
                            component={Link}
                            to={process.env.PUBLIC_URL + "/group/" + group._id}
                            style={styles.button}
                          >
                            <Icon>border_all</Icon>
                          </IconButton>
                          <IconButton
                            color="primary"
                            // onClick={() => exportExcel(group)}
                            onClick={() =>
                              setDialogSelectFieldsState({ group, open: true })
                            }
                            style={styles.button}
                          >
                            <Icon>download</Icon>
                          </IconButton>
                          <IconButton
                            color="primary"
                            component="a"
                            href={
                              process.env.PUBLIC_URL +
                              "/group/student/" +
                              group._id
                            }
                            target="printFrame"
                            style={styles.button}
                          >
                            <Icon>print</Icon>
                          </IconButton>
                          {reg_staff !== false && (
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={() => openConfig(group)}
                              style={styles.button}
                            >
                              <Icon>settings</Icon>
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomDialog
            state={dialogState}
            onClose={() => setDialogState({ ...dialogState, open: false })}
          />
          <SelectFieldDialog
            state={dialogSelectFieldsState}
            onClose={() => setDialogSelectFieldsState({ open: false })}
            onDownload={(group, fields) => exportExcel(group, fields)}
          />
        </>
      )}
      {groups.length === 0 && (
        <div
          style={{ textAlign: "center", padding: "20px 20px", color: "#999" }}
        >
          ไม่พบข้อมูล
        </div>
      )}
    </div>
  );
}

function SelectFieldDialog({ state, onClose, onDownload }) {
  const [checkboxs, setCheckboxs] = useState();
  useEffect(() => {
    setCheckboxs([
      { label: "คำนำหน้าชื่อนักเรียน", field: "prefix", checked: true, col: 0 },
      { label: "ชื่อจริงนักเรียน", field: "first_name", checked: true, col: 0 },
      { label: "ชื่อกลาง", field: "middle_name", checked: true, col: 0 },
      { label: "นามสกุล", field: "last_name", checked: true, col: 0 },

      { label: "Prefix", field: "prefix_en", col: 0 },
      { label: "First name", field: "first_name_en", col: 0 },
      { label: "Middle name", field: "middle_name_en", col: 0 },
      { label: "Last name", field: "last_name_en", col: 0 },

      { label: "ชื่อเล่น", field: "nickname", col: 0 },
      { label: "เพศ", field: "gender", col: 0 },
      { label: "สัญชาติ", field: "nationality", col: 0 },
      { label: "เชื้อชาติ", field: "origin", col: 0 },
      { label: "ศาสนา", field: "religion", col: 0 },
      { label: "วันเดือนปีเกิด", field: "birth_date", col: 0 },
      { label: "กลุ่มเลือด", field: "blood_group", col: 0 },

      { label: "วันที่เข้าเรียน", field: "attended_date", col: 0 },
      {
        label: "ชื่อโรงเรียนเดิม",
        field: "study_history.school_name",
        type: "ARRAY",
        col: 0,
      },
      {
        label: "ชั้นเรียนเดิม",
        field: "study_history.level",
        type: "ARRAY",
        col: 0,
      },

      { label: "รหัสประจำบ้าน", field: "house_id", col: 1 },
      { label: "บ้านเลขที่", field: "house_no", col: 1 },
      { label: "ซอย", field: "soi", col: 1 },
      { label: "หมู่บ้าน", field: "village", col: 1 },
      { label: "ถนน", field: "street", col: 1 },
      { label: "ตำบล", field: "sub_district", col: 1 },
      { label: "อำเภอ", field: "district", col: 1 },
      { label: "จังหวัด", field: "province", col: 1 },
      { label: "รหัสไปรษณีย์", field: "zip_code", col: 1 },
      { label: "บ้านเลขที่ (ปัจจุบัน)", field: "current_house_no", col: 1 },
      { label: "ซอย (ปัจจุบัน)", field: "current_soi", col: 1 },
      { label: "หมู่บ้าน (ปัจจุบัน)", field: "current_village", col: 1 },
      { label: "ถนน (ปัจจุบัน)", field: "current_street", col: 1 },
      { label: "ตำบล (ปัจจุบัน)", field: "current_sub_district", col: 1 },
      { label: "อำเภอ (ปัจจุบัน)", field: "current_district", col: 1 },
      { label: "จังหวัด (ปัจจุบัน)", field: "current_province", col: 1 },
      { label: "รหัสไปรษณีย์ (ปัจจุบัน)", field: "current_zip_code", col: 1 },

      {
        label: "อาชีพของบิดา",
        field: "job|father",
        col: 2,
        collection: "curators",
      },
      {
        label: "อาชีพของมารดา",
        field: "job|mother",
        col: 2,
        collection: "curators",
      },
      {
        label: "อาชีพของผู้ปกครอง",
        field: "job|curator",
        col: 2,
        collection: "curators",
      },
      { label: "สถานะภาพครอบครัว", field: "family_status", col: 2 },
      {
        label: "จำนวนพี่น้องในโรงเรียน",
        field: "tonkla_study_brother_number",
        col: 2,
      },
      {
        label: "ช่วงรายได้ของบิดา",
        field: "salary|father",
        col: 2,
        collection: "curators",
      },
      {
        label: "ช่วงรายได้ของมารดา",
        field: "salary|mother",
        col: 2,
        collection: "curators",
      },
      {
        label: "ช่วงรายได้ของผู้ปกครอง",
        field: "salary|curator",
        col: 2,
        collection: "curators",
      },

      {
        label: "คำนำหน้าชื่อบิดา",
        field: "prefix|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อบิดา",
        field: "first_name|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อกลางบิดา",
        field: "middle_name|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "นามสกุลบิดา",
        field: "last_name|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "เบอร์โทรบิดา",
        field: "phone_number|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "คำนำหน้าชื่อบิดา (Eng)",
        field: "prefix_en|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อบิดา (Eng)",
        field: "first_name_en|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อกลางบิดา (Eng)",
        field: "middle_name_en|father",
        col: 3,
        collection: "curators",
      },
      {
        label: "นามสกุลบิดา (Eng)",
        field: "last_name_en|father",
        col: 3,
        collection: "curators",
      },

      {
        label: "คำนำหน้าชื่อมารดา",
        field: "prefix|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อมารดา",
        field: "first_name|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อกลางมารดา",
        field: "middle_name|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "นามสกุลมารดา",
        field: "last_name|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "เบอร์โทรมารดา",
        field: "phone_number|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "คำนำหน้าชื่อมารดา (Eng)",
        field: "prefix_en|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อมารดา (Eng)",
        field: "first_name_en|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "ชื่อกลางมารดา (Eng)",
        field: "middle_name_en|mother",
        col: 3,
        collection: "curators",
      },
      {
        label: "นามสกุลมารดา (Eng)",
        field: "last_name_en|mother",
        col: 3,
        collection: "curators",
      },
    ]);
  }, []);
  function onCheck(value, field) {
    let CHK = JSON.parse(JSON.stringify(checkboxs));
    let key = CHK.findIndex((j) => j.field === field);
    CHK[key].checked = value;
    setCheckboxs(CHK);
  }

  function onSelect() {
    let sel = checkboxs.filter((j) => j.checked === true);
    if (sel.length > 0) onDownload(state.group, sel);
  }
  return (
    <div>
      <Dialog open={state.open} maxWidth="xl">
        <DialogTitle>Select Field</DialogTitle>
        <DialogContent sx={{ width: "900px" }}>
          <Grid container>
            {checkboxs &&
              [0, 1, 2, 3].map((col) => (
                <Grid item xs={3} key={col}>
                  <FormGroup>
                    {checkboxs
                      .filter((j) => j.col === col)
                      .map((chk, key) => (
                        <FormControlLabel
                          key={key}
                          checked={chk.checked || false}
                          onChange={(val, val2) => onCheck(val2, chk.field)}
                          control={<Checkbox sx={{ padding: "5px 5px" }} />}
                          label={
                            <div style={{ fontSize: ".9rem" }}>{chk.label}</div>
                          }
                        />
                      ))}
                  </FormGroup>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSelect}>Select</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CustomDialog({ state, onClose }) {
  return (
    <div>
      <Dialog open={state.open}>
        <DialogTitle>{state.title}</DialogTitle>
        <DialogContent>
          {(() => {
            switch (state.dialog) {
              case "setting":
                return <DialogSetting group={state.group} />;
              default:
                <></>;
            }
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function DialogSetting({ group }) {
  const [years, setYears] = useState();
  const [yearForm, setYearForm] = useState({});
  const [users, setUsers] = useState();
  const [currentGroup, setCurrentGroup] = useState();

  const fetchUsers = async () => {
    let users = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      {
        collection: "users",
        match: { "permissions.appName": "reg" },
        sort: "usn",
      },
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    setUsers(users.data.result);
  };

  useEffect(() => {
    (async () => {
      let dt = await getStampTime();
      let years = [];
      for (let i = dt.detail.year + 1; i > dt.detail.year - 3; i--) {
        years.push({ label: i + 543, value: i });
      }
      fetchUsers();
      setYears(years);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      let dt = await getStampTime();
      let CurrentGroup = await axios.get(
        Conf.endpoint.tonkla + "/reg/query/groups/" + group._id,
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      CurrentGroup = CurrentGroup.data;
      let i = CurrentGroup.limit.findIndex(
        (j) => j.school_year_en === dt.detail.year
      );
      let quota = 0;
      if (i > -1) quota = CurrentGroup.limit[i].quota;
      if (!CurrentGroup.advisors) CurrentGroup.advisors = [];
      setCurrentGroup(CurrentGroup || {});
      setYearForm({
        quota,
        school_year_en: dt.detail.year,
      });
    })();
  }, [group]);
  const selectUser = async (event) => {
    const {
      target: { value },
    } = event;
    let ad = typeof value === "string" ? value.split(",") : value;
    setCurrentGroup({ ...currentGroup, advisors: ad });
    await axios.put(
      Conf.endpoint.tonkla + "/reg/group/advisor",
      { advisors: ad, _id: group._id },
      {
        headers: { Authorization: cookie.access_token },
      }
    );
  };
  async function saveLimit() {
    let send = {
      quota: yearForm.quota,
      school_year_th: yearForm.school_year_en + 543,
      school_year_en: yearForm.school_year_en,
      _id: currentGroup._id,
    };
    let result = await axios.put(
      Conf.endpoint.tonkla + "/reg/group/limit",
      send,
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    if (result.data.error) alert(result.data.error.message);
    else {
      setCurrentGroup(result.data);
      alert("Save completed");
    }
  }
  async function changeYear(e) {
    let y = parseInt(e.target.value);
    let i = currentGroup.limit.findIndex((j) => j.school_year_en === y);
    let quota = 0;
    if (i > -1) quota = currentGroup.limit[i].quota;
    setYearForm({
      ...yearForm,
      school_year_en: y,
      quota,
    });
  }
  return (
    <div>
      {years && yearForm.school_year_en && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ minWidth: "200px" }}
                  className="td"
                >
                  ปีการศึกษา
                </TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: "200px" }}
                  className="td"
                >
                  จำนวนที่รับ
                </TableCell>
                <TableCell style={{ minWidth: "50px" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="inputTd">
                  <FormControl fullWidth size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      value={yearForm.school_year_en}
                      onChange={changeYear}
                    >
                      {years.map((y, key) => (
                        <MenuItem value={y.value} key={key}>
                          {y.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className="inputTd">
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={yearForm.quota || 0}
                    onChange={(e) =>
                      setYearForm({ ...yearForm, quota: e.target.value })
                    }
                    inputProps={{
                      style: { textAlign: "center" },
                    }}
                  />
                </TableCell>

                <TableCell className="inputTd" align="center">
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={saveLimit}
                  >
                    <Icon>save</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {currentGroup && users && (
        <>
          <div style={{ margin: "20px auto 10px" }} className="bold">
            กำหนดครูประจำชั้น
          </div>

          <FormControl fullWidth size="small" style={{ margin: "0px" }}>
            <Select
              multiple
              value={currentGroup.advisors}
              onChange={selectUser}
              input={<OutlinedInput />}
              renderValue={(selected) => selected.join(", ")}
            >
              {users.map((user) => (
                <MenuItem key={user.usn} value={user.usn}>
                  <Checkbox
                    checked={
                      currentGroup.advisors.indexOf(user.usn) > -1 || false
                    }
                  />
                  <ListItemText primary={user.usn} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
}
