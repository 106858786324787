import React, { useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import PageNameBox from "../components/PageNameBox";
import Pagebar from "../components/Pagebar";

import ProfileBox from "../components/ProfileBox";
import {
  checkPermission,
  fetchData,
  getFullName,
  showUploadImageSrc,
} from "../js/main";
import moment from "moment";
import Conf from "../Conf";
import { initializeApp, getApps } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { Stack, TextField } from "@mui/material";

!getApps().length ? initializeApp(Conf.firebaseConfig) : getApps();

async function getAssignPickup() {
  return new Promise(async (resolve) => {
    let pickup_date = moment().format("YYYY-MM-DD");
    let match = { pickup_date };
    let result = await fetchData("post", "/query", {
      collection: "pickup_assigns",
      match,
      populate: {
        path: "student_id",
        select:
          "prefix first_name last_name middle_name prefix_en first_name_en middle_name_en last_name_en nickname current_group program",
        populate: {
          path: "current_group",
          select: "-_id name pickup_gate start_pickup",
        },
      },
    });
    resolve(result);
  });
}

async function getAllGroupsAndLevel() {
  return new Promise(async (resolve) => {
    let r = { groups: [], levels: [] };
    let groups = await fetchData("post", "/query", {
      collection: "groups",
      sort: { name: 1 },
    });
    for (let group of groups.result) {
      let i = r.groups.findIndex((j) => j.value === group.name);
      if (i < 0) r.groups.push({ label: group.name, value: group.name });
      i = r.levels.findIndex((j) => j.value === group.level);
      if (i < 0) r.levels.push({ label: group.level, value: group.level });
    }
    resolve(r);
  });
}

function getPickups(params) {
  return new Promise(async (resolve) => {
    let match = {
      $and: [{ request_date: moment().format("YYYY-MM-DD") }],
    };
    let limit = 1000;
    let keyword = null;
    if (params) {
      limit = params.limit || 1000;
      match = params.match || null;
      keyword = params.keyword || null;
    }
    let pickups = await fetchData("post", "/query", {
      collection: "pickups",
      match,
      keyword,
      populate: "pickup_person_id",
      populate2: {
        path: "student_id",
        select:
          "prefix first_name middle_name last_name prefix_en first_name_en middle_name_en last_name_en nickname program pickup_gate current_group",
        populate: {
          path: "current_group",
          select: "level",
        },
      },
      sort: { request_stamp_time: -1 },
      limit: limit || 1000,
    });
    // if (filter.level !== "ALL") {
    //   pickups.result = await pickups.result.filter(
    //     (j) => j.student_id.current_group.level === filter.level
    //   );
    // }
    for (let i = 0; i < pickups.result.length; i++) {
      let student = pickups.result[i].student_id;
      student = {
        prefix: student.prefix_en,
        first_name: student.first_name_en,
        middle_name: student.middle_name_en,
        last_name: student.last_name_en,
      };
      pickups.result[i].student_fullname = getFullName(
        pickups.result[i].student_id
      );
      pickups.result[i].student_fullname_en = getFullName(student);
      pickups.result[i].pickup_fullname = getFullName(
        pickups.result[i].pickup_person_id
      );
    }
    resolve(pickups);
  });
}

export default function Pickup(props) {
  const [profile, setProfile] = useState();
  const [tabNo, setTabNo] = useState(0);
  const [pickups, setPickups] = useState();
  const [assigns, setAssigns] = useState();
  const [refresh, setRefresh] = useState();
  const [showAll, setShowAll] = useState(true);
  const [filter, setFilter] = useState({ level: "ALL", group: "ALL" });
  const [groups, setGroups] = useState([]);
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
          let ref = new Date().toString();
          let assigns = await getAssignPickup();
          let { groups, levels } = await getAllGroupsAndLevel();
          levels.unshift({ label: "ALL LEVELS", value: "ALL" });
          groups.unshift({ label: "ALL GROUPS", value: "ALL" });
          setLevels(levels);
          setGroups(groups);
          setAssigns(assigns);
          setRefresh(ref);
        }
      }
    })();
  }, [props]);

  useEffect(() => {
    async function loadData(filter) {
      let pickups = await getPickups(filter);
      setPickups(pickups);
    }
    (async () => {
      if (refresh) {
        const database = getDatabase();
        const pickupRef = ref(database, "/refresh_app/pickup");
        onValue(pickupRef, (snapshot) => {
          // const data = snapshot.val();
          loadData();
          // setRefresh(data);
        });
      }
    })();
  }, [refresh]);

  async function changeFilter(field, value) {
    setFilter({ ...filter, [field]: value });
  }

  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>

          <Grid container>
            <Grid item xs={12} md={6}>
              <PageNameBox page={props.page} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ paddingTop: "10px" }}
              align="right"
            >
              <Stack direction="row" style={{ width: "370px" }}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Level Filter
                  </InputLabel>
                  <Select
                    native
                    labelId="demo-simple-select-label"
                    value={filter.level}
                    label="Level Filter"
                    onChange={(e) => {
                      changeFilter("level", e.target.value);
                    }}
                    size="small"
                    sx={{ width: "180px" }}
                  >
                    {levels.map((item, key) => (
                      <option value={item.value} key={key}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Group Filter
                  </InputLabel>
                  <Select
                    native
                    labelId="demo-simple-select-label"
                    value={filter.group}
                    label="Group Filter"
                    onChange={(e) => {
                      changeFilter("group", e.target.value);
                    }}
                    size="small"
                    sx={{ width: "180px", marginLeft: "5px" }}
                  >
                    {groups.map((item, key) => (
                      <option value={item.value} key={key}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <div>
                <FormGroup>
                  <FormControlLabel
                    style={{
                      textAlign: "right",
                      margin: "0px 0px auto auto",
                      width: "110px",
                    }}
                    control={
                      <Checkbox
                        checked={showAll}
                        onChange={(e) => setShowAll(e.target.checked)}
                      />
                    }
                    label={<div>Show All</div>}
                  />
                </FormGroup>
              </div>
            </Grid>
          </Grid>
          {assigns && (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabNo} onChange={(e, val) => setTabNo(val)}>
                <Tab label="Pickup List" />
                <Tab
                  label={
                    <Badge
                      color="error"
                      badgeContent={assigns.count || 0}
                      style={{ paddingRight: "10px" }}
                      invisible={false}
                    >
                      Assign List
                    </Badge>
                  }
                />
                <Tab label="Parents Accepting Students" />
              </Tabs>
            </Box>
          )}

          {pickups && (
            <>
              {
                {
                  0: (
                    <PickupList
                      pickups={pickups}
                      showAll={showAll}
                      filter={filter}
                    />
                  ),
                  1: <>{assigns && <AssignPickup assigns={assigns} />}</>,
                  2: <PickupHistory />,
                }[tabNo]
              }
            </>
          )}
        </div>
      )}
    </>
  );
}
function PickupHistory() {
  const [loading, setLoading] = useState(false);
  const [pickups, setPickups] = useState();
  const [pageState, setPageState] = useState({ pageNo: 1, pageCount: 1 });
  const [filter, setFilter] = useState({
    limit: 50,
    skip: 0,
    match: {
      request_date: moment().format("YYYY-MM-DD"),
    },
    showAll: false,
  });
  useEffect(() => {
    (async () => {
      if (filter) {
        setLoading(true);
        let Filter = JSON.parse(JSON.stringify(filter));
        if (Filter.showAll === true) delete Filter.match.request_date;
        let pickups = await fetchData("post", "/pickup/history", Filter);
        let pageCount = Math.ceil(pickups.count / filter.limit);
        let pageNo = filter.skip / filter.limit + 1;
        if (pageNo > pageCount) pageNo = 1;
        setPageState({ pageNo, pageCount });
        setPickups(pickups);
        setLoading(false);
      }
    })();
  }, [filter]);

  function changeKeyword(e) {
    let keyword = e.target.value;
    setFilter({
      ...filter,
      keyword: {
        value: keyword,
        fields: [
          "student.student_code",
          "student.nickname",
          "student.first_name",
          "student.last_name",
        ],
      },
      skip: 0,
    });
  }
  function changeDate(dt) {
    setFilter({
      ...filter,
      match: {
        request_date: dt,
      },
      showAll: false,
      skip: 0,
    });
  }

  return (
    <div>
      {pickups && (
        <>
          <Grid container spacing={1} style={{ marginTop: "20px" }}>
            <Grid item xs={12} md={6}>
              <TextField
                placeholder="ค้นหา..."
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ maxWidth: "250px" }}
                InputProps={{
                  endAdornment: <Icon>search</Icon>,
                }}
                onKeyUp={(e) => e.key === "Enter" && changeKeyword(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} align="right">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD MMM YYYY"
                  label="Pickup Date"
                  // value={dayjs(filter.match.pickup_date)}
                  value={dayjs(filter.match.request_date)}
                  onChange={(val) => {
                    changeDate(moment(new Date(val)).format("YYYY-MM-DD"));
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      InputLabelProps: { shrink: true },
                      fullWidth: true,
                      sx: { maxWidth: "250px" },
                    },
                  }}
                />
              </LocalizationProvider>
              <div>
                <FormControlLabel
                  onChange={(val, val2) =>
                    setFilter({ ...filter, showAll: val2, skip: 0 })
                  }
                  control={<Checkbox checked={filter.showAll} />}
                  label="Show All Date"
                />
              </div>
            </Grid>
          </Grid>
          <div className="bold" style={{ paddingBottom: "10px" }}>
            Result : {pickups.count} persons
          </div>
          <div className="bold" style={{ paddingBottom: "15px" }}>
            <Pagebar
              size="small"
              page={pageState.pageNo}
              count={pageState.pageCount}
              changePage={(val) =>
                setFilter({ ...filter, skip: (val - 1) * filter.limit })
              }
            />
          </div>
          {loading === true && <LinearProgress />}
          <PickupHistoryTable pickups={pickups.result} no={filter.skip + 1} />
        </>
      )}
    </div>
  );
}

function PickupHistoryTable({ pickups, no }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ maxWidth: "100px" }}>
                No.
              </TableCell>
              <TableCell align="center" style={{ maxWidth: "150px" }}>
                Student Code
              </TableCell>
              <TableCell align="center">Student Name</TableCell>
              <TableCell align="center" style={{ maxWidth: "200px" }}>
                Student Nickname
              </TableCell>
              <TableCell align="center">Pickup Name</TableCell>
              <TableCell align="center" style={{ maxWidth: "200px" }}>
                Request Time
              </TableCell>
              <TableCell align="center" style={{ maxWidth: "200px" }}>
                Confirm Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pickups.map((pickup, key) => (
              <TableRow key={pickup._id} hover>
                <TableCell align="center">{no + key}</TableCell>
                <TableCell align="center">{pickup.student_code}</TableCell>
                <TableCell>{pickup.student_fullname}</TableCell>
                <TableCell align="center">{pickup.student_nickname}</TableCell>
                <TableCell>{pickup.curator_fullname}</TableCell>
                <TableCell align="center">
                  {moment(pickup.request_stamp_time).format(
                    "DD MMM YYYY, H:mm"
                  )}
                </TableCell>
                <TableCell align="center">
                  {pickup.pickup_stamp_time &&
                    moment(pickup.pickup_stamp_time).format(
                      "DD MMM YYYY, H:mm"
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function PickupList(props) {
  const [pickups, setPickups] = useState();
  useEffect(() => {
    if (props.pickups) {
      let { level, group } = props.filter;
      let pickups = JSON.parse(JSON.stringify(props.pickups));
      if (level !== "ALL") {
        pickups.result = pickups.result.filter(
          (j) => j.student_id.current_group.level === level
        );
        pickups.count = pickups.result.length;
      }

      if (group !== "ALL") {
        pickups.result = pickups.result.filter((j) => j.group_name === group);
        pickups.count = pickups.result.length;
      }
      if (props.showAll !== true) {
        pickups.result = pickups.result.filter((j) => j.status !== "confirm");
        pickups.count = pickups.result.length;
      }
      setPickups(pickups);
    }
  }, [props]);

  // async function clear() {
  //   if (window.confirm("Confirm")) await fetchData("get", "/pickup/clear");
  // }
  return (
    <div style={{ paddingTop: "10px" }}>
      {pickups && (
        <List component={Paper}>
          {pickups.count === 0 && (
            <div
              style={{
                textAlign: "center",
                padding: "20px 20px",
                color: "#999",
              }}
            >
              Not Found Information
            </div>
          )}
          {pickups.result.map((person, key) => (
            <PersonItem
              key={key}
              len={pickups.result.length}
              person={person}
              no={key}
            />
          ))}
        </List>
      )}
    </div>
  );
}

function PersonItem({ person, len, no }) {
  const [thumbnail, setThumbnail] = useState();
  useEffect(() => {
    (async () => {
      let thumbnail = null;
      if (person.pickup_person_id.photo) {
        thumbnail = await showUploadImageSrc(person.pickup_person_id.photo);
      } else {
        thumbnail = person.pickup_person_id.line_profile.pictureUrl;
      }
      setThumbnail(thumbnail);
    })();
  }, [person]);
  return (
    <ListItem
      sx={
        no < len - 1
          ? {
              borderBottom: "1px solid #ddd",
            }
          : {}
      }
    >
      <ListItemAvatar>
        {thumbnail && (
          <Avatar sx={{ width: "60px", height: "60px" }}>
            <img src={thumbnail} style={{ width: "60px" }} alt="" />
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText sx={{ paddingLeft: "20px" }}>
        <div className="bold">
          {person.student_fullname} ({person.student_id.nickname})
        </div>
        <div style={{ fontSize: ".8rem", color: "#999" }}>
          Group : {person.group_name} {person.student_id.program} (Gate{" "}
          {person.pickup_gate})
        </div>

        <div style={{ fontSize: ".8rem", color: "#999" }}>
          {person.student_fullname_en}
        </div>
        <div style={{ fontSize: ".8rem", color: "#090" }}>
          Pickup name : {person.pickup_fullname}{" "}
          <span
            className="bold"
            style={
              person.status === "request"
                ? { color: "#f00" }
                : { color: "#090" }
            }
          >
            ({person.status.toUpperCase()})
          </span>
        </div>
      </ListItemText>
    </ListItem>
  );
}

function AssignPickup({ assigns }) {
  return (
    <div style={{ paddingTop: "10px" }}>
      <List component={Paper}>
        {assigns.count === 0 && (
          <div
            style={{
              textAlign: "center",
              padding: "20px 20px",
              color: "#999",
            }}
          >
            Not Found Information
          </div>
        )}
        {assigns.result.map((person, key) => (
          <ListItem
            key={key}
            sx={
              key < assigns.result.length - 1
                ? {
                    borderBottom: "1px solid #ddd",
                  }
                : {}
            }
          >
            <ListItemAvatar>
              <Avatar sx={{ width: "60px", height: "60px" }}>
                <Icon style={{ fontSize: "2rem" }}>person</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText sx={{ paddingLeft: "20px" }}>
              <div className="bold">
                {getFullName(person.student_id)} ({person.student_id.nickname})
              </div>
              <div style={{ fontSize: ".8rem", color: "#999" }}>
                Group : {person.student_id.current_group.name}{" "}
                {person.student_id.program} (Gate{" "}
                {person.student_id.current_group.pickup_gate})
              </div>
              <div style={{ fontSize: ".8rem", color: "#999" }}>
                Time : {person.assign_time || "-"}
              </div>

              <div style={{ fontSize: ".8rem", color: "#090" }}>
                Pickup name : {person.fullname}{" "}
                {person.pickup_stamp_time && (
                  <span>({person.pickup_stamp_time})</span>
                )}
              </div>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
