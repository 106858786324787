import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { Button, Container, Icon, Stack } from "@mui/material";
import liff from "@line/liff";

import { fetchData } from "../js/main";
import Conf from "../Conf";

export default function LineSync(props) {
  const [profile, setProfile] = useState();
  const [channels, setChannels] = useState([]);
  useEffect(() => {
    (async () => {
      if (props.profile) {
        let channels = await fetchData("post", "/query", {
          collection: "line_channels",
        });
        let profiles = await fetchData("post", "/query", {
          collection: "line_profiles",
          match: { usn: props.profile.usn },
        });
        for (let sync of profiles.result) {
          let i = channels.result.findIndex((j) => j._id === sync.channel);
          if (i > -1) channels.result[i].sync = sync;
        }

        let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
        if (cookie["register_liffId"]) {
          await liff.init({
            liffId: cookie["register_liffId"],
          });
          const register_liffId = cookie["register_liffId"];
          liff.ready.then(async () => {
            if (liff.isLoggedIn()) {
              let Channels = JSON.parse(JSON.stringify(channels.result));
              let i = Channels.findIndex((j) => j.liffId === register_liffId);
              if (i > -1) {
                let lineProfile = await liff.getProfile();
                let channel = Channels[i];
                if (channel.sync) lineProfile._id = channel.sync._id;
                lineProfile.usn = props.profile.usn;
                lineProfile.channel = channel._id;
                let result = await fetchData("put", "/line", lineProfile);
                Channels[i].sync = result;
                console.log(Channels[i]);
                console.log(result);
                setChannels(Channels);
              }
            }
          });
          delete cookie["register_liffId"];
          localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
        }
        setChannels(channels.result);
        setProfile(props.profile);
      }
    })();
  }, [props.profile]);

  async function login(channel) {
    await liff.init({
      liffId: channel.liffId,
    });
    liff.ready.then(async () => {
      if (liff.isLoggedIn()) {
        let lineProfile = await liff.getProfile();
        lineProfile.usn = profile.usn;
        lineProfile.channel = channel._id;
        if (channel.sync) lineProfile._id = channel.sync._id;
        let result = await fetchData("put", "/line", lineProfile);
        let i = channels.findIndex((j) => j._id === channel._id);
        if (i > -1) {
          let Channels = JSON.parse(JSON.stringify(channels));
          Channels[i].sync = result;
          setChannels(Channels);
        }
      } else {
        let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
        cookie["register_liffId"] = channel.liffId;
        localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
        liff.login();
      }
    });
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <Container maxWidth="xs" sx={{ paddingTop: "10vh" }}>
            {channels.map((ch, key) => (
              <Stack direction="row" spacing={1} key={key}>
                <div style={{ width: "50px" }}>
                  {ch.sync ? (
                    <img
                      src={ch.sync.pictureUrl}
                      alt=""
                      style={{
                        width: "45px",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <Icon sx={{ fontSize: "2.9rem", color: "#ccc" }}>
                      block
                    </Icon>
                  )}
                </div>
                <div>
                  <Button
                    size="large"
                    variant="outlined"
                    fullWidth
                    onClick={() => login(ch)}
                  >
                    Sync with {ch.name}
                  </Button>
                  {/* <Button
                    size="large"
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      liff.init({ liffId: ch.liffId });
                      if (liff.isLoggedIn()) {
                        liff.logout();
                      }
                    }}
                  >
                    signout {ch.liffId}
                  </Button> */}
                </div>
              </Stack>
            ))}
          </Container>
        </div>
      )}
    </>
  );
}
