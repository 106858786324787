import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Switch from "@mui/material/Switch";
import { fetchData, sortJSON, getFullName } from "../js/main";
import { useForm } from "react-hook-form";

import axios from "axios";
import Conf from "../Conf";
import { Paper } from "@mui/material";
import { useParams } from "react-router-dom";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

// const prefixs = getPrefixs("prefix_th");
export default function FormPickupPerson({ student_id, register_id, options }) {
  const [persons, setPersons] = useState();
  const [dialogState, setDialogState] = useState({ open: false });
  useEffect(() => {
    (async () => {
      let match = { student_id: student_id };
      if (!student_id && register_id) match = { register_id: register_id };
      let Persons = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        { collection: "pickup_persons", match, sort: "priority" },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      if (Persons.data && Persons.data.count > 0) {
        setPersons(Persons.data.result);
      } else {
        setPersons([
          { student_id },
          { student_id },
          { student_id },
          { student_id },
        ]);
      }
    })();
  }, [student_id, register_id]);

  function newPerson() {
    let Persons = JSON.parse(JSON.stringify(persons));
    let priority = 0;

    let otp = Math.floor(Math.random() * 999999);
    otp = ("000000" + otp).slice(-6);
    let max = Math.max(...Persons.map((o) => o.priority));
    if (Persons.length > 0 && max)
      priority = Math.max(...Persons.map((o) => o.priority));
    priority++;
    Persons.push({ priority, otp });
    setPersons(Persons);
  }

  async function changePriority(key, addPriority) {
    let targetKey = key + addPriority;
    if (persons[targetKey]) {
      let Persons = JSON.parse(JSON.stringify(persons));
      let priority = Persons[key].priority;
      Persons[key].priority = Persons[targetKey].priority;
      Persons[targetKey].priority = priority;
      let result = await fetchData("put", "/pickup/person/priority", {
        _id: Persons[key]._id,
        target_id: Persons[targetKey]._id,
      });
      if (result) {
        Persons = await sortJSON(Persons, "priority");
        setPersons(Persons);
      }
    }
  }
  function updatePerson(value, person_id) {
    let Persons = JSON.parse(JSON.stringify(persons));
    let i = Persons.findIndex((j) => j._id === person_id);
    if (i > -1) {
      Persons[i] = { ...Persons[i], ...value };
      setPersons(Persons);
    }
  }
  async function sortPerson() {
    let Persons = JSON.parse(JSON.stringify(persons));
    for (var i = 0; i < Persons.length; i++) {
      var priority = i + 1;
      var send = { _id: Persons[i]._id, priority };
      await fetchData("put", "/pickup/person", send);
      Persons[i].priority = priority;
    }
    setPersons(Persons);
  }
  return (
    <div>
      {persons && (
        <Paper>
          <List sx={{ width: "100%" }}>
            {persons.map((person, key) => (
              <PersonItem
                key={key}
                person={person}
                border={key < persons.length - 1 ? true : false}
                no={key}
                changePriority={(addPriority) =>
                  changePriority(key, addPriority)
                }
                onShowOTP={() => {
                  setDialogState({ ...person, open: true });
                }}
              />
            ))}
          </List>
          <div style={{ padding: "20px 20px", textAlign: "right" }}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<Icon>add</Icon>}
              onClick={newPerson}
            >
              Add Pickup Person
            </Button>
          </div>
          {dialogState && dialogState._id && (
            <OTPDialog
              state={dialogState}
              onClose={() => setDialogState({ open: false })}
              onUpdatePerson={updatePerson}
            />
          )}
        </Paper>
      )}
      <div style={{ textAlign: "right", paddingTop: "20px" }}>
        <Button variant="contained" onClick={sortPerson}>
          Sort Person
        </Button>
      </div>
    </div>
  );
}

function PersonItem({ person, border, no, changePriority, onShowOTP }) {
  const { register, handleSubmit } = useForm({ values: person });
  const [img, setImg] = useState();
  const [disabled, setDisabled] = useState();
  const [fileUpload, setFileUpload] = useState();
  const params = useParams();
  useEffect(() => {
    (async () => {
      if (person.file) {
        setImg(person.file);
      } else if (person.photo) {
        let img = Conf.endpoint.upload + "/" + person.photo;
        if (person.photo.indexOf("file.php?") > -1) {
          img = await axios.get(Conf.endpoint.fileUpload + "/" + person.photo);
          img = img.data;
        } else img = null;
        setImg(img);
      }
      setDisabled(person.disabled);
    })();
  }, [person]);

  async function upload(person) {
    return new Promise(async (resolve) => {
      if (fileUpload) {
        let path = await axios.put(Conf.endpoint.fileUpload, {
          file_stream: fileUpload,
          file_type: "pickup",
        });
        person.photo = "file.php?id=" + path.data._id;
      }
      resolve(person);
    });
  }

  async function save(data) {
    data.disabled = disabled;
    data.student_id = params.id;
    let person = await upload(data);
    let result = await fetchData("put", "/pickup/person", person);
    if (result.error) alert(result.error.message);
    else alert("Save completed");
  }

  function changeImage(e) {
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        let size = calc_image_size(reader.result);
        if (size < 500) {
          setFileUpload(reader.result);
          setImg(reader.result);
        } else {
          alert("Image file must smaller than 500KB");
        }
      };
    }
  }
  function calc_image_size(image) {
    let y = 1;
    if (image.endsWith("==")) {
      y = 2;
    }
    const x_size = image.length * (3 / 4) - y;

    return Math.round(x_size / 1024);
  }
  return (
    <ListItem
      alignItems="flex-start"
      style={border === true ? { borderBottom: "1px solid #eee" } : {}}
    >
      <ListItemAvatar
        style={{ width: "160px", paddingRight: "15px", textAlign: "center" }}
      >
        {img ? (
          <img alt="" src={img} style={{ width: "130px" }} />
        ) : (
          <Icon style={{ fontSize: "8rem", color: "#999" }}>person</Icon>
        )}
        <div style={{ textAlign: "center" }}>
          <label htmlFor={"person-img-" + person.priority}>
            <input
              accept="image/png,image/jpeg,image/PNG,image/JPEG"
              id={"person-img-" + person.priority}
              type="file"
              onChange={changeImage}
              style={{ display: "none" }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Icon>photo_camera</Icon>
            </IconButton>
          </label>
          <IconButton color="primary" component="span" onClick={onShowOTP}>
            <Icon>pin</Icon>
          </IconButton>
        </div>
      </ListItemAvatar>
      <ListItemText>
        <form onSubmit={handleSubmit(save)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{ fontSize: ".8rem" }} className="bold">
                {`บุคคลที่ ${person.priority}`}
              </div>
            </Grid>
            <Grid item xs={6} align="right" style={{ paddingTop: "5px" }}>
              <FormControlLabel
                control={<Switch checked={!disabled} />}
                onChange={(e, val) => setDisabled(!val)}
                label={<div style={{ fontSize: ".8rem" }}>Enabled</div>}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register("prefix")}
                fullWidth
                size="small"
                label="Prefix"
                placeholder="คำนำหน้า"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                {...register("first_name")}
                fullWidth
                size="small"
                label="First name"
                placeholder="ชื่อ"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register("middle_name")}
                fullWidth
                size="small"
                label="Middle name"
                placeholder="ชื่อกลาง"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                {...register("last_name")}
                label="Last name"
                size="small"
                fullWidth
                placeholder="นามสกุล"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register("prefix_en")}
                fullWidth
                size="small"
                label="Prefix (EN)"
                placeholder="คำนำหน้าภาษาอังกฤษ"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                {...register("first_name_en")}
                fullWidth
                size="small"
                label="First name (EN)"
                placeholder="ชื่อภาษาอังกฤษ"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register("middle_name_en")}
                fullWidth
                size="small"
                label="Middle name (EN)"
                placeholder="ชื่อกลางภาษาอังกฤษ"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                {...register("last_name_en")}
                label="Last name (EN)"
                size="small"
                fullWidth
                placeholder="นามสกุลภาษาอังกฤษ"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("relation")}
                fullWidth
                size="small"
                label="Relation"
                placeholder="ความสัมพันธ์"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("phone_number")}
                label="Phone Number"
                size="small"
                fullWidth
                placeholder="หมายเลขโทรศ้พท์"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={7}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{ width: "150px" }}
                startIcon={<Icon>save</Icon>}
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={5} align="right">
              {no > 0 && (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  style={{ padding: "3px 3px" }}
                  onClick={() => changePriority(-1)}
                >
                  <Icon>keyboard_arrow_up</Icon>
                </IconButton>
              )}
              {border === true && (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  style={{ padding: "3px 3px" }}
                  onClick={() => changePriority(1)}
                >
                  <Icon>keyboard_arrow_down</Icon>
                </IconButton>
              )}
            </Grid>
          </Grid>
        </form>
      </ListItemText>
    </ListItem>
  );
}

function OTPDialog(props) {
  const [state, setState] = useState();
  useEffect(() => {
    if (props && props.state) setState(props.state);
    else setState(null);
  }, [props]);

  async function save() {
    let result = await fetchData("put", "/pickup/person/otp", {
      person_id: state._id,
      otp: state.otp,
    });
    if (result && result.otp) {
      props.onClose();
      props.onUpdatePerson({ otp: result.otp }, state._id);
      // console.log(result, state.otp);
    }
  }
  return (
    <div>
      {state && (
        <Dialog open={props.state.open}>
          <DialogTitle>Verify OTP</DialogTitle>
          <DialogContent>
            <DialogContentText
              component="div"
              style={{ paddingBottom: "15px", minWidth: "300px" }}
            >
              {getFullName(state)}{" "}
              {state.relation && "(" + state.relation + ")"}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="OTP"
              fullWidth
              variant="outlined"
              value={state.otp || ""}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setState({ ...state, otp: e.target.value });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={save}>save</Button>
            <Button onClick={() => props.onClose()}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
