import { useEffect, useState } from "react";
import { InputTable } from "./InputComponents";
import { registration_documents } from "../const";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import FormGroup from "@mui/material/FormGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const styles = {
  title: {
    fontWeight: 500,
    marginTop: "30px",
  },
};

export default function FormStudy({ student }) {
  const [form, setForm] = useState(student);
  const [openDialog, setOpenDialog] = useState(false);
  function changeField(field, value) {
    setForm({ ...form, [field]: value });
  }
  async function save() {
    let send = {
      _id: form._id,
      study_history: form.study_history,
      resign: form.resign,
      registration_documents: form.registration_documents,
    };
    let Student = await axios.put(Conf.endpoint.tonkla + "/reg/student", send, {
      headers: {
        Authorization: cookie.access_token,
      },
    });
    if (Student.data.error) alert(Student.data.error.message);
    else alert("Save Completed");
  }
  function goPrint(path) {
    path = process.env.PUBLIC_URL + "/print/" + path + "/" + student._id;
    document.getElementById("printFrame").src = path;
    setOpenDialog(false);
  }
  return (
    <div>
      {form && (
        <>
          <HistoryInfo
            form={form}
            onChange={(field, values) => changeField(field, values)}
          />
          <ResignInfo form={form} onChange={changeField} />
          <RegisterDocumentInfo
            documents={form.registration_documents}
            onChange={changeField}
          />

          <Grid container>
            <Grid item xs={12} sm={6}>
              <Button
                color="success"
                variant="contained"
                style={{ marginTop: "20px", width: "150px", color: "#fff" }}
                startIcon={<Icon>print</Icon>}
                onClick={() => setOpenDialog(true)}
              >
                Print
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} align="right">
              <Button
                variant="contained"
                style={{ marginTop: "20px", width: "150px" }}
                startIcon={<Icon>save</Icon>}
                onClick={save}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <AlertDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            goPrint={(path) => goPrint(path)}
          />
          <iframe
            name="printFrame"
            id="printFrame"
            title="printFrame"
            style={{ width: "0px", height: "0px", border: "none" }}
          ></iframe>
        </>
      )}
    </div>
  );
}

function AlertDialog({ open, onClose, goPrint }) {
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Select Form</DialogTitle>
        <DialogContent style={{ minWidth: "300px" }}>
          <Button variant="outlined" fullWidth onClick={() => goPrint("pp7e")}>
            ปพ.7 (ENG)
          </Button>
          <Button
            variant="outlined"
            fullWidth
            style={{ marginTop: "10px" }}
            onClick={() => goPrint("pp7")}
          >
            ปพ.7 (THAI)
          </Button>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function HistoryInfo({ form, onChange }) {
  const [histories, setHistories] = useState([]);
  const cols = [
    {
      title: "ระดับชั้น",
      style: { width: "230px" },
      valueStyle: { textAlign: "center" },
    },
    { title: "ชื่อโรงเรียน" },
    {
      title: "จังหวัด",
      style: { width: "230px" },
      valueStyle: { textAlign: "center" },
    },
  ];
  useEffect(() => {
    (async () => {
      let studies = JSON.parse(JSON.stringify(form.study_history || []));
      studies = await studies.map((j) => {
        return {
          value0: j.level,
          value1: j.school_name,
          value2: j.school_province,
        };
      });
      setHistories(studies);
    })();
  }, [form.study_history]);

  function decodeHistories(values) {
    return new Promise(async (resolve) => {
      let studies = await values.map((j) => {
        return {
          level: j.value0,
          school_name: j.value1,
          school_province: j.value2,
        };
      });
      resolve(studies);
    });
  }
  async function changeFields(values) {
    values = await decodeHistories(values);
    onChange("study_history", values);
  }
  return (
    <div>
      <h3 style={styles.title}>ข้อมูลประวัติการศึกษา</h3>
      <InputTable
        data={histories || []}
        cols={cols}
        changeFields={changeFields}
      />
    </div>
  );
}

function ResignInfo({ form, onChange }) {
  const [resigns, setResigns] = useState([]);
  const cols = [
    {
      title: "เหตุผล",
    },
    {
      title: "วันที่ลาออก",
      inputType: "date",
      style: { width: "150px" },
      valueStyle: { textAlign: "center" },
    },
    {
      title: "สถานศึกษาที่ไปเรียนต่อ",
      style: { width: "200px" },
      valueStyle: { textAlign: "center" },
    },
    {
      title: "จังหวัด/ประเทศ",
      style: { width: "150px" },
      valueStyle: { textAlign: "center" },
    },
  ];
  useEffect(() => {
    (async () => {
      let Resigns = JSON.parse(JSON.stringify(form.resign || []));
      Resigns = await Resigns.map((j) => {
        return {
          value0: j.reson,
          value1: j.resign_date,
          value2: j.new_school,
          value3: j.new_school_location,
        };
      });
      setResigns(Resigns);
    })();
  }, [form.resign]);
  function decode(values) {
    return new Promise(async (resolve) => {
      let studies = await values.map((j) => {
        return {
          reson: j.value0,
          resign_date: j.value1,
          new_school: j.value2,
          new_school_location: j.value3,
        };
      });
      resolve(studies);
    });
  }
  async function changeFields(values) {
    console.log(values);
    values = await decode(values);
    onChange("resign", values);
  }
  return (
    <div>
      <h3 style={styles.title}>ข้อมูลประวัติการศึกษา</h3>
      <InputTable
        data={resigns || []}
        cols={cols}
        changeFields={changeFields}
      />
    </div>
  );
}

function RegisterDocumentInfo({ documents, onChange }) {
  function onChecked(e) {
    let docs = JSON.parse(JSON.stringify(documents));
    if (e.target.checked === true) docs.push(e.target.value);
    else docs = docs.filter((j) => j !== e.target.value);
    onChange("registration_documents", docs);
  }
  return (
    <div>
      <h3 style={styles.title}>หลักฐานการสมัครเรียน</h3>
      <Grid container style={{ paddingLeft: "10px" }}>
        {[0, 1, 2].map((index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <FormGroup>
              {registration_documents
                .filter((j) => j.group_no === index)
                .map((doc, key) => (
                  <FormControlLabel
                    key={key}
                    onChange={onChecked}
                    value={doc.label}
                    checked={
                      documents.findIndex((j) => j === doc.label) > -1
                        ? true
                        : false
                    }
                    control={<Checkbox />}
                    label={<div>{doc.label}</div>}
                  />
                ))}
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
