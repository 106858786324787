import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables,
} from "chart.js";
// import { Chart } from "react-chartjs-2";

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);
export default function App({ state }) {
  return (
    <>
      <h3 style={{ padding: "0px 0px", margin: "10px", fontWeight: 500 }}>
        {state.title}
      </h3>
      {/* <Chart type="bar" data={state} /> */}
    </>
  );
}
