import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./Login";
import Blank from "./Blank";
import Header from "./components/Header";
import Student from "./data/Student";
import QRCode from "./data/QRCode";
import Group from "./data/Group";
import GroupChange from "./data/GroupChange";
import Advisor from "./data/Advisor";
import UpdateStudent from "./data/UpdateStudent";
import GroupStudent from "./data/GroupStudent";
import GroupTable from "./data/GroupTable";
import User from "./setting/User";
import Options from "./setting/Options";
import StgPickupTime from "./setting/PickupTime";
import LineSync from "./setting/LineSync";
import Pickup from "./action/Pickup";
import Ticket from "./action/Ticket";
import CheckPresent from "./action/CheckPresent";
import Checkin from "./action/Checkin";
import RptCheckPresent from "./reports/CheckPresent";
import RptStudent from "./reports/Student";
import RptFood from "./reports/Food";
import RptHealthy from "./reports/Healthy";
import RptPickup from "./reports/Pickup";
import RptPickupPerson from "./reports/PickupPerson";

import Test from "./Test";

import PrintPP7E from "./prints/PP7E";
import PrintPP7 from "./prints/PP7";

import ExcelExport from "./components/ExcelExport";
import Conf from "./Conf";
import { getPermission, checkRegStaff /*fetchData */ } from "./js/main";
import RptOverallCheckPresent from "./prints/RptOverallCheckPresent";

function App(props) {
  const [pages, setPages] = useState([]);
  const [profile, setProfile] = useState();
  const [path, setPath] = useState(null);

  const noHeaderPaths = [
    "/login",
    "/signout",
    "/action/checkin",
    "/pickup/line",
    "/test",
  ];
  const noHeaderSubstring = ["/group/student/", "/print/"];
  const getPages = async (profile) => {
    let Pages = [
      {
        path: "/test",
        component: Test,
      },
      {
        path: "/blank",
        component: Blank,
      },
      {
        component: Login,
        path: "/login",
      },
      {
        label: "ข้อมูล",
        menuId: 1,
        priority: 1,
      },
      {
        label: "นักเรียน",
        menuId: 10,
        priority: 1,
        subOf: 1,
        icon: "face",
        component: Student,
        path: "/student",
        forReg: true,
      },
      {
        label: "นักเรียน",
        icon: "face",
        component: Student,
        path: "/student/:id",
      },
      {
        label: "กลุ่มเรียน",
        menuId: 20,
        priority: 1,
        subOf: 1,
        icon: "group",
        path: "/group",
        component: Group,
      },
      {
        label: "กลุ่มเรียน",
        icon: "group",
        path: "/group/student/:id",
        component: GroupTable,
      },
      {
        label: "กลุ่มเรียน",
        icon: "group",
        path: "/group/:group_id",
        component: GroupStudent,
      },
      {
        label: "ข้อมูลนักเรียน",
        icon: "person",
        path: "/advisor/student/:student_id",
        component: Advisor,
      },
      {
        path: "/exportexcel",
        component: ExcelExport,
      },

      {
        label: "คิวอาร์โค้ด",
        menuId: 30,
        priority: 1,
        subOf: 1,
        icon: "qr_code_2",
        path: "/qrcode",
        component: QRCode,
        forReg: true,
      },
      {
        label: "ย้ายกลุ่มเรียน",
        menuId: 40,
        priority: 1,
        subOf: 1,
        icon: "published_with_changes",
        path: "/groupchange",
        component: GroupChange,
        forReg: true,
      },
      {
        label: "ปรับปรุงข้อมูล",
        menuId: 50,
        priority: 1,
        subOf: 1,
        icon: "cloud_sync",
        path: "/sync",
        component: UpdateStudent,
        forReg: true,
      },
      {
        label: "ปรับปรุงข้อมูล",
        priority: 1,
        icon: "cloud_sync",
        path: "/sync/:id",
        component: UpdateStudent,
        forReg: true,
      },
      {
        label: "ปฏิบัติการ",
        menuId: 20,
        priority: 100,
      },
      {
        label: "เช็กชื่อ",
        menuId: 220,
        priority: 100,
        subOf: 20,
        icon: "fact_check",
        path: "/form/checkpresent",
        component: CheckPresent,
      },
      {
        label: "การมารับ",
        menuId: 230,
        priority: 100,
        subOf: 20,
        icon: "call_missed",
        path: "/action/pickup",
        component: Pickup,
      },
      {
        label: "แสกนบัตร",
        icon: "credit_card",
        menuId: 230,
        priority: 1,
        subOf: 20,
        component: Checkin,
        path: "/action/checkin",
        newWindow: true,
        // forReg: true,
      },
      {
        label: "ข้อความ",
        icon: "message",
        menuId: 240,
        priority: 100,
        subOf: 20,
        component: Ticket,
        path: "/ticket",
      },

      {
        label: "รายงาน",
        menuId: 30,
        priority: 100,
      },
      {
        label: "การมาเรียน",
        menuId: 320,
        priority: 100,
        subOf: 30,
        icon: "playlist_add_check",
        path: "/report/checkpresent",
        component: RptCheckPresent,
      },
      {
        label: "จำนวนนักเรียน",
        menuId: 330,
        priority: 100,
        subOf: 30,
        icon: "groups",
        path: "/report/student",
        component: RptStudent,
        forReg: true,
      },
      {
        label: "การแพ้อาหาร",
        menuId: 340,
        priority: 100,
        subOf: 30,
        icon: "fastfood",
        path: "/report/food",
        component: RptFood,
      },

      {
        label: "ประวัติสุขภาพ",
        menuId: 350,
        priority: 100,
        subOf: 30,
        icon: "health_and_safety",
        path: "/report/healthy",
        component: RptHealthy,
      },
      {
        label: "การมารับ",
        menuId: 350,
        priority: 100,
        subOf: 30,
        icon: "hail",
        path: "/report/pickup",
        component: RptPickup,
      },
      {
        label: "ผู้ปกครอง",
        menuId: 350,
        priority: 100,
        subOf: 30,
        icon: "escalator_warning",
        path: "/report/pickupperson",
        component: RptPickupPerson,
      },
      {
        label: "ตั้งค่า",
        menuId: 40,
        priority: 1,
        // forReg: true,
      },
      {
        label: "ตัวเลือก",
        menuId: 420,
        priority: 100,
        subOf: 40,
        icon: "rule",
        path: "/option",
        component: Options,
      },
      {
        label: "สิทธิการใช้งาน",
        menuId: 430,
        priority: 100,
        subOf: 40,
        icon: "person_add",
        path: "/user",
        component: User,
      },
      {
        label: "เวลาการมารับ",
        menuId: 440,
        priority: 100,
        subOf: 40,
        icon: "access_time",
        path: "/setting/pickuptime",
        component: StgPickupTime,
      },
      {
        label: "ผูก Line Account",
        menuId: 450,
        priority: 1,
        subOf: 40,
        icon: "maps_ugc",
        path: "/setting/linesync",
        component: LineSync,
      },

      // PRINT
      {
        path: "/print/pp7e/:id",
        component: PrintPP7E,
      },
      {
        path: "/print/pp7/:id",
        component: PrintPP7,
      },
      {
        path: "/print/checkpresent/overall/:from_date/:to_date",
        component: RptOverallCheckPresent,
      },
    ];
    if (!profile || profile.reg_staff !== true) {
      Pages = Pages.filter((j) => j.forReg !== true);
    }
    setPages(Pages);
    setPath(window.location.pathname);
  };
  useEffect(() => {
    (async () => {
      let p = await getPermission();
      if (p) {
        p.reg_staff = await checkRegStaff(p);
        setProfile(p);
        if (window.location.pathname === "/") {
          window.location = process.env.PUBLIC_URL + "/group";
        }
      }
    })();
  }, []);
  useEffect(() => {
    if (
      profile ||
      window.location.pathname === "/test" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/action/checkin"
    ) {
      getPages(profile);
    }
  }, [profile]);

  function signOut() {
    localStorage.removeItem(Conf.cookie_name);
    window.location = process.env.PUBLIC_URL + "/login";
  }
  function checkSubstringPath() {
    let r = false;
    for (let arr of noHeaderSubstring) {
      let subPath = path.substring(0, arr.length);
      if (arr === subPath) r = true;
    }
    return r;
  }
  return (
    <div className="App">
      <BrowserRouter>
        {profile &&
          path &&
          noHeaderPaths.indexOf(path) < 0 &&
          checkSubstringPath() === false && (
            <Header
              onSignOut={signOut}
              menus={pages.filter((j) => j.menuId)}
              {...props}
            />
          )}

        <Routes>
          {path &&
            pages
              .filter((j) => j.component)
              .map((page, key) => (
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}${page.path}`}
                  element={
                    <page.component {...props} profile={profile} page={page} />
                  }
                  key={key}
                />
              ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
