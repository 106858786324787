import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import CuratorsReport from "./advisor/Curators";
import PickupReport from "./advisor/Pickups";

import Grid from "@mui/material/Grid";
import {
  checkPermission,
  getGroupAdvisor,
  getStudent,
  getGroups,
} from "../js/main";
import { GridSelect } from "../components/InputComponents";
import Breadcrumbs from "../components/Breadcrumbs";

export default function Group(props) {
  const [profile, setProfile] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState();
  const [student, setStudent] = useState();
  const [state, setState] = useState({ reportNo: 2 });
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          let student_id = props.match.params.student_id;
          let student = await getStudent(student_id);
          let groups = {};
          if (props.profile.reg_staff === true) {
            groups = await getGroups();
            groups = { result: groups.groups };
          } else {
            groups = await getGroupAdvisor(props.profile.usn);
          }
          let i = groups.result.findIndex(
            (j) => j._id === student.current_group
          );
          if (i > -1) {
            student.fullname =
              student.prefix +
              " " +
              student.first_name +
              " " +
              (student.middle_name || "") +
              " " +
              student.last_name;
            let breadcrumbs = [
              { label: "กลุ่มเรียน", to: process.env.PUBLIC_URL + "/group" },
              {
                label: groups.result[i].name,
                to: process.env.PUBLIC_URL + "/group/" + student.current_group,
              },
              { label: student.fullname },
            ];
            setBreadcrumbs(breadcrumbs);
            setProfile(props.profile);
            setStudent(student);
          } else alert("Permission denied");
        }
      }
    })();
  }, [props]);
  return (
    <>
      {student && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container>
            <GridSelect
              grid={[12, 12, 6, 4]}
              label="รายงาน"
              disabled_empty={true}
              onChange={(val) => {
                console.log(val);
                setState({ ...state, reportNo: val });
              }}
              options={[
                { label: "ผู้ปกครอง", value: 1 },
                { label: "ผู้มารับ", value: 2 },
              ]}
              value={state.reportNo}
            />
          </Grid>
          {
            {
              1: <CuratorsReport student={student} />,
              2: <PickupReport student={student} />,
            }[state.reportNo]
          }
        </div>
      )}
    </>
  );
}
