import React from "react";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";

export default function ProfileBox(props) {
  const { profile } = props;
  return (
    <div style={{ paddingTop: "3px" }}>
      <Button
        style={{
          float: "right",
          fontSize: ".8rem",
          color: "#666",
          padding: "0px 0px",
        }}
      >
        <Badge color="error" variant="dot" invisible={false}>
          <div style={{ paddingRight: "5px" }}>
            {profile && profile.display_name}
          </div>
        </Badge>
      </Button>
      <div style={{ float: "right", marginRight: "5px", paddingTop: "2px" }}>
        <Icon style={{ fontSize: "1.1rem", color: "#666" }}>
          account_circle
        </Icon>
      </div>
    </div>
  );
}
