import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Icon, IconButton, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { fetchData } from "../../js/main";
import { Fragment } from "react";
import Autocomplete from "@mui/material/Autocomplete";

function getAllGroups() {
  return new Promise(async (resolve) => {
    let groups = await fetchData("post", "/query", {
      collection: "ticket_groups",
      sort: { name: 1 },
      populate: {
        path: "accounts",
        select: "fullname usn",
      },
    });
    resolve(groups);
  });
}

export default function TicketGroups() {
  const [users, setUsers] = useState();
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    (async () => {
      let users = await fetchData("post", "/query", {
        collection: "users",
        select: "fullname usn",
      });
      users.result = await users.result.map((user) => {
        user.label = user.usn;
        return user;
      });
      let groups = await getAllGroups();
      setGroups(groups.result);
      setUsers(users.result);
    })();
  }, []);

  async function saveGroup(group, callback) {
    let result = await fetchData("put", "/ticket/group", group);
    let Groups = JSON.parse(JSON.stringify(groups));
    if (group._id) {
      let i = Groups.findIndex((j) => j._id === group._id);
      if (i > -1) {
        Groups[i] = result;
      }
    } else Groups.push(result);
    setGroups(Groups);
    if (callback) callback();
  }
  async function deleteGroup(group_id) {
    if (window.confirm("Confirm to delete")) {
      await fetchData("delete", "/ticket/group/" + group_id);
      let Groups = JSON.parse(
        JSON.stringify(groups.filter((j) => j._id !== group_id))
      );
      setGroups(Groups);
    }
  }

  function editGroup(key) {
    let Groups = JSON.parse(JSON.stringify(groups));
    Groups[key].edit = !Groups[key].edit;
    setGroups(Groups);
  }
  async function addUser(user, key) {
    if (user) {
      let result = await fetchData("put", "/ticket/group/user", {
        group_id: groups[key]._id,
        user_id: user._id,
      });
      if (result && result._id) {
        let Groups = JSON.parse(JSON.stringify(groups));
        Groups[key].accounts.push(user);
        setGroups(Groups);
      }
    }
  }
  async function deleteUser(user_id, group_id) {
    if (window.confirm("Confirm to delete")) {
      let Groups = JSON.parse(JSON.stringify(groups));
      let path = "/ticket/group/" + group_id + "/user/" + user_id;
      await fetchData("delete", path);
      let key = Groups.findIndex((j) => j._id === group_id);
      Groups[key].accounts = await Groups[key].accounts.filter(
        (j) => j._id !== user_id
      );
      setGroups(Groups);
    }
  }
  return (
    <div>
      {users && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: "100px" }}>
                  No.
                </TableCell>
                <TableCell align="center">Group Name / Account</TableCell>
                <TableCell align="center" sx={{ width: "100px" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              <EditGroup onSave={saveGroup} />
              {groups.map((group, key) => (
                <Fragment key={key}>
                  {group.edit === true ? (
                    <EditGroup
                      group={group}
                      onSave={saveGroup}
                      onEdit={() => editGroup(key)}
                      onDelete={() => deleteGroup(group._id)}
                    />
                  ) : (
                    <ShowGroup
                      group={group}
                      no={key + 1}
                      onEdit={() => editGroup(key)}
                      onAddUser={(user) => addUser(user, key)}
                      onDeleteUser={deleteUser}
                      users={users}
                    />
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

function ShowGroup({ group, users, no, onEdit, onAddUser, onDeleteUser }) {
  return (
    <Fragment>
      <TableRow>
        <TableCell align="center">{no}</TableCell>
        <TableCell>{group.name}</TableCell>
        <TableCell align="center">
          <IconButton
            color="primary"
            sx={{ padding: "5px 5px" }}
            onClick={onEdit}
          >
            <Icon>edit</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
      <ShowAccount
        users={users}
        onAddUser={onAddUser}
        onDeleteUser={onDeleteUser}
        group={group}
      />
    </Fragment>
  );
}

function ShowAccount({ users, group, onAddUser, onDeleteUser }) {
  const [form, setForm] = useState();
  return (
    <Fragment>
      <TableRow sx={{ backgroundColor: "#f1f1f1" }}>
        <TableCell />
        <TableCell className="inputTd">
          <Autocomplete
            disablePortal
            options={users}
            value={form && form.label ? form.label : ""}
            size="small"
            sx={{ background: "#fff" }}
            onChange={(event, newValue) => {
              setForm(newValue);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder="Account" />
            )}
          />
        </TableCell>
        <TableCell align="center">
          <IconButton
            color="primary"
            sx={{ padding: "5px 5px" }}
            onClick={() => {
              onAddUser(form);
              setForm(null);
            }}
            disabled={form ? false : true}
          >
            <Icon>add_circle</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
      {group.accounts.map((user, key) => (
        <TableRow sx={{ backgroundColor: "#f1f1f1" }} key={key}>
          <TableCell />
          <TableCell>
            {user.fullname} ({user.usn})
          </TableCell>
          <TableCell align="center">
            <IconButton
              color="error"
              sx={{ padding: "5px 5px" }}
              onClick={() => onDeleteUser(user._id, group._id)}
            >
              <Icon>clear</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
}

function EditGroup({ group, onSave, onEdit, onDelete }) {
  const [form, setForm] = useState();
  useEffect(() => {
    if (group) setForm(group);
    else setForm({});
  }, [group]);

  return (
    <>
      {form && (
        <TableRow>
          <TableCell align="center">
            {group ? (
              <IconButton
                color="error"
                sx={{ padding: "5px 5px" }}
                onClick={onDelete}
              >
                <Icon>clear</Icon>
              </IconButton>
            ) : (
              <Icon sx={{ color: "#999" }}>add</Icon>
            )}
          </TableCell>
          <TableCell className="inputTd">
            <TextField
              fullWidth
              size="small"
              placeholder="Group Name"
              value={form.name || ""}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </TableCell>
          <TableCell align="center">
            {group && (
              <IconButton
                color="success"
                sx={{ padding: "5px 5px" }}
                onClick={onEdit}
              >
                <Icon>undo</Icon>
              </IconButton>
            )}
            <IconButton
              color="primary"
              sx={{ padding: "5px 5px" }}
              onClick={() => onSave(form, () => setForm({}))}
            >
              <Icon>save</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
