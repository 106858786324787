import React from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

export default function PageNameBox(props) {
  const { page } = props;
  return (
    <div>
      <Grid container>
        <Grid item style={{ width: "45px", paddingTop: "12px" }}>
          <Icon style={{ fontSize: "2rem" }}>{page.icon}</Icon>
        </Grid>
        <Grid item>
          <h1 style={{ padding: "0px 0px", margin: "0px" }}>{page.label}</h1>
        </Grid>
      </Grid>
    </div>
  );
}
