import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Conf from "./Conf";
import axios from "axios";

import { initializeApp, getApps } from "firebase/app";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  // signInWithRedirect,
  // getRedirectResult,
} from "firebase/auth";

// const app = initializeApp({
//   apiKey: Conf.fbKey,
//   authDomain: Conf.fbAuthDomain,
//   // databaseURL: "https://tonkla-a1378.firebaseio.com",
//   // projectId: "tonkla-a1378",
//   // storageBucket: "tonkla-a1378.appspot.com",
//   // messagingSenderId: "1094459691353",
//   // appId: "1:1094459691353:web:df251052b61fb6c9babc48",
// });

const app = !getApps().length ? initializeApp(Conf.firebaseConfig) : getApps();
// const app = initializeApp(Conf.firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.tonkla.ac.th">
        Tonkla School
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  function login() {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        let access_token = await axios.get(
          Conf.endpoint.tonkla + "/reg/admin/auth",
          {
            headers: { Authorization: result.user.accessToken },
          }
        );
        if (access_token.data && access_token.data.access_token) {
          localStorage.setItem(
            Conf.cookie_name,
            JSON.stringify({ access_token: access_token.data.access_token })
          );
          window.location = process.env.PUBLIC_URL + "/group";
        } else {
          alert("Permission denied");
        }
      })
      .catch((error) => {});
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(" + process.env.PUBLIC_URL + "/asset/img/loginimg.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Button
              fullWidth
              component={Paper}
              // variant="outlined"
              sx={{ mt: 6, mb: 2, paddingRight: "20px" }}
              onClick={() => login()}
              startIcon={
                <img
                  src={process.env.PUBLIC_URL + "/asset/img/google_icon.png"}
                  style={{ height: "30px", marginRight: "20px" }}
                  alt=""
                />
              }
            >
              Sign In
            </Button>
            <Copyright sx={{ mt: 3 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
