import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import { checkPermission } from "../js/main";
import { GridSelect } from "../components/InputComponents";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
// import Icon from "@mui/material/Icon";
import axios from "axios";
import Conf from "../Conf";
// import QRCode from "qrcode.react";
import { TextField } from "@mui/material";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function QRCodePage(props) {
  const [profile, setProfile] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) setProfile(props.profile);
      }
    })();
  }, [props]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <GroupPage />
        </div>
      )}
    </>
  );
}

function GroupPage() {
  const [groups, setGroups] = useState();
  const [state, setState] = useState();
  const [students, setStudents] = useState([]);
  useEffect(() => {
    (async () => {
      let Groups = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "groups",
          match: { "student_id.0": { $exists: true } },
          sort: { name: 1 },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      if (Groups.data) {
        Groups = await Groups.data.result.map((g) => {
          return {
            label: g.name,
            value: g._id,
          };
        });
        setGroups(Groups);
        setState({ group: Groups[0].value });
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (state) {
        let Students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: { current_group: state.group },
            sort: { student_code: 1 },
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        setStudents(Students.data.result);
      }
    })();
  }, [state]);

  async function genQR(id, type, value) {
    let Students = JSON.parse(JSON.stringify(students));
    let i = Students.findIndex((j) => j._id === id);
    if (i > -1) {
      let result = await axios.put(
        Conf.endpoint.tonkla + "/reg/student/qrcode/" + id,
        {
          type,
          value,
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      if (type === "curator") {
        Students[i].curator_qrcode = result.data.curator_qrcode;
      } else if (type === "student") {
        Students[i].student_qrcode = result.data.student_qrcode;
      }
      setStudents(Students);
    }
  }
  function changeCode(e, key) {
    let val = e.target.value;
    let Students = JSON.parse(JSON.stringify(students));
    Students[key].student_qrcode = val;
    setStudents(Students);
  }
  return (
    <div>
      {state && (
        <>
          <Grid container>
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Group"
              options={groups || []}
              onChange={(val) => setState({ ...state, group: val })}
              value={state.group || "-"}
            />
          </Grid>
          <br />
          <StudentTable
            students={students}
            genQR={genQR}
            changeCode={changeCode}
          />
        </>
      )}
    </div>
  );
}

function StudentTable({ students, genQR, changeCode }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className="th"
                align="center"
                style={{ width: "80px" }}
              >
                No
              </TableCell>
              <TableCell
                className="th"
                align="center"
                style={{ width: "100px" }}
              >
                Student ID
              </TableCell>
              <TableCell className="th" align="center">
                Student Name
              </TableCell>
              <TableCell
                className="th"
                align="center"
                style={{ width: "160px" }}
              >
                Student QR code
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, key) => (
              <TableRow key={key}>
                <TableCell className="td" align="center">
                  {key + 1}
                </TableCell>
                <TableCell className="td" align="center">
                  {student.student_code}
                </TableCell>
                <TableCell className="td">
                  {student.prefix} {student.first_name} {student.middle_name}{" "}
                  {student.last_name}
                </TableCell>
                <TableCell className="inputTd" align="center">
                  {/* {student.student_qrcode ? (
                    <QRCode
                      value={
                        "https://hca.tonkla.ac.th/" + student.student_qrcode
                      }
                      size={80}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<Icon>qr_code_2</Icon>}
                      onClick={() => genQR(student._id, "student")}
                    >
                      Code
                    </Button>
                  )} */}
                  <TextField
                    fullWidth
                    size="small"
                    value={student.student_qrcode || ""}
                    id={"student_qrcode_" + key}
                    inputProps={{
                      style: { textAlign: "center" },
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        genQR(student._id, "student", e.target.value);
                        let id = "student_qrcode_" + (key + 1);
                        document.getElementById(id).select();
                      }
                    }}
                    onChange={(e) => changeCode(e, key)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
