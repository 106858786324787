import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import Pagebar from "../../components/Pagebar";

import axios from "axios";
import Conf from "../../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Resign() {
  const [statuses, setStatuses] = useState([]);
  const [students, setStudents] = useState();
  const [state, setState] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      let statuses = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "student_statuses",
          limit: 0,
          match: {
            label: { $in: ["ลาออก", "พักการเรียน", "จบการศึกษา", "จำหน่าย"] },
          },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      statuses = await statuses.data.result.map((j) => {
        return { label: j.label, value: j._id };
      });
      setStatuses(statuses);
      let selected = await statuses.map((status) => status.value);
      setState({ skip: 0, limit: 30, selected });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (state) {
        setLoading(true);
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            skip: state.skip,
            limit: state.limit,
            match: {
              student_status: { $in: state.selected },
            },
            populate: {
              path: "current_group",
              select: "name",
            },
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        let no = state.skip;
        students.data.result = await students.data.result.map((student) => {
          let i = statuses.findIndex((j) => j.value === student.student_status);
          student.no = ++no;
          student.current_status = statuses[i].label;
          return student;
        });
        setStudents(students.data);
        setLoading(false);
      }
    })();
  }, [state, statuses]);
  async function changeSelectedStatuses(e) {
    let State = JSON.parse(JSON.stringify(state));
    let value = e.target.value;
    if (e.target.checked === true) {
      State.selected.push(value);
    } else {
      State.selected = await State.selected.filter((j) => j !== value);
    }
    setState(State);
  }
  return (
    <div>
      {students && (
        <>
          {statuses.map((status, key) => (
            <FormControlLabel
              key={key}
              onChange={(e) => changeSelectedStatuses(e)}
              control={
                <Checkbox
                  checked={state.selected.indexOf(status.value) > -1}
                  value={status.value}
                />
              }
              label={<div>{status.label}</div>}
            />
          ))}

          <div style={{ paddingTop: "10px", paddingBottom: "15px" }}>
            <Pagebar
              size="small"
              page={page}
              count={students.count / state.limit}
              changePage={(val) => {
                setState({ ...state, skip: (val - 1) * state.limit });
                setPage(val);
              }}
            />
          </div>
          {loading === true && <LinearProgress />}
          <ReportTable students={students.result} />
        </>
      )}
    </div>
  );
}

function ReportTable({ students }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="th" style={{ width: "80px" }}>
                ลำดับ
              </TableCell>
              <TableCell className="th">ชื่อ -นามสุกล</TableCell>
              <TableCell className="th" style={{ width: "200px" }}>
                ชื่อเล่น
              </TableCell>
              <TableCell className="th" style={{ width: "150px" }}>
                ห้องเรียน
              </TableCell>
              <TableCell className="th" style={{ width: "200px" }}>
                หมายเหตุ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, key) => (
              <TableRow key={key}>
                <TableCell className="td" align="center">
                  {student.no}
                </TableCell>
                <TableCell className="td">
                  {student.prefix} {student.first_name} {student.middle_name}{" "}
                  {student.last_name}
                </TableCell>
                <TableCell className="td" align="center">
                  {student.nickname}
                </TableCell>
                <TableCell className="td" align="center">
                  {student.current_group && student.current_group.name}
                </TableCell>
                <TableCell className="td" align="center">
                  {student.current_status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
