import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import TicketGroups from "./user/TicketGroups";

import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { checkPermission } from "../js/main";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function User(props) {
  const [profile, setProfile] = useState();
  const [tabNo, setTabNo] = useState(0);
  const [users, setUsers] = useState();
  const [user, setUser] = useState({});

  const fetchUsers = async () => {
    let users = await axios.post(
      Conf.endpoint.tonkla + "/reg/query",
      {
        collection: "users",
        match: { "permissions.appName": "reg" },
        sort: "usn",
      },
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    setUsers(users.data);
  };
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
          fetchUsers();
        }
      }
    })();
  }, [props]);
  async function deleteUser() {
    if (user._id) {
      let result = await axios.delete(
        Conf.endpoint.tonkla + "/reg/user/" + user._id,
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      if (result.data.error) alert(result.data.error.message);
      else {
        let Users = JSON.parse(JSON.stringify(users));
        Users.result = Users.result.filter((j) => j._id !== user._id);
        setUsers(Users);
        setUser({});
      }
    }
  }
  async function save() {
    let result = await axios.put(Conf.endpoint.tonkla + "/reg/user", user, {
      headers: { Authorization: cookie.access_token },
    });
    if (result.data.error) alert(result.data.error.message);
    else if (result.data._id) {
      result = result.data;
      let Users = JSON.parse(JSON.stringify(users));
      let i = Users.result.findIndex((j) => j._id === result._id);
      if (i > -1) {
        Users.result[i] = result;
      } else {
        Users.result.push(result);
      }
      setUsers(Users);
      setUser({});
    }
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />

          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "20px",
            }}
          >
            <Tabs value={tabNo} onChange={(val, val2) => setTabNo(val2)}>
              <Tab label="Assign Users" />
              <Tab label="Message Groups" />
            </Tabs>
          </Box>
          {
            {
              0: (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ width: "80px" }}
                          className="td"
                        >
                          No.
                        </TableCell>
                        <TableCell align="center" className="td">
                          Username (G-Suit)
                        </TableCell>
                        <TableCell align="center" className="td">
                          Full name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: "80px" }}
                          className="td"
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="inputTd" align="center">
                          <IconButton
                            color="error"
                            component="span"
                            disabled={!user._id}
                            onClick={deleteUser}
                          >
                            <Icon style={{ fontSize: "1.3rem" }}>clear</Icon>
                          </IconButton>
                        </TableCell>
                        <TableCell className="inputTd">
                          <TextField
                            size="small"
                            id="usn"
                            variant="outlined"
                            value={user.usn || ""}
                            onChange={(e) =>
                              setUser({
                                ...user,
                                [e.target.id]: e.target.value,
                              })
                            }
                            fullWidth
                            placeholder="ชื่อผู้ใช้งาน"
                          />
                        </TableCell>
                        <TableCell className="inputTd">
                          <TextField
                            size="small"
                            value={user.fullname || ""}
                            id="fullname"
                            variant="outlined"
                            onChange={(e) =>
                              setUser({
                                ...user,
                                [e.target.id]: e.target.value,
                              })
                            }
                            fullWidth
                            placeholder="ชื่อ-นามสกุล"
                          />
                        </TableCell>
                        <TableCell className="inputTd" align="center">
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={save}
                          >
                            <Icon style={{ fontSize: "1.3rem" }}>save</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {users &&
                        users.result.map((u, key) => (
                          <TableRow key={key}>
                            <TableCell className="td" align="center">
                              {key + 1}
                            </TableCell>
                            <TableCell className="td">{u.usn}</TableCell>
                            <TableCell className="td">{u.fullname}</TableCell>
                            <TableCell className="controlTd" align="center">
                              {user._id !== u._id ? (
                                <>
                                  <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setUser(u)}
                                  >
                                    <Icon style={{ fontSize: "1.3rem" }}>
                                      edit
                                    </Icon>
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton
                                    color="inherit"
                                    component="span"
                                    onClick={() => setUser({})}
                                  >
                                    <Icon
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "#0c0",
                                      }}
                                    >
                                      undo
                                    </Icon>
                                  </IconButton>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ),
              1: <TicketGroups />,
            }[tabNo]
          }
        </div>
      )}
    </>
  );
}
