import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import axios from "axios";
import { getFullName } from "../../js/main";
import Conf from "../../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Curators({ student }) {
  const [pickups, setPickups] = useState([]);
  useEffect(() => {
    (async () => {
      let r = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "pickup_persons",
          limit: 0,
          match: { student_id: student._id },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      setPickups(r.data.result);
    })();
  }, [student]);

  return (
    <div>
      <br />
      <br />
      {pickups.length === 0 ? (
        <div style={{ color: "#999", textAlign: "center" }}>ไม่พบข้อมูล</div>
      ) : (
        <>
          {pickups
            .filter((j) => j.first_name)
            .map((person) => (
              <PickupPerson key={person._id} person={person} />
            ))}
        </>
      )}
    </div>
  );
}

function PickupPerson({ person }) {
  const [img, setImg] = useState();
  useEffect(() => {
    (async () => {
      if (person.photo) {
        let img = Conf.endpoint.upload + "/" + person.photo;
        if (person.photo.indexOf("file.php?") > -1) {
          img = await axios.get(Conf.endpoint.fileUpload + "/" + person.photo);
          img = img.data;
        }
        setImg(img);
      }
    })();
  }, [person]);

  return (
    <div style={{ marginBottom: "30px" }}>
      <Grid container>
        <Grid item xs={12} sm={5} md={4} lg={3} align="center">
          {img ? (
            <img
              src={img}
              alt=""
              style={{
                border: "1px solid #ccc",
                maxWidth: "60%",
              }}
            />
          ) : (
            <Icon style={{ color: "#ccc", fontSize: "9rem" }}>person</Icon>
          )}
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <div
            style={{ fontSize: "1.8rem", fontWeight: "500", marginTop: "25px" }}
          >
            {getFullName(person)}
          </div>
          <div style={{ fontSize: "1.2rem", marginTop: "15px" }}>
            ความสัมพันธ์ : {person.relation || "-"}
          </div>
          <div style={{ fontSize: "1.2rem", marginTop: "15px" }}>
            หมายเลขโทรศัพท์ : {person.phone_number || "-"}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
