import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Grid from "@mui/material/Grid";
import {
  checkPermission,
  getGroupsStudents,
  getGroupAdvisor,
  getGroups,
} from "../js/main";
import WeightAndTall from "./forms/WeightAndTall.js";
import Breadcrumbs from "../components/Breadcrumbs";
import ListStudent from "../components/ListStudent";
import { GridSelect } from "../components/InputComponents";
// import { useHistory } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

export default function Group(props) {
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState();
  const params = useParams();
  const [state, setState] = useState({ tabNo: 1, params, props });
  // const history = useHistory();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      let { props, params } = state;
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          let group_id = params.group_id;
          let groups = {};
          if (props.profile.reg_staff === true) {
            groups = await getGroups();
            groups = { result: groups.groups };
          } else {
            groups = await getGroupAdvisor(props.profile.usn);
          }
          let i = groups.result.findIndex((j) => j._id === group_id);
          if (i > -1 || props.profile.reg_staff === true) {
            let students = await getGroupsStudents(group_id);
            let breadcrumbs = [
              { label: "กลุ่มเรียน", to: process.env.PUBLIC_URL + "/group" },
              { label: groups.result[i].name },
            ];
            setBreadcrumbs(breadcrumbs);
            setStudents(students.result);
            setProfile(props.profile);
          } else alert("Permission denied");
        }
      }
    })();
  }, [state]);
  return (
    <>
      {students && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container>
            <GridSelect
              grid={[12, 12, 6, 4]}
              label="แบบฟอร์ม"
              disabled_empty={true}
              onChange={(val) => setState({ ...state, tabNo: val })}
              options={[
                { label: "รายชื่อนักเรียน", value: 1 },
                { label: "ข้อมูลน้ำหนัก-ส่วนสูง", value: 2 },
              ]}
              value={state.tabNo || "-"}
            />
          </Grid>
          <br />
          {
            {
              1: (
                <ListStudent
                  students={students}
                  onSelect={(val) =>
                    // history.push(
                    //   process.env.PUBLIC_URL + "/advisor/student/" + val._id
                    // )
                    navigate(
                      process.env.PUBLIC_URL + "/advisor/student/" + val._id
                    )
                  }
                />
              ),
              2: <WeightAndTall students={students} />,
            }[state.tabNo]
          }
        </div>
      )}
    </>
  );
}
