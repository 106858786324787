import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Icon from "@mui/material/Icon";
import { GridSelect } from "../components/InputComponents";
import {
  checkPermission,
  getGroups,
  getGroupsStudents,
  getFullName,
  getFullNameFormFields,
  fetchData,
} from "../js/main";
import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Pickup(props) {
  const [profile, setProfile] = useState();
  const [state, setState] = useState();
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
          let groups = await getGroups();
          setState({ groups: groups.groups });
        }
      }
    })();
  }, [props]);
  useEffect(() => {}, [state]);

  return (
    <>
      {state && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <PickupTable groups={state.groups} profile={profile} />

          <iframe
            title="printFrame"
            id="printFrame"
            name="printFrame"
            style={{ width: "0px", height: "0px", border: "none" }}
          ></iframe>
        </div>
      )}
    </>
  );
}
function setGroupSelectValues(groups) {
  return new Promise(async (resolve) => {
    let gr = await groups.map((j) => {
      return { label: j.name, value: j._id };
    });
    resolve(gr);
  });
}

function PickupDialog({ data, onClose }) {
  const [img, setImg] = useState();
  useEffect(() => {
    (async () => {
      if (data && data.photo) {
        let img = await axios.get(Conf.endpoint.fileUpload + "/" + data.photo);

        img = img.data;
        setImg(img);
      } else setImg("");
    })();
  }, [data]);

  return (
    <div>
      <Dialog open={data.open}>
        <DialogTitle id="alert-dialog-title">Pickup Person</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item style={{ width: "150px" }}>
              {data.photo && img ? (
                <>
                  <img src={img} style={{ width: "120px" }} alt="" />
                </>
              ) : (
                <>
                  <Icon style={{ fontSize: "8rem", color: "#999" }}>
                    person
                  </Icon>
                </>
              )}
            </Grid>
            <Grid item style={{ width: "300px" }}>
              <div style={{ fontSize: ".7rem", fontWeight: 600 }}>Name</div>
              <div>
                {data.prefix} {data.first_name} {data.last_name}
              </div>
              <div
                style={{ fontSize: ".7rem", fontWeight: 600, marginTop: "7px" }}
              >
                Relation
              </div>
              <div>{data.relation || "-"}</div>
              <div
                style={{ fontSize: ".7rem", fontWeight: 600, marginTop: "7px" }}
              >
                Phone number
              </div>
              <div>{data.phone_number}</div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function PickupTable({ groups, profile }) {
  const [state, setState] = useState();
  const [students, setStudents] = useState();
  const [pickups, setPickups] = useState();
  const [curators, setCurators] = useState();
  const [pickupDialog, setPickupDialog] = useState({ open: false });
  function getCurators(ids) {
    return new Promise(async (resolve) => {
      let curators = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "curators",
          match: { student_id: { $in: ids } },
          sort: { curator_type: 1 },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      resolve(curators.data.result);
    });
  }
  function getPickups(ids) {
    return new Promise(async (resolve) => {
      let pickups = await axios.post(
        Conf.endpoint.tonkla + "/reg/query",
        {
          collection: "pickup_persons",
          match: {
            $and: [
              { student_id: { $in: ids } },
              { first_name: { $exists: true } },
              { disabled: { $ne: true } },
            ],
          },
          sort: { priority: 1 },
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      resolve(pickups.data.result);
    });
  }
  useEffect(() => {
    (async () => {
      if (groups[0]) {
        let gr = await setGroupSelectValues(groups);
        setState({ groups: gr, group: gr[0].value });
      }
    })();
  }, [groups]);
  useEffect(() => {
    (async () => {
      if (state && state.group) {
        let students = await getGroupsStudents(state.group);
        let ids = await students.result.map((j) => j._id);
        let pickups = await getPickups(ids);
        let curators = await getCurators(ids);
        setPickups(pickups);
        setCurators(curators);
        setStudents(students.result);
      }
    })();
  }, [state]);
  function decodeCuratorType(student) {
    let type = student.curator_type;
    let r = "";
    if (type === "curator")
      r = student.relation ? "(" + student.relation + ")" : "(ผู้ปกครอง)";
    else if (type === "father") r = "(บิดา)";
    else if (type === "mother") r = "(มารดา)";
    else if (type === "emergency") r = "(ผู้ติดต่อฉุกเฉิน)";
    return r;
  }
  function openCurator(curator) {
    curator.relation = decodeCuratorType(curator);
    curator.relation = curator.relation.replace("(", "");
    curator.relation = curator.relation.replace(")", "");
    curator.open = true;
    setPickupDialog(curator);
  }
  async function exportExcelAll() {
    let groupName = state.groups.findIndex((j) => j.value === state.group);
    let allPickups = await fetchData("post", "/query", {
      collection: "pickup_persons",
      match: {
        $and: [{ disabled: { $ne: true } }, { student_id: { $exists: true } }],
      },
      sort: { priority: 1 },
    });
    allPickups = allPickups.result;
    groupName = state.groups[groupName].label;
    let sheet = [];
    let no = 1;
    let allStudents = await fetchData("post", "/query", {
      collection: "students",
      select:
        "prefix first_name middle_name last_name student_code nickname current_group",
      match: { student_status: "5e098ba9d6cad40654a9fd3f" },
      populate: { path: "current_group", select: "name" },
      sort: "current_group",
    });
    for (let st of allStudents.result) {
      let txtParents = "";
      let Curators = await curators.filter((j) => j.student_id === st._id);
      for (let curator of Curators) {
        let fullname = await getFullName(curator);
        txtParents += fullname + ",";
      }
      let txtPickups = "";
      let txtPickupsEn = "";
      let Pickups = await allPickups.filter((j) => j.student_id === st._id);
      for (let pickup of Pickups) {
        let fullname = await getFullName(pickup);
        let fullname_en = getFullNameFormFields(
          pickup.prefix_en,
          pickup.first_name_en,
          pickup.middle_name_en,
          pickup.last_name_en
        );
        txtPickups +=
          pickup.priority +
          ". " +
          fullname +
          " " +
          pickup.phone_number +
          " (" +
          pickup.relation +
          ") OTP : " +
          (pickup.otp || "-") +
          ", ";
        txtPickupsEn +=
          pickup.priority +
          ". " +
          fullname_en +
          " " +
          pickup.phone_number +
          " (" +
          pickup.relation +
          "), ";
      }
      let obj = {
        no,
        group: st.current_group.name,
        student_code: st.student_code,
        fullname: await getFullName(st),
        nickname: st.nickname || "",
        parents: txtParents,
        pickup_name: txtPickups,
        pickup_name_en: txtPickupsEn,
      };
      no++;
      sheet.push(obj);
    }

    let ex = {
      filename: "all_student",
      sheet_name: groupName,
      head: [
        { label: "ลำดับ", value: "no" },
        { label: "กลุ่ม", value: "group" },
        { label: "รหัส", value: "student_code" },
        { label: "ชื่อสกุล", value: "fullname" },
        { label: "ชื่อเล่น", value: "nickname" },
        { label: "ผู้ปกครอง", value: "parents" },
        { label: "ผู้ปกครองรับ-ส่ง", value: "pickup_name" },
        { label: "ผู้ปกครองรับ-ส่ง (Eng)", value: "pickup_name_en" },
      ],
      sheet: sheet,
    };
    let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
    cookie.export = ex;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));

    document.getElementById("printFrame").src =
      process.env.PUBLIC_URL + "/exportexcel";
  }
  async function exportExcel() {
    let groupName = state.groups.findIndex((j) => j.value === state.group);
    groupName = state.groups[groupName].label;
    let sheet = [];
    let no = 1;
    for (let st of students) {
      let txtParents = "";
      let Curators = await curators.filter((j) => j.student_id === st._id);
      for (let curator of Curators) {
        let fullname = await getFullName(curator);
        txtParents += fullname + ",";
      }
      let txtPickups = "";
      let txtPickupsEn = "";
      let Pickups = await pickups.filter((j) => j.student_id === st._id);
      for (let pickup of Pickups) {
        let fullname = await getFullName(pickup);
        let fullname_en = getFullNameFormFields(
          pickup.prefix_en,
          pickup.first_name_en,
          pickup.middle_name_en,
          pickup.last_name_en
        );
        txtPickups +=
          pickup.priority +
          ". " +
          fullname +
          " " +
          pickup.phone_number +
          " (" +
          pickup.relation +
          "), ";
        txtPickupsEn +=
          pickup.priority +
          ". " +
          fullname_en +
          " " +
          pickup.phone_number +
          " (" +
          pickup.relation +
          "), ";
      }
      let obj = {
        no,
        student_code: st.student_code,
        fullname: await getFullName(st),
        nickname: st.nickname || "",
        parents: txtParents,
        pickup_name: txtPickups,
        pickup_name_en: txtPickupsEn,
      };
      no++;
      sheet.push(obj);
    }
    let ex = {
      filename: groupName,
      sheet_name: groupName,
      head: [
        { label: "ลำดับ", value: "no" },
        { label: "รหัส", value: "student_code" },
        { label: "ชื่อสกุล", value: "fullname" },
        { label: "ชื่อเล่น", value: "nickname" },
        { label: "ผู้ปกครอง", value: "parents" },
        { label: "ผู้ปกครองรับ-ส่ง", value: "pickup_name" },
        { label: "ผู้ปกครองรับ-ส่ง (Eng)", value: "pickup_name_en" },
      ],
      sheet: sheet,
    };
    let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
    cookie.export = ex;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));

    document.getElementById("printFrame").src =
      process.env.PUBLIC_URL + "/exportexcel";
  }
  return (
    <div>
      {students && (
        <>
          <PickupDialog
            data={pickupDialog}
            onClose={() => setPickupDialog({ open: false })}
          />
          <Grid container style={{ marginBottom: "20px" }}>
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Group"
              options={state.groups || []}
              onChange={(val) => setState({ ...state, group: val })}
              value={state.group}
              disabled_empty={true}
            />
            <Grid
              item
              xs={12}
              sm={6}
              md={9}
              lg={9}
              align="right"
              style={{ paddingTop: "5px" }}
            >
              {profile.reg_staff === true && (
                <Button
                  variant="outlined"
                  startIcon={<Icon>cloud_download</Icon>}
                  onClick={exportExcelAll}
                  style={{ marginRight: "10px" }}
                >
                  Download All
                </Button>
              )}

              <Button
                variant="outlined"
                startIcon={<Icon>download</Icon>}
                onClick={exportExcel}
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="th" style={{ width: "80px" }}>
                    ลำดับ
                  </TableCell>
                  <TableCell className="th" style={{ width: "100px" }}>
                    รหัส
                  </TableCell>
                  <TableCell className="th">ชื่อสกุล</TableCell>
                  <TableCell className="th" style={{ width: "100px" }}>
                    ชื่อเล่น
                  </TableCell>
                  <TableCell className="th" style={{ width: "250px" }}>
                    ผู้ปกครอง
                  </TableCell>
                  <TableCell className="th" style={{ width: "250px" }}>
                    ผู้ปกครองรับ-ส่ง
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, key) => (
                  <TableRow hover key={key}>
                    <TableCell
                      align="center"
                      className="td"
                      style={{ verticalAlign: "top" }}
                    >
                      {key + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td"
                      style={{ verticalAlign: "top" }}
                    >
                      {student.student_code}
                    </TableCell>
                    <TableCell className="td" style={{ verticalAlign: "top" }}>
                      {student.prefix} {student.first_name}{" "}
                      {student.middle_name} {student.last_name}
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ verticalAlign: "top" }}
                    >
                      {student.nickname}
                    </TableCell>
                    <TableCell
                      className="td"
                      style={{ verticalAlign: "top", fontSize: ".8rem" }}
                    >
                      {curators
                        .filter((j) => j.student_id === student._id)
                        .map((item, key) => (
                          <div key={key}>
                            <Button
                              style={{
                                padding: "0px",
                                margin: "0px",
                                fontSize: ".8rem",
                                textAlign: "left",
                              }}
                              onClick={() => openCurator(item)}
                            >
                              <div>
                                {item.prefix} {item.first_name} {item.last_name}{" "}
                                {decodeCuratorType(item)}
                              </div>
                            </Button>
                          </div>
                        ))}
                    </TableCell>
                    <TableCell className="td" style={{ verticalAlign: "top" }}>
                      {pickups
                        .filter((j) => j.student_id === student._id)
                        .map((item, key) => (
                          <div key={key}>
                            <Button
                              style={{
                                padding: "0px",
                                margin: "0px",
                                fontSize: ".8rem",
                                textAlign: "left",
                              }}
                              onClick={() =>
                                setPickupDialog({ ...item, ...{ open: true } })
                              }
                            >
                              <div>
                                {item.prefix} {item.first_name} {item.last_name}
                                {item.relation && <> ({item.relation})</>}
                              </div>
                            </Button>
                          </div>
                        ))}
                    </TableCell>
                  </TableRow>
                ))}
                {students.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      ไม่พบข้อมูล
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
