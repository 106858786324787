import React, { useEffect, useState } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MultiType from "../charts/MultiType";
import GroupStudent from "./rptStudent/GroupStudent";
import Brethren from "./rptStudent/Brethren";
import Resign from "./rptStudent/Resign";
import {
  checkPermission,
  getGroups,
  sortJSON,
  sortJSONDESC,
  // getAge,
  // getStampTime,
  diffYearMonthDay,
} from "../js/main";
import { GridSelect } from "../components/InputComponents";
import Pagebar from "../components/Pagebar";
import axios from "axios";
import Conf from "../Conf";
import { student_normal_status } from "../const";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Student(props) {
  const [profile, setProfile] = useState();
  const [state, setState] = useState({ report_no: 6 });
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) setProfile(props.profile);
      }
    })();
  }, [props]);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <br />
          <Grid container>
            <GridSelect
              grid={[12, 6, 5, 4]}
              label="รานงาน"
              disabled_empty={true}
              options={[
                { label: "นักเรียนแยกตามชั้นเรียน", value: "0" },
                { label: "นักเรียนแยกตามห้องเรียน", value: "7" },
                { label: "นักเรียนตามจังหวัด", value: "1" },
                { label: "นักเรียนตามระดับการศึกษา", value: "2" },
                { label: "อายุนักเรียนแยกตามชั้นเรียน", value: "3" },
                { label: "การได้รับเงินอุดหนุน", value: "4" },
                { label: "นักเรียนมอบตัวล่วงหน้า", value: "5" },
                { label: "พี่-น้อง", value: "6" },
                { label: "ลาออก/พักการเรียน/พ้นสภาพ", value: "8" },
              ]}
              onChange={(val) => setState({ ...state, report_no: val })}
              value={state.report_no || "-"}
            />
            <Grid item xs={12} style={{ paddingTop: "15px" }}>
              {
                {
                  0: <LevelReport />,
                  1: <ProvinceReport />,
                  2: <GradeReport />,
                  3: <AgeReport />,
                  4: <SubsidyReport />,
                  5: <AttendReport />,
                  6: <Brethren />,
                  7: <GroupStudent />,
                  8: <Resign />,
                }[state.report_no]
              }
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

function SubsidyReport() {
  const [state, setState] = useState({ skip: 0, limit: 30, subsidy: "เบิก" });
  const [page, setPage] = useState(1);
  const [students, setStudents] = useState({
    skip: 0,
    limit: 30,
    subsidy: "เบิก",
  });
  useEffect(() => {
    function getStudents() {
      return new Promise(async (resolve) => {
        let match = {
          $and: [{ student_status: student_normal_status }],
        };
        if (state.subsidy === "เบิก") {
          match["$and"].push({
            subsidy: state.subsidy,
          });
        } else {
          match["$and"].push({
            subsidy: { $ne: "เบิก" },
          });
        }
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match,
            select:
              "prefix first_name middle_name last_name idx subsidy student_code current_group birth_date",
            limit: state.limit,
            skip: state.skip,
            populate: "current_group",
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(students.data);
      });
    }
    (async () => {
      let students = await getStudents();
      setStudents(students);
    })();
  }, [state]);
  return (
    <div>
      {students && (
        <>
          <Grid container>
            <Grid item xs={6} className="bold" style={{ paddingTop: "7px" }}>
              Result : {students.count}
            </Grid>
            <Grid item xs={6} align="right">
              <FormControlLabel
                control={<Checkbox checked={state.subsidy === "เบิก"} />}
                onChange={(e) => {
                  setPage(1);
                  e.target.checked === true
                    ? setState({ ...state, subsidy: "เบิก", skip: 0 })
                    : setState({ ...state, subsidy: "", skip: 0 });
                }}
                label={<div>เบิกเงินอุดหนุน</div>}
              />
            </Grid>
          </Grid>
          <Pagebar
            size="small"
            page={page}
            count={students.count / state.limit}
            changePage={(val) => {
              setState({ ...state, skip: (val - 1) * state.limit });
              setPage(val);
            }}
          />
          <TableContainer component={Paper} style={{ marginTop: "15px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "60px" }}
                    rowSpan={2}
                  >
                    ลำดับ
                  </TableCell>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "170px" }}
                  >
                    เลขประจำตัวประชาชน
                  </TableCell>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "140px" }}
                  >
                    เลขประจำตัวนักเรียน
                  </TableCell>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "80px" }}
                  >
                    ชั้นเรียน
                  </TableCell>
                  <TableCell className="th" align="center">
                    ชื่อ-นามสกุล
                  </TableCell>
                  <TableCell
                    className="th"
                    align="center"
                    style={{ width: "120px" }}
                  >
                    วันเกิด
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students &&
                  students.result &&
                  students.result.map((student, key) => (
                    <TableRow key={key}>
                      <TableCell className="td" align="center">
                        {key + state.skip + 1}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {student.idx}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {student.student_code}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {student.current_group.name}
                      </TableCell>
                      <TableCell className="td">
                        {student.prefix} {student.first_name}{" "}
                        {student.middle_name} {student.last_name}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {student.birth_date}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

function AgeReport() {
  const [levels, setLevels] = useState([]);
  const ages = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  useEffect(() => {
    function getStudents() {
      return new Promise(async (resolve) => {
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: {
              $and: [
                { student_status: student_normal_status },
                { birth_date: { $exists: true } },
              ],
            },
            select: "birth_date current_group",
            limit: 0,
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(students.data.result);
      });
    }
    (async () => {
      let students = await getStudents();
      let groups = await getGroups();
      let levels = [];
      groups = await sortJSON(groups.groups, "level");
      for (let student of students) {
        let dt = new Date(student.birth_date);
        let age = Date.now() - dt.getTime();
        age = new Date(age);
        student.age = Math.abs(age.getUTCFullYear() - 1970);
      }
      for (let group of groups) {
        group.age = [];
        let level_name = group.level;
        if (group.level_year && group.level_year !== "-") {
          level_name += " " + group.level_year;
        }
        let level_ind = levels.findIndex((j) => j.label === level_name);
        if (level_ind < 0) {
          levels.push({ label: level_name, age: [], total: 0, less3: 0 });
          level_ind = levels.findIndex((j) => j.label === level_name);
        }
        let st = students.filter((j) => j.current_group === group._id);
        for (let student of st) {
          let age = student.age;
          if (!levels[level_ind].age[age]) levels[level_ind].age[age] = 1;
          else levels[level_ind].age[age]++;
          if (age < 3) levels[level_ind].less3++;
          levels[level_ind].total++;
        }
      }
      setLevels(levels);
    })();
  }, []);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="th"
                align="center"
                style={{ width: "50px" }}
                rowSpan={2}
              >
                ลำดับ
              </TableCell>
              <TableCell className="th" align="center" rowSpan={2}>
                ชั้นเรียน
              </TableCell>
              <TableCell
                className="th"
                align="center"
                colSpan={18}
                style={{ borderLeft: "1px solid #ccc" }}
              >
                จำนวนนักเรียน
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="th"
                align="center"
                style={{
                  width: "70px",
                  fontSize: ".7rem",
                  borderLeft: "1px solid #ccc",
                }}
              >
                ต่ำกว่า 3 ปี
              </TableCell>
              {ages.map((age) => (
                <TableCell
                  className="th"
                  align="center"
                  style={{
                    width: "40px",
                    fontSize: ".7rem",
                    borderLeft: "1px solid #ccc",
                  }}
                  key={age}
                >
                  {age} ปี
                </TableCell>
              ))}
              <TableCell
                className="th"
                align="center"
                style={{
                  width: "40px",
                  fontSize: ".7rem",
                  borderLeft: "1px solid #ccc",
                }}
              >
                รวม
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {levels.map((level, key) => (
              <TableRow key={key}>
                <TableCell className="td" align="center">
                  {key + 1}
                </TableCell>
                <TableCell className="td">{level.label}</TableCell>
                <TableCell
                  className="td"
                  align="center"
                  style={{
                    borderLeft: "1px solid #ccc",
                  }}
                >
                  {level.less3}
                </TableCell>
                {ages.map((age) => (
                  <TableCell
                    className="td"
                    align="center"
                    key={age}
                    style={{
                      borderLeft: "1px solid #ccc",
                    }}
                  >
                    {level.age[age]}
                  </TableCell>
                ))}
                <TableCell
                  className="td"
                  align="center"
                  style={{
                    borderLeft: "1px solid #ccc",
                  }}
                >
                  {level.total}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function GradeReport() {
  const [levels, setLevels] = useState();
  const [totals, setTotals] = useState();
  const [chartState, setChartState] = useState();
  useEffect(() => {
    function getLevels() {
      return new Promise(async (resolve) => {
        let groups = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          { collection: "groups", select: "-student_id -limit -advisors" },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(groups.data.result);
      });
    }

    function getStudents() {
      return new Promise(async (resolve) => {
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: { student_status: student_normal_status },
            select: "gender current_group",
            limit: 0,
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(students.data.result);
      });
    }
    function renderReport(levels, students) {
      return new Promise(async (resolve) => {
        let Totals = { male: 0, female: 0, sum: 0 };
        for (let level of levels) {
          let Students = students.filter((j) => j.current_group === level._id);
          level.total_student = Students.length;
          level.total_male_student = 0;
          level.total_female_student = 0;
          for (let student of Students) {
            if (student.gender === "ชาย") {
              level.total_male_student++;
              Totals.male++;
            } else if (student.gender === "หญิง") {
              level.total_female_student++;
              Totals.female++;
            }
            Totals.sum++;
          }
        }
        setTotals(Totals);
        let Levels = [];
        for (let level of levels) {
          let level_name = level.level;
          let i = Levels.findIndex((j) => j.name === level_name);
          if (i < 0) {
            Levels.push({
              name: level_name,
              total: level.total_student,
              male: level.total_male_student,
              female: level.total_female_student,
            });
          } else {
            Levels[i].total += level.total_student;
            Levels[i].male += level.total_male_student;
            Levels[i].female += level.total_female_student;
          }
        }
        resolve(Levels);
      });
    }
    (async () => {
      let Levels = await getLevels();
      let Students = await getStudents();
      Levels = await renderReport(Levels, Students);
      setLevels(Levels);
      setChartState({
        title: "จำนวนนักเรียน",
        labels: await Levels.map((level) => level.name),
        datasets: [
          {
            type: "bar",
            label: "ชาย",
            backgroundColor: "rgb(75, 192, 192)",
            data: await Levels.map((level) => level.male),
            borderColor: "white",
          },
          {
            type: "bar",
            label: "หญิง",
            backgroundColor: "rgb(53, 162, 235)",
            data: await Levels.map((level) => level.female),
            borderColor: "white",
          },
          {
            type: "bar",
            label: "รวม",
            backgroundColor: "rgb(255, 99, 132)",
            data: await Levels.map((level) => level.total),
            borderColor: "white",
          },
        ],
      });
    })();
  }, []);
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ width: "60px" }}
                      rowSpan={2}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell className="th" align="center" rowSpan={2}>
                      ชั้นเรียน
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      colSpan={3}
                      style={{ borderLeft: "1px solid #ccc" }}
                    >
                      จำนวนนักเรียน
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px", borderLeft: "1px solid #ccc" }}
                    >
                      ชาย
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px" }}
                    >
                      หญิง
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px" }}
                    >
                      รวม
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {levels &&
                    levels.map((level, key) => (
                      <TableRow key={key}>
                        <TableCell className="td" align="center">
                          {key + 1}
                        </TableCell>
                        <TableCell className="td">{level.name}</TableCell>
                        <TableCell
                          className="td"
                          align="center"
                          style={{ borderLeft: "1px solid #ccc" }}
                        >
                          {level.male}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {level.female}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {level.total}
                        </TableCell>
                      </TableRow>
                    ))}
                  {totals && (
                    <>
                      <TableRow>
                        <TableCell colSpan={2} className="bold" align="center">
                          รวมทั้งหมด
                        </TableCell>
                        <TableCell className="bold" align="center">
                          {totals.male}
                        </TableCell>
                        <TableCell className="bold" align="center">
                          {totals.female}
                        </TableCell>
                        <TableCell className="bold" align="center">
                          {totals.sum}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper style={{ padding: "10px 10px" }}>
            {chartState && <MultiType state={chartState} />}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

function LevelReport() {
  const [levels, setLevels] = useState();
  const [totals, setTotals] = useState();
  const [chartState, setChartState] = useState();
  useEffect(() => {
    function getLevels() {
      return new Promise(async (resolve) => {
        let groups = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          { collection: "groups", select: "-student_id -limit -advisors" },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(groups.data.result);
      });
    }

    function getStudents() {
      return new Promise(async (resolve) => {
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: { student_status: student_normal_status },
            select: "gender current_group",
            limit: 0,
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(students.data.result);
      });
    }
    function renderReport(levels, students) {
      return new Promise(async (resolve) => {
        let Totals = { male: 0, female: 0, sum: 0 };
        for (let level of levels) {
          let Students = students.filter((j) => j.current_group === level._id);
          level.total_student = Students.length;
          level.total_male_student = 0;
          level.total_female_student = 0;
          for (let student of Students) {
            if (student.gender === "ชาย") {
              level.total_male_student++;
              Totals.male++;
            } else if (student.gender === "หญิง") {
              level.total_female_student++;
              Totals.female++;
            }
            Totals.sum++;
          }
        }
        setTotals(Totals);
        let Levels = [];
        for (let level of levels) {
          let level_name = level.level;
          if (level.level_year && level.level_year !== "-") {
            level_name += " " + level.level_year;
          }
          let i = Levels.findIndex((j) => j.name === level_name);
          if (i < 0) {
            Levels.push({
              name: level_name,
              total: level.total_student,
              male: level.total_male_student,
              female: level.total_female_student,
            });
          } else {
            Levels[i].total += level.total_student;
            Levels[i].male += level.total_male_student;
            Levels[i].female += level.total_female_student;
          }
        }
        resolve(Levels);
      });
    }
    (async () => {
      let Levels = await getLevels();
      let Students = await getStudents();
      Levels = await renderReport(Levels, Students);
      setLevels(Levels);
      setChartState({
        title: "จำนวนนักเรียน",
        labels: await Levels.map((level) => level.name),
        datasets: [
          {
            type: "bar",
            label: "ชาย",
            backgroundColor: "rgb(75, 192, 192)",
            data: await Levels.map((level) => level.male),
            borderColor: "white",
          },
          {
            type: "bar",
            label: "หญิง",
            backgroundColor: "rgb(53, 162, 235)",
            data: await Levels.map((level) => level.female),
            borderColor: "white",
          },
          {
            type: "bar",
            label: "รวม",
            backgroundColor: "rgb(255, 99, 132)",
            data: await Levels.map((level) => level.total),
            borderColor: "white",
          },
        ],
      });
    })();
  }, []);
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ width: "60px" }}
                      rowSpan={2}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell className="th" align="center" rowSpan={2}>
                      ชั้นเรียน
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      colSpan={3}
                      style={{ borderLeft: "1px solid #ccc" }}
                    >
                      จำนวนนักเรียน
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px", borderLeft: "1px solid #ccc" }}
                    >
                      ชาย
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px" }}
                    >
                      หญิง
                    </TableCell>
                    <TableCell
                      className="th"
                      align="center"
                      style={{ minWidth: "80px" }}
                    >
                      รวม
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {levels &&
                    levels.map((level, key) => (
                      <TableRow key={key}>
                        <TableCell className="td" align="center">
                          {key + 1}
                        </TableCell>
                        <TableCell className="td">{level.name}</TableCell>
                        <TableCell
                          className="td"
                          align="center"
                          style={{ borderLeft: "1px solid #ccc" }}
                        >
                          {level.male}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {level.female}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {level.total}
                        </TableCell>
                      </TableRow>
                    ))}
                  {totals && (
                    <TableRow>
                      <TableCell colSpan={2} className="bold" align="center">
                        รวมทั้งหมด
                      </TableCell>
                      <TableCell className="bold" align="center">
                        {totals.male}
                      </TableCell>
                      <TableCell className="bold" align="center">
                        {totals.female}
                      </TableCell>
                      <TableCell className="bold" align="center">
                        {totals.sum}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper style={{ padding: "10px 10px" }}>
            {chartState && <MultiType state={chartState} />}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

function ProvinceReport() {
  const [provinces, setProvinces] = useState([]);
  useEffect(() => {
    function getStudents() {
      return new Promise(async (resolve) => {
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: { student_status: student_normal_status },
            select: "province district",
            sort: { province: 1, district: 1 },
            limit: 0,
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        resolve(students.data.result);
      });
    }
    function getProvinces(students) {
      return new Promise(async (resolve) => {
        let provinces = [];
        for (let student of students) {
          let i = provinces.findIndex((j) => j.label === student.province);
          if (i < 0) {
            let districts = [];
            let pv = students.filter((j) => j.province === student.province);
            for (let p of pv) {
              let i = districts.findIndex((j) => j.label === p.district);
              if (i < 0) districts.push({ label: p.district, count: 1 });
              else districts[i].count++;
            }
            provinces.push({ label: student.province, districts });
          }
        }
        resolve(provinces);
      });
    }
    (async () => {
      let students = await getStudents();
      let provinces = await getProvinces(students);
      setProvinces(provinces);
    })();
  }, []);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="th"
                align="center"
                style={{ width: "80px" }}
              >
                ลำดับ
              </TableCell>
              <TableCell className="th" align="center">
                จังหวัด
              </TableCell>
              <TableCell
                className="th"
                align="center"
                style={{ width: "150px" }}
              >
                จำนวน
              </TableCell>
            </TableRow>
          </TableHead>
          {provinces.map((province, key) => (
            <TableBody key={key}>
              <TableRow hover>
                <TableCell align="center" className="td bold">
                  {key + 1}
                </TableCell>
                <TableCell className="td bold">
                  {province.label || "ไม่ระบุ"}
                </TableCell>
                <TableCell className="td" />
              </TableRow>
              {province.districts.map((district, key) => (
                <TableRow hover key={key} style={{ background: "#f5f5f5" }}>
                  <TableCell className="td" />
                  <TableCell className="td">
                    {district.label || "ไม่ระบุ"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {district.count}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </div>
  );
}

function AttendReport() {
  const [state, setState] = useState();
  const [selectAll, setSelectAll] = useState(true);
  const [schoolYears, setSchoolYears] = useState();
  const [oriStudents, setOriStudents] = useState();
  const [students, setStudents] = useState();
  useEffect(() => {
    (async () => {
      let years = await axios.post(
        Conf.endpoint.tonkla + "/reg/groupquery",
        {
          collection: "registers",
          field: "attending_year",
        },
        {
          headers: {
            Authorization: cookie.access_token,
          },
        }
      );
      years = await sortJSONDESC(
        years.data.filter((j) => j._id),
        "_id"
      );
      years = await years
        .filter((j) => j._id && j._id !== "-")
        .map((j) => {
          return { label: parseInt(j._id) + 543, value: j._id };
        });
      setSchoolYears(years);
      setState({ term: "1", school_year: years ? years[0].value : "-" });
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (state) {
        let students = await axios.post(
          Conf.endpoint.tonkla + "/reg/query",
          {
            collection: "students",
            match: {
              $and: [
                { register: { $exists: true } },
                { register: { $ne: null } },
              ],
            },
            select:
              "prefix first_name middle_name last_name nickname register birth_date current_group",
            populate: {
              path: "register",
              select: "attending_year term level grade_year",
              match: {
                $and: [
                  { attending_year: state.school_year },
                  { term: state.term },
                ],
              },
            },
            limit: 0,
          },
          {
            headers: {
              Authorization: cookie.access_token,
            },
          }
        );
        students.data.result = students.data.result.filter(
          (j) => j.register !== null
        );
        let now = state.school_year + "-05-16";
        now = new Date(now);
        students.data.result = await students.data.result.map((student) => {
          (async () => {
            let dt = new Date(student.birth_date);
            let age = await diffYearMonthDay(dt, now);
            if (age.year * 12 > age.month) age.year--;
            let month = age.month - age.year * 12;
            // let age = await getAge(dt, now);
            student.age = age.year + "." + month;
          })();
          return student;
        });
        setOriStudents(students.data);

        if (selectAll === false) {
          students.data.result = students.data.result.filter(
            (j) => !j.current_group
          );
        }
        setStudents(students.data);
      }
    })();
  }, [state, selectAll]);

  function getSelectAll(status) {
    let Students = JSON.parse(JSON.stringify(oriStudents));
    if (status === false) {
      Students.result = Students.result.filter((j) => !j.current_group);
    }
    setStudents(Students);
    setSelectAll(status);
  }
  return (
    <div>
      {students && (
        <>
          <Grid container spacing={1} style={{ marginTop: "10px" }}>
            <GridSelect
              grid={[6, 4, 3, 2]}
              label="ภาคเรียนที่"
              disabled_empty={true}
              options={[{ label: "1" }, { label: "2" }]}
              onChange={(val) => setState({ ...state, term: val })}
              value={state.term || "-"}
            />
            <GridSelect
              grid={[6, 8, 4, 3]}
              label="ปีการศึกษา"
              disabled_empty={true}
              options={schoolYears}
              onChange={(val) => setState({ ...state, school_year: val })}
              value={state.school_year || "-"}
            />
            <Grid item xs={12} sm={12} md={5} lg={7} align="right">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => getSelectAll(e.target.checked)}
                  />
                }
                label={<div>แสดงทั้งหมด</div>}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="th" style={{ width: "80px" }}>
                        ลำดับ
                      </TableCell>
                      <TableCell className="th">ชื่อ -นามสุกล</TableCell>
                      <TableCell className="th" style={{ width: "180px" }}>
                        ชื่อเล่น
                      </TableCell>
                      <TableCell className="th" style={{ width: "100px" }}>
                        ระดับชั้น
                      </TableCell>
                      <TableCell className="th" style={{ width: "200px" }}>
                        โปรแกรม
                      </TableCell>
                      <TableCell className="th" style={{ width: "100px" }}>
                        วันเกิด
                      </TableCell>
                      <TableCell className="th" style={{ width: "120px" }}>
                        อายุ ณ 16 พค.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students &&
                      students.result.map((student, key) => (
                        <TableRow key={key}>
                          <TableCell className="td" align="center">
                            {key + 1}
                          </TableCell>
                          <TableCell className="td">
                            {student.prefix} {student.first_name}{" "}
                            {student.middle_name} {student.last_name}
                          </TableCell>
                          <TableCell className="td">
                            {student.nickname}
                          </TableCell>
                          <TableCell className="td" align="center">
                            {student.register.level}{" "}
                            {student.register.grade_year !== "-" &&
                              student.register.grade_year}
                          </TableCell>
                          <TableCell className="td" align="center">
                            {student.program}
                          </TableCell>
                          <TableCell className="td" align="center">
                            {student.birth_date}
                          </TableCell>
                          <TableCell className="td" align="center">
                            {student.age}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
