const on_dev = false;
module.exports = {
  fbKey: "AIzaSyCr1Z1WoR-tWAvx0uMVdowq2RUgHHna6dk",
  fbAuthDomain: "tonkla-a1378.firebaseapp.com",
  firebaseConfig: {
    apiKey: "AIzaSyCr1Z1WoR-tWAvx0uMVdowq2RUgHHna6dk",
    authDomain: "tonkla-a1378.firebaseapp.com",
    databaseURL: "https://tonkla-a1378.firebaseio.com",
    projectId: "tonkla-a1378",
    storageBucket: "tonkla-a1378.appspot.com",
    messagingSenderId: "1094459691353",
    appId: "1:1094459691353:web:df251052b61fb6c9babc48",
  },
  endpoint: {
    tonkla:
      on_dev === true
        ? "http://localhost:3001"
        : // "https://tonkla-backend.azurewebsites.net"
          "https://tonkla-backend.azurewebsites.net",
    upload: "https://tca.tonkla.ac.th/upload",
    fileUpload: "https://tca.tonkla.ac.th/upload/file.php",
  },
  cookie_name: "tonkla-cookie",
};
