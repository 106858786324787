import React, { useEffect, useState, Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment/moment";
import { director_name, registrar_name } from "../const";
import { fetchData, sortJSON } from "../js/main";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";

function getAbsentPersons(from_date, to_date) {
  return new Promise(async (resolve) => {
    let checkins = await fetchData("post", "/query", {
      collection: "checkins",
      match: {
        $and: [
          { check_date: { $gte: from_date } },
          { check_date: { $lte: to_date } },
        ],
      },
      populate: {
        path: "student_id",
        select: "prefix first_name middle_name last_name gender",
      },
      populate2: {
        path: "group_id",
        select: "level level_abbrev level_year",
      },
    });
    checkins = checkins.result;
    resolve(checkins);
  });
}

function getDayLevelFacets(checkins, arrLevels) {
  return new Promise(async (resolve) => {
    let levels = JSON.parse(JSON.stringify(arrLevels));
    for (let i = 0; i < levels.length; i++) {
      let level = levels[i];
      let check_date = checkins.filter(
        (j) =>
          j.group_id.level === level.level &&
          j.group_id.level_year === level.level_year
      );
      let student_female_count = check_date.filter(
        (j) => j.student_id.gender === "หญิง"
      );
      let student_male_count = check_date.filter(
        (j) => j.student_id.gender === "ชาย"
      );
      let present_count = check_date.filter(
        (j) => j.status === "ม" || j.status === "ส"
      );
      levels[i].student_count = check_date.length;
      levels[i].student_female_count = student_female_count.length;
      levels[i].student_male_count = student_male_count.length;

      levels[i].sick_count = check_date.filter((j) => j.status === "ป").length;
      levels[i].sick_female_count = student_female_count.filter(
        (j) => j.status === "ป"
      ).length;
      levels[i].sick_male_count = student_male_count.filter(
        (j) => j.status === "ป"
      ).length;

      levels[i].business_count = check_date.filter(
        (j) => j.status === "ล"
      ).length;
      levels[i].business_female_count = student_female_count.filter(
        (j) => j.status === "ล"
      ).length;
      levels[i].business_male_count = student_male_count.filter(
        (j) => j.status === "ล"
      ).length;

      levels[i].leave_count = check_date.filter((j) => j.status === "ข").length;
      levels[i].leave_female_count = student_female_count.filter(
        (j) => j.status === "ข"
      ).length;
      levels[i].leave_male_count = student_male_count.filter(
        (j) => j.status === "ข"
      ).length;

      levels[i].present_count = present_count.length;
      levels[i].present_female_count = student_female_count.filter(
        (j) => j.status === "ม" || j.status === "ส"
      ).length;
      levels[i].present_male_count = student_male_count.filter(
        (j) => j.status === "ม" || j.status === "ส"
      ).length;

      levels[i].absent_persons = check_date.filter(
        (j) => j.status === "ข" || j.status === "ล" || j.status === "ป"
      );
      levels[i].present_percent = (
        (present_count.length / check_date.length) *
        100
      ).toFixed(2);
    }
    resolve(levels);
  });
}

function groupLevel(checkins) {
  return new Promise(async (resolve) => {
    let levels = [];

    for (let i = 0; i < checkins.length; i++) {
      let checkin = checkins[i];
      let level =
        checkin.group_id.level_year === "-"
          ? checkin.group_id.level
          : checkin.group_id.level + " " + checkin.group_id.level_year;
      let j = levels.findIndex((j) => j.label === level);
      if (j < 0) {
        levels.push({
          label: level,
          level: checkin.group_id.level,
          level_year: checkin.group_id.level_year,
        });
      }
    }
    levels = await sortJSON(levels, "label");
    resolve(levels);
  });
}

function getFacets(checkins) {
  return new Promise(async (resolve) => {
    let facets = {
      check_date: [],
    };
    let levels = await groupLevel(checkins);
    for (let i = 0; i < checkins.length; i++) {
      let checkin = checkins[i];
      let j = facets.check_date.findIndex(
        (j) => j.label === checkin.check_date
      );
      if (j < 0) {
        let check_date = checkins.filter(
          (j) => j.check_date === checkin.check_date
        );
        let student_female_count = check_date.filter(
          (j) => j.student_id.gender === "หญิง"
        );
        let student_male_count = check_date.filter(
          (j) => j.student_id.gender === "ชาย"
        );
        let present_count = check_date.filter(
          (j) => j.status === "ม" || j.status === "ส"
        );
        let newCheckDate = {
          label: checkin.check_date,
          student_count: check_date.length,
          student_female_count: student_female_count.length,
          student_male_count: student_male_count.length,
          sick_count: check_date.filter((j) => j.status === "ป").length,
          sick_female_count: student_female_count.filter(
            (j) => j.status === "ป"
          ).length,
          sick_male_count: student_male_count.filter((j) => j.status === "ป")
            .length,

          business_count: check_date.filter((j) => j.status === "ล").length,
          business_female_count: student_female_count.filter(
            (j) => j.status === "ล"
          ).length,
          business_male_count: student_male_count.filter(
            (j) => j.status === "ล"
          ).length,

          leave_count: check_date.filter((j) => j.status === "ข").length,
          leave_female_count: student_female_count.filter(
            (j) => j.status === "ข"
          ).length,
          leave_male_count: student_male_count.filter((j) => j.status === "ข")
            .length,

          present_count: present_count.length,
          present_female_count: student_female_count.filter(
            (j) => j.status === "ม" || j.status === "ส"
          ).length,
          present_male_count: student_male_count.filter(
            (j) => j.status === "ม" || j.status === "ส"
          ).length,
          present_percent: (
            (present_count.length / check_date.length) *
            100
          ).toFixed(2),
        };
        newCheckDate.levels = await getDayLevelFacets(check_date, levels);
        facets.check_date.push(newCheckDate);
      }
    }
    resolve(facets);
  });
}

export default function RptOverallCheckPresent() {
  const [report, setReport] = useState();
  const params = useParams();
  useEffect(() => {
    (async () => {
      let { from_date, to_date } = params;
      let checkins = await getAbsentPersons(from_date, to_date);
      let facets = await getFacets(checkins);
      setReport(facets);
    })();
  }, [params]);

  useEffect(() => {
    if (report) window.print();
  }, [report]);

  return (
    <div style={{ width: "20cm", margin: "auto" }}>
      {report &&
        report.check_date.map((rpt, key) => (
          <RptOverallDaily key={key} report={rpt} />
        ))}
    </div>
  );
}

const styles = {
  td: { fontSize: "9pt", padding: "3px 3px !important" },
};
function RptOverallDaily({ report }) {
  const fields = [
    "student_male_count",
    "student_female_count",
    "student_count",
    "business_male_count",
    "business_female_count",
    "sick_male_count",
    "sick_female_count",
    "leave_male_count",
    "leave_female_count",
    "present_male_count",
    "present_female_count",
    "present_count",
    "present_percent",
  ];
  return (
    <div style={{ pageBreakBefore: "always" }}>
      <div style={{ textAlign: "center" }}>
        <div className="bold">โรงเรียน​ต้น​กล้า</div>
        <div className="bold">รายงาน​สถิติ​การ​มา​เรี​ยน</div>
        <div className="bold">
          ประ​จําวัน {moment(report.label).format("dddd, DD MMMM YYYY")}
        </div>
      </div>

      <TableContainer sx={{ marginTop: ".5cm", marginBottom: ".3cm" }}>
        <Table sx={{ border: "1px solid #111" }}>
          <ReportTableHead />
          <TableBody>
            {report.levels.map((level, key) => (
              <LevelRow key={key} level={level} />
            ))}
            <TableRow>
              <TableCell
                sx={styles.td}
                className="bold"
                style={{ borderBottom: "1px solid #111" }}
                align="center"
              >
                รวม
              </TableCell>
              {fields.map((field, key) => (
                <TableCell
                  key={key}
                  sx={styles.td}
                  align="center"
                  style={{
                    borderBottom: "1px solid #111",
                    borderLeft: "1px solid #111",
                  }}
                  className="bold"
                >
                  {report[field]}
                </TableCell>
              ))}
              <TableCell
                sx={styles.td}
                style={{
                  borderBottom: "1px solid #111",
                  borderLeft: "1px solid #111",
                }}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div style={styles.td} className="bold">
        ราย​ชี่อ​นักเรียน​ที่ ขาด​ ลา ​มา​สาย
      </div>
      <TableContainer sx={{ marginTop: ".2cm", border: "none" }}>
        <Table>
          <TableBody>
            {report.levels.map((level, key) => (
              <AbsentList key={key} level={level} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container sx={{ marginTop: "2cm", fontSize: "9pt" }}>
        <Grid item xs={6} align="center">
          <div>
            ................................................................................
          </div>
          <div style={{ marginTop: ".1cm" }}>({registrar_name})</div>
          <div>นายทะเบียน</div>
        </Grid>
        <Grid item xs={6} align="center">
          <div>
            ................................................................................
          </div>
          <div style={{ marginTop: ".1cm" }}>({director_name})</div>
          <div>ผู้อำนวยการ</div>
        </Grid>
      </Grid>
    </div>
  );
}

function AbsentList({ level }) {
  return (
    <TableRow>
      <TableCell
        sx={styles.td}
        style={{ border: "none", width: "3cm", verticalAlign: "top" }}
        align="center"
      >
        {level.label}
      </TableCell>
      <TableCell
        sx={styles.td}
        style={{
          border: "none",
          borderBottom: ".5px dotted #aaa",
        }}
      >
        {level.absent_persons.map((person, key) => (
          <span key={key}>
            {key + 1}.{person.student_id.prefix}
            {person.student_id.first_name} {person.student_id.last_name} (
            {person.status}){" "}
          </span>
        ))}
      </TableCell>
    </TableRow>
  );
}

function LevelRow({ level }) {
  const fields = [
    "student_male_count",
    "student_female_count",
    "student_count",
    "business_male_count",
    "business_female_count",
    "sick_male_count",
    "sick_female_count",
    "leave_male_count",
    "leave_female_count",
    "present_male_count",
    "present_female_count",
    "present_count",
    "present_percent",
  ];
  return (
    <TableRow>
      <TableCell sx={styles.td} style={{ borderBottom: "1px solid #111" }}>
        {level.label}
      </TableCell>
      {fields.map((field, key) => (
        <TableCell
          key={key}
          sx={styles.td}
          align="center"
          style={{
            borderBottom: "1px solid #111",
            borderLeft: "1px solid #111",
          }}
        >
          {level[field]}
        </TableCell>
      ))}
      <TableCell
        sx={styles.td}
        style={{
          borderBottom: "1px solid #111",
          borderLeft: "1px solid #111",
        }}
      />
    </TableRow>
  );
}

function ReportTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          className="td"
          align="center"
          style={{
            ...styles.td,
            ...{ width: "90px", borderBottom: "1px solid #111" },
          }}
          rowSpan={3}
        >
          ระดับชั้น
        </TableCell>
        <TableCell
          className="controlTd"
          align="center"
          rowSpan={2}
          colSpan={3}
          style={{
            ...styles.td,
            ...{
              borderLeft: "1px solid #111",
              borderBottom: "1px solid #111",
            },
          }}
        >
          จำนวนนักเรียนเต็ม
        </TableCell>
        <TableCell
          className="controlTd"
          align="center"
          colSpan={6}
          style={{
            ...styles.td,
            ...{
              borderLeft: "1px solid #111",
              borderBottom: "1px solid #111",
            },
          }}
        >
          จำนวนนักเรียนที่ไม่มาเรียน
        </TableCell>
        <TableCell
          className="controlTd"
          align="center"
          rowSpan={2}
          colSpan={3}
          style={{
            ...styles.td,
            ...{
              borderLeft: "1px solid #111",
              borderBottom: "1px solid #111",
            },
          }}
        >
          จำนวนที่มาเรียน
        </TableCell>
        <TableCell
          className="controlTd"
          align="center"
          rowSpan={3}
          style={{
            ...styles.td,
            ...{
              borderLeft: "1px solid #111",
              borderBottom: "1px solid #111",
            },
          }}
        >
          ร้อยละ
        </TableCell>
        <TableCell
          className="td"
          align="center"
          style={{
            ...styles.td,
            ...{
              borderLeft: "1px solid #111",
              borderBottom: "1px solid #111",
            },
          }}
          rowSpan={3}
        >
          หมายเหตุ
        </TableCell>
      </TableRow>
      <TableRow>
        {["ลากิจ", "ลาป่วย", "ขาด"].map((item, key) => (
          <TableCell
            align="center"
            className="controlTd"
            key={key}
            colSpan={2}
            style={{
              ...styles.td,
              ...{
                width: "40px",
                borderLeft: "1px solid #111",
                borderBottom: "1px solid #111",
              },
            }}
          >
            {item}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {["ชาย", "หญิง", "รวม"].map((gender, key) => (
          <TableCell
            key={key}
            align="center"
            className="controlTd"
            style={{
              ...styles.td,
              ...{
                width: "40px",
                borderLeft: "1px solid #111",
                borderBottom: "1px solid #111",
              },
            }}
          >
            {gender}
          </TableCell>
        ))}

        {["ลากิจ", "ลาป่วย", "ขาด"].map((item, key) => (
          <Fragment key={key}>
            <TableCell
              align="center"
              className="controlTd"
              style={{
                ...styles.td,
                ...{
                  width: "40px",
                  borderLeft: "1px solid #111",
                  borderBottom: "1px solid #111",
                },
              }}
            >
              ชาย
            </TableCell>
            <TableCell
              align="center"
              className="controlTd"
              style={{
                ...styles.td,
                ...{
                  width: "40px",
                  borderLeft: "1px solid #111",
                  borderBottom: "1px solid #111",
                },
              }}
            >
              หญิง
            </TableCell>
          </Fragment>
        ))}
        {["ชาย", "หญิง", "รวม"].map((gender, key) => (
          <TableCell
            key={key}
            align="center"
            className="controlTd"
            style={{
              ...styles.td,
              ...{
                width: "40px",
                borderLeft: "1px solid #111",
                borderBottom: "1px solid #111",
              },
            }}
          >
            {gender}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/*
function RptOverallDaily({ report, sumFields }) {
  const styles = {
    td: { fontSize: "9pt", padding: "3px 3px !important" },
  };
  return (
    <Grid container style={{ pageBreakBefore: "always" }}>
      <Grid item xs={12} style={{ paddingTop: "0px" }} align="center">
        <div className="bold" style={{ fontSize: "14pt" }}>
          โรงเรียน​ต้น​กล้า
        </div>
        <div className="bold" style={{ fontSize: "14pt" }}>
          รายงาน​สถิติ​การ​มา​เรี​ยน
        </div>
        <div className="bold" style={{ fontSize: "14pt" }}>
          ประ​จําวัน {moment(report.date_report).format("dddd, DD MMMM YYYY")}
        </div>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: ".5cm" }}>
        <TableContainer>
          <Table sx={{ border: "1px solid #111" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  className="td"
                  align="center"
                  style={{
                    ...styles.td,
                    ...{ width: "40px", borderBottom: "1px solid #111" },
                  }}
                  rowSpan={2}
                >
                  ลำดับ
                </TableCell>
                <TableCell
                  className="td"
                  align="center"
                  rowSpan={2}
                  sx={styles.td}
                  style={{
                    borderLeft: "1px solid #111",
                    borderBottom: "1px solid #111",
                    width: "80px",
                  }}
                >
                  กลุ่มเรียน
                </TableCell>
                <TableCell
                  className="controlTd"
                  align="center"
                  colSpan={3}
                  style={{
                    ...styles.td,
                    ...{
                      borderLeft: "1px solid #111",
                      borderBottom: "1px solid #111",
                    },
                  }}
                >
                  จำนวนเต็ม
                </TableCell>
                <TableCell
                  className="controlTd"
                  align="center"
                  colSpan={3}
                  style={{
                    ...styles.td,
                    ...{
                      borderLeft: "1px solid #111",
                      borderBottom: "1px solid #111",
                    },
                  }}
                >
                  จำนวนมาเรียน
                </TableCell>
                <TableCell
                  className="controlTd"
                  align="center"
                  colSpan={3}
                  style={{
                    ...styles.td,
                    ...{
                      borderLeft: "1px solid #111",
                      borderBottom: "1px solid #111",
                    },
                  }}
                >
                  จำนวนขาดเรียน
                </TableCell>
                <TableCell
                  className="td"
                  align="center"
                  style={{
                    ...styles.td,
                    ...{
                      borderLeft: "1px solid #111",
                      borderBottom: "1px solid #111",
                    },
                  }}
                  rowSpan={2}
                >
                  หมายเหตุ
                </TableCell>
              </TableRow>
              <TableRow>
                {[0, 1, 2].map((status, key) => (
                  <Fragment key={key}>
                    <TableCell
                      align="center"
                      className="controlTd"
                      style={{
                        ...styles.td,
                        ...{
                          width: "40px",
                          borderLeft: "1px solid #111",
                          borderBottom: "1px solid #111",
                        },
                      }}
                    >
                      รวม
                    </TableCell>
                    <TableCell
                      align="center"
                      className="controlTd"
                      style={{
                        ...styles.td,
                        ...{
                          width: "40px",
                          borderBottom: "1px solid #111",
                        },
                      }}
                    >
                      ชาย
                    </TableCell>
                    <TableCell
                      align="center"
                      className="controlTd"
                      style={{
                        ...styles.td,
                        ...{ width: "40px", borderBottom: "1px solid #111" },
                      }}
                    >
                      หญิง
                    </TableCell>
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {report.groups.map((group, key) => (
                <TableRow key={key} hover>
                  <TableCell
                    className="td"
                    align="center"
                    sx={styles.td}
                    style={{ borderBottom: "1px solid #111" }}
                  >
                    {key + 1}
                  </TableCell>
                  <TableCell
                    className="td"
                    align="center"
                    sx={styles.td}
                    style={{
                      borderLeft: "1px solid #111",
                      borderBottom: "1px solid #111",
                    }}
                  >
                    {group.name}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    sx={styles.td}
                    style={{
                      borderLeft: "1px solid #111",
                      borderBottom: "1px solid #111",
                    }}
                  >
                    {group.summations.student_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    style={{ borderBottom: "1px solid #111" }}
                    sx={styles.td}
                  >
                    {group.summations.student_male_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    style={{ borderBottom: "1px solid #111" }}
                    sx={styles.td}
                  >
                    {group.summations.student_female_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    style={{
                      ...styles.td,
                      ...{
                        borderLeft: "1px solid #111",
                        borderBottom: "1px solid #111",
                      },
                    }}
                  >
                    {group.summations.present_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    sx={styles.td}
                    style={{ borderBottom: "1px solid #111" }}
                  >
                    {group.summations.present_male_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    sx={styles.td}
                    style={{ borderBottom: "1px solid #111" }}
                  >
                    {group.summations.present_female_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    style={{
                      ...styles.td,
                      ...{
                        borderLeft: "1px solid #111",
                        borderBottom: "1px solid #111",
                      },
                    }}
                  >
                    {group.summations.absent_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    sx={styles.td}
                    style={{ borderBottom: "1px solid #111" }}
                  >
                    {group.summations.absent_male_count}
                  </TableCell>
                  <TableCell
                    className="controlTd"
                    align="center"
                    sx={styles.td}
                    style={{ borderBottom: "1px solid #111" }}
                  >
                    {group.summations.absent_female_count}
                  </TableCell>
                  <TableCell
                    className="td"
                    style={{
                      ...styles.td,
                      ...{
                        borderLeft: "1px solid #111",
                        borderBottom: "1px solid #111",
                      },
                    }}
                  />
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  align="center"
                  className="bold"
                  colSpan={2}
                  sx={styles.td}
                  style={{ borderBottom: "1px solid #111" }}
                >
                  รวม
                </TableCell>
                {sumFields.map((field, key) => (
                  <TableCell
                    align="center"
                    key={key}
                    sx={styles.td}
                    style={
                      key % 3 === 0
                        ? {
                            borderLeft: "1px solid #111",
                            borderBottom: "1px solid #111",
                          }
                        : { borderBottom: "1px solid #111" }
                    }
                  >
                    <div className="bold">{report.summations[field]}</div>
                  </TableCell>
                ))}
                <TableCell
                  className="td"
                  style={{
                    borderLeft: "1px solid #111",
                    borderBottom: "1px solid #111",
                  }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        item
        xs={12}
        className="bold"
        style={{ marginTop: ".3cm" }}
        sx={styles.td}
      >
        รายชื่อผู้ขาดเรียน
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableBody>
            {report.groups.map((group, key) => (
              <TableRow key={key} hover>
                <TableCell
                  className="td"
                  align="center"
                  sx={styles.td}
                  style={{ width: "120px", border: "none" }}
                >
                  ห้อง {group.name}
                </TableCell>
                <TableCell
                  className="td"
                  sx={styles.td}
                  style={{ width: "40px", border: "none" }}
                >
                  ได้แก่
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "1px solid #111",
                    padding: ".2cm .2cm",
                    fontSize: "9pt",
                  }}
                >
                  {group.absent_persons.map((person, key) => (
                    <span
                      style={{
                        marginRight: "5px",
                      }}
                      key={key}
                    >
                      {person.fullname} ({person.status}),
                    </span>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
*/
