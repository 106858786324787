import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

import { fetchData } from "../../js/main";

export default function SyncStudent({ logs, student_id }) {
  const [student, setStudent] = useState();
  const [compairResults, setCompairResults] = useState();
  useEffect(() => {
    (async () => {
      if (student_id) {
        let Student = await fetchData("get", "/query/students/" + student_id);
        if (Student && Student._id) setStudent(Student);
      }
    })();
  }, [student_id]);
  const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  function selectLog(log_id) {
    let i = logs.findIndex((j) => j._id === log_id);
    let fields = Object.keys(student);
    let compair = [];
    fields = fields.filter((j) => j !== "_id");
    for (let field of fields) {
      if (Array.isArray(student[field]) === false) {
        if (student[field] !== logs[i][field]) {
          compair.push({
            field: field,
            new: student[field],
            old: logs[i][field],
            type: "value",
            label: field.replace("_", " ").toUpperCase(),
          });
        }
      } else {
        let c = equals(student[field], logs[i][field]);
        if (c !== true) {
          compair.push({
            field: field,
            old: student[field],
            new: logs[i][field],
            type: "array",
            label: field.replace("_", " ").toUpperCase(),
          });
        }
      }
    }
    setCompairResults(compair);
  }
  return (
    <div>
      {student && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Grid container spacing={1}>
              <Grid
                item
                className="bold"
                style={{ fontSize: "1.7rem", paddingBottom: "15px" }}
              >
                <Icon>schedule</Icon>
              </Grid>
              <Grid
                item
                className="bold"
                style={{ fontSize: "1.5rem", paddingBottom: "15px" }}
              >
                Update History
              </Grid>
            </Grid>
            <List component={Paper}>
              {logs &&
                logs.map((log, key) => (
                  <ListItem
                    disablePadding
                    key={key}
                    sx={
                      key < logs.length - 1
                        ? { borderBottom: "1px solid #ddd" }
                        : {}
                    }
                  >
                    <ListItemButton
                      component="button"
                      onClick={() => selectLog(log._id)}
                    >
                      <ListItemText align="center">
                        <div>{log.log_show_date}</div>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
            {/* {JSON.stringify(logs)} */}
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={1}>
              <Grid
                item
                className="bold"
                style={{ fontSize: "1.7rem", paddingBottom: "15px" }}
              >
                <Icon>update</Icon>
              </Grid>
              <Grid
                item
                className="bold"
                style={{ fontSize: "1.5rem", paddingBottom: "15px" }}
              >
                Update Result
              </Grid>
            </Grid>
            {compairResults ? (
              <>
                <ShowCompair compair={compairResults} />
                <Button
                  variant="contained"
                  fullWidth
                  style={{ marginTop: "20px" }}
                >
                  Update Data
                </Button>
              </>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "#aaa",
                  paddingTop: "20px",
                }}
              >
                Select Update History First
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function ShowCompair({ compair }) {
  return (
    <div>
      {compair.map((result, key) => (
        <div key={key}>
          <div className="bold">{result.label}</div>
          {result.type === "value" ? (
            <div style={{ paddingBottom: "10px" }}>
              <div
                style={{ fontSize: ".7rem", color: "#0b0", fontWeight: "bold" }}
              >
                Current value
              </div>
              <div style={{ paddingLeft: "10px" }}>{result.new}</div>
              <div
                style={{ fontSize: ".7rem", color: "#00f", fontWeight: "bold" }}
              >
                New value
              </div>
              <div style={{ paddingLeft: "10px" }}>{result.old}</div>
            </div>
          ) : (
            <div style={{ marginBottom: "15px" }}>
              <ShowArrayTable item={result} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function ShowArrayTable({ item }) {
  const [fields, setFields] = useState();
  useEffect(() => {
    if (item) {
      let keys = Object.keys(item.new[0]);
      let fields = [];
      for (let key of keys) {
        fields.push({ name: key, label: key.replace("_", " ").toUpperCase() });
      }
      setFields(fields);
    }
  }, [item]);

  return (
    <div>
      {fields && (
        <>
          <div style={{ fontSize: ".7rem", color: "#0b0", fontWeight: "bold" }}>
            Current value
          </div>
          <ArrayTable fields={fields} rows={item.old} />
          <div style={{ fontSize: ".7rem", color: "#00f", fontWeight: "bold" }}>
            New value
          </div>
          <ArrayTable fields={fields} rows={item.new} />
        </>
      )}
    </div>
  );
}

function ArrayTable({ fields, rows }) {
  return (
    <div style={{ margin: "5px auto 5px" }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {fields.map((field, key) => (
                <TableCell key={key}>{field.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => (
              <TableRow key={key}>
                {fields.map((field, key) => (
                  <TableCell key={key}>{row[field.name]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
