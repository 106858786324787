import React, { useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Dialog from "@mui/material/Dialog";
import Collapse from "@mui/material/Collapse";
import Badge from "@mui/material/Badge";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Pagebar from "../components/Pagebar";
import {
  checkPermission,
  fetchData,
  showUploadImageSrc,
  getStudentFromPickupId,
  getFullName,
} from "../js/main";
import moment from "moment";
import { DialogTitle, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
// import Conf from "../Conf";

const limit = 30;

function fetchTicket(usn, skip) {
  return new Promise(async (resolve) => {
    if (!skip) skip = 0;
    let Tickets = await fetchData("post", "/query", {
      collection: "tickets",
      match: { "accounts.account": usn },
      sort: { create_time: -1 },
      skip,
      limit,
    });
    for (let i = 0; i < Tickets.result.length; i++) {
      let opened = Tickets.result[i].accounts.findIndex(
        (j) => j.account === usn && j.open_time
      );
      Tickets.result[i].opened = opened;
    }
    resolve(Tickets);
  });
}

export default function Pickup(props) {
  const [state, setState] = useState({ skip: 0 });
  const [profile, setProfile] = useState();
  const [tickets, setTickets] = useState();
  const [dialogState, setDialogState] = useState({ open: false });

  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) {
          setProfile(props.profile);
        }
      }
    })();
  }, [props]);
  useEffect(() => {
    (async () => {
      if (profile) {
        let Tickets = await fetchTicket(profile.usn);
        setTickets(Tickets);
      }
    })();
  }, [profile]);

  async function openDialog(key) {
    let { result } = tickets;
    if (result[key].opened !== 1) {
      let data = await fetchData(
        "put",
        "/pickup/message/status/" + result[key]._id
      );
      if (data) {
        result[key] = data;
      }
      result[key].opened = 1;
    }
    setTickets({ ...tickets, result });
    setDialogState({ ...dialogState, open: true, ticket: result[key] });
  }
  async function changeTicketStatus(request_status, ticket_id) {
    let { result } = tickets;
    let key = result.findIndex((j) => j._id === ticket_id);
    if (key > -1) {
      await fetchData("put", "/pickup/message/status/" + result[key]._id, {
        request_status,
      });
      result[key].request_status = request_status;
      setTickets({ ...tickets, result });
      setDialogState({ ...dialogState, open: false });
    }
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          {tickets && (
            <div style={{ marginTop: "10px" }}>
              <Pagebar
                count={Math.floor(tickets.count / limit) + 1}
                page={state.skip / limit + 1}
                changePage={async (val) => {
                  let s = (val - 1) * limit;
                  let Tickets = await fetchTicket(profile.usn, s);
                  setTickets(Tickets);
                  setState({ ...state, skip: s });
                }}
              />
              <List component={Paper} style={{ marginTop: "10px" }}>
                {tickets.result.map((ticket, key) => (
                  <TicketItem
                    key={key}
                    ticket={ticket}
                    border={key < tickets.result.length - 1}
                    onOpen={() => openDialog(key)}
                    profile={profile}
                  />
                ))}
              </List>
            </div>
          )}
          <MailDialog
            open={dialogState.open}
            ticket={dialogState.ticket}
            onClose={() => setDialogState({ ...dialogState, open: false })}
            onChangeStatus={changeTicketStatus}
            profile={profile}
          />
        </div>
      )}
    </>
  );
}

function TicketItem({ ticket, border, onOpen, profile }) {
  function checkViewer() {
    let r = false;
    let i = ticket.viewer.findIndex((j) => j.usn === profile.usn);
    if (i > -1) r = true;
    return r;
  }
  return (
    <ListItem
      style={border === true ? { borderBottom: "1px solid #ddd" } : {}}
      button
      onClick={onOpen}
    >
      <ListItemAvatar>
        {ticket.request_status === "done" ? (
          <Icon style={{ fontSize: "4rem", color: "#0a0" }}>check_circle</Icon>
        ) : (
          <>
            {ticket.request_status === "doing" ? (
              <Icon style={{ fontSize: "4rem", color: "#ccc" }}>pending</Icon>
            ) : (
              <>
                {ticket.opened > -1 ? (
                  <Icon style={{ fontSize: "4rem", color: "#ccc" }}>
                    drafts
                  </Icon>
                ) : (
                  <Icon style={{ fontSize: "4rem", color: "#ccc" }}>mail</Icon>
                )}
              </>
            )}
          </>
        )}
      </ListItemAvatar>
      <ListItemText sx={{ paddingLeft: "20px" }}>
        <Badge color="error" variant="dot" invisible={checkViewer()}>
          <div className="bold" style={{ paddingRight: "5px" }}>
            {ticket.subject}
          </div>
        </Badge>
        <div style={{ fontSize: ".8rem", color: "#090" }}>
          {ticket.send_name}
        </div>
        <div style={{ fontSize: ".7rem", color: "#999" }}>
          {moment(ticket.create_time).format("LLLL")} ({ticket.request_system})
        </div>
      </ListItemText>
    </ListItem>
  );
}

function MailDialog({ open, profile, ticket, onClose, onChangeStatus }) {
  const [tagDialogState, setTagDialogState] = useState();
  const [state, setState] = useState();
  const [form, setForm] = useState();
  const [replies, setReplies] = useState();
  useEffect(() => {
    setTagDialogState({ open: false });
  }, []);
  useEffect(() => {
    (async () => {
      if (ticket) {
        let pickup = await fetchData("post", "/query", {
          collection: "pickup_persons",
          limit: 1,
          match: { _id: ticket.pickup_id },
        });
        if (pickup.count > 0 && pickup.result[0].photo) {
          let src = await showUploadImageSrc(pickup.result[0].photo);
          ticket.icon = (
            <div
              style={{
                backgroundImage: "url(" + src + ")",
                backgroundSize: "cover",
                borderRadius: "50%",
                width: "100px",
                height: "100px",
                border: "1px solid #ccc",
              }}
            ></div>
          );
        } else {
          ticket.icon = (
            <Icon style={{ fontSize: "6.8rem", color: "#aaa" }}>
              account_circle
            </Icon>
          );
        }
        ticket.showOpen = false;
        ticket.student = {};
        ticket.student = await getStudentFromPickupId(ticket.pickup_id);
        ticket.student.fullname = await getFullName(ticket.student);

        if (ticket.student.photo_url) {
          let src = await showUploadImageSrc(ticket.student.photo_url);
          ticket.student.icon = (
            <div
              style={{
                backgroundImage: "url(" + src + ")",
                backgroundSize: "cover",
                borderRadius: "50%",
                width: "100px",
                height: "100px",
                border: "1px solid #ccc",
              }}
            ></div>
          );
        } else {
          ticket.student.icon = (
            <Icon style={{ fontSize: "6.8rem", color: "#aaa" }}>
              account_circle
            </Icon>
          );
        }
        let replies = await fetchData(
          "get",
          "/pickup/message/reply/" + ticket._id
        );
        setReplies(replies);
        setForm({
          message: "",
          ticket_id: ticket._id,
        });
        setState(ticket);
      }
    })();
  }, [ticket]);

  function showOpenUser() {
    let showOpen = !state.showOpen;
    setState({ ...state, showOpen });
  }
  async function reply() {
    if (form.message !== "") {
      let send = JSON.parse(JSON.stringify(form));
      send.send_name = profile.display_name;
      let result = await fetchData("put", "/pickup/message/reply", send);
      let Replies = JSON.parse(JSON.stringify(replies));
      Replies.push(result);
      setReplies(Replies);
      setForm({ ...form, message: "" });
    }
  }
  return (
    <div>
      {state && (
        <Dialog open={open} maxWidth="md" fullWidth>
          {/* <DialogTitle id="alert-dialog-title">Ticket</DialogTitle> */}
          <DialogContent style={{ paddingTop: "30px" }}>
            <DialogContentText style={{ whiteSpace: "pre-line" }}>
              <Grid container>
                <>
                  <Grid item xs={2} align="center">
                    {state.icon}
                  </Grid>
                  <Grid item xs={4} style={{ paddingTop: "15px" }}>
                    <Typography component="div" className="bold">
                      {ticket.send_name}
                    </Typography>
                    <Typography
                      component="div"
                      style={{ color: "#090", fontSize: ".9rem" }}
                    >
                      {moment(ticket.create_time).format("LLLL")}
                    </Typography>
                    <Typography
                      component="div"
                      style={{ fontSize: ".9rem", color: "#999" }}
                    >
                      Phone No. : {ticket.phone_number || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} align="center">
                    {state.student.icon}
                  </Grid>
                  <Grid item xs={4} style={{ paddingTop: "15px" }}>
                    <Typography component="div" className="bold">
                      {ticket && ticket.student.fullname}
                    </Typography>
                    <Typography
                      component="div"
                      style={{ color: "#090", fontSize: ".9rem" }}
                    >
                      Group :{" "}
                      {ticket.student.current_group
                        ? ticket.student.current_group.name
                        : ""}
                      <span
                        style={{
                          color: "#aaa",
                          fontSize: ".7rem",
                          paddingLeft: "5px",
                        }}
                      >
                        {ticket.student.program}
                      </span>
                    </Typography>
                    <Typography
                      component="div"
                      style={{ fontSize: ".9rem", color: "#999" }}
                    >
                      Account : {ticket.student.account}
                    </Typography>
                  </Grid>
                </>
                <Grid item xs={12}>
                  <DialogBox
                    post={{
                      postName: ticket.send_name,
                      postDate: ticket.create_time,
                      message: ticket.message,
                      attachment: state.file,
                    }}
                  />
                  {replies &&
                    replies.map((reply) => (
                      <DialogBox
                        post={{
                          postName: reply.send_name,
                          postDate: reply.create_time,
                          message: reply.message,
                        }}
                        key={reply._id}
                      />
                    ))}

                  <TextField
                    label={"Reply (" + profile.display_name + ")"}
                    multiline
                    fullWidth
                    row={2}
                    value={form.message || ""}
                    onChange={(e) =>
                      setForm({ ...form, message: e.target.value })
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography component="div" style={{ marginTop: "10px" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={state.request_status}
                        onChange={(e, value) => {
                          setState({ ...state, request_status: value });
                          onChangeStatus(value, state._id);
                        }}
                      >
                        <FormControlLabel
                          value="todo"
                          control={<Radio />}
                          label={<div>To do</div>}
                        />
                        <FormControlLabel
                          value="doing"
                          control={<Radio />}
                          label={<div>Doing</div>}
                        />
                        <FormControlLabel
                          value="done"
                          control={<Radio />}
                          label={<div>Done</div>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                  <Typography
                    component="div"
                    style={{ fontSize: ".8rem", color: "#999" }}
                  >
                    <Button
                      onClick={showOpenUser}
                      style={{ fontSize: ".7rem", padding: "0px" }}
                    >
                      Show Opened User
                    </Button>
                    <Collapse in={state.showOpen}>
                      {ticket.accounts
                        .filter((j) => j.open_time)
                        .map((acc) => (
                          <div key={acc.account}>
                            {acc.account} (
                            {moment(acc.open_time).format("LLLL")})
                          </div>
                        ))}
                    </Collapse>
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={6}>
                <Button
                  onClick={() =>
                    setTagDialogState({ ...tagDialogState, open: true })
                  }
                >
                  Tag Another
                </Button>
              </Grid>
              <Grid item xs={6} align="right">
                <Button onClick={reply}>Reply Message</Button>
                <Button
                  onClick={() => {
                    onClose();
                    setState(null);
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      {tagDialogState && ticket && (
        <TagDialog
          open={tagDialogState.open}
          ticket_id={ticket._id}
          onClose={() => setTagDialogState({ ...tagDialogState, open: false })}
        />
      )}
    </div>
  );
}

function DialogBox({ post }) {
  return (
    <div
      style={{
        margin: "15px auto 15px",
        padding: "10px 15px",
        color: "#222",
        paddingBottom: "15px",
        border: "1px solid #ccc",
        borderRadius: "3px",
      }}
    >
      <div style={{ marginBottom: "5px", fontSize: ".9rem" }}>
        <span style={{ fontWeight: 500 }}>{post.postName}</span>
        <span style={{ marginLeft: "10px", color: "#999" }}>
          {moment(post.postDate).format("LLLL")}
        </span>
      </div>
      {post.message}

      {post.attachment && (
        <Stack direction="row" spacing={1} style={{ marginTop: "20px" }}>
          <Icon style={{ fontSize: "1.1rem" }}>attach_file</Icon>
          <Button
            sx={{ textTransform: "none" }}
            component="a"
            style={{ padding: "0px 0px", fontSize: ".7rem" }}
            target="_blank"
            href={post.attachment.file_path + "&method=img"}
          >
            {post.attachment.file_name || "Download"}
          </Button>
        </Stack>
      )}
    </div>
  );
}

function TagDialog({ open, ticket_id, onClose }) {
  const [form, setForm] = useState();
  const [accounts, setAccounts] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    (async () => {
      let result = await fetchData("post", "/query", {
        collection: "users",
        select: "fullname usn",
      });
      result.result = await result.result.map((user) => {
        user.label = user.usn;
        return user;
      });
      setAllUsers(result.result);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (ticket_id) {
        let result = await fetchData("post", "/query", {
          collection: "tickets",
          match: { _id: ticket_id },
          select: "accounts",
        });
        console.log(result.result[0].accounts);
        if (result.count > 0) {
          setForm(null);
          setAccounts(result.result[0].accounts);
        }
      }
    })();
  }, [ticket_id]);

  async function onTagAccount(usn) {
    let i = accounts.findIndex((j) => j.account === usn);
    if (i < 0) {
      console.log("/ticket/" + ticket_id + "/account");
      let result = await fetchData("put", "/ticket/" + ticket_id + "/account", {
        account: usn,
      });
      console.log(result);
      let Accounts = JSON.parse(JSON.stringify(accounts));
      Accounts.push({ account: usn });
      setAccounts(Accounts);
      setForm(null);
    }
  }
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>{"Tag Another"}</DialogTitle>
        <DialogContent sx={{ minWidth: "350px" }}>
          <Autocomplete
            disablePortal
            options={allUsers}
            value={form && form.label ? form.label : ""}
            size="small"
            sx={{ background: "#fff" }}
            onChange={(event, newValue) => {
              setForm(newValue);
            }}
            onKeyUp={(e) => e.key === "Enter" && form && onTagAccount(form.usn)}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder="Account" />
            )}
          />
          <Typography
            component="div"
            sx={{ padding: "10px 5px", color: "#333", fontWeight: 300 }}
          >
            {accounts.map((account) => (
              <Typography component="div" key={account.account}>
                {account.account}
              </Typography>
            ))}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
