import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Summary from "./rptPickup/Summary";
import Student from "./rptPickup/Student";
import { checkPermission, fetchData, getFullName } from "../js/main";
import { Box } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function Blank(props) {
  const [profile, setProfile] = useState();
  const [detail, setDetail] = useState();
  const [tabNo, setTabNo] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    (async () => {
      if (props.profile && props.page) {
        let permission = await checkPermission(props.profile, props.page.path);
        if (permission === true) setProfile(props.profile);
      }
    })();
  }, [props]);

  async function showDetail(match, type) {
    let pickups = {};
    if (type === "assign") {
      let result = await fetchData("post", "/query", {
        collection: "pickup_assigns",
        populate: {
          path: "student_id",
          select: "prefix first_name last_name program",
        },
        match,
        sort: { stamp_time: 1 },
        limit: 0,
      });
      console.log(result.result[0]);
      pickups = {
        result: [],
      };
      pickups.result = await result.result.map((pickup) => {
        return {
          request_stamp_time: pickup.stamp_time,
          student_fullname: getFullName(pickup.student_id),
          curator_fullname: pickup.fullname,
          program: pickup.student_id.program,
          group_name: pickup.group_name,
        };
      });
    } else {
      pickups = await fetchData("post", "/query", {
        collection: "pickups",
        match,
        sort: { request_stamp_time: 1 },
        limit: 0,
      });
    }
    console.log(pickups);
    setDetail(pickups);
    setShowDialog(true);
  }
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />

          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              margin: "10px auto 20px",
            }}
          >
            <Tabs value={tabNo} onChange={(val, val2) => setTabNo(val2)}>
              <Tab component="div" label="Summary" />
              <Tab component="div" label="Usage" />
            </Tabs>
          </Box>
          {{ 0: <Summary onShowDetail={showDetail} />, 1: <Student /> }[tabNo]}
          {/* {detail && <DetailTable detail={detail} />} */}
          {detail && (
            <DetailDialog
              open={showDialog}
              onClose={() => setShowDialog(false)}
              detail={detail}
            />
          )}
        </div>
      )}
    </>
  );
}

function DetailDialog({ open, onClose, detail }) {
  return (
    <div>
      <Dialog open={open} maxWidth="xl">
        <DialogTitle>Pickup Detail</DialogTitle>
        <DialogContent>
          <DetailTable detail={detail} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function DetailTable({ detail }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: "60px" }}>
                No
              </TableCell>
              <TableCell align="center" sx={{ width: "150px" }}>
                Request Time
              </TableCell>
              <TableCell align="center" sx={{ width: "150px" }}>
                Confirm Time
              </TableCell>
              <TableCell align="center" sx={{ width: "250px" }}>
                Student Name
              </TableCell>
              <TableCell align="center" sx={{ width: "250px" }}>
                Pickup Name
              </TableCell>
              <TableCell align="center" sx={{ width: "80px" }}>
                Group
              </TableCell>
              <TableCell align="center" sx={{ width: "80px" }}>
                Program
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detail.result.map((pickup, key) => (
              <TableRow key={key} hover>
                <TableCell align="center">{key + 1}</TableCell>
                <TableCell align="center">
                  {moment(pickup.request_stamp_time).format(
                    "DD MMM YYYY, HH:mm"
                  )}
                </TableCell>
                <TableCell align="center">
                  {pickup.pickup_stamp_time &&
                    moment(pickup.pickup_stamp_time).format(
                      "DD MMM YYYY, HH:mm"
                    )}
                </TableCell>
                <TableCell>{pickup.student_fullname}</TableCell>
                <TableCell>{pickup.curator_fullname}</TableCell>
                <TableCell align="center">{pickup.group_name}</TableCell>
                <TableCell align="center">
                  {pickup.program && pickup.program.split(" ")[0]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
