import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import moment from "moment";
import {
  fetchData,
  getGroups,
  getGroupsStudents,
  groupBy,
} from "../../js/main";

export default function Student() {
  const [groups, setGroups] = useState();
  const [filter, setFilter] = useState();
  const [students, setStudents] = useState();
  const [pickups, setPickups] = useState();
  const [assigns, setAssigns] = useState();
  const [dateList, setDateList] = useState([]);
  useEffect(() => {
    (async () => {
      let groups = await getGroups();
      groups = groups.groups;
      let dt = new Date();
      let fromDate = new Date();
      fromDate.setDate(dt.getDate() - 7);
      setFilter({
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(dt).format("YYYY-MM-DD"),
        group_id: groups[0]._id,
      });
      setGroups(groups);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (filter) {
        let students = await getGroupsStudents(filter.group_id);
        setStudents(students);
        let ids = await students.result.map((j) => j._id);
        let pickups = await fetchData("post", "/query", {
          collection: "pickups",
          match: {
            $and: [
              { student_id: { $in: ids } },
              { request_date: { $gte: filter.fromDate } },
              { request_date: { $lte: filter.toDate } },
            ],
          },
          limit: 0,
          select: "student_id request_date pickup_date",
          sort: { request_date: 1 },
        });
        let dateList = await groupBy(pickups.result, "request_date");
        let assigns = await fetchData("post", "/query", {
          collection: "pickup_assigns",
          match: {
            $and: [
              { student_id: { $in: ids } },
              { pickup_date: { $gte: filter.fromDate } },
              { pickup_date: { $lte: filter.toDate } },
            ],
          },
        });
        setAssigns(assigns.result);
        setPickups(pickups.result);
        setDateList(dateList);
      }
    })();
  }, [filter]);

  return (
    <div>
      {filter && (
        <FilterForm
          filter={filter}
          changeFilter={(field, val) => setFilter({ ...filter, [field]: val })}
          groups={groups}
        />
      )}
      {students && (
        <GroupTable
          students={students}
          dateList={dateList}
          pickups={pickups}
          assigns={assigns}
        />
      )}
    </div>
  );
}

function FilterForm({ filter, changeFilter, groups }) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Group</InputLabel>
              <Select
                value={filter.group_id}
                label="Group"
                onChange={(e) => changeFilter("group_id", e.target.value)}
                native
              >
                {groups.map((group, key) => (
                  <option value={group._id} key={key}>
                    {group.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              format="DD MMM YYYY"
              label="From Date"
              value={dayjs(filter.fromDate)}
              onChange={(val) =>
                changeFilter(
                  "fromDate",
                  moment(new Date(val)).format("YYYY-MM-DD")
                )
              }
              slotProps={{
                textField: {
                  size: "small",
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              format="DD MMM YYYY"
              label="To Date"
              value={dayjs(filter.toDate)}
              onChange={(val) => {
                changeFilter(
                  "toDate",
                  moment(new Date(val)).format("YYYY-MM-DD")
                );
              }}
              slotProps={{
                textField: {
                  size: "small",
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                },
              }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
}

const styles = { td: { fontSize: ".8rem", padding: "5px 5px" } };

function GroupTable({ students, dateList, pickups, assigns }) {
  function getTotalDay(dt) {
    let p = pickups.filter((j) => j.request_date === dt);
    let a = assigns.filter((j) => j.pickup_date === dt);
    return p.length + a.length;
  }
  return (
    <div>
      {pickups && (
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ fontSize: ".7rem", width: "60px", padding: "5px 5px" }}
                  rowSpan={2}
                >
                  No
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: ".7rem", width: "60px", padding: "5px 5px" }}
                  rowSpan={2}
                >
                  Code
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: ".7rem", width: "60px", padding: "5px 5px" }}
                  rowSpan={2}
                >
                  Program
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: ".7rem", width: "60px", padding: "5px 5px" }}
                  rowSpan={2}
                >
                  Prefix
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: ".7rem", width: "100px", padding: "5px 5px" }}
                  rowSpan={2}
                >
                  First Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: ".7rem", width: "100px", padding: "5px 5px" }}
                  rowSpan={2}
                >
                  Last Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: ".7rem", width: "70px", padding: "5px 5px" }}
                  rowSpan={2}
                >
                  Nickame
                </TableCell>
                {dateList.map((dt, key) => (
                  <TableCell
                    key={key}
                    sx={{ fontSize: ".7rem", padding: "5px 5px" }}
                  >
                    {moment(dt.request_date).format("D MMM")}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {dateList.map((dt, key) => (
                  <TableCell
                    key={key}
                    sx={{ fontSize: ".7rem", padding: "5px 5px" }}
                  >
                    {getTotalDay(dt.request_date)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.result.map((student, key) => (
                <TableRow key={key} hover>
                  <TableCell align="center" sx={styles.td}>
                    {key + 1}
                  </TableCell>
                  <TableCell align="center" sx={styles.td}>
                    {student.student_code}
                  </TableCell>
                  <TableCell align="center" sx={styles.td}>
                    {student.program && student.program.split(" ")[0]}
                  </TableCell>
                  <TableCell sx={styles.td}>{student.prefix}</TableCell>
                  <TableCell sx={styles.td}>{student.first_name}</TableCell>
                  <TableCell sx={styles.td}>{student.last_name}</TableCell>
                  <TableCell align="center" sx={styles.td}>
                    {student.nickname}
                  </TableCell>
                  {dateList.map((dt, key) => (
                    <ReportCell
                      key={key}
                      data={{
                        pickups: pickups.filter(
                          (j) =>
                            j.student_id === student._id &&
                            j.request_date === dt.request_date
                        ),
                        assign: assigns.filter(
                          (j) =>
                            j.student_id === student._id &&
                            j.pickup_date === dt.request_date
                        ),
                      }}
                    />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

function ReportCell({ data }) {
  const [state, setState] = useState();
  useEffect(() => {
    let { assign, pickups } = data;
    let value = "";
    let style = {};
    if (assign && assign[0]) {
      value = "A";
      style = { background: "#fcc", fontWeight: "bold" };
    } else if (pickups && pickups[0]) {
      let pickup = pickups[0];
      if (pickup.pickup_date) {
        style = { background: "#ffe599", fontWeight: "bold" };
        value = "C";
      } else if (pickup.request_date) {
        style = { background: "#cfc", fontWeight: "bold" };
        value = "R";
      }
    }
    let state = { value, style };
    setState(state);
  }, [data]);

  return (
    <>
      {state && (
        <TableCell align="center" sx={{ ...styles.td, ...state.style }}>
          {state.value} {data.pickups[0] && `(${data.pickups.length})`}
        </TableCell>
      )}
    </>
  );
}
